import { IS_DEV } from "@/config";
import MiniSearch from "minisearch";
import { observe } from "mobx";

export type NonEmptyArray<T> = [T, ...T[]];

export class MinisearchIndex<
	TItem extends object,
	TIdKey extends keyof TItem & string,
> {
	#searchIndex: MiniSearch<TItem>;
	#disposer: () => void;
	#sourceMap: Map<TItem[TIdKey], TItem>;
	#indexedFields: NonEmptyArray<keyof TItem & string>;

	constructor({
		sourceMap,
		idKey,
		indexedFields,
	}: {
		sourceMap: Map<TItem[TIdKey], TItem>;
		idKey: TIdKey;
		indexedFields: NonEmptyArray<keyof TItem & string>;
	}) {
		this.#sourceMap = sourceMap;
		this.#indexedFields = indexedFields;
		this.#searchIndex = new MiniSearch({
			idField: idKey,
			fields: this.#indexedFields,
		});

		// Initial population of the index
		for (const item of this.#sourceMap.values()) {
			this.#searchIndex.add(item);
		}

		// Observe map changes
		this.#disposer = observe(this.#sourceMap, (change) => {
			if (change.type === "delete") {
				this.#searchIndex.discard(change.name as TIdKey);
			} else if (change.type === "update") {
				this.#searchIndex.replace(change.newValue);
			} else if (change.type === "add") {
				this.#searchIndex.add(change.newValue);
			}
		});
	}

	search = ({
		query,
		limit,
	}: {
		query: string;
		limit?: number;
	}): { id: TItem[TIdKey]; score: number }[] => {
		if (query.trim() === "") {
			return [];
		}

		const startTime = performance.now();
		const rawResults = this.#searchIndex.search(query, { prefix: true });
		const endTime = performance.now();
		if (IS_DEV) {
			console.log(`Search time for ${query}: ${endTime - startTime}ms`);
		}

		const results = rawResults.map((result) => ({
			id: result.id,
			score: result.score,
		}));

		if (limit) {
			return results.slice(0, limit);
		}

		return results;
	};

	dispose() {
		this.#disposer();
	}
}
