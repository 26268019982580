// https://github.com/mobxjs/mobx/discussions/2850#discussioncomment-497321

import {
	$mobx,
	type AnnotationsMap,
	type CreateObservableOptions,
	isObservable,
	makeObservable,
} from "mobx";

const annotationsSymbol = Symbol();
const objectPrototype = Object.prototype;

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type NoInfer<T> = [T][T extends any ? 0 : never];
type Annotations<
	// biome-ignore lint/complexity/noBannedTypes: <explanation>
	T extends Object = Object,
	U extends PropertyKey = never,
> = AnnotationsMap<T, U>;

export const makeAutoObservableAbstract = <
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	T extends object & { [annotationsSymbol]?: any },
	AdditionalKeys extends PropertyKey = never,
>(
	target: T,
	overrides?: Annotations<T, NoInfer<AdditionalKeys>>,
	options?: CreateObservableOptions,
): T => {
	// Make sure nobody called makeObservable/makeAutoObservable/extendObservable/makeSimpleAutoObservable previously (eg in parent constructor)
	if (isObservable(target)) {
		throw new Error("Target must not be observable");
	}

	let annotations = target[annotationsSymbol];

	if (!annotations) {
		annotations = {} as Annotations;

		let current = target;
		while (current && current !== objectPrototype) {
			// biome-ignore lint/complexity/noForEach: <explanation>
			Reflect.ownKeys(current).forEach((key) => {
				if (key === $mobx || key === "constructor") return;
				// biome-ignore lint/style/noNonNullAssertion: <explanation>
				annotations![key] = !overrides
					? true
					: key in overrides
						? overrides[key as keyof typeof overrides]
						: true;
			});

			current = Object.getPrototypeOf(current);
		}

		// Cache if class
		const proto = Object.getPrototypeOf(target);
		if (proto && proto !== objectPrototype) {
			Object.defineProperty(proto, annotationsSymbol, { value: annotations });
		}
	} else {
		// Apply only annotations existed in target already
		// https://github.com/mobxjs/mobx/discussions/2850#discussioncomment-1396837
		// biome-ignore lint/complexity/noBannedTypes: <explanation>
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		const tmp = {} as Annotations<Object, any>;
		for (const key in target) {
			if (annotations[key]) {
				tmp[key] = annotations[key];
			}
		}
		annotations = tmp;
	}

	return makeObservable(target, annotations, options);
};
