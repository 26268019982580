import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { createPageId, createWriteId } from "@/lib/id-generators";
import { OptimisticAction, type Transaction } from "@/lib/sync/action-executor";
import { ElectricOptimisticMap } from "@/lib/sync/optimistic-map";
import { createPageRoute, updatePageRoute } from "@api/fastAPI";
import type {
	PageId,
	PageResource,
	PageResourceUpdate,
	ResourceRef,
	UserId,
} from "@api/schemas";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { type Result, err, ok } from "neverthrow";

function createPageResource(name: string, creatorId: UserId): PageResource {
	const pageId = createPageId();
	const writeId = createWriteId();
	const createdAt = new Date().toISOString();
	return {
		page_id: pageId,
		name,
		creator_id: creatorId,
		write_id: writeId,
		created_at: createdAt,
		updated_at: createdAt,
		deleted_at: null,
		content: "",
		is_home: false,
	};
}

export class PageStore {
	appState: AppState;
	map: ElectricOptimisticMap<PageResource, "page_id", "write_id">;
	// This cache lets use turn getChildResourceLinks into a synchronous
	// function. Ideally, it would live on a Page class object... The first
	// element of the value is the page's content; if the current page's
	// content matches it, we can return the cached children. Otherwise, we
	// need to re-parse the page's content to get the child resource links.
	private pageChildrenCache: Map<PageId, [string, ResourceRef[]]>;

	constructor(appState: AppState) {
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/pages`,
			idKey: "page_id",
			writeIdKey: "write_id",
			shapeHash: openapiHashes.PageResource,
		});
		this.pageChildrenCache = observable.map();
		makeAutoObservable(this);
	}

	get homePageId(): Result<PageId, Error> {
		for (const page of this.map.values()) {
			if (page.is_home) {
				return ok(page.page_id);
			}
		}
		return err(new Error("Home page not found"));
	}

	get homePage(): Result<PageResource, Error> {
		return this.homePageId.andThen((pageId) =>
			this.map.get(pageId).map((page) => page),
		);
	}

	/**
	 * Parses the page content for all <a> elements that match ResourceLinks and returns their ResourceRefs.
	 * ResourceRefs are cached at the store level and will update as tab heads load.
	 *
	 * ResourceLinks start with `/`.
	 */
	getChildResourceLinks = (pageId: PageId): Result<ResourceRef[], Error> => {
		return this.map.get(pageId).map((page) => {
			const cacheValue = this.pageChildrenCache.get(pageId);
			if (cacheValue && cacheValue[0] === page.content) {
				return cacheValue[1];
			}

			// Use DOMParser to parse the HTML content
			const parser = new DOMParser();
			const doc = parser.parseFromString(page.content, "text/html");

			// Select all <a> elements with href starting with "/"
			const anchorElements = Array.from(doc.querySelectorAll('a[href^="/"]'));

			if (anchorElements.length === 0) {
				return [];
			}

			// Get all hrefs
			const hrefs = anchorElements
				.map((a) => a.getAttribute("href"))
				.filter((href): href is string => href !== null);

			// Load tab heads in the background and update the cache as they load
			Promise.all(
				hrefs.map((path) => this.appState.tabStore.getTabHead(path)),
			).then((heads) => {
				const children = heads
					.map((head) => head.resourceRef)
					.filter((ref): ref is ResourceRef => ref !== undefined);
				runInAction(() => {
					this.pageChildrenCache.set(pageId, [page.content, children]);
				});
			});
			return [];
		});
	};

	createPage = (props: CreatePageAction["args"]) => {
		this.appState.actionQueue.run(
			new CreatePageAction({
				...props,
			}),
		);
	};

	updatePage = (props: UpdatePageAction["args"]) => {
		this.appState.actionQueue.run(
			new UpdatePageAction({
				...props,
			}),
		);
	};
}

export class CreatePageAction extends OptimisticAction<
	AppState,
	{
		onLocalSuccess: (newPage: PageResource) => void;
	},
	{
		insert: PageResource;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		insert: PageResource;
	}> {
		const newPageResource = createPageResource("New Page", state.userId);
		state.workspace.pages.map.insert(tx, newPageResource);
		this.args.onLocalSuccess(newPageResource);
		return { insert: newPageResource };
	}

	async remote(context: {
		localResult: { insert: PageResource };
	}): Promise<void> {
		await createPageRoute({
			insert: context.localResult.insert,
		});
	}
}

export class UpdatePageAction extends OptimisticAction<
	AppState,
	{
		pageId: PageId;
		name?: string;
		content?: string;
	},
	{
		update: PageResourceUpdate;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{ update: PageResourceUpdate }> {
		// Prevent the home page's name from being changed
		// TODO(John): fix result check
		const homePageId = state.workspace.pages.homePageId;
		if (
			homePageId.isOk() &&
			this.args.pageId === homePageId.value &&
			this.args.name !== undefined
		) {
			throw new Error("Cannot update home page name");
		}
		const update: PageResourceUpdate = {
			page_id: this.args.pageId,
			name: this.args.name,
			content: this.args.content,
			write_id: createWriteId(),
			updated_at: new Date().toISOString(),
		};
		state.workspace.pages.map.update(tx, this.args.pageId, update);
		return { update };
	}

	async remote(context: {
		localResult: { update: PageResourceUpdate };
	}): Promise<void> {
		await updatePageRoute({
			update: context.localResult.update,
		});
	}
}
