/**
 * Hooks for each db store.
 */

import { useAppContext } from "@/contexts/app-context/use-app-context";

export function useUploadsStore() {
	const appContext = useAppContext();
	return appContext.workspace.uploads;
}

export function useWebpagesStore() {
	const appContext = useAppContext();
	return appContext.workspace.webpages;
}

export function useFeedChannelsStore() {
	const appContext = useAppContext();
	return appContext.workspace.feedChannels;
}

export function useFeedItemsStore() {
	const appContext = useAppContext();
	return appContext.workspace.feedItems;
}

export function useTablesStore() {
	const appContext = useAppContext();
	return appContext.workspace.tables;
}

export function useFieldsStore() {
	const appContext = useAppContext();
	return appContext.workspace.fields;
}

export function useUsersStore() {
	const appContext = useAppContext();
	return appContext.userStore;
}

export function usePageStore() {
	const appContext = useAppContext();
	return appContext.workspace.pages;
}
