import { TextEditor } from "@/components/editor";
import { Button } from "@/components/ui/button";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { EventOrStep } from "@/contexts/assistant/stores/assistant-session-store";
import { ObjectLinkComponent, ObjectLinkContent } from "@/plugins/object-link";
import type {
	CreatedAssistantSessionEvent,
	Event,
	MessageId,
	ResourceRef,
	SentMessageEvent,
	SessionAssistant,
	SessionAssistantId,
	Step,
} from "@api/schemas";
import { CaretUpDown, Desktop } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

const CROCKFORD_BASE32_CHARS = "0123456789ABCDEFGHJKMNPQRSTVWXYZ";

const TAILWIND_COLORS = {
	blue: "blue",
	green: "green",
	purple: "purple",
	pink: "pink",
	indigo: "indigo",
	red: "red",
	orange: "orange",
	gray: "gray",
	teal: "teal",
	lime: "lime",
	cyan: "cyan",
	emerald: "emerald",
	fuchsia: "fuchsia",
} as const;

/**
 * Deterministically maps a ULID to a Tailwind color class
 * @param ulid The ULID string
 * @returns A Tailwind color class
 */
type ColorType = "bg" | "border" | "text";
type ColorResult = {
	className: string; // for regular tailwind usage
	cssVariable: string; // for injected styles
};

export function getColorForSession(
	ulid: string,
	type: ColorType = "bg",
	shade = 400,
): ColorResult {
	if (!ulid || ulid === "") {
		return {
			className: `${type}-neutral-${shade}`,
			cssVariable: `var(--assistant-neutral-${shade})`,
		};
	}

	const lastChar = ulid.slice(-1).toUpperCase();
	const value = CROCKFORD_BASE32_CHARS.indexOf(lastChar);

	if (value === -1) {
		console.warn(`invalid crockford base32 char found in ulid: ${lastChar}`);
		return {
			className: `${type}-neutral-${shade}`,
			cssVariable: `var(--assistant-neutral-${shade})`,
		};
	}

	const colors = Object.values(TAILWIND_COLORS);
	const color = colors[value % colors.length];

	return {
		className: `${type}-${color}-${shade}`,
		cssVariable: `var(--assistant-${color}-${shade})`,
	};
}
// const TableCellValueComponent = observer(
// 	({ event }: { event: UpdatedCellEvent["data"] }) => {
// 		return (
// 			<div className="inline-flex w-full flex-col">
// 				<span className="mb-1 ml-1 font-mono text-neutral-500 text-xs">
// 					Cell Value
// 				</span>
// 				<ViewOnlyEditor
// 					className="border p-1"
// 					// TODO(John): other cell types
// 					// @ts-ignore
// 					content={event.table_cell_value.cell_value}
// 				/>
// 			</div>
// 		);
// 	},
// );

const SentMessageEventDataComponent = observer(
	({ event }: { event: SentMessageEvent["data"] }) => {
		return (
			<div className="inline-flex w-full flex-col">
				<span className="mb-1 ml-1 font-mono text-neutral-500 text-xs">
					Content
				</span>
				<TextEditor
					className="border bg-neutral-50 p-1 opacity-70 [&_.ProseMirror]:text-xs"
					options={{ content: event.message.content, editable: false }}
				/>
			</div>
		);
	},
);

const CreatedAssistantSessionEventDataComponent = observer(
	({ event }: { event: CreatedAssistantSessionEvent["data"] }) => {
		return (
			<div className="inline-flex w-full flex-col">
				<span className="mb-1 ml-1 font-mono text-neutral-500 text-xs">
					Goal
				</span>
				<TextEditor
					className="border p-1"
					options={{ content: event.assistant_session.goal, editable: false }}
				/>
			</div>
		);
	},
);

export const EventComponent = observer(function EventComponent({
	event,
	globalOpen,
}: {
	event: Event;
	globalOpen: boolean;
}) {
	const [open, setOpen] = useState(globalOpen);

	// Gross but fine
	useEffect(() => {
		setOpen(globalOpen);
	}, [globalOpen]);

	const renderEventData = (
		event: Event,
	): {
		header: React.ReactNode;
		content: React.ReactNode;
	} => {
		switch (event.type) {
			case "sent_message": {
				const messageId = event.data.message.message_id;
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Sent</span>
							<ObjectLinkComponent
								href={`/message/${messageId}`}
								className="min-w-0"
							>
								<ObjectLinkContent
									href={`/message/${messageId}`}
									label="Message"
								/>
							</ObjectLinkComponent>
						</>
					),
					content: <SentMessageEventDataComponent event={event.data} />,
				};
			}

			case "created_assistant_session": {
				const sessionAssistantId =
					event.data.assistant_session.session_assistant_id;
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Created</span>
							<ObjectLinkComponent
								href={`/assistant-session/${sessionAssistantId}`}
								className="min-w-0"
							>
								<ObjectLinkContent
									href={`/assistant-session/${sessionAssistantId}`}
								/>
							</ObjectLinkComponent>
						</>
					),
					content: (
						<CreatedAssistantSessionEventDataComponent event={event.data} />
					),
				};
			}

			case "opened_thread": {
				const threadId = event.data.thread_id;
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Opened</span>
							<ObjectLinkComponent
								href={`/message/${threadId}`}
								className="min-w-0"
							>
								<ObjectLinkContent href={`/message/${threadId}`} />
							</ObjectLinkComponent>
						</>
					),
					content: null,
				};
			}

			case "opened_tab": {
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Opened</span>
							<ObjectLinkComponent
								href={event.data.tab.path}
								className="min-w-0"
							>
								<ObjectLinkContent href={event.data.tab.path} />
							</ObjectLinkComponent>
						</>
					),
					content: null,
				};
			}

			case "navigated_tab": {
				return {
					header: (
						<>
							<span className="mr-1.5 flex-none">Opened</span>
							{/* TODO(John): merge path objects */}
							{/* <ObjectLinkComponent pathObject={pathObject} className="min-w-0">
								<ObjectLinkContent pathObject={pathObject} />
							</ObjectLinkComponent> */}
						</>
					),
					content: null,
				};
			}

			case "closed_tab": {
				return {
					// TODO(John): more information about this tab
					header: <span>Closed Tab</span>,
					content: null,
				};
			}

			// case "created_table": {
			// 	// TODO(John): implement
			// 	return {
			// 		header: <span>Created Table</span>,
			// 		content: null,
			// 	};
			// }

			// case "created_row": {
			// 	// TODO(John): implement
			// 	return {
			// 		header: <span>Created Row</span>,
			// 		content: null,
			// 	};
			// }

			// case "created_column": {
			// 	// TODO(John): implement
			// 	return {
			// 		header: <span>Created Column</span>,
			// 		content: null,
			// 	};
			// }

			// case "updated_cell": {
			// 	const tableId = event.data.table_id;
			// 	const pathObject: TablePathObject = {
			// 		path: "table",
			// 		resource_id: tableId,
			// 		row_id: null,
			// 	};
			// 	return {
			// 		header: (
			// 			<>
			// 				<span className="mr-1.5 flex-none">Updated</span>
			// 				<ObjectLinkComponent pathObject={pathObject} className="min-w-0">
			// 					<ObjectLinkContent pathObject={pathObject} label="Table" />
			// 				</ObjectLinkComponent>
			// 				<span className="ml-1.5 flex-none">Cell</span>
			// 			</>
			// 		),
			// 		content: <TableCellValueComponent event={event.data} />,
			// 	};
			// }

			case "created_user_session": {
				console.error("Should never happen");
				return {
					header: <span>Created User Session</span>,
					content: null,
				};
			}

			case "ended_session": {
				return {
					header: <span>Ended Session</span>,
					content: null,
				};
			}
		}
	};

	const { header, content } = renderEventData(event);
	return (
		<div className="flex flex-col gap-1">
			<Collapsible open={open} onOpenChange={setOpen}>
				{/* Header */}
				<div className="flex flex-col flex-nowrap gap-1">
					<span className="flex-none text-neutral-500 text-xs">
						{new Date(event.created_at).toLocaleString("en-US", {
							month: "numeric",
							day: "numeric",
							hour: "numeric",
							minute: "numeric",
						})}
					</span>
					<div className="flex min-w-0 grow flex-nowrap items-center text-neutral-800 text-xs">
						{header}
						{content && (
							<CollapsibleTrigger>
								<CaretUpDown size={16} />
							</CollapsibleTrigger>
						)}
					</div>
				</div>
				{content && (
					<CollapsibleContent className="flex flex-col">
						<div className="mt-2 text-xs">{content}</div>
					</CollapsibleContent>
				)}
			</Collapsible>
		</div>
	);
});

export const StepComponent = observer(
	({ step, globalOpen }: { step: Step; globalOpen: boolean }) => {
		const [open, setOpen] = useState(globalOpen);

		// Gross but fine
		useEffect(() => {
			setOpen(globalOpen);
		}, [globalOpen]);

		// const renderAction = (action: Action, index: number) => {
		// 	switch (action.type) {
		// 		case "edit_text":
		// 			return (
		// 				<div className="flex items-center gap-2" key={index}>
		// 					<Keyboard size={16} weight="fill" />
		// 					<ViewOnlyEditor
		// 						className="border p-1"
		// 						content={action.replacement}
		// 					/>
		// 				</div>
		// 			);
		// 		case "click":
		// 			return (
		// 				<div className="flex items-center gap-2" key={index}>
		// 					<MouseSimple size={16} weight="fill" />
		// 					<span>Click</span>
		// 				</div>
		// 			);
		// 	}
		// };
		return (
			<div className="flex flex-col gap-2">
				<Collapsible open={open} onOpenChange={setOpen}>
					<div className="flex flex-col gap-1">
						<span className="text-neutral-500 text-xs">
							{new Date(step.created_at).toLocaleString("en-US", {
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
							})}
						</span>
						<div className="flex items-center gap-1 text-neutral-800 text-xs">
							<span>Thinking</span>
							<CollapsibleTrigger>
								<CaretUpDown size={16} />
							</CollapsibleTrigger>
						</div>
					</div>

					<CollapsibleContent className="flex flex-col">
						<TextEditor
							className="mt-2 border bg-neutral-50 p-1 opacity-70 [&_.ProseMirror]:text-xs"
							options={{ content: step.thinking, editable: false }}
						/>
					</CollapsibleContent>
				</Collapsible>
				{/* {step.actions.map((action, index) => renderAction(action, index))} */}
			</div>
		);
	},
);

export const StatusDot = ({
	active,
}: {
	active: boolean;
}) => (
	<div
		className={clsx(
			"h-2 w-2 shrink-0 rounded-full",
			active ? "bg-green-500" : "border border-neutral-300 bg-white",
		)}
	/>
);

export const AssistantPresenceIndicator = observer(
	({
		sessionAssistantId,
		active,
	}: { sessionAssistantId: SessionAssistantId | null; active: boolean }) => {
		const backgroundColor = sessionAssistantId
			? getColorForSession(sessionAssistantId).className
			: "bg-neutral-400";
		const textColor = sessionAssistantId
			? getColorForSession(sessionAssistantId, "text").className
			: "text-neutral-400";

		return (
			<div
				className={clsx(
					"relative flex h-5 w-5 shrink-0 items-center justify-center rounded-sm",
					active ? `${backgroundColor} animate-pulse` : "",
				)}
			>
				<Desktop
					size={14}
					weight="bold"
					className={clsx(active ? "text-white" : textColor)}
				/>
				<div className="-right-0.5 absolute bottom-0">
					<StatusDot active={active} />
				</div>
			</div>
		);
	},
);

export const SessionPreview = observer(
	({ assistantSession }: { assistantSession: SessionAssistant }) => {
		const appContext = useAppContext();
		return (
			<Button
				variant="ghost"
				className="flex h-fit w-full items-start justify-start gap-3 rounded-none p-2"
				onClick={() => {
					appContext.rightSidebarState.activityViewerActiveSessionAssistantId =
						assistantSession.session_assistant_id;
					appContext.rightSidebarState.rightSidebarTab = "assistant_activity";
				}}
			>
				<AssistantPresenceIndicator
					sessionAssistantId={assistantSession.session_assistant_id}
					active={appContext.assistantSessionStore.sessionIsActive(
						assistantSession.session_assistant_id,
					)}
				/>
				<div className="flex min-w-0 flex-col items-start gap-0.5 truncate">
					<span className="font-medium text-neutral-500 text-xs">
						{new Date(assistantSession.started_at).toLocaleString("en-US", {
							month: "numeric",
							day: "numeric",
							hour: "numeric",
							minute: "numeric",
						})}
					</span>
					<TextEditor
						className="min-w-0 truncate text-start [&_.ProseMirror]:text-xs"
						options={{ content: assistantSession?.goal, editable: false }}
					/>
				</div>
			</Button>
		);
	},
);

interface SessionPreviewWithEventsProps {
	assistantSession: SessionAssistant;
	eventsOrSteps: EventOrStep[];
}

export const SessionPreviewWithEvents = observer(
	function SessionPreviewWithEvents({
		assistantSession,
		eventsOrSteps,
	}: SessionPreviewWithEventsProps) {
		const appContext = useAppContext();

		return (
			<Collapsible className="border border-neutral-200" defaultOpen={true}>
				<Button
					variant="ghost"
					className="flex h-fit w-full items-start justify-start gap-3 rounded-none border-neutral-100 border-b p-2"
					onClick={() => {
						appContext.rightSidebarState.activityViewerActiveSessionAssistantId =
							assistantSession.session_assistant_id;
					}}
				>
					<CollapsibleTrigger
						asChild
						onClick={(e) => {
							e.stopPropagation();
						}}
						className=""
					>
						<AssistantPresenceIndicator
							sessionAssistantId={assistantSession.session_assistant_id}
							active={appContext.assistantSessionStore.sessionIsActive(
								assistantSession.session_assistant_id,
							)}
						/>
					</CollapsibleTrigger>
					<div className="flex flex-col items-start gap-1">
						<span className="font-medium text-neutral-500 text-xs">
							{new Date(assistantSession.started_at).toLocaleString("en-US", {
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
							})}
						</span>
						<TextEditor
							className="text-start [&_.ProseMirror]:text-xs"
							options={{ content: assistantSession?.goal, editable: false }}
						/>
					</div>
				</Button>
				<CollapsibleContent className="flex flex-col gap-2 bg-neutral-50 py-2 pr-2 pl-4 text-neutral-500 text-xs">
					{eventsOrSteps.length === 0 ? (
						"No events"
					) : (
						<>
							{eventsOrSteps.length - 3 > 0 && (
								<span className="text-neutral-400 text-xs">
									{eventsOrSteps.length - 3} more events
								</span>
							)}

							{eventsOrSteps.slice(-3).map((eventOrStep) => {
								if (eventOrStep.type === "event") {
									return (
										<EventComponent
											key={eventOrStep.data.event_id}
											event={eventOrStep.data}
											globalOpen={false}
										/>
									);
								}
								return (
									<StepComponent
										key={eventOrStep.data.step_id}
										step={eventOrStep.data}
										globalOpen={false}
									/>
								);
							})}
						</>
					)}
				</CollapsibleContent>
			</Collapsible>
		);
	},
);

export const GlobalAssistantsPresenceIndicator = observer(() => {
	const appContext = useAppContext();
	return (
		<BaseAssistantsPresenceIndicator
			filters={{
				resourceFilterValue: null,
				threadIdFilterValue: null,
				searchQuery: null,
			}}
			onClickHandler={() => {
				appContext.rightSidebarState.rightSidebarTab = "assistant_activity";
			}}
		/>
	);
});

export const ThreadAssistantsPresenceIndicator = observer(
	({ messageId }: { messageId: MessageId }) => {
		const appContext = useAppContext();
		return (
			<BaseAssistantsPresenceIndicator
				filters={{
					threadIdFilterValue: messageId,
					resourceFilterValue: null,
					searchQuery: null,
				}}
				onClickHandler={() => {
					appContext.rightSidebarState.setFilterToThread(messageId);
				}}
			/>
		);
	},
);

export const ResourceAssistantsPresenceIndicator = observer(
	({ resourceRef }: { resourceRef: ResourceRef }) => {
		const appContext = useAppContext();
		return (
			<BaseAssistantsPresenceIndicator
				filters={{
					resourceFilterValue: resourceRef,
					threadIdFilterValue: null,
					searchQuery: null,
				}}
				onClickHandler={() => {
					appContext.rightSidebarState.setFilterToResource(resourceRef);
				}}
				hideIfNoSessions={true}
			/>
		);
	},
);

const BaseAssistantsPresenceIndicator = observer(
	({
		filters,
		onClickHandler,
		hideIfNoSessions,
	}: {
		filters: {
			resourceFilterValue: ResourceRef | null;
			threadIdFilterValue: MessageId | null;
			searchQuery: string | null;
		};
		onClickHandler: () => void;
		hideIfNoSessions?: boolean;
	}) => {
		const appContext = useAppContext();

		const sessionsInsideResource =
			appContext.assistantSessionStore.enrichedAssistantSessionsWithFilters(
				filters,
			);

		if (sessionsInsideResource.size === 0) {
			return null;
		}

		const activeSessions = Array.from(sessionsInsideResource.values()).filter(
			(assistantSession) => assistantSession.sessionAssistant.ended_at === null,
		);

		// Show only events from past 24 hours
		// Limit to 3 most recent for now
		const recentlyCompletedSessions = Array.from(
			sessionsInsideResource.values(),
		)
			.filter(
				(assistantSession) =>
					assistantSession.sessionAssistant.ended_at !== null,
			)
			.filter(
				(assistantSession) =>
					assistantSession.sessionAssistant.ended_at &&
					new Date(assistantSession.sessionAssistant.ended_at).getTime() >
						new Date().getTime() - 24 * 60 * 60 * 1000,
			)
			.slice(0, 3);

		if (
			hideIfNoSessions &&
			activeSessions.length === 0 &&
			recentlyCompletedSessions.length === 0
		) {
			return null;
		}

		return (
			<Popover>
				<PopoverTrigger
					onClick={(e) => {
						e.stopPropagation();
					}}
					className="flex shrink-0 cursor-pointer items-center rounded p-[1px] hover:bg-neutral-200"
				>
					{/**
					 * TODO(Tae): I'm not sure what the design I want here is
					 * At the moment, I show the presence indicator for the first active session
					 * */}
					<AssistantPresenceIndicator
						sessionAssistantId={
							activeSessions.length > 0
								? activeSessions[0].sessionAssistant.session_assistant_id
								: null
						}
						active={activeSessions.length > 0}
					/>
					{/** Only show if there are multiple active sessions */}
					{activeSessions.length > 1 ? (
						<div className="flex h-5 items-center justify-center rounded px-1">
							<span className="font-medium text-neutral-600 text-xs">
								{activeSessions.length}
							</span>
						</div>
					) : (
						<></>
					)}
					{/* </button> */}
				</PopoverTrigger>
				<PopoverContent
					className="p-0"
					// The presence indicator is used within resource nodes
					// and we don't want to open the resource when clicking in the presence indicator
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className="flex flex-col">
						<div className="flex items-center justify-between gap-1 border-neutral-100 border-b p-2 text-left font-semibold text-neutral-800 text-xs">
							<div className="flex items-center gap-1">
								<StatusDot active={true} />
								<span className="font-semibold text-neutral-800 text-xs">
									Active
								</span>
							</div>
							<Button
								variant="ghost"
								type="button"
								className="h-auto min-h-0 min-w-0 px-2 py-1 text-neutral-500 text-xs"
								onClick={onClickHandler}
							>
								Go to Activity
							</Button>
						</div>
						<div className="mt-0 flex flex-col">
							{activeSessions.length ? (
								activeSessions.map((assistantSession) => {
									return (
										<SessionPreview
											key={
												assistantSession.sessionAssistant.session_assistant_id
											}
											assistantSession={assistantSession.sessionAssistant}
										/>
									);
								})
							) : (
								<div className="border-neutral-100 border-b bg-neutral-50 p-4 text-neutral-500 text-xs">
									No active assistants.
								</div>
							)}
						</div>
					</div>
					<div className="flex flex-col">
						<div className="flex items-center gap-1 border-neutral-100 border-b p-2 text-left font-semibold text-neutral-800 text-xs">
							<StatusDot active={false} />
							<span>Recently Completed</span>
						</div>
						<div className="mt-0 flex flex-col">
							{recentlyCompletedSessions.map((assistantSession) => (
								<SessionPreview
									key={assistantSession.sessionAssistant.session_assistant_id}
									assistantSession={assistantSession.sessionAssistant}
								/>
							))}
						</div>
					</div>
				</PopoverContent>
			</Popover>
		);
	},
);
