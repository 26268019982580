import { Cell } from "@/components/table/cell";
import { FieldColumnHeader } from "@/components/table/field-column-header";
import { TableComponent } from "@/components/table/table-component";
import { useViewCreatorState } from "@/pages/tabs/tables/-components/computed-table-creator/use-view-creator-state";
import { SELECT_VIEW_PRIMARY_COLUMN } from "@/pages/tabs/tables/-components/computed-table-creator/view-creator-state";
import type { QueryResultRowsItem } from "@api/schemas";
import { createColumnHelper } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";

const columnHelper = createColumnHelper<QueryResultRowsItem>();

export const ViewPreview = observer(() => {
	const viewCreatorState = useViewCreatorState();

	if (!viewCreatorState) return null;
	const queryResult = viewCreatorState.queryResult;

	if (queryResult === null) {
		return "No results";
	}

	const columns = queryResult.columns.map((queryColumn) => {
		return columnHelper.display({
			id: queryColumn.name,
			header: ({ column }) => (
				<FieldColumnHeader
					column={column}
					name={queryColumn.name}
					fieldType="view"
					dataType={queryColumn.data_type}
					isPrimary={
						viewCreatorState.viewType === "select"
							? queryColumn.name === SELECT_VIEW_PRIMARY_COLUMN
							: false
					}
				/>
			),
			cell: observer((props) => {
				return (
					<Cell
						dataType={queryColumn.data_type}
						value={props.row.original[queryColumn.name]}
						editable={false}
					/>
				);
			}),
		});
	});

	return <TableComponent columns={columns} data={queryResult.rows} />;
});
