import type { AppState } from "@/contexts/app-context/app-context";
import {
	type AssistantStatus,
	type Event,
	type EventId,
	EventType,
	type MessageId,
	type ResourceRef,
	type SessionAssistant,
	type SessionAssistantEndedAt,
	type SessionAssistantId,
	type SessionId,
	type Step,
	type StepId,
	type TabId,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";

export interface ActiveSessionWithStatus {
	assistantSession: SessionAssistant;
	status: AssistantStatus;
}

export type EventOrStep =
	| { type: "event"; data: Event }
	| { type: "step"; data: Step };

export class AssistantSessionStore {
	appState: AppState;

	assistantSessions: Map<SessionAssistantId, SessionAssistant> = new Map();
	// TODO(Tae): Remove
	activeAssistantSessionStatuses: Map<SessionAssistantId, AssistantStatus> =
		new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	// Mutations
	createSessionAssistantLocally = (assistantSession: SessionAssistant) => {
		this.assistantSessions.set(
			assistantSession.session_assistant_id,
			assistantSession,
		);
	};

	// Queries
	getAssistantSessionById(
		sessionAssistantId: SessionAssistantId,
	): SessionAssistant | null {
		return this.assistantSessions.get(sessionAssistantId) ?? null;
	}

	getTabs(
		sessionAssistantId: SessionAssistantId,
		asOf?: Date,
	): Map<
		TabId,
		{
			path: string;
			openedAt: Date;
			closedAt: Date | null;
		}
	> {
		/**
		 * Gets open (and closed) tabs for a session by replaying tab events.
		 *
		 * Process:
		 * 1. queries all tab-related events (open/close/navigate) before as_of
		 * 2. replays events chronologically to build current tab state
		 * 3. handles:
		 *    - OpenedTabEvent: adds new tab
		 *    - NavigatedTabEvent: updates tab path
		 *    - ClosedTabEvent: removes tab
		 */

		// Get all tab-related events before as_of
		const events =
			this.appState.eventStore.getEventsForAssistantSessionIdBefore(
				sessionAssistantId,
				asOf,
			);

		// Replay events chronologically to build current tab state
		const tabs: Map<
			TabId,
			{
				path: string;
				openedAt: Date;
				closedAt: Date | null;
			}
		> = new Map();
		for (const event of events) {
			switch (event.type) {
				case EventType.opened_tab: {
					const openedTab = event.data.tab;
					if (!openedTab.tab_id) {
						continue;
					}
					tabs.set(openedTab.tab_id, {
						path: openedTab.path,
						openedAt: new Date(event.created_at),
						closedAt: null,
					});
					break;
				}
				case EventType.navigated_tab: {
					const navigatedTabId = event.data.tab_id;
					if (!navigatedTabId) {
						continue;
					}
					tabs.set(navigatedTabId, {
						path: event.data.path,
						openedAt: new Date(event.created_at),
						closedAt: null,
					});
					break;
				}
				case EventType.closed_tab: {
					const closedTabId = event.data.tab_id;
					if (!closedTabId) {
						continue;
					}
					const tab = tabs.get(closedTabId);
					if (!tab) {
						continue;
					}
					tabs.set(closedTabId, {
						...tab,
						closedAt: new Date(event.created_at),
					});
					break;
				}
			}
		}
		return tabs;
	}

	getAggregate(sessionAssistantId: SessionAssistantId): {
		sessionAssistant: SessionAssistant | null;
		tabs: Map<
			TabId,
			{
				path: string;
				openedAt: Date;
				closedAt: Date | null;
			}
		>;
	} {
		/**
		 * Gets core session state by combining session assistant, (not) open thread (unlike server), and open tabs.
		 *
		 * This mirrors the logic of the server-side `getAggregate` function.
		 * In the client, we use this to display the
		 */
		const asOf = new Date();
		const sessionAssistant = this.getAssistantSessionById(sessionAssistantId);
		if (!sessionAssistant) {
			return {
				sessionAssistant: null,
				tabs: new Map(),
			};
		}
		const tabs = this.getTabs(sessionAssistantId, asOf);
		return {
			sessionAssistant,
			tabs,
		};
	}

	get _activeSessions(): SessionAssistant[] {
		return Array.from(this.assistantSessions.values()).filter(
			(session) => !session.ended_at,
		);
	}

	// TODO(Tae): Remove
	get activeSessionsWithStatus(): ActiveSessionWithStatus[] {
		return Array.from(this.activeAssistantSessionStatuses.entries()).map(
			([sessionAssistantId, status]) => {
				const assistantSession = this.assistantSessions.get(sessionAssistantId);
				if (!assistantSession) {
					throw new Error("Assistant session not found");
				}
				return {
					assistantSession,
					status,
				};
			},
		);
	}

	sessionIsActive(sessionAssistantId: SessionAssistantId): boolean {
		return this._activeSessions.some(
			(session) => session.session_assistant_id === sessionAssistantId,
		);
	}

	setSessionAssistantEndedAt(sessionAssistantId: SessionAssistantId) {
		const sessionAssistant = this.getAssistantSessionById(sessionAssistantId);
		if (!sessionAssistant) {
			return;
		}

		this.assistantSessions.set(sessionAssistantId, {
			...sessionAssistant,
			ended_at: new Date().toISOString() as SessionAssistantEndedAt,
		});
	}
	assistantSessionsWithFilters(filters: {
		searchQuery: string | null;
	}): SessionAssistant[] {
		const searchQuery = filters.searchQuery;

		if (!searchQuery) {
			return Array.from(this.assistantSessions.values());
		}
		// Right now, we only handle search query filtering
		// Since resourceId and threadId filtering is handled by the event store
		return Array.from(this.assistantSessions.values()).filter((session) => {
			// Use the session goal
			const goal = session.goal.toLowerCase();
			return goal.includes(searchQuery.toLowerCase());
		});
	}

	eventsAndStepsWithFilters = computedFn(
		(filters: {
			resourceFilterValue: ResourceRef | null;
			threadIdFilterValue: MessageId | null;
			searchQuery: string | null;
		}): EventOrStep[] => {
			// Find events with matching resourceRef
			if (filters.resourceFilterValue) {
				const eventsForResource = this.appState.eventStore.eventsForResource(
					filters.resourceFilterValue,
				);
				return eventsForResource.map((event) => ({
					type: "event",
					data: event,
				})) as EventOrStep[];
			}

			// Find events with matching threadId
			if (filters.threadIdFilterValue) {
				const eventsForThreadId = this.appState.eventStore.eventsForThreadId(
					filters.threadIdFilterValue,
				);
				return eventsForThreadId.map((event) => ({
					type: "event",
					data: event,
				})) as EventOrStep[];
			}

			// For now, we don't handle search queries with events
			// Having to use all steps and events is potentially a bottleneck
			const steps = Array.from(this.appState.stepStore.steps.values()).map(
				(step) => ({
					type: "step",
					data: step,
				}),
			);
			const events = Array.from(this.appState.eventStore.events.values()).map(
				(event) => ({
					type: "event",
					data: event,
				}),
			) as EventOrStep[];

			return [...steps, ...events].sort((a, b) => {
				return (
					new Date(b.data.created_at).getTime() -
					new Date(a.data.created_at).getTime()
				);
			}) as EventOrStep[];
		},
	);

	enrichedAssistantSessionsWithFilters(filters: {
		resourceFilterValue: ResourceRef | null;
		threadIdFilterValue: MessageId | null;
		searchQuery: string | null;
	}): Map<
		SessionId,
		{
			sessionAssistant: SessionAssistant;
			eventsOrSteps: EventOrStep[];
		}
	> {
		// Find events that match the filters
		const eventOrSteps = this.eventsAndStepsWithFilters(filters);

		// Find sessions that match the filters
		const sessions = this.assistantSessionsWithFilters(filters);

		// Group events by session
		const sessionsWithEventsAndStepsSeparate = new Map<
			SessionId,
			{
				sessionAssistant: SessionAssistant;
				events: Map<EventId, Event>;
				steps: Map<StepId, Step>;
			}
		>();

		for (const eventOrStep of eventOrSteps) {
			let sessionId: SessionId | null = null;
			if (eventOrStep.type === "event") {
				const event = eventOrStep.data;
				if (!("session_id" in event.data)) {
					continue;
				}
				sessionId = event.data.session_id as SessionId;
			} else if (eventOrStep.type === "step") {
				const step = eventOrStep.data;
				sessionId = step.session_assistant_id;
			}
			if (!sessionId) {
				continue;
			}

			if (!sessionsWithEventsAndStepsSeparate.has(sessionId)) {
				// Shortcut to get intersection of sessions and events/steps
				const sessionAssistant = sessions.find(
					(session) => session.session_assistant_id === sessionId,
				);
				if (!sessionAssistant) {
					continue;
				}
				sessionsWithEventsAndStepsSeparate.set(sessionId, {
					sessionAssistant,
					events: new Map(),
					steps: new Map(),
				});
			}

			const sessionMap = sessionsWithEventsAndStepsSeparate.get(sessionId);
			if (!sessionMap) {
				continue;
			}
			if (eventOrStep.type === "event") {
				sessionMap.events.set(eventOrStep.data.event_id, eventOrStep.data);
			} else if (eventOrStep.type === "step") {
				sessionMap.steps.set(eventOrStep.data.step_id, eventOrStep.data);
			}
		}

		// Combine events and steps into a single array
		const sessionsWithEventsAndSteps = new Map<
			SessionId,
			{
				sessionAssistant: SessionAssistant;
				eventsOrSteps: EventOrStep[];
			}
		>();

		for (const [
			sessionId,
			sessionMap,
		] of sessionsWithEventsAndStepsSeparate.entries()) {
			sessionsWithEventsAndSteps.set(sessionId, {
				sessionAssistant: sessionMap.sessionAssistant,
				eventsOrSteps: [
					...(Array.from(sessionMap.events.values()).map((event) => ({
						type: "event",
						data: event,
					})) as EventOrStep[]),
					...(Array.from(sessionMap.steps.values()).map((step) => ({
						type: "step",
						data: step,
					})) as EventOrStep[]),
				].sort((a, b) => {
					return (
						new Date(b.data.created_at).getTime() -
						new Date(a.data.created_at).getTime()
					);
				}),
			});
		}

		return sessionsWithEventsAndSteps;
	}
}
