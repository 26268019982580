import { getEditorExtensions } from "@/components/editor";
import type { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import type { MessageId, MessageWithMetadata } from "@api/schemas";
import { Chat } from "@phosphor-icons/react";
import { Editor } from "@tiptap/core";
import { makeAutoObservable, runInAction } from "mobx";

export class MessageTabState implements BaseTabState {
	tab: Tab;
	// The parent message of the thread
	parentMessage: MessageWithMetadata | null;

	editor: Editor;
	attachments: string[] = [];
	editorContent: { text: string; html: string } = { text: "", html: "" };

	attachmentsQuery = "";
	showAttachmentsMenu = false;

	constructor(tab: Tab, messageId: MessageId | null) {
		this.tab = tab;
		this.tab.setState(this);

		let message: MessageWithMetadata | null = null;
		if (messageId) {
			message =
				tab.tabStore.appState.messageStore.getMessageByIdWithMetadata(
					messageId,
				);
		}
		this.parentMessage = message;

		this.editor = new Editor({
			extensions: getEditorExtensions(tab.tabStore.appState.localSearchStore),
			content: "",
			editorProps: {
				attributes: {
					class:
						"text-sm min-h-32 max-h-96 w-full p-2 overflow-y-auto outline-none bg-white",
				},
			},
			onUpdate: ({ editor }) => {
				runInAction(() => {
					this.editorContent = {
						text: editor.getText(),
						html: editor.getHTML(),
					};
				});
			},
		});

		makeAutoObservable(this);
	}

	get head() {
		return {
			icon: Chat,
			label: "Message",
		};
	}

	get attachmentResults() {
		return this.tab.tabStore.appState.localSearchStore.searchResourceObjectLink(
			this.attachmentsQuery,
		);
	}
}
