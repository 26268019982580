import { Cell } from "@/components/table/cell";
import { RenderFieldColumnIcon } from "@/components/table/field-column-header";
import { FieldCreatorPopover } from "@/components/table/field-creator-popover";
import { DeleteColumnDialogContent } from "@/components/table/field-modifier-popover";
import type { FieldTypeIconProps } from "@/components/table/field-type-indicators";
import {
	AlertDialog,
	AlertDialogContent,
	AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useTableViewContext } from "@/contexts/tables/use-table-context";
import { CaretLeft, CaretRight, X } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

const TableRecordViewHeader = observer(() => {
	const tableViewState = useTableViewContext();
	const { visibleRecordRowIndex: pageViewRowIndex } = tableViewState;

	// this is always offset by 1 from the row index
	const [rowNumberInput, setRowNumberInput] = useState(
		pageViewRowIndex ? (pageViewRowIndex + 1).toString() : "1",
	);

	const resolveRowNumberUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
		const raw = e.target.value;
		setRowNumberInput(raw); // always update the input value

		// only update number state if it's valid
		const parsed = Number.parseInt(raw);
		if (Number.isNaN(parsed)) {
			return;
		}
		// if the index is valid, update the page view row id
		// do nothing if the index is out of bounds
		if (
			parsed > 0 &&
			parsed <= tableViewState.tableState.sortedRecords.length
		) {
			tableViewState.setVisibleRecordLink(
				tableViewState.tableState.sortedRecords[parsed - 1].link,
			);
		}
	};

	return (
		<div className="flex items-center gap-1 border-neutral-100 border-b bg-neutral-50 p-1">
			<button
				type="button"
				// decrement the page view row index
				onClick={() => {
					if (pageViewRowIndex !== null && pageViewRowIndex > 0) {
						const previousRow = tableViewState.tableState.sortedRecords.at(
							pageViewRowIndex - 1,
						);
						if (previousRow) {
							tableViewState.setVisibleRecordLink(previousRow.link);
							setRowNumberInput(pageViewRowIndex.toString());
						}
					}
				}}
				// disable if first page or no page view row id
				disabled={pageViewRowIndex === 0 || pageViewRowIndex === null}
				className="text-neutral-600 disabled:text-neutral-300"
			>
				<CaretLeft className="h-4 w-4 " />
			</button>
			<span className="text-neutral-600 text-sm">
				<input
					type="number"
					value={rowNumberInput}
					className={clsx("w-8 rounded-md border px-1 py-0.5 text-center")}
					onChange={resolveRowNumberUpdate}
				/>{" "}
				of {tableViewState.tableState.sortedRecords.length} rows
			</span>
			<button
				type="button"
				onClick={() => {
					if (
						pageViewRowIndex !== null &&
						pageViewRowIndex <
							tableViewState.tableState.sortedRecords.length - 1
					) {
						const nextRow = tableViewState.tableState.sortedRecords.at(
							pageViewRowIndex + 1,
						);
						if (nextRow) {
							tableViewState.setVisibleRecordLink(nextRow.link);
							setRowNumberInput((pageViewRowIndex + 1 + 1).toString());
						}
					}
				}}
				// disable if last page or no page view row id
				disabled={
					pageViewRowIndex ===
						tableViewState.tableState.sortedRecords.length - 1 ||
					pageViewRowIndex === null
				}
				className="text-neutral-600 disabled:text-neutral-300"
			>
				<CaretRight className="h-4 w-4 " />
			</button>
		</div>
	);
});

const RecordBody = observer(() => {
	const tableViewState = useTableViewContext();
	const { visibleRecordLink: visibleRecordId, visibleRecordRowIndex } =
		tableViewState;

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		// default to the first row
		if (visibleRecordRowIndex === null) {
			if (tableViewState.tableState.sortedRecords.length > 0) {
				tableViewState.setVisibleRecordLink(
					tableViewState.tableState.sortedRecords[0].link,
				);
			}
		}
	}, [visibleRecordRowIndex, tableViewState.tableState.sortedRecords.length]);

	if (visibleRecordId === null) {
		return null;
	}
	const visibleRecord =
		tableViewState.tableState.getRecordByLink(visibleRecordId);

	const fields = tableViewState.tableState.sortedFields
		.map((sortedFields) => {
			if ("viewFields" in sortedFields) {
				return [...sortedFields.viewFields, ...sortedFields.regularFields];
			}
			return [...sortedFields.primaryFields, ...sortedFields.regularFields];
		})
		.unwrapOr([]);

	return (
		<>
			{fields.map(([field, dataType]) => {
				return (
					<div key={field.field_id} className="flex flex-col gap-4 p-4">
						<span className="flex items-center gap-2">
							<span className="flex grow items-center gap-2">
								{RenderFieldColumnIcon({
									fieldType: field.type,
									dataType,
								} as FieldTypeIconProps)}
								<span className="text-neutral-500 text-sm">{field.name}</span>
							</span>

							<AlertDialog>
								<AlertDialogTrigger
									onClick={(e) => {
										e.stopPropagation();
									}}
									asChild
								>
									<button type="button">
										<X className="h-4 w-4 text-neutral-500" />
									</button>
								</AlertDialogTrigger>
								<AlertDialogContent>
									<DeleteColumnDialogContent fieldId={field.field_id} />
								</AlertDialogContent>
							</AlertDialog>
						</span>

						<div className="flex flex-col gap-2">
							{/* <DescriptionEditor block={block} /> */}
							<div className="flex flex-row gap-1">
								{/* TODO(John): pass in the view query */}
								<Cell
									field={field}
									dataType={dataType}
									value={visibleRecord.cell_values[field.field_id]}
									editable={true}
								/>
							</div>
						</div>
					</div>
				);
			})}
			<div className="w-full p-4">
				<FieldCreatorPopover>
					<button type="button" className="text-neutral-500 text-xs">
						+ Add field
					</button>
				</FieldCreatorPopover>
			</div>
		</>
	);
});

export const TableRecordView = observer(() => {
	return (
		<div className="flex h-full flex-col">
			<TableRecordViewHeader />
			<RecordBody />
		</div>
	);
});
