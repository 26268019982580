import { TableRow, type TableRowProps } from "@/components/table/table-row";
import { useTableSensors } from "@/components/table/utils";
import { DragOverlay, useDraggable, useDroppable } from "@dnd-kit/core";
import {
	DndContext,
	type DragEndEvent,
	type DragOverEvent,
	type DragStartEvent,
	pointerWithin,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import type { Table } from "@tanstack/react-table";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

const DraggableRow = observer(
	<TData, TRowId extends string>(props: {
		rowId: TRowId;
		overRowId: TRowId | null;
		draggingRowIds: Set<TRowId> | null;
		children: React.ReactElement<TableRowProps<TData>>;
	}) => {
		const { setNodeRef: setDropNodeRef } = useDroppable({
			id: props.rowId,
		});
		const {
			attributes,
			listeners,
			setNodeRef: setDragNodeRef,
		} = useDraggable({
			id: props.rowId,
		});
		const isDragging = props.draggingRowIds?.has(props.rowId);

		return (
			<>
				{/* Marker if dragged row is hovering over this row */}
				{props.overRowId === props.rowId && (
					<div
						key={`row-drop-indicator-${props.rowId}`}
						className="z-10 h-0 w-full p-0 ring-1 ring-blue-500"
					/>
				)}
				<div
					className={clsx(isDragging && "opacity-50")}
					ref={(ref) => {
						setDragNodeRef(ref);
						setDropNodeRef(ref);
					}}
					{...attributes}
					{...listeners}
				>
					{props.children}
				</div>
			</>
		);
	},
);

export type RowDndContextProps<TData, TRowId extends string> = {
	table: Table<TData>;
	selectedRowIds: Set<TRowId>;
	moveRows: (rowIds: Set<TRowId>, precedingRowId: TRowId | null) => void;
	children: React.ReactElement<TableRowProps<TData>>[];
};

export const RowDndContext = observer(
	<TData, TRowId extends string>(props: RowDndContextProps<TData, TRowId>) => {
		const [draggedRowId, setDraggedRowId] = useState<TRowId | null>(null);
		const [overRowId, setOverRowId] = useState<TRowId | null>(null);
		const [draggingRowIds, setDraggingRowIds] = useState<Set<TRowId> | null>(
			null,
		);
		//
		// Get rows being dragged for drag overlay
		const draggingRows = useMemo(() => {
			if (!draggingRowIds) return [];
			return props.table
				.getRowModel()
				.rows.filter((row) => draggingRowIds.has(row.id as TRowId));
		}, [props.table.getRowModel, draggingRowIds]);

		function handleRowDragStart(event: DragStartEvent) {
			const activeDroppableId = event.active.id;

			const draggedId = activeDroppableId as TRowId;
			// Get selected ids from the current state
			setDraggedRowId(draggedId);

			const draggingIds = props.selectedRowIds.has(draggedId)
				? props.selectedRowIds
				: new Set([draggedId]);
			setDraggingRowIds(draggingIds);
		}

		function handleRowDragOver(event: DragOverEvent) {
			const over = event.over;
			if (!over) return;
			setOverRowId(over.id as TRowId);
		}

		function handleRowDragEnd(event: DragEndEvent) {
			const { active, over } = event;

			setDraggedRowId(null);
			setOverRowId(null);
			setDraggingRowIds(null);

			if (!over) return;

			const draggedRowId = active.id as TRowId;
			const overRowId = over.id as TRowId;
			// Get selected ids from the current state
			const draggingIds = props.selectedRowIds.has(draggedRowId)
				? props.selectedRowIds
				: new Set([draggedRowId]);

			props.moveRows(draggingIds, overRowId);
		}
		const sensors = useTableSensors();

		return (
			<DndContext
				collisionDetection={pointerWithin}
				modifiers={[restrictToVerticalAxis]}
				onDragStart={handleRowDragStart}
				onDragOver={handleRowDragOver}
				onDragEnd={handleRowDragEnd}
				sensors={sensors}
			>
				{React.Children.map(props.children, (child) => {
					if (!React.isValidElement(child) || child.type !== TableRow)
						return null;
					const row = (child.props as TableRowProps<TData>).row;
					const rowId = row.id as TRowId;
					return (
						<DraggableRow
							key={rowId}
							rowId={rowId}
							overRowId={overRowId}
							draggingRowIds={draggingRowIds}
						>
							{child}
						</DraggableRow>
					);
				})}
				{/* If dragging to the end */}
				{draggedRowId && overRowId === null && (
					<div className="z-10 h-0 w-full p-0 ring-2 ring-blue-500" />
				)}
				<DragOverlay>
					{draggedRowId && (
						<div
							className="flex flex-col border"
							style={{
								width: props.table.getCenterTotalSize(),
							}}
						>
							{draggingRows.map((row) => (
								<TableRow key={`drag-preview-${row.id}`} row={row} />
							))}
						</div>
					)}
				</DragOverlay>
			</DndContext>
		);
	},
);
