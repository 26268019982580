import { SidebarNavigationButton } from "@/components/layout/left-sidebar/sidebar-navigation-button";
import { ToggleLeftSidebarButton } from "@/components/layout/toggle-left-sidebar-button";
import { PageTree } from "@/components/tree/tree";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { cn } from "@/lib/utils";
import { SignedIn, UserButton } from "@clerk/clerk-react";
import {
	File,
	FileArrowUp,
	FileMagnifyingGlass,
	Globe,
	type Icon,
	MagnifyingGlass,
	Rss,
	Table,
} from "@phosphor-icons/react";
import type { LinkProps } from "@tanstack/react-router";
import { useMediaQuery } from "@uidotdev/usehooks";
import { observer } from "mobx-react-lite";

const Overlay = ({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}) => {
	if (!isOpen) {
		return null;
	}

	return (
		<div
			role="button"
			aria-label="Close sidebar"
			tabIndex={0}
			className="absolute top-0 right-0 bottom-0 left-0 z-40 bg-black/10 opacity-80"
			onClick={onClose}
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.key === " ") {
					onClose();
				}
			}}
		/>
	);
};

type SidebarItem = {
	icon: Icon;
	label: string;
	to: LinkProps["to"];
};

const SidebarSection = ({
	title,
	items,
	navigate,
}: {
	title: string;
	items: SidebarItem[];
	navigate: (location: LinkProps) => void;
}) => {
	return (
		<div className="flex min-h-0 w-full shrink-0 flex-col">
			<div className="flex items-center gap-2 px-2 py-1.5">
				<span className="font-semibold text-neutral-500 text-xs">{title}</span>
			</div>
			{items.map((item) => (
				<SidebarNavigationButton
					key={item.to}
					ButtonIcon={item.icon}
					label={item.label}
					location={{ to: item.to }}
					navigate={navigate}
				/>
			))}
		</div>
	);
};

export const Sidebar = observer(() => {
	const appContext = useAppContext();
	const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
	const tabsContext = useTabStore();

	const fileItems: SidebarItem[] = [
		{ icon: File, label: "Pages", to: "/pages" },
		{ icon: FileArrowUp, label: "Uploads", to: "/uploads" },
		{ icon: Globe, label: "Webpages", to: "/web" },
		{ icon: Table, label: "Tables", to: "/tables" },
	];

	const toolItems: SidebarItem[] = [
		{ icon: FileMagnifyingGlass, label: "Search", to: "/search" },
		{ icon: MagnifyingGlass, label: "Web Search", to: "/web-search" },
	];

	const integrationItems: SidebarItem[] = [
		{ icon: Rss, label: "RSS Feeds", to: "/feeds" },
	];

	return (
		<>
			{isSmallDevice && (
				<Overlay
					isOpen={appContext.leftSidebarState.showSidebar}
					onClose={() => {
						appContext.leftSidebarState.setShowSidebar(false);
					}}
				/>
			)}
			<div
				className={cn(
					"z-50 h-full w-full min-w-0 shrink-0 overflow-hidden",
					isSmallDevice
						? "absolute top-0 bottom-0 border-r shadow-xl"
						: "relative",
				)}
			>
				<nav
					className={cn(
						"group/sidebar flex h-full w-full flex-col gap-3 bg-white p-1 backdrop-blur",
					)}
				>
					{isSmallDevice && (
						<div className="flex p-1">
							<ToggleLeftSidebarButton />
						</div>
					)}

					{appContext.leftSidebarState.treeState && (
						<PageTree treeState={appContext.leftSidebarState.treeState} />
					)}

					<SidebarSection
						title="Files"
						items={fileItems}
						navigate={tabsContext.navigateFromOutsideTab}
					/>

					<SidebarSection
						title="Tools"
						items={toolItems}
						navigate={tabsContext.navigateFromOutsideTab}
					/>

					<SidebarSection
						title="Integrations"
						items={integrationItems}
						navigate={tabsContext.navigateFromOutsideTab}
					/>

					<div className="grow" />

					<SignedIn>
						<div className="flex items-center border-neutral-100 border-t bg-white p-2">
							<UserButton />
						</div>
					</SignedIn>
				</nav>
			</div>
		</>
	);
});
