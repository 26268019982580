import { TableComponent } from "@/components/table/table-component";
import { useUploadsIndex } from "@/contexts/resource-indexes/hooks";
import { UploadsHomeTabState } from "@/contexts/uploads/tab-state";
import { FileUploadDialog } from "@/pages/tabs/uploads/-components/file-upload-dialog";
import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

const UploadsHomePage = observer(function UploadsHomePage() {
	const uploadsIndex = useUploadsIndex();
	return (
		<div className="flex min-h-0 w-full grow flex-col gap-4 p-4">
			<h1 className="font-bold text-2xl">Uploads</h1>
			<div className="flex">
				<FileUploadDialog />
			</div>
			<TableComponent
				getRowId={(row) => row.link}
				columns={uploadsIndex.columns}
				data={uploadsIndex.rows}
			/>
		</div>
	);
});

export const Route = createFileRoute("/uploads/")({
	component: UploadsHomePage,
	loader: ({ context: { tab } }) => {
		const tabState = new UploadsHomeTabState(tab);
		return { tabState };
	},
});
