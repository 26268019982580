import type { PageTreeState } from "@/components/tree/tree-state";
import {} from "@/components/ui/alert-dialog";
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuSeparator,
} from "@/components/ui/context-menu";
import { Dialog } from "@/components/ui/dialog";
import { useUploadsStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { MetadataEditDialogContent } from "@/pages/tabs/uploads/-components/metadata-edit-dialog";
import type { UploadResourceRef } from "@api/schemas";
import { DownloadSimple, PencilSimple } from "@phosphor-icons/react";
import { ContextMenuTrigger } from "@radix-ui/react-context-menu";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const EditUploadMetaDialog = observer(
	({
		onOpenChange,
		upload,
	}: {
		onOpenChange: (open: boolean) => void;
		upload: UploadResourceRef;
	}) => {
		return (
			<Dialog open onOpenChange={onOpenChange}>
				<MetadataEditDialogContent uploadId={upload.resource_id} />
			</Dialog>
		);
	},
);

export const ContextMenuActions = observer(
	({
		treeState,
	}: {
		treeState: PageTreeState;
	}) => {
		const uploadsStore = useUploadsStore();
		const rightClickedNode = treeState.rightClickedNode;
		const [openDialog, setOpenDialog] = useState<{
			type: "editUploadMeta";
			props: { upload: UploadResourceRef };
		} | null>(null);
		if (!rightClickedNode) {
			return null;
		}
		const onOpenChangeHandler = (open: boolean) => {
			if (!open) {
				setOpenDialog(null);
				treeState.setRightClickedNode(null);
			}
		};
		let openDialogComponent: React.ReactNode | null = null;
		if (!openDialog) {
			openDialogComponent = null;
		} else {
			switch (openDialog.type) {
				case "editUploadMeta":
					openDialogComponent = (
						<EditUploadMetaDialog
							upload={openDialog.props.upload}
							onOpenChange={onOpenChangeHandler}
						/>
					);
					break;

				default: {
					const _exhaustiveCheck: never = openDialog.type;
					openDialogComponent = _exhaustiveCheck;
				}
			}
		}

		return (
			<ContextMenuContent>
				{openDialogComponent}
				{rightClickedNode.type === "upload" && (
					<>
						<ContextMenuItem
							onClick={() => {
								setOpenDialog({
									type: "editUploadMeta",
									props: {
										upload: rightClickedNode,
									},
								});
							}}
							className="flex items-center gap-2"
						>
							<PencilSimple weight="bold" /> Edit upload metadata
						</ContextMenuItem>
						<ContextMenuItem
							className="flex items-center gap-2"
							onClick={() => {
								uploadsStore.downloadUploadPdf(rightClickedNode.resource_id);
							}}
						>
							<DownloadSimple weight="bold" /> Processed PDF
						</ContextMenuItem>
						<ContextMenuItem
							className="flex items-center gap-2"
							onClick={() => {
								uploadsStore.downloadOriginalUploadResource(
									rightClickedNode.resource_id,
								);
							}}
						>
							<DownloadSimple weight="bold" /> Original{" "}
							{uploadsStore.getResourceById(rightClickedNode.resource_id).match(
								(upload) => upload.mimetype.toUpperCase(),
								() => {
									throw new Error("Upload not found");
								},
							)}
						</ContextMenuItem>
					</>
				)}
				<ContextMenuSeparator />
			</ContextMenuContent>
		);
	},
);

export const PageTreeContextMenuWrapper = observer(
	(props: { children: React.ReactNode; treeState: PageTreeState }) => {
		return (
			<ContextMenu>
				<ContextMenuTrigger>{props.children}</ContextMenuTrigger>
				<ContextMenuActions treeState={props.treeState} />
			</ContextMenu>
		);
	},
);
