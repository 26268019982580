import { MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";

export const ADD_COLUMN_ID = "ADD_COLUMN";
export const SELECT_COLUMN_ID = "SELECT_COLUMN";

export const useTableSensors = () =>
	useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				// Used to separate click events (e.g. for toggling header menus) from drag events
				delay: 250,
				distance: 5,
			},
		}),
		useSensor(TouchSensor, {}),
	);

/**
 * We use column IDs in CSS variables, so we need to ensure they are safe for
 * CSS.
 */
export function sanitizeForCSSVariableName(value: string) {
	// 1. Trim whitespace
	let s = value.trim();

	// 2. Replace invalid chars with '-'
	s = s.replace(/[^a-zA-Z0-9_-]/g, "-");

	// 3. If it starts with a digit or a dash, prepend an underscore
	if (/^[0-9-]/.test(s)) {
		s = `_${s}`;
	}

	// 4. Ensure not empty
	if (s === "") {
		s = "_";
	}
	return s;
}
