/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AddFieldRequest,
  AddRelationshipRequest,
  BodyCreateUploadRoute,
  BootstrapSessionResponse,
  CreateComputedTableRequest,
  CreatePageRequest,
  CreateTableRequest,
  CrossrefResponse,
  DeleteFieldRequest,
  DeletePageRequest,
  DeleteRecordsRequest,
  DeleteRelationshipRequest,
  DiffEvent,
  FeedItemId,
  Field,
  GetTableRecordsResponse,
  GetTableRecordsRouteParams,
  GoogleBooksResponse,
  GuessRssUrlParams,
  HTTPValidationError,
  IndexFeedRequest,
  IndexWebpageRequest,
  InferUploadMetadataRequest,
  InferUploadMetadataResponse,
  InsertEmptyRecordRequest,
  MessageWithMetadata,
  PageResolution,
  PreviewRssFeedParams,
  PreviewRssFeedResponse,
  QueryCrossrefRequest,
  QueryGoogleBooksRequest,
  RefetchFeedChannelParams,
  Resource,
  ResourceRef,
  RunViewDefRequest,
  RunViewDefResponse,
  SearchFull,
  SearchId,
  SearchRequest,
  SyncUserEventRequest,
  SyncUserFillTableRequest,
  TocSection,
  UpdateFieldRequest,
  UpdatePageRequest,
  UpdateRecordsRequest,
  UpdateSelectFieldPropertiesRequest,
  UpdateUploadMetadataRequest,
  UploadId,
  WatchTableParams,
  WebSearchRequest,
  WebSearchResponse,
  WebpageId,
  WorkspaceUpdate
} from './schemas'




/**
 * @summary Search Route
 */
export const searchRoute = (
    searchRequest: SearchRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchFull>> => {
    
    
    return axios.default.post(
      `/search`,
      searchRequest,options
    );
  }



export const getSearchRouteMutationOptions = <TData = Awaited<ReturnType<typeof searchRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['searchRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchRoute>>, {data: SearchRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: SearchRequest}, TContext>}

    export type SearchRouteMutationResult = NonNullable<Awaited<ReturnType<typeof searchRoute>>>
    export type SearchRouteMutationBody = SearchRequest
    export type SearchRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Route
 */
export const useSearchRoute = <TData = Awaited<ReturnType<typeof searchRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: SearchRequest},
        TContext
      > => {

      const mutationOptions = getSearchRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Search Route
 */
export const getSearchRoute = (
    searchId: SearchId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchFull>> => {
    
    
    return axios.default.get(
      `/search/${searchId}`,options
    );
  }


export const getGetSearchRouteQueryKey = (searchId: SearchId,) => {
    return [`/search/${searchId}`] as const;
    }

    
export const getGetSearchRouteQueryOptions = <TData = Awaited<ReturnType<typeof getSearchRoute>>, TError = AxiosError<HTTPValidationError>>(searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchRouteQueryKey(searchId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchRoute>>> = ({ signal }) => getSearchRoute(searchId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(searchId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetSearchRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getSearchRoute>>>
export type GetSearchRouteQueryError = AxiosError<HTTPValidationError>


export function useGetSearchRoute<TData = Awaited<ReturnType<typeof getSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSearchRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSearchRoute<TData = Awaited<ReturnType<typeof getSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSearchRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSearchRoute<TData = Awaited<ReturnType<typeof getSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Search Route
 */

export function useGetSearchRoute<TData = Awaited<ReturnType<typeof getSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetSearchRouteQueryOptions(searchId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Upload Pdf
 */
export const getUploadPdf = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/uploads/pdf/${uploadId}`,options
    );
  }


export const getGetUploadPdfQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/pdf/${uploadId}`] as const;
    }

    
export const getGetUploadPdfQueryOptions = <TData = Awaited<ReturnType<typeof getUploadPdf>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUploadPdfQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadPdf>>> = ({ signal }) => getUploadPdf(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetUploadPdfQueryResult = NonNullable<Awaited<ReturnType<typeof getUploadPdf>>>
export type GetUploadPdfQueryError = AxiosError<HTTPValidationError>


export function useGetUploadPdf<TData = Awaited<ReturnType<typeof getUploadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadPdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadPdf<TData = Awaited<ReturnType<typeof getUploadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadPdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadPdf<TData = Awaited<ReturnType<typeof getUploadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Upload Pdf
 */

export function useGetUploadPdf<TData = Awaited<ReturnType<typeof getUploadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetUploadPdfQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Download Original
 */
export const downloadOriginal = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/uploads/original/${uploadId}`,options
    );
  }


export const getDownloadOriginalQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/original/${uploadId}`] as const;
    }

    
export const getDownloadOriginalQueryOptions = <TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadOriginalQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadOriginal>>> = ({ signal }) => downloadOriginal(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type DownloadOriginalQueryResult = NonNullable<Awaited<ReturnType<typeof downloadOriginal>>>
export type DownloadOriginalQueryError = AxiosError<HTTPValidationError>


export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadOriginal>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadOriginal>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Download Original
 */

export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getDownloadOriginalQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Upload Page Image Route
 */
export const getUploadPageImageRoute = (
    uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/uploads/page_image/${uploadId}/${pageIndex}/${resolution}.jpg`,options
    );
  }


export const getGetUploadPageImageRouteQueryKey = (uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution,) => {
    return [`/uploads/page_image/${uploadId}/${pageIndex}/${resolution}.jpg`] as const;
    }

    
export const getGetUploadPageImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUploadPageImageRouteQueryKey(uploadId,pageIndex,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadPageImageRoute>>> = ({ signal }) => getUploadPageImageRoute(uploadId,pageIndex,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId && pageIndex && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetUploadPageImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getUploadPageImageRoute>>>
export type GetUploadPageImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetUploadPageImageRoute<TData = Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadPageImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadPageImageRoute<TData = Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadPageImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadPageImageRoute<TData = Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Upload Page Image Route
 */

export function useGetUploadPageImageRoute<TData = Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetUploadPageImageRouteQueryOptions(uploadId,pageIndex,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Upload Extracted Image Route
 */
export const getUploadExtractedImageRoute = (
    uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/uploads/extracted_image/${uploadId}/${pageIndex}/${indexInPage}.jpg`,options
    );
  }


export const getGetUploadExtractedImageRouteQueryKey = (uploadId: UploadId,
    pageIndex: number,
    indexInPage: number,) => {
    return [`/uploads/extracted_image/${uploadId}/${pageIndex}/${indexInPage}.jpg`] as const;
    }

    
export const getGetUploadExtractedImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUploadExtractedImageRouteQueryKey(uploadId,pageIndex,indexInPage);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>> = ({ signal }) => getUploadExtractedImageRoute(uploadId,pageIndex,indexInPage, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId && pageIndex && indexInPage), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetUploadExtractedImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>>
export type GetUploadExtractedImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetUploadExtractedImageRoute<TData = Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadExtractedImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadExtractedImageRoute<TData = Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadExtractedImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadExtractedImageRoute<TData = Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Upload Extracted Image Route
 */

export function useGetUploadExtractedImageRoute<TData = Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetUploadExtractedImageRouteQueryOptions(uploadId,pageIndex,indexInPage,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Upload Metadata Route
 */
export const updateUploadMetadataRoute = (
    updateUploadMetadataRequest: UpdateUploadMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/uploads/update_metadata`,
      updateUploadMetadataRequest,options
    );
  }



export const getUpdateUploadMetadataRouteMutationOptions = <TData = Awaited<ReturnType<typeof updateUploadMetadataRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['updateUploadMetadataRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, {data: UpdateUploadMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateUploadMetadataRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: UpdateUploadMetadataRequest}, TContext>}

    export type UpdateUploadMetadataRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateUploadMetadataRoute>>>
    export type UpdateUploadMetadataRouteMutationBody = UpdateUploadMetadataRequest
    export type UpdateUploadMetadataRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Upload Metadata Route
 */
export const useUpdateUploadMetadataRoute = <TData = Awaited<ReturnType<typeof updateUploadMetadataRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: UpdateUploadMetadataRequest},
        TContext
      > => {

      const mutationOptions = getUpdateUploadMetadataRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Cover Image Route
 */
export const getCoverImageRoute = (
    uploadId: UploadId,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/uploads/cover_image/${uploadId}/${resolution}.jpg`,options
    );
  }


export const getGetCoverImageRouteQueryKey = (uploadId: UploadId,
    resolution: PageResolution,) => {
    return [`/uploads/cover_image/${uploadId}/${resolution}.jpg`] as const;
    }

    
export const getGetCoverImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCoverImageRouteQueryKey(uploadId,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCoverImageRoute>>> = ({ signal }) => getCoverImageRoute(uploadId,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetCoverImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getCoverImageRoute>>>
export type GetCoverImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCoverImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCoverImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Cover Image Route
 */

export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetCoverImageRouteQueryOptions(uploadId,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Upload Route
 */
export const createUploadRoute = (
    bodyCreateUploadRoute: BodyCreateUploadRoute, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UploadId>> => {
    
    const formData = new FormData();
formData.append('file', bodyCreateUploadRoute.file)
formData.append('upload_id', bodyCreateUploadRoute.upload_id)
formData.append('infer_metadata', bodyCreateUploadRoute.infer_metadata.toString())

    return axios.default.post(
      `/uploads/create`,
      formData,options
    );
  }



export const getCreateUploadRouteMutationOptions = <TData = Awaited<ReturnType<typeof createUploadRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyCreateUploadRoute}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['createUploadRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUploadRoute>>, {data: BodyCreateUploadRoute}> = (props) => {
          const {data} = props ?? {};

          return  createUploadRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: BodyCreateUploadRoute}, TContext>}

    export type CreateUploadRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createUploadRoute>>>
    export type CreateUploadRouteMutationBody = BodyCreateUploadRoute
    export type CreateUploadRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Upload Route
 */
export const useCreateUploadRoute = <TData = Awaited<ReturnType<typeof createUploadRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyCreateUploadRoute}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: BodyCreateUploadRoute},
        TContext
      > => {

      const mutationOptions = getCreateUploadRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Crossref
 */
export const queryCrossref = (
    queryCrossrefRequest: QueryCrossrefRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CrossrefResponse>> => {
    
    
    return axios.default.post(
      `/uploads/query_crossref`,
      queryCrossrefRequest,options
    );
  }



export const getQueryCrossrefMutationOptions = <TData = Awaited<ReturnType<typeof queryCrossref>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: QueryCrossrefRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['queryCrossref'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queryCrossref>>, {data: QueryCrossrefRequest}> = (props) => {
          const {data} = props ?? {};

          return  queryCrossref(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: QueryCrossrefRequest}, TContext>}

    export type QueryCrossrefMutationResult = NonNullable<Awaited<ReturnType<typeof queryCrossref>>>
    export type QueryCrossrefMutationBody = QueryCrossrefRequest
    export type QueryCrossrefMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Query Crossref
 */
export const useQueryCrossref = <TData = Awaited<ReturnType<typeof queryCrossref>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: QueryCrossrefRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: QueryCrossrefRequest},
        TContext
      > => {

      const mutationOptions = getQueryCrossrefMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Google Books
 */
export const queryGoogleBooks = (
    queryGoogleBooksRequest: QueryGoogleBooksRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GoogleBooksResponse>> => {
    
    
    return axios.default.post(
      `/uploads/query_google_books`,
      queryGoogleBooksRequest,options
    );
  }



export const getQueryGoogleBooksMutationOptions = <TData = Awaited<ReturnType<typeof queryGoogleBooks>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: QueryGoogleBooksRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['queryGoogleBooks'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queryGoogleBooks>>, {data: QueryGoogleBooksRequest}> = (props) => {
          const {data} = props ?? {};

          return  queryGoogleBooks(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: QueryGoogleBooksRequest}, TContext>}

    export type QueryGoogleBooksMutationResult = NonNullable<Awaited<ReturnType<typeof queryGoogleBooks>>>
    export type QueryGoogleBooksMutationBody = QueryGoogleBooksRequest
    export type QueryGoogleBooksMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Query Google Books
 */
export const useQueryGoogleBooks = <TData = Awaited<ReturnType<typeof queryGoogleBooks>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: QueryGoogleBooksRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: QueryGoogleBooksRequest},
        TContext
      > => {

      const mutationOptions = getQueryGoogleBooksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Infer Upload Metadata
 */
export const inferUploadMetadata = (
    inferUploadMetadataRequest: InferUploadMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InferUploadMetadataResponse>> => {
    
    
    return axios.default.post(
      `/uploads/infer_metadata`,
      inferUploadMetadataRequest,options
    );
  }



export const getInferUploadMetadataMutationOptions = <TData = Awaited<ReturnType<typeof inferUploadMetadata>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: InferUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['inferUploadMetadata'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inferUploadMetadata>>, {data: InferUploadMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  inferUploadMetadata(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: InferUploadMetadataRequest}, TContext>}

    export type InferUploadMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof inferUploadMetadata>>>
    export type InferUploadMetadataMutationBody = InferUploadMetadataRequest
    export type InferUploadMetadataMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Infer Upload Metadata
 */
export const useInferUploadMetadata = <TData = Awaited<ReturnType<typeof inferUploadMetadata>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: InferUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: InferUploadMetadataRequest},
        TContext
      > => {

      const mutationOptions = getInferUploadMetadataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Upload Toc
 */
export const getUploadToc = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TocSection[]>> => {
    
    
    return axios.default.get(
      `/uploads/toc/${uploadId}`,options
    );
  }


export const getGetUploadTocQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/toc/${uploadId}`] as const;
    }

    
export const getGetUploadTocQueryOptions = <TData = Awaited<ReturnType<typeof getUploadToc>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUploadTocQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadToc>>> = ({ signal }) => getUploadToc(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetUploadTocQueryResult = NonNullable<Awaited<ReturnType<typeof getUploadToc>>>
export type GetUploadTocQueryError = AxiosError<HTTPValidationError>


export function useGetUploadToc<TData = Awaited<ReturnType<typeof getUploadToc>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadToc>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadToc<TData = Awaited<ReturnType<typeof getUploadToc>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadToc>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadToc<TData = Awaited<ReturnType<typeof getUploadToc>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Upload Toc
 */

export function useGetUploadToc<TData = Awaited<ReturnType<typeof getUploadToc>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetUploadTocQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Bootstrap Session
 */
export const bootstrapSession = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BootstrapSessionResponse>> => {
    
    
    return axios.default.get(
      `/user/bootstrap_session`,options
    );
  }


export const getBootstrapSessionQueryKey = () => {
    return [`/user/bootstrap_session`] as const;
    }

    
export const getBootstrapSessionQueryOptions = <TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBootstrapSessionQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof bootstrapSession>>> = ({ signal }) => bootstrapSession({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type BootstrapSessionQueryResult = NonNullable<Awaited<ReturnType<typeof bootstrapSession>>>
export type BootstrapSessionQueryError = AxiosError<unknown>


export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof bootstrapSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof bootstrapSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Bootstrap Session
 */

export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getBootstrapSessionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Attempts to locate an RSS feed URL from a given webpage URL by:
- Fetching the html
- Checking for <link> tags with type="application/rss+xml" or "application/atom+xml"

Returns first valid feed URL found or 404s if none found

raises:
    HTTPException(404): if no feed url could be found
 * @summary Guess Rss Url
 */
export const guessRssUrl = (
    params: GuessRssUrlParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    
    return axios.default.post(
      `/feeds/guess_rss_url`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGuessRssUrlMutationOptions = <TData = Awaited<ReturnType<typeof guessRssUrl>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{params: GuessRssUrlParams}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['guessRssUrl'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof guessRssUrl>>, {params: GuessRssUrlParams}> = (props) => {
          const {params} = props ?? {};

          return  guessRssUrl(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{params: GuessRssUrlParams}, TContext>}

    export type GuessRssUrlMutationResult = NonNullable<Awaited<ReturnType<typeof guessRssUrl>>>
    
    export type GuessRssUrlMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Guess Rss Url
 */
export const useGuessRssUrl = <TData = Awaited<ReturnType<typeof guessRssUrl>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{params: GuessRssUrlParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {params: GuessRssUrlParams},
        TContext
      > => {

      const mutationOptions = getGuessRssUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Fetches and parses an RSS feed to preview its content and check for duplicates.

args:
    rss_url: direct url to rss feed
    url: original webpage url
    auth: auth context for user permissions

returns:
    PreviewRssFeedResponse containing:
    - parsed feed preview with metadata and recent items
    - list of any existing feeds with same rss_url
 * @summary Preview Rss Feed
 */
export const previewRssFeed = (
    params: PreviewRssFeedParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PreviewRssFeedResponse>> => {
    
    
    return axios.default.post(
      `/feeds/preview_rss_feed`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPreviewRssFeedMutationOptions = <TData = Awaited<ReturnType<typeof previewRssFeed>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{params: PreviewRssFeedParams}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['previewRssFeed'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof previewRssFeed>>, {params: PreviewRssFeedParams}> = (props) => {
          const {params} = props ?? {};

          return  previewRssFeed(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{params: PreviewRssFeedParams}, TContext>}

    export type PreviewRssFeedMutationResult = NonNullable<Awaited<ReturnType<typeof previewRssFeed>>>
    
    export type PreviewRssFeedMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Preview Rss Feed
 */
export const usePreviewRssFeed = <TData = Awaited<ReturnType<typeof previewRssFeed>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{params: PreviewRssFeedParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {params: PreviewRssFeedParams},
        TContext
      > => {

      const mutationOptions = getPreviewRssFeedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Index Feed Channel Route
 */
export const indexFeedChannelRoute = (
    indexFeedRequest: IndexFeedRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<boolean>> => {
    
    
    return axios.default.post(
      `/feeds/index_feed_channel`,
      indexFeedRequest,options
    );
  }



export const getIndexFeedChannelRouteMutationOptions = <TData = Awaited<ReturnType<typeof indexFeedChannelRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: IndexFeedRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['indexFeedChannelRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof indexFeedChannelRoute>>, {data: IndexFeedRequest}> = (props) => {
          const {data} = props ?? {};

          return  indexFeedChannelRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: IndexFeedRequest}, TContext>}

    export type IndexFeedChannelRouteMutationResult = NonNullable<Awaited<ReturnType<typeof indexFeedChannelRoute>>>
    export type IndexFeedChannelRouteMutationBody = IndexFeedRequest
    export type IndexFeedChannelRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Index Feed Channel Route
 */
export const useIndexFeedChannelRoute = <TData = Awaited<ReturnType<typeof indexFeedChannelRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: IndexFeedRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: IndexFeedRequest},
        TContext
      > => {

      const mutationOptions = getIndexFeedChannelRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Refetch a feed channel by fetching the feed again and updating the feed items
 * @summary Refetch Feed Channel
 */
export const refetchFeedChannel = (
    params: RefetchFeedChannelParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/feeds/refetch_feed_channel`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRefetchFeedChannelMutationOptions = <TData = Awaited<ReturnType<typeof refetchFeedChannel>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{params: RefetchFeedChannelParams}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['refetchFeedChannel'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof refetchFeedChannel>>, {params: RefetchFeedChannelParams}> = (props) => {
          const {params} = props ?? {};

          return  refetchFeedChannel(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{params: RefetchFeedChannelParams}, TContext>}

    export type RefetchFeedChannelMutationResult = NonNullable<Awaited<ReturnType<typeof refetchFeedChannel>>>
    
    export type RefetchFeedChannelMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Refetch Feed Channel
 */
export const useRefetchFeedChannel = <TData = Awaited<ReturnType<typeof refetchFeedChannel>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{params: RefetchFeedChannelParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {params: RefetchFeedChannelParams},
        TContext
      > => {

      const mutationOptions = getRefetchFeedChannelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Feed Item Page Image Route
 */
export const getFeedItemPageImageRoute = (
    feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/feeds/page_image/${feedItemId}/${pageIndex}/${resolution}.jpg`,options
    );
  }


export const getGetFeedItemPageImageRouteQueryKey = (feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution,) => {
    return [`/feeds/page_image/${feedItemId}/${pageIndex}/${resolution}.jpg`] as const;
    }

    
export const getGetFeedItemPageImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemPageImageRouteQueryKey(feedItemId,pageIndex,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>> = ({ signal }) => getFeedItemPageImageRoute(feedItemId,pageIndex,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(feedItemId && pageIndex && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetFeedItemPageImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>>
export type GetFeedItemPageImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItemPageImageRoute<TData = Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemPageImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemPageImageRoute<TData = Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemPageImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemPageImageRoute<TData = Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Feed Item Page Image Route
 */

export function useGetFeedItemPageImageRoute<TData = Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetFeedItemPageImageRouteQueryOptions(feedItemId,pageIndex,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Feed Item Extracted Image Route
 */
export const getFeedItemExtractedImageRoute = (
    feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/feeds/extracted_image/${feedItemId}/${pageIndex}/${indexInPage}.jpg`,options
    );
  }


export const getGetFeedItemExtractedImageRouteQueryKey = (feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number,) => {
    return [`/feeds/extracted_image/${feedItemId}/${pageIndex}/${indexInPage}.jpg`] as const;
    }

    
export const getGetFeedItemExtractedImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemExtractedImageRouteQueryKey(feedItemId,pageIndex,indexInPage);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>> = ({ signal }) => getFeedItemExtractedImageRoute(feedItemId,pageIndex,indexInPage, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(feedItemId && pageIndex && indexInPage), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetFeedItemExtractedImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>>
export type GetFeedItemExtractedImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItemExtractedImageRoute<TData = Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemExtractedImageRoute<TData = Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemExtractedImageRoute<TData = Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Feed Item Extracted Image Route
 */

export function useGetFeedItemExtractedImageRoute<TData = Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetFeedItemExtractedImageRouteQueryOptions(feedItemId,pageIndex,indexInPage,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Feed Item Pdf
 */
export const getFeedItemPdf = (
    feedItemId: FeedItemId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/feeds/pdf/${feedItemId}`,options
    );
  }


export const getGetFeedItemPdfQueryKey = (feedItemId: FeedItemId,) => {
    return [`/feeds/pdf/${feedItemId}`] as const;
    }

    
export const getGetFeedItemPdfQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItemPdf>>, TError = AxiosError<HTTPValidationError>>(feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemPdfQueryKey(feedItemId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItemPdf>>> = ({ signal }) => getFeedItemPdf(feedItemId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(feedItemId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetFeedItemPdfQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItemPdf>>>
export type GetFeedItemPdfQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItemPdf<TData = Awaited<ReturnType<typeof getFeedItemPdf>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemPdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemPdf<TData = Awaited<ReturnType<typeof getFeedItemPdf>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemPdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemPdf<TData = Awaited<ReturnType<typeof getFeedItemPdf>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Feed Item Pdf
 */

export function useGetFeedItemPdf<TData = Awaited<ReturnType<typeof getFeedItemPdf>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetFeedItemPdfQueryOptions(feedItemId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Feed Item Toc
 */
export const getFeedItemToc = (
    feedItemId: FeedItemId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TocSection[]>> => {
    
    
    return axios.default.get(
      `/feeds/toc/${feedItemId}`,options
    );
  }


export const getGetFeedItemTocQueryKey = (feedItemId: FeedItemId,) => {
    return [`/feeds/toc/${feedItemId}`] as const;
    }

    
export const getGetFeedItemTocQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItemToc>>, TError = AxiosError<HTTPValidationError>>(feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemTocQueryKey(feedItemId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItemToc>>> = ({ signal }) => getFeedItemToc(feedItemId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(feedItemId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetFeedItemTocQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItemToc>>>
export type GetFeedItemTocQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItemToc<TData = Awaited<ReturnType<typeof getFeedItemToc>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemToc>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemToc<TData = Awaited<ReturnType<typeof getFeedItemToc>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemToc>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemToc<TData = Awaited<ReturnType<typeof getFeedItemToc>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Feed Item Toc
 */

export function useGetFeedItemToc<TData = Awaited<ReturnType<typeof getFeedItemToc>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetFeedItemTocQueryOptions(feedItemId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Syncs a client execution of a message action.
 * @summary Sync User Event Route
 */
export const syncUserEventRoute = (
    syncUserEventRequest: SyncUserEventRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<null>> => {
    
    
    return axios.default.post(
      `/sessions/sync_user_event`,
      syncUserEventRequest,options
    );
  }



export const getSyncUserEventRouteMutationOptions = <TData = Awaited<ReturnType<typeof syncUserEventRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SyncUserEventRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['syncUserEventRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncUserEventRoute>>, {data: SyncUserEventRequest}> = (props) => {
          const {data} = props ?? {};

          return  syncUserEventRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: SyncUserEventRequest}, TContext>}

    export type SyncUserEventRouteMutationResult = NonNullable<Awaited<ReturnType<typeof syncUserEventRoute>>>
    export type SyncUserEventRouteMutationBody = SyncUserEventRequest
    export type SyncUserEventRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Sync User Event Route
 */
export const useSyncUserEventRoute = <TData = Awaited<ReturnType<typeof syncUserEventRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SyncUserEventRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: SyncUserEventRequest},
        TContext
      > => {

      const mutationOptions = getSyncUserEventRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This route has a special case for message events that indicate a table fill.
Perhaps we could detect it somehow though.
 * @summary Sync Fill Table Action Route
 */
export const syncFillTableActionRoute = (
    syncUserFillTableRequest: SyncUserFillTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<null>> => {
    
    
    return axios.default.post(
      `/sessions/sync_fill_table_request`,
      syncUserFillTableRequest,options
    );
  }



export const getSyncFillTableActionRouteMutationOptions = <TData = Awaited<ReturnType<typeof syncFillTableActionRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SyncUserFillTableRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['syncFillTableActionRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncFillTableActionRoute>>, {data: SyncUserFillTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  syncFillTableActionRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: SyncUserFillTableRequest}, TContext>}

    export type SyncFillTableActionRouteMutationResult = NonNullable<Awaited<ReturnType<typeof syncFillTableActionRoute>>>
    export type SyncFillTableActionRouteMutationBody = SyncUserFillTableRequest
    export type SyncFillTableActionRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Sync Fill Table Action Route
 */
export const useSyncFillTableActionRoute = <TData = Awaited<ReturnType<typeof syncFillTableActionRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SyncUserFillTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: SyncUserFillTableRequest},
        TContext
      > => {

      const mutationOptions = getSyncFillTableActionRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Workspace Update
 */
export const dummyWorkspaceUpdate = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WorkspaceUpdate>> => {
    
    
    return axios.default.post(
      `/dummy/workspace_update`,undefined,options
    );
  }



export const getDummyWorkspaceUpdateMutationOptions = <TData = Awaited<ReturnType<typeof dummyWorkspaceUpdate>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['dummyWorkspaceUpdate'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyWorkspaceUpdate>>, void> = () => {
          

          return  dummyWorkspaceUpdate(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,void, TContext>}

    export type DummyWorkspaceUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof dummyWorkspaceUpdate>>>
    
    export type DummyWorkspaceUpdateMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Workspace Update
 */
export const useDummyWorkspaceUpdate = <TData = Awaited<ReturnType<typeof dummyWorkspaceUpdate>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyWorkspaceUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Message With Agent Type
 */
export const dummyMessageWithAgentType = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MessageWithMetadata>> => {
    
    
    return axios.default.post(
      `/dummy/message-with-agent-type`,undefined,options
    );
  }



export const getDummyMessageWithAgentTypeMutationOptions = <TData = Awaited<ReturnType<typeof dummyMessageWithAgentType>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['dummyMessageWithAgentType'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyMessageWithAgentType>>, void> = () => {
          

          return  dummyMessageWithAgentType(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,void, TContext>}

    export type DummyMessageWithAgentTypeMutationResult = NonNullable<Awaited<ReturnType<typeof dummyMessageWithAgentType>>>
    
    export type DummyMessageWithAgentTypeMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Message With Agent Type
 */
export const useDummyMessageWithAgentType = <TData = Awaited<ReturnType<typeof dummyMessageWithAgentType>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyMessageWithAgentTypeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Resource
 */
export const dummyResource = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Resource>> => {
    
    
    return axios.default.post(
      `/dummy/resource`,undefined,options
    );
  }



export const getDummyResourceMutationOptions = <TData = Awaited<ReturnType<typeof dummyResource>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['dummyResource'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyResource>>, void> = () => {
          

          return  dummyResource(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,void, TContext>}

    export type DummyResourceMutationResult = NonNullable<Awaited<ReturnType<typeof dummyResource>>>
    
    export type DummyResourceMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Resource
 */
export const useDummyResource = <TData = Awaited<ReturnType<typeof dummyResource>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyResourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Resource Ref
 */
export const dummyResourceRef = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ResourceRef>> => {
    
    
    return axios.default.post(
      `/dummy/resource_ref`,undefined,options
    );
  }



export const getDummyResourceRefMutationOptions = <TData = Awaited<ReturnType<typeof dummyResourceRef>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['dummyResourceRef'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyResourceRef>>, void> = () => {
          

          return  dummyResourceRef(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,void, TContext>}

    export type DummyResourceRefMutationResult = NonNullable<Awaited<ReturnType<typeof dummyResourceRef>>>
    
    export type DummyResourceRefMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Resource Ref
 */
export const useDummyResourceRef = <TData = Awaited<ReturnType<typeof dummyResourceRef>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyResourceRefMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Table Request
 */
export const dummyTableRequest = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DiffEvent>> => {
    
    
    return axios.default.post(
      `/dummy/table_events`,undefined,options
    );
  }



export const getDummyTableRequestMutationOptions = <TData = Awaited<ReturnType<typeof dummyTableRequest>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['dummyTableRequest'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyTableRequest>>, void> = () => {
          

          return  dummyTableRequest(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,void, TContext>}

    export type DummyTableRequestMutationResult = NonNullable<Awaited<ReturnType<typeof dummyTableRequest>>>
    
    export type DummyTableRequestMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Table Request
 */
export const useDummyTableRequest = <TData = Awaited<ReturnType<typeof dummyTableRequest>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyTableRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Field
 */
export const dummyField = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Field>> => {
    
    
    return axios.default.post(
      `/dummy/field`,undefined,options
    );
  }



export const getDummyFieldMutationOptions = <TData = Awaited<ReturnType<typeof dummyField>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['dummyField'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyField>>, void> = () => {
          

          return  dummyField(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,void, TContext>}

    export type DummyFieldMutationResult = NonNullable<Awaited<ReturnType<typeof dummyField>>>
    
    export type DummyFieldMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Field
 */
export const useDummyField = <TData = Awaited<ReturnType<typeof dummyField>>, TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyFieldMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Web Search Route
 */
export const webSearchRoute = (
    webSearchRequest: WebSearchRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WebSearchResponse>> => {
    
    
    return axios.default.post(
      `/web-search`,
      webSearchRequest,options
    );
  }



export const getWebSearchRouteMutationOptions = <TData = Awaited<ReturnType<typeof webSearchRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: WebSearchRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['webSearchRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof webSearchRoute>>, {data: WebSearchRequest}> = (props) => {
          const {data} = props ?? {};

          return  webSearchRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: WebSearchRequest}, TContext>}

    export type WebSearchRouteMutationResult = NonNullable<Awaited<ReturnType<typeof webSearchRoute>>>
    export type WebSearchRouteMutationBody = WebSearchRequest
    export type WebSearchRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Web Search Route
 */
export const useWebSearchRoute = <TData = Awaited<ReturnType<typeof webSearchRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: WebSearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: WebSearchRequest},
        TContext
      > => {

      const mutationOptions = getWebSearchRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Webpage Page Image Route
 */
export const getWebpagePageImageRoute = (
    webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/webpages/page_image/${webpageId}/${pageIndex}/${resolution}.jpg`,options
    );
  }


export const getGetWebpagePageImageRouteQueryKey = (webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution,) => {
    return [`/webpages/page_image/${webpageId}/${pageIndex}/${resolution}.jpg`] as const;
    }

    
export const getGetWebpagePageImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError = AxiosError<HTTPValidationError>>(webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWebpagePageImageRouteQueryKey(webpageId,pageIndex,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebpagePageImageRoute>>> = ({ signal }) => getWebpagePageImageRoute(webpageId,pageIndex,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(webpageId && pageIndex && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetWebpagePageImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getWebpagePageImageRoute>>>
export type GetWebpagePageImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetWebpagePageImageRoute<TData = Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpagePageImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpagePageImageRoute<TData = Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpagePageImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpagePageImageRoute<TData = Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Webpage Page Image Route
 */

export function useGetWebpagePageImageRoute<TData = Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetWebpagePageImageRouteQueryOptions(webpageId,pageIndex,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Webpage Extracted Image Route
 */
export const getWebpageExtractedImageRoute = (
    webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/webpages/extracted_image/${webpageId}/${pageIndex}/${indexInPage}.jpg`,options
    );
  }


export const getGetWebpageExtractedImageRouteQueryKey = (webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number,) => {
    return [`/webpages/extracted_image/${webpageId}/${pageIndex}/${indexInPage}.jpg`] as const;
    }

    
export const getGetWebpageExtractedImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWebpageExtractedImageRouteQueryKey(webpageId,pageIndex,indexInPage);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>> = ({ signal }) => getWebpageExtractedImageRoute(webpageId,pageIndex,indexInPage, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(webpageId && pageIndex && indexInPage), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetWebpageExtractedImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>>
export type GetWebpageExtractedImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetWebpageExtractedImageRoute<TData = Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpageExtractedImageRoute<TData = Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpageExtractedImageRoute<TData = Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Webpage Extracted Image Route
 */

export function useGetWebpageExtractedImageRoute<TData = Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetWebpageExtractedImageRouteQueryOptions(webpageId,pageIndex,indexInPage,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Webpage Pdf
 */
export const getWebpagePdf = (
    webpageId: WebpageId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/webpages/pdf/${webpageId}`,options
    );
  }


export const getGetWebpagePdfQueryKey = (webpageId: WebpageId,) => {
    return [`/webpages/pdf/${webpageId}`] as const;
    }

    
export const getGetWebpagePdfQueryOptions = <TData = Awaited<ReturnType<typeof getWebpagePdf>>, TError = AxiosError<HTTPValidationError>>(webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWebpagePdfQueryKey(webpageId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebpagePdf>>> = ({ signal }) => getWebpagePdf(webpageId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(webpageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetWebpagePdfQueryResult = NonNullable<Awaited<ReturnType<typeof getWebpagePdf>>>
export type GetWebpagePdfQueryError = AxiosError<HTTPValidationError>


export function useGetWebpagePdf<TData = Awaited<ReturnType<typeof getWebpagePdf>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpagePdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpagePdf<TData = Awaited<ReturnType<typeof getWebpagePdf>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpagePdf>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpagePdf<TData = Awaited<ReturnType<typeof getWebpagePdf>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Webpage Pdf
 */

export function useGetWebpagePdf<TData = Awaited<ReturnType<typeof getWebpagePdf>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetWebpagePdfQueryOptions(webpageId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Index Webpage Route
 */
export const indexWebpageRoute = (
    indexWebpageRequest: IndexWebpageRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/webpages/index_webpage`,
      indexWebpageRequest,options
    );
  }



export const getIndexWebpageRouteMutationOptions = <TData = Awaited<ReturnType<typeof indexWebpageRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: IndexWebpageRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['indexWebpageRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof indexWebpageRoute>>, {data: IndexWebpageRequest}> = (props) => {
          const {data} = props ?? {};

          return  indexWebpageRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: IndexWebpageRequest}, TContext>}

    export type IndexWebpageRouteMutationResult = NonNullable<Awaited<ReturnType<typeof indexWebpageRoute>>>
    export type IndexWebpageRouteMutationBody = IndexWebpageRequest
    export type IndexWebpageRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Index Webpage Route
 */
export const useIndexWebpageRoute = <TData = Awaited<ReturnType<typeof indexWebpageRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: IndexWebpageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: IndexWebpageRequest},
        TContext
      > => {

      const mutationOptions = getIndexWebpageRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Webpage Toc
 */
export const getWebpageToc = (
    webpageId: WebpageId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TocSection[]>> => {
    
    
    return axios.default.get(
      `/webpages/toc/${webpageId}`,options
    );
  }


export const getGetWebpageTocQueryKey = (webpageId: WebpageId,) => {
    return [`/webpages/toc/${webpageId}`] as const;
    }

    
export const getGetWebpageTocQueryOptions = <TData = Awaited<ReturnType<typeof getWebpageToc>>, TError = AxiosError<HTTPValidationError>>(webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWebpageTocQueryKey(webpageId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebpageToc>>> = ({ signal }) => getWebpageToc(webpageId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(webpageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetWebpageTocQueryResult = NonNullable<Awaited<ReturnType<typeof getWebpageToc>>>
export type GetWebpageTocQueryError = AxiosError<HTTPValidationError>


export function useGetWebpageToc<TData = Awaited<ReturnType<typeof getWebpageToc>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpageToc>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpageToc<TData = Awaited<ReturnType<typeof getWebpageToc>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpageToc>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpageToc<TData = Awaited<ReturnType<typeof getWebpageToc>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Webpage Toc
 */

export function useGetWebpageToc<TData = Awaited<ReturnType<typeof getWebpageToc>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetWebpageTocQueryOptions(webpageId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Table Route
 */
export const createTableRoute = (
    createTableRequest: CreateTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/create`,
      createTableRequest,options
    );
  }



export const getCreateTableRouteMutationOptions = <TData = Awaited<ReturnType<typeof createTableRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateTableRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['createTableRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTableRoute>>, {data: CreateTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  createTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: CreateTableRequest}, TContext>}

    export type CreateTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createTableRoute>>>
    export type CreateTableRouteMutationBody = CreateTableRequest
    export type CreateTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Table Route
 */
export const useCreateTableRoute = <TData = Awaited<ReturnType<typeof createTableRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: CreateTableRequest},
        TContext
      > => {

      const mutationOptions = getCreateTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Table Records Route
 */
export const getTableRecordsRoute = (
    params: GetTableRecordsRouteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetTableRecordsResponse>> => {
    
    
    return axios.default.get(
      `/tables/records`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetTableRecordsRouteQueryKey = (params: GetTableRecordsRouteParams,) => {
    return [`/tables/records`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTableRecordsRouteQueryOptions = <TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTableRecordsRouteQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTableRecordsRoute>>> = ({ signal }) => getTableRecordsRoute(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetTableRecordsRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getTableRecordsRoute>>>
export type GetTableRecordsRouteQueryError = AxiosError<HTTPValidationError>


export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableRecordsRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableRecordsRoute>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Table Records Route
 */

export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetTableRecordsRouteQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Watch Table
 */
export const watchTable = (
    params: WatchTableParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/tables/watch`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getWatchTableQueryKey = (params: WatchTableParams,) => {
    return [`/tables/watch`, ...(params ? [params]: [])] as const;
    }

    
export const getWatchTableQueryOptions = <TData = Awaited<ReturnType<typeof watchTable>>, TError = AxiosError<HTTPValidationError>>(params: WatchTableParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof watchTable>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWatchTableQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof watchTable>>> = ({ signal }) => watchTable(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof watchTable>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type WatchTableQueryResult = NonNullable<Awaited<ReturnType<typeof watchTable>>>
export type WatchTableQueryError = AxiosError<HTTPValidationError>


export function useWatchTable<TData = Awaited<ReturnType<typeof watchTable>>, TError = AxiosError<HTTPValidationError>>(
 params: WatchTableParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof watchTable>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof watchTable>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWatchTable<TData = Awaited<ReturnType<typeof watchTable>>, TError = AxiosError<HTTPValidationError>>(
 params: WatchTableParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof watchTable>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof watchTable>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWatchTable<TData = Awaited<ReturnType<typeof watchTable>>, TError = AxiosError<HTTPValidationError>>(
 params: WatchTableParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof watchTable>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Watch Table
 */

export function useWatchTable<TData = Awaited<ReturnType<typeof watchTable>>, TError = AxiosError<HTTPValidationError>>(
 params: WatchTableParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof watchTable>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getWatchTableQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Add Field Route
 */
export const addFieldRoute = (
    addFieldRequest: AddFieldRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/add_field`,
      addFieldRequest,options
    );
  }



export const getAddFieldRouteMutationOptions = <TData = Awaited<ReturnType<typeof addFieldRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: AddFieldRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['addFieldRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addFieldRoute>>, {data: AddFieldRequest}> = (props) => {
          const {data} = props ?? {};

          return  addFieldRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: AddFieldRequest}, TContext>}

    export type AddFieldRouteMutationResult = NonNullable<Awaited<ReturnType<typeof addFieldRoute>>>
    export type AddFieldRouteMutationBody = AddFieldRequest
    export type AddFieldRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Field Route
 */
export const useAddFieldRoute = <TData = Awaited<ReturnType<typeof addFieldRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: AddFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: AddFieldRequest},
        TContext
      > => {

      const mutationOptions = getAddFieldRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Relationship Route
 */
export const addRelationshipRoute = (
    addRelationshipRequest: AddRelationshipRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/add_relationship`,
      addRelationshipRequest,options
    );
  }



export const getAddRelationshipRouteMutationOptions = <TData = Awaited<ReturnType<typeof addRelationshipRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: AddRelationshipRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['addRelationshipRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addRelationshipRoute>>, {data: AddRelationshipRequest}> = (props) => {
          const {data} = props ?? {};

          return  addRelationshipRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: AddRelationshipRequest}, TContext>}

    export type AddRelationshipRouteMutationResult = NonNullable<Awaited<ReturnType<typeof addRelationshipRoute>>>
    export type AddRelationshipRouteMutationBody = AddRelationshipRequest
    export type AddRelationshipRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Relationship Route
 */
export const useAddRelationshipRoute = <TData = Awaited<ReturnType<typeof addRelationshipRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: AddRelationshipRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: AddRelationshipRequest},
        TContext
      > => {

      const mutationOptions = getAddRelationshipRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Field Route
 */
export const updateFieldRoute = (
    updateFieldRequest: UpdateFieldRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/update`,
      updateFieldRequest,options
    );
  }



export const getUpdateFieldRouteMutationOptions = <TData = Awaited<ReturnType<typeof updateFieldRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateFieldRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['updateFieldRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFieldRoute>>, {data: UpdateFieldRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateFieldRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: UpdateFieldRequest}, TContext>}

    export type UpdateFieldRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateFieldRoute>>>
    export type UpdateFieldRouteMutationBody = UpdateFieldRequest
    export type UpdateFieldRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Field Route
 */
export const useUpdateFieldRoute = <TData = Awaited<ReturnType<typeof updateFieldRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: UpdateFieldRequest},
        TContext
      > => {

      const mutationOptions = getUpdateFieldRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Select Field Properties Route
 */
export const updateSelectFieldPropertiesRoute = (
    updateSelectFieldPropertiesRequest: UpdateSelectFieldPropertiesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/update_select_field_properties`,
      updateSelectFieldPropertiesRequest,options
    );
  }



export const getUpdateSelectFieldPropertiesRouteMutationOptions = <TData = Awaited<ReturnType<typeof updateSelectFieldPropertiesRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateSelectFieldPropertiesRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['updateSelectFieldPropertiesRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSelectFieldPropertiesRoute>>, {data: UpdateSelectFieldPropertiesRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateSelectFieldPropertiesRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: UpdateSelectFieldPropertiesRequest}, TContext>}

    export type UpdateSelectFieldPropertiesRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateSelectFieldPropertiesRoute>>>
    export type UpdateSelectFieldPropertiesRouteMutationBody = UpdateSelectFieldPropertiesRequest
    export type UpdateSelectFieldPropertiesRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Select Field Properties Route
 */
export const useUpdateSelectFieldPropertiesRoute = <TData = Awaited<ReturnType<typeof updateSelectFieldPropertiesRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateSelectFieldPropertiesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: UpdateSelectFieldPropertiesRequest},
        TContext
      > => {

      const mutationOptions = getUpdateSelectFieldPropertiesRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Field Route
 */
export const deleteFieldRoute = (
    deleteFieldRequest: DeleteFieldRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/delete`,
      deleteFieldRequest,options
    );
  }



export const getDeleteFieldRouteMutationOptions = <TData = Awaited<ReturnType<typeof deleteFieldRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: DeleteFieldRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['deleteFieldRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFieldRoute>>, {data: DeleteFieldRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteFieldRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: DeleteFieldRequest}, TContext>}

    export type DeleteFieldRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFieldRoute>>>
    export type DeleteFieldRouteMutationBody = DeleteFieldRequest
    export type DeleteFieldRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Field Route
 */
export const useDeleteFieldRoute = <TData = Awaited<ReturnType<typeof deleteFieldRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: DeleteFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: DeleteFieldRequest},
        TContext
      > => {

      const mutationOptions = getDeleteFieldRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Relationship Route
 */
export const deleteRelationshipRoute = (
    deleteRelationshipRequest: DeleteRelationshipRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/delete_relationship`,
      deleteRelationshipRequest,options
    );
  }



export const getDeleteRelationshipRouteMutationOptions = <TData = Awaited<ReturnType<typeof deleteRelationshipRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: DeleteRelationshipRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['deleteRelationshipRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRelationshipRoute>>, {data: DeleteRelationshipRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteRelationshipRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: DeleteRelationshipRequest}, TContext>}

    export type DeleteRelationshipRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRelationshipRoute>>>
    export type DeleteRelationshipRouteMutationBody = DeleteRelationshipRequest
    export type DeleteRelationshipRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Relationship Route
 */
export const useDeleteRelationshipRoute = <TData = Awaited<ReturnType<typeof deleteRelationshipRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: DeleteRelationshipRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: DeleteRelationshipRequest},
        TContext
      > => {

      const mutationOptions = getDeleteRelationshipRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Insert Empty Record Route
 */
export const insertEmptyRecordRoute = (
    insertEmptyRecordRequest: InsertEmptyRecordRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/records/insert_empty`,
      insertEmptyRecordRequest,options
    );
  }



export const getInsertEmptyRecordRouteMutationOptions = <TData = Awaited<ReturnType<typeof insertEmptyRecordRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: InsertEmptyRecordRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['insertEmptyRecordRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof insertEmptyRecordRoute>>, {data: InsertEmptyRecordRequest}> = (props) => {
          const {data} = props ?? {};

          return  insertEmptyRecordRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: InsertEmptyRecordRequest}, TContext>}

    export type InsertEmptyRecordRouteMutationResult = NonNullable<Awaited<ReturnType<typeof insertEmptyRecordRoute>>>
    export type InsertEmptyRecordRouteMutationBody = InsertEmptyRecordRequest
    export type InsertEmptyRecordRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Insert Empty Record Route
 */
export const useInsertEmptyRecordRoute = <TData = Awaited<ReturnType<typeof insertEmptyRecordRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: InsertEmptyRecordRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: InsertEmptyRecordRequest},
        TContext
      > => {

      const mutationOptions = getInsertEmptyRecordRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Records Route
 */
export const updateRecordsRoute = (
    updateRecordsRequest: UpdateRecordsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/records/update_records`,
      updateRecordsRequest,options
    );
  }



export const getUpdateRecordsRouteMutationOptions = <TData = Awaited<ReturnType<typeof updateRecordsRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['updateRecordsRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRecordsRoute>>, {data: UpdateRecordsRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateRecordsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: UpdateRecordsRequest}, TContext>}

    export type UpdateRecordsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateRecordsRoute>>>
    export type UpdateRecordsRouteMutationBody = UpdateRecordsRequest
    export type UpdateRecordsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Records Route
 */
export const useUpdateRecordsRoute = <TData = Awaited<ReturnType<typeof updateRecordsRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: UpdateRecordsRequest},
        TContext
      > => {

      const mutationOptions = getUpdateRecordsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Records Route
 */
export const deleteRecordsRoute = (
    deleteRecordsRequest: DeleteRecordsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/records/delete`,
      deleteRecordsRequest,options
    );
  }



export const getDeleteRecordsRouteMutationOptions = <TData = Awaited<ReturnType<typeof deleteRecordsRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: DeleteRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['deleteRecordsRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRecordsRoute>>, {data: DeleteRecordsRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteRecordsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: DeleteRecordsRequest}, TContext>}

    export type DeleteRecordsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRecordsRoute>>>
    export type DeleteRecordsRouteMutationBody = DeleteRecordsRequest
    export type DeleteRecordsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Records Route
 */
export const useDeleteRecordsRoute = <TData = Awaited<ReturnType<typeof deleteRecordsRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: DeleteRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: DeleteRecordsRequest},
        TContext
      > => {

      const mutationOptions = getDeleteRecordsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Run View Def Route
 */
export const runViewDefRoute = (
    runViewDefRequest: RunViewDefRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RunViewDefResponse>> => {
    
    
    return axios.default.post(
      `/tables/views/run_view_def`,
      runViewDefRequest,options
    );
  }



export const getRunViewDefRouteMutationOptions = <TData = Awaited<ReturnType<typeof runViewDefRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: RunViewDefRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['runViewDefRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof runViewDefRoute>>, {data: RunViewDefRequest}> = (props) => {
          const {data} = props ?? {};

          return  runViewDefRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: RunViewDefRequest}, TContext>}

    export type RunViewDefRouteMutationResult = NonNullable<Awaited<ReturnType<typeof runViewDefRoute>>>
    export type RunViewDefRouteMutationBody = RunViewDefRequest
    export type RunViewDefRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Run View Def Route
 */
export const useRunViewDefRoute = <TData = Awaited<ReturnType<typeof runViewDefRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: RunViewDefRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: RunViewDefRequest},
        TContext
      > => {

      const mutationOptions = getRunViewDefRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Computed Table Route
 */
export const createComputedTableRoute = (
    createComputedTableRequest: CreateComputedTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/views/create_computed_table`,
      createComputedTableRequest,options
    );
  }



export const getCreateComputedTableRouteMutationOptions = <TData = Awaited<ReturnType<typeof createComputedTableRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['createComputedTableRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createComputedTableRoute>>, {data: CreateComputedTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  createComputedTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: CreateComputedTableRequest}, TContext>}

    export type CreateComputedTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createComputedTableRoute>>>
    export type CreateComputedTableRouteMutationBody = CreateComputedTableRequest
    export type CreateComputedTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Computed Table Route
 */
export const useCreateComputedTableRoute = <TData = Awaited<ReturnType<typeof createComputedTableRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: CreateComputedTableRequest},
        TContext
      > => {

      const mutationOptions = getCreateComputedTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Uploads
 */
export const uploads = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/uploads`,options
    );
  }


export const getUploadsQueryKey = () => {
    return [`/shapes/uploads`] as const;
    }

    
export const getUploadsQueryOptions = <TData = Awaited<ReturnType<typeof uploads>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUploadsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof uploads>>> = ({ signal }) => uploads({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type UploadsQueryResult = NonNullable<Awaited<ReturnType<typeof uploads>>>
export type UploadsQueryError = AxiosError<unknown>


export function useUploads<TData = Awaited<ReturnType<typeof uploads>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof uploads>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useUploads<TData = Awaited<ReturnType<typeof uploads>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof uploads>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useUploads<TData = Awaited<ReturnType<typeof uploads>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Uploads
 */

export function useUploads<TData = Awaited<ReturnType<typeof uploads>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getUploadsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Feed Channels
 */
export const feedChannels = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/feed_channels`,options
    );
  }


export const getFeedChannelsQueryKey = () => {
    return [`/shapes/feed_channels`] as const;
    }

    
export const getFeedChannelsQueryOptions = <TData = Awaited<ReturnType<typeof feedChannels>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFeedChannelsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof feedChannels>>> = ({ signal }) => feedChannels({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type FeedChannelsQueryResult = NonNullable<Awaited<ReturnType<typeof feedChannels>>>
export type FeedChannelsQueryError = AxiosError<unknown>


export function useFeedChannels<TData = Awaited<ReturnType<typeof feedChannels>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof feedChannels>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFeedChannels<TData = Awaited<ReturnType<typeof feedChannels>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof feedChannels>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFeedChannels<TData = Awaited<ReturnType<typeof feedChannels>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Feed Channels
 */

export function useFeedChannels<TData = Awaited<ReturnType<typeof feedChannels>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getFeedChannelsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Feed Items
 */
export const feedItems = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/feed_items`,options
    );
  }


export const getFeedItemsQueryKey = () => {
    return [`/shapes/feed_items`] as const;
    }

    
export const getFeedItemsQueryOptions = <TData = Awaited<ReturnType<typeof feedItems>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFeedItemsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof feedItems>>> = ({ signal }) => feedItems({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type FeedItemsQueryResult = NonNullable<Awaited<ReturnType<typeof feedItems>>>
export type FeedItemsQueryError = AxiosError<unknown>


export function useFeedItems<TData = Awaited<ReturnType<typeof feedItems>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof feedItems>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFeedItems<TData = Awaited<ReturnType<typeof feedItems>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof feedItems>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFeedItems<TData = Awaited<ReturnType<typeof feedItems>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Feed Items
 */

export function useFeedItems<TData = Awaited<ReturnType<typeof feedItems>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getFeedItemsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Tables
 */
export const tables = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/tables`,options
    );
  }


export const getTablesQueryKey = () => {
    return [`/shapes/tables`] as const;
    }

    
export const getTablesQueryOptions = <TData = Awaited<ReturnType<typeof tables>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTablesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tables>>> = ({ signal }) => tables({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TablesQueryResult = NonNullable<Awaited<ReturnType<typeof tables>>>
export type TablesQueryError = AxiosError<unknown>


export function useTables<TData = Awaited<ReturnType<typeof tables>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tables>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useTables<TData = Awaited<ReturnType<typeof tables>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tables>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useTables<TData = Awaited<ReturnType<typeof tables>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Tables
 */

export function useTables<TData = Awaited<ReturnType<typeof tables>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getTablesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Fields
 */
export const fields = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/fields`,options
    );
  }


export const getFieldsQueryKey = () => {
    return [`/shapes/fields`] as const;
    }

    
export const getFieldsQueryOptions = <TData = Awaited<ReturnType<typeof fields>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFieldsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fields>>> = ({ signal }) => fields({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type FieldsQueryResult = NonNullable<Awaited<ReturnType<typeof fields>>>
export type FieldsQueryError = AxiosError<unknown>


export function useFields<TData = Awaited<ReturnType<typeof fields>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof fields>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFields<TData = Awaited<ReturnType<typeof fields>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof fields>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFields<TData = Awaited<ReturnType<typeof fields>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Fields
 */

export function useFields<TData = Awaited<ReturnType<typeof fields>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getFieldsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Users
 */
export const users = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/users`,options
    );
  }


export const getUsersQueryKey = () => {
    return [`/shapes/users`] as const;
    }

    
export const getUsersQueryOptions = <TData = Awaited<ReturnType<typeof users>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof users>>> = ({ signal }) => users({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type UsersQueryResult = NonNullable<Awaited<ReturnType<typeof users>>>
export type UsersQueryError = AxiosError<unknown>


export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof users>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof users>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Users
 */

export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getUsersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Search Results
 */
export const searchResults = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/search_results`,options
    );
  }


export const getSearchResultsQueryKey = () => {
    return [`/shapes/search_results`] as const;
    }

    
export const getSearchResultsQueryOptions = <TData = Awaited<ReturnType<typeof searchResults>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchResultsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchResults>>> = ({ signal }) => searchResults({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type SearchResultsQueryResult = NonNullable<Awaited<ReturnType<typeof searchResults>>>
export type SearchResultsQueryError = AxiosError<unknown>


export function useSearchResults<TData = Awaited<ReturnType<typeof searchResults>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchResults>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useSearchResults<TData = Awaited<ReturnType<typeof searchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchResults>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useSearchResults<TData = Awaited<ReturnType<typeof searchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Search Results
 */

export function useSearchResults<TData = Awaited<ReturnType<typeof searchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getSearchResultsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Web Search Results
 */
export const webSearchResults = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/web_search_results`,options
    );
  }


export const getWebSearchResultsQueryKey = () => {
    return [`/shapes/web_search_results`] as const;
    }

    
export const getWebSearchResultsQueryOptions = <TData = Awaited<ReturnType<typeof webSearchResults>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWebSearchResultsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof webSearchResults>>> = ({ signal }) => webSearchResults({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type WebSearchResultsQueryResult = NonNullable<Awaited<ReturnType<typeof webSearchResults>>>
export type WebSearchResultsQueryError = AxiosError<unknown>


export function useWebSearchResults<TData = Awaited<ReturnType<typeof webSearchResults>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof webSearchResults>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWebSearchResults<TData = Awaited<ReturnType<typeof webSearchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof webSearchResults>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWebSearchResults<TData = Awaited<ReturnType<typeof webSearchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Web Search Results
 */

export function useWebSearchResults<TData = Awaited<ReturnType<typeof webSearchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getWebSearchResultsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Webpages
 */
export const webpages = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/webpages`,options
    );
  }


export const getWebpagesQueryKey = () => {
    return [`/shapes/webpages`] as const;
    }

    
export const getWebpagesQueryOptions = <TData = Awaited<ReturnType<typeof webpages>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWebpagesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof webpages>>> = ({ signal }) => webpages({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type WebpagesQueryResult = NonNullable<Awaited<ReturnType<typeof webpages>>>
export type WebpagesQueryError = AxiosError<unknown>


export function useWebpages<TData = Awaited<ReturnType<typeof webpages>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof webpages>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWebpages<TData = Awaited<ReturnType<typeof webpages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof webpages>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWebpages<TData = Awaited<ReturnType<typeof webpages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Webpages
 */

export function useWebpages<TData = Awaited<ReturnType<typeof webpages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getWebpagesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Pages
 */
export const pages = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/pages`,options
    );
  }


export const getPagesQueryKey = () => {
    return [`/shapes/pages`] as const;
    }

    
export const getPagesQueryOptions = <TData = Awaited<ReturnType<typeof pages>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPagesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pages>>> = ({ signal }) => pages({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type PagesQueryResult = NonNullable<Awaited<ReturnType<typeof pages>>>
export type PagesQueryError = AxiosError<unknown>


export function usePages<TData = Awaited<ReturnType<typeof pages>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePages<TData = Awaited<ReturnType<typeof pages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePages<TData = Awaited<ReturnType<typeof pages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Pages
 */

export function usePages<TData = Awaited<ReturnType<typeof pages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getPagesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Page Route
 */
export const createPageRoute = (
    createPageRequest: CreatePageRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/pages/create`,
      createPageRequest,options
    );
  }



export const getCreatePageRouteMutationOptions = <TData = Awaited<ReturnType<typeof createPageRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreatePageRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['createPageRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPageRoute>>, {data: CreatePageRequest}> = (props) => {
          const {data} = props ?? {};

          return  createPageRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: CreatePageRequest}, TContext>}

    export type CreatePageRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createPageRoute>>>
    export type CreatePageRouteMutationBody = CreatePageRequest
    export type CreatePageRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Page Route
 */
export const useCreatePageRoute = <TData = Awaited<ReturnType<typeof createPageRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreatePageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: CreatePageRequest},
        TContext
      > => {

      const mutationOptions = getCreatePageRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Page Route
 */
export const updatePageRoute = (
    updatePageRequest: UpdatePageRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.put(
      `/pages/update`,
      updatePageRequest,options
    );
  }



export const getUpdatePageRouteMutationOptions = <TData = Awaited<ReturnType<typeof updatePageRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdatePageRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['updatePageRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePageRoute>>, {data: UpdatePageRequest}> = (props) => {
          const {data} = props ?? {};

          return  updatePageRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: UpdatePageRequest}, TContext>}

    export type UpdatePageRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updatePageRoute>>>
    export type UpdatePageRouteMutationBody = UpdatePageRequest
    export type UpdatePageRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Page Route
 */
export const useUpdatePageRoute = <TData = Awaited<ReturnType<typeof updatePageRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdatePageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: UpdatePageRequest},
        TContext
      > => {

      const mutationOptions = getUpdatePageRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Page Route
 */
export const deletePageRoute = (
    deletePageRequest: DeletePageRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.delete(
      `/pages/delete`,{data:
      deletePageRequest, ...options}
    );
  }



export const getDeletePageRouteMutationOptions = <TData = Awaited<ReturnType<typeof deletePageRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: DeletePageRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
const mutationKey = ['deletePageRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePageRoute>>, {data: DeletePageRequest}> = (props) => {
          const {data} = props ?? {};

          return  deletePageRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: DeletePageRequest}, TContext>}

    export type DeletePageRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deletePageRoute>>>
    export type DeletePageRouteMutationBody = DeletePageRequest
    export type DeletePageRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Page Route
 */
export const useDeletePageRoute = <TData = Awaited<ReturnType<typeof deletePageRoute>>, TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: DeletePageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        TData,
        TError,
        {data: DeletePageRequest},
        TContext
      > => {

      const mutationOptions = getDeletePageRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Ping
 */
export const ping = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    
    return axios.default.get(
      `/ping`,options
    );
  }


export const getPingQueryKey = () => {
    return [`/ping`] as const;
    }

    
export const getPingQueryOptions = <TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ping>>> = ({ signal }) => ping({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type PingQueryResult = NonNullable<Awaited<ReturnType<typeof ping>>>
export type PingQueryError = AxiosError<unknown>


export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof ping>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof ping>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Ping
 */

export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getPingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Test Error
 */
export const testError = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    
    return axios.default.get(
      `/test_error`,options
    );
  }


export const getTestErrorQueryKey = () => {
    return [`/test_error`] as const;
    }

    
export const getTestErrorQueryOptions = <TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTestErrorQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof testError>>> = ({ signal }) => testError({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TestErrorQueryResult = NonNullable<Awaited<ReturnType<typeof testError>>>
export type TestErrorQueryError = AxiosError<unknown>


export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof testError>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof testError>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Test Error
 */

export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getTestErrorQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




