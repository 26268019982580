import { TextEditor } from "@/components/editor";
import { AssistantPresenceIndicator } from "@/components/layout/right-sidebar/assistant-presence";
import { ObjectLinkComponent } from "@/plugins/object-link";
import type { MessageWithMetadata, SessionAssistantId } from "@api/schemas";
import { formatDistanceToNow } from "date-fns";
import { observer } from "mobx-react-lite";

const MessageContent = observer(function MessageContent({
	content,
}: {
	content: string;
}) {
	return (
		<TextEditor
			className="w-full text-sm"
			options={{ content, editable: false }}
		/>
	);
});

/**
 * Shows the message with metadata.
 */
export const MessageWithMetadataComponent = observer(
	function MessageWithMetadataComponent({
		message,
	}: {
		message: MessageWithMetadata;
	}) {
		const { user } = message;
		return (
			<div className="flex w-full min-w-0 grow flex-col px-3">
				{/* Message Header */}
				<div className="flex justify-between text-sm">
					{/* Name */}
					<span className="flex items-center gap-2">
						<span className="font-semibold text-neutral-700">
							{message.agent_type === "user" ? (
								user.user_first_name ? (
									<>
										{user.user_first_name} {user.user_last_name || ""}
									</>
								) : (
									user.user_email
								)
							) : (
								"Assistant"
							)}
						</span>
						{message.agent_type === "assistant" && (
							<ObjectLinkComponent
								href={`/assistant-session/${message.session_id}`}
							>
								<AssistantPresenceIndicator
									sessionAssistantId={message.session_id as SessionAssistantId}
									active={false}
								/>
							</ObjectLinkComponent>
						)}
					</span>
					{/* Timestamp */}
					<span className="text-neutral-500 text-xs">
						{formatDistanceToNow(new Date(message.created_at ?? ""))} ago
					</span>
				</div>
				<MessageContent content={message.content} />
			</div>
		);
	},
);
