import { Favicon } from "@/components/favicon";
import { Button } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandInputWithBadge,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "@/components/ui/command";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTitle,
} from "@/components/ui/dialog";
import { CommandKPage } from "@/contexts/app-context/cmd-k";
import { useFeedChannelsStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { LocalSearchableResourceRef } from "@/contexts/local-search-store";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { resourceRefToPath } from "@/lib/paths";
import { AddFeedDialog } from "@/pages/tabs/feeds/-components/add-feed-dialog";
import { useGetIconAndLabel } from "@/plugins/object-link";
import { useAuth } from "@clerk/clerk-react";
import { Gear, Info, MagnifyingGlass, Plus, Rss } from "@phosphor-icons/react";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import type { LinkProps } from "@tanstack/react-router";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";

export const CommandKButton = observer(() => {
	const appContext = useAppContext();
	const setCmdKOpen = (open: boolean) => {
		runInAction(() => {
			appContext.cmdKOpen = open;
		});
	};

	return (
		<Button
			variant={"outline"}
			className="gap-1 text-neutral-600"
			onClick={() => setCmdKOpen(true)}
		>
			<span className="font-medium text-sm">Command bar</span>{" "}
			<kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-medium font-mono text-[10px] text-muted-foreground opacity-100">
				<span className="text-xs">⌘</span>K
			</kbd>
		</Button>
	);
});

const ResourceCommandItem = observer(function ResourceCommandItem({
	resourceRef,
	navigateWrapper,
}: {
	resourceRef: LocalSearchableResourceRef;
	navigateWrapper: (location: LinkProps) => void;
}) {
	const path = resourceRefToPath(resourceRef);
	const { Icon, defaultLabel } = useGetIconAndLabel(path);
	return (
		<CommandItem
			onSelect={() => {
				navigateWrapper({
					href: path,
				});
			}}
		>
			<Icon className="mr-2 h-4 w-4" />
			{defaultLabel}
		</CommandItem>
	);
});

export const CommandKDialog = observer(() => {
	const appContext = useAppContext();
	const feedChannelsStore = useFeedChannelsStore();
	const feedChannels = feedChannelsStore.sortedResources;
	const toggleOpen = () => {
		runInAction(() => {
			appContext.cmdKOpen = !appContext.cmdKOpen;
		});
	};
	const [value, setValue] = React.useState("");

	const tabStore = useTabStore();
	const { signOut } = useAuth();

	const localSearchStore = appContext.localSearchStore;
	const fileSearchResults = localSearchStore.searchResources(value);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	React.useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				toggleOpen();
			}
		};

		document.addEventListener("keydown", down);
		return () => document.removeEventListener("keydown", down);
	}, []);

	const navigateWrapper = (location: LinkProps) => {
		tabStore.navigateFromOutsideTab(location, true);
		appContext.setCmdKOpen(false);
	};

	return (
		<>
			<AddFeedDialog />
			<Dialog
				open={appContext.cmdKOpen}
				onOpenChange={(open) => {
					appContext.setCmdKOpen(open);
				}}
			>
				<DialogContent className="overflow-hidden p-0">
					<VisuallyHidden.Root>
						<DialogTitle>Command K</DialogTitle>
						<DialogDescription>Press Esc to close Command K</DialogDescription>
					</VisuallyHidden.Root>
					<Command
						className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-neutral-500 dark:[&_[cmdk-group-heading]]:text-neutral-400 [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5"
						onKeyDown={(e) => {
							// Escape goes to previous page
							// Backspace goes to previous page when search is empty
							if (e.key === "Escape" || (e.key === "Backspace" && !value)) {
								e.preventDefault();
								appContext.popCmdKPage();
							}
						}}
					>
						{appContext.lastCmdKPage === "RSS" ? (
							<>
								<CommandInputWithBadge
									placeholder="Type the name or URL of an RSS feed..."
									value={value}
									onValueChange={setValue}
								/>
								<CommandList>
									<CommandGroup heading="">
										{value && (
											<CommandItem
												onSelect={() => {
													runInAction(() => {
														appContext.showAddFeedDialog = true;
													});
												}}
												value={value}
											>
												<Plus className="mr-2 h-4 w-4" />
												<span>Add a feed</span>
											</CommandItem>
										)}
									</CommandGroup>
									<CommandGroup heading="Feeds">
										{feedChannels?.map((channel) => (
											<CommandItem
												key={channel.feed_channel_id}
												onSelect={() => {
													navigateWrapper({
														to: "/feeds/feed-channel/$feed-channel-id",
														params: {
															"feed-channel-id": channel.feed_channel_id,
														},
													});
												}}
											>
												{channel.feed_link ? (
													<Favicon
														url={channel.feed_link}
														alt={channel.name}
														className="h-6 w-6 shrink-0 rounded-md bg-neutral-200"
													/>
												) : (
													<div className="h-6 w-6 shrink-0 rounded-md bg-neutral-300" />
												)}
												<span className="ml-2">{channel.name}</span>
											</CommandItem>
										))}
									</CommandGroup>
								</CommandList>
							</>
						) : (
							<></>
						)}

						{!appContext.lastCmdKPage ? (
							<>
								<CommandInput
									placeholder="Type a command or search..."
									value={value}
									onValueChange={setValue}
								/>
								<CommandList>
									<CommandEmpty>No results found.</CommandEmpty>
									<CommandGroup heading="Navigation">
										<CommandItem
											onSelect={() => {
												navigateWrapper({
													to: "/search",
												});
												appContext.setCmdKOpen(false);
											}}
										>
											<MagnifyingGlass className="mr-2 h-4 w-4" />
											<span>Navigate to Search</span>
										</CommandItem>
										{/* <CommandItem
											onSelect={() => {
												navigateWrapper({
													to: "/library/folder",
												});
												appContext.setCmdKOpen(false);
											}}
										>
											<Books className="mr-2 h-4 w-4" />
											<span>Navigate to Library</span>
										</CommandItem> */}
										<CommandItem
											onSelect={() => {
												window.open(
													"https://vllg.notion.site/How-does-the-research-assistant-work-77ae2b1dc43a48a69ce3260e68d3b4e8",
													"_blank",
												);
											}}
										>
											<Info className="mr-2 h-4 w-4" />
											<span>How does the research assistant work?</span>
										</CommandItem>
										<CommandItem
											onSelect={() => {
												window.open(
													"https://vllg.notion.site/How-does-the-search-page-work-f24c59a827474449ab320860314d6019?pvs=25",
													"_blank",
												);
											}}
										>
											<Info className="mr-2 h-4 w-4" />
											<span>How does the search page work?</span>
										</CommandItem>
									</CommandGroup>

									<CommandGroup heading="Resources">
										{fileSearchResults.map((result) => {
											return (
												<ResourceCommandItem
													key={result.resource_id}
													resourceRef={result}
													navigateWrapper={navigateWrapper}
												/>
											);
										})}
										{fileSearchResults.length === 0 && (
											<div className="py-1 text-center text-neutral-500 text-sm">
												No results found.
											</div>
										)}
									</CommandGroup>
									<CommandSeparator />
									<CommandGroup heading="Actions">
										<CommandItem
											onSelect={() => {
												appContext.pushCmdKPage(CommandKPage.RSS);
											}}
										>
											<Rss className="mr-2 h-4 w-4" />
											<span>Manage RSS Feeds</span>
										</CommandItem>
									</CommandGroup>
									<CommandSeparator />

									<CommandSeparator />
									<CommandGroup heading="Settings">
										<CommandItem
											onSelect={() => {
												signOut();
											}}
										>
											<Gear className="mr-2 h-4 w-4 text-red-700" />

											<span className="text-red-700">Sign out</span>
										</CommandItem>
									</CommandGroup>
								</CommandList>
							</>
						) : (
							<></>
						)}
					</Command>
				</DialogContent>
			</Dialog>
		</>
	);
});
