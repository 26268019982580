import { API_ENDPOINT_WS, IS_DEV } from "@/config";
import {
	type CommandKPage,
	getLastCmdKPage,
	openCmdKPage,
	popCmdKPage,
	pushCmdKPage,
	setCmdKOpen,
} from "@/contexts/app-context/cmd-k";
import {
	type AssistantEvent,
	EventStore,
	createEvent,
} from "@/contexts/app-context/db-store/event-store";
import {
	MessageStore,
	createMessage,
} from "@/contexts/app-context/db-store/message-store";
import { UserStore } from "@/contexts/app-context/db-store/user-store";
import { DevSettings } from "@/contexts/app-context/dev-settings";
import { LeftSidebarState } from "@/contexts/app-context/left-sidebar-state";
import { RightSidebarState } from "@/contexts/app-context/right-sidebar-state";
import { AppContext } from "@/contexts/app-context/use-app-context";
import {
	attemptReconnect,
	handleWorkspaceUpdate,
} from "@/contexts/app-context/workspace-updates";
import { AssistantSessionStore } from "@/contexts/assistant/stores/assistant-session-store";
import { StepStore } from "@/contexts/assistant/stores/step-store";
import {
	FeedChannelsStore,
	FeedItemsStore,
} from "@/contexts/feeds/stores/feed-stores";
import { LocalSearchStore } from "@/contexts/local-search-store";
import { PageStore } from "@/contexts/pages/page-store";
import { PendingResourcesStore } from "@/contexts/pending-resources";
import { PagesIndex } from "@/contexts/resource-indexes/pages";
import { UploadsIndex } from "@/contexts/resource-indexes/uploads";
import { WebpagesIndex } from "@/contexts/resource-indexes/webpages";
import { SearchStore } from "@/contexts/search/stores/search-store";
import { createSyncedAction } from "@/contexts/synced-actions";
import { FieldsStore } from "@/contexts/tables/stores/field-store";
import { TablesStore } from "@/contexts/tables/stores/table-store";
import { TabStore } from "@/contexts/tabs/tabs-context";
import { UploadsStore } from "@/contexts/uploads/stores/upload-store";
import { WebSearchStore } from "@/contexts/web/stores/web-search-store";
import { WebpageStore } from "@/contexts/web/stores/webpage-store";
import { createSessionUserId } from "@/lib/id-generators";
import { ActionExecutor } from "@/lib/sync/action-executor";
import { bootstrapSession, syncFillTableActionRoute } from "@api/fastAPI";
import type {
	ActiveAssistantSessionStatus,
	AssistantStatus,
	Event,
	Message,
	SentMessageEvent,
	SessionAssistant,
	SessionAssistantId,
	SessionId,
	SessionUser,
	SessionUserId,
	Step,
	TableId,
	UserId,
	WorkspaceUpdate,
} from "@api/schemas";
import { useAuth, useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { useMediaQuery } from "@uidotdev/usehooks";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { computedFn } from "mobx-utils";
import { type ReactNode, useEffect, useState } from "react";
import { toast } from "sonner";

interface Workspace {
	uploads: UploadsStore;
	feedChannels: FeedChannelsStore;
	feedItems: FeedItemsStore;
	tables: TablesStore;
	fields: FieldsStore;
	webpages: WebpageStore;
	pages: PageStore;
}

/**
 * TODO(John): think about where to put these
 */
interface ResourceIndexes {
	uploads: UploadsIndex;
	pages: PagesIndex;
	webpages: WebpagesIndex;
}

// TODO(John): convert these to stores
interface Session {
	sessionsUser: Map<SessionUserId, SessionUser>;
}

export class AppState {
	userId: UserId;
	userStore: UserStore;
	workspace: Workspace;
	resourceIndexes: ResourceIndexes;

	localSearchStore: LocalSearchStore;
	pendingResources: PendingResourcesStore;

	tabStore: TabStore = new TabStore(this);
	searchStore: SearchStore = new SearchStore(this);
	webSearchStore: WebSearchStore = new WebSearchStore(this);
	sessionUserId: SessionUserId;
	session: Session | null = null;

	// database stores
	messageStore: MessageStore = new MessageStore(this);
	assistantSessionStore: AssistantSessionStore = new AssistantSessionStore(
		this,
	);
	stepStore: StepStore = new StepStore(this);
	eventStore: EventStore = new EventStore(this);

	// UI State
	leftSidebarState: LeftSidebarState = new LeftSidebarState(this);
	rightSidebarState: RightSidebarState = new RightSidebarState(this);

	// navigate: ReturnType<typeof useNavigate>;
	getToken: ReturnType<typeof useAuth>["getToken"];

	// Command K
	cmdKOpen = false;
	cmdKPages: CommandKPage[] = [];
	showAddFeedDialog = false;

	// WebSocket for workspace-level updates
	ws: WebSocket | null = null;
	wsConnected = false;
	reconnectAttempts = 0;
	reconnectTimeoutId: Timer | null = null;

	actionQueue = new ActionExecutor(this);

	devSettings: DevSettings = new DevSettings();

	constructor({
		userId,
		getToken,
	}: {
		userId: UserId;
		getToken: ReturnType<typeof useAuth>["getToken"];
	}) {
		makeAutoObservable(this);

		this.userId = userId;
		this.getToken = getToken;
		this.sessionUserId = this.#initializeSessionUserId(userId);

		makePersistable(this.rightSidebarState, {
			name: "RightSidebarState",
			properties: [
				"showRightSidebar",
				"rightSidebarTab",
				"activeSessionsDialogOpen",
				"activityViewerActiveSessionAssistantId",
			],
			storage: window.localStorage,
		});
		makePersistable(this.leftSidebarState, {
			name: "LeftSidebarState",
			properties: ["showSidebar"],
			storage: window.localStorage,
		});
		makePersistable(this.devSettings, {
			name: "DevSettings",
			properties: ["showTabUrls"],
			storage: window.localStorage,
		});

		// TODO(John): does workspace still make sense to use?
		this.workspace = {
			uploads: new UploadsStore(this),
			feedChannels: new FeedChannelsStore(this),
			feedItems: new FeedItemsStore(this),
			tables: new TablesStore(this),
			fields: new FieldsStore(this),
			webpages: new WebpageStore(this),
			pages: new PageStore(this),
		};
		this.resourceIndexes = {
			uploads: new UploadsIndex(this.workspace.uploads),
			pages: new PagesIndex(this.workspace.pages),
			webpages: new WebpagesIndex(this.workspace.webpages),
		};
		this.userStore = new UserStore(this);
		this.pendingResources = new PendingResourcesStore(this);
		this.localSearchStore = new LocalSearchStore(this);
	}

	#initializeSessionUserId(userId: UserId): SessionUserId {
		const STORAGE_KEY = "userIdSessionUserIdRecord";
		let record: Record<UserId, SessionUserId> = {};

		const recordString = localStorage.getItem(STORAGE_KEY);
		if (recordString) {
			try {
				record = JSON.parse(recordString) as Record<UserId, SessionUserId>;
			} catch (error) {
				console.warn("Failed to parse userIdSessionUserIdRecord:", error);
				localStorage.removeItem(STORAGE_KEY);
			}
		}

		if (userId in record) {
			return record[userId];
		}

		const newSessionUserId = createSessionUserId();
		record[userId] = newSessionUserId;
		try {
			localStorage.setItem(STORAGE_KEY, JSON.stringify(record));
		} catch (error) {
			console.error("Failed to set userIdSessionUserIdRecord:", error);
		}

		return newSessionUserId;
	}

	async init({
		isReconnect,
	}: {
		isReconnect: boolean;
	}) {
		const token = await this.getToken();

		if (!token) {
			Sentry.captureMessage("No token found in AppContext", "error");
			toast.error("Unable to authenticate. Please refresh the page.");
			return;
		}

		bootstrapSession()
			.then((res) => {
				runInAction(() => {
					this.session = {
						sessionsUser: new Map(
							res.data.session_users.map((user) => [
								user.session_user_id,
								user,
							]),
						),
					};
					this.assistantSessionStore.assistantSessions = new Map(
						res.data.session_assistants.map((assistant) => [
							assistant.session_assistant_id,
							assistant,
						]),
					);
					// TODO(Tae): Probably remove
					this.assistantSessionStore.activeAssistantSessionStatuses = new Map(
						res.data.assistant_session_statuses.map((status) => [
							status.assistant_session_id,
							status.status,
						]),
					);

					this.messageStore.messages = new Map(
						res.data.messages.map((message) => [message.message_id, message]),
					);
					this.eventStore.events = new Map(
						res.data.events.map((event) => [event.event_id, event]),
					);
					this.stepStore.steps = new Map(
						res.data.steps.map((step) => [step.step_id, step]),
					);
				});
			})
			.catch((err) => {
				Sentry.captureException(err);
				if (IS_DEV) {
					toast.error(`${err}`);
				} else {
					toast.error("Failed to initialize session. Please refresh the page.");
				}
			});

		const ws = new WebSocket(
			`${API_ENDPOINT_WS}/sessions/ws?token=${token}&session_user_id=${this.sessionUserId}`,
		);

		ws.onopen = () => {
			runInAction(() => {
				this.ws = ws;
				this.wsConnected = true;
				if (isReconnect) {
					this.reconnectAttempts = 0;
					toast.success("Reconnected to workspace.");
				}
			});
		};

		ws.addEventListener("message", (event) => {
			queueMicrotask(async () => {
				try {
					const data:
						| WorkspaceUpdate
						| Event
						| Step
						| ActiveAssistantSessionStatus = JSON.parse(event.data);
					if ("event_id" in data) {
						this.eventStore.handleEventLocally(data);
					} else if ("step_id" in data) {
						this.stepStore.createStepLocally(data);
					} else if ("assistant_session_id" in data) {
						// TODO(Tae): Refactor
						this.assistantSessionStore.activeAssistantSessionStatuses.set(
							data.assistant_session_id as SessionAssistantId,
							data.status as AssistantStatus,
						);
					} else {
						this.#handleWorkspaceUpdate(data);
					}
				} catch (e) {
					console.error("Error parsing websocket response JSON:", e);
					return;
				}
			});
		});

		ws.onclose = (e) => {
			// 1000 is the code for "normal closure"
			if (e.code === 1000) {
				return;
			}
			runInAction(() => {
				this.wsConnected = false;
				this.ws = null;
			});

			this.#attemptReconnect();
		};

		ws.onerror = (error) => {
			Sentry.captureException(error);
			this.#attemptReconnect();
		};
	}

	/*
	Workspace updates channel methods
	*/
	#attemptReconnect = attemptReconnect.bind(this);
	#handleWorkspaceUpdate = handleWorkspaceUpdate.bind(this);
	/*
	Command K-related methods
	*/
	popCmdKPage = popCmdKPage.bind(this);
	pushCmdKPage = pushCmdKPage.bind(this);
	setCmdKOpen = setCmdKOpen.bind(this);
	openCmdKPage = openCmdKPage.bind(this);
	get lastCmdKPage() {
		return getLastCmdKPage.bind(this)();
	}

	getSessionById(sessionId: SessionId): SessionAssistant | SessionUser | null {
		if (!this.session) {
			return null;
		}
		const maybeSessionAssistant =
			this.assistantSessionStore.getAssistantSessionById(
				sessionId as SessionAssistantId,
			);
		if (maybeSessionAssistant) {
			return maybeSessionAssistant;
		}
		const maybeSessionUser = this.session.sessionsUser.get(
			sessionId as SessionUserId,
		);
		if (maybeSessionUser) {
			return maybeSessionUser;
		}
		return null;
	}

	createSessionUserLocally = (sessionUser: SessionUser) => {
		this.session?.sessionsUser.set(sessionUser.session_user_id, sessionUser);
	};

	fillTable = createSyncedAction<
		AppState,
		{
			tableId: TableId;
			tableName: string;
		},
		SentMessageEvent,
		void
	>({
		async local({ tableId, tableName }) {
			const event = createEvent({
				eventType: "sent_message",
				userId: this.userId,
				data: {
					session_id: this.sessionUserId,
					message: createMessage({
						content: `<p>Fill out or update the existing cells in <a href='/table/${tableId}'>${tableName}</a> based on the column descriptions.</p>`,
						sessionId: this.sessionUserId,
						parentMessageId: null,
						attachments: [],
					}),
				},
			});
			this.eventStore.handleEventLocally(event);
			return event;
		},
		async remote(_, localResult) {
			await syncFillTableActionRoute({
				message_event: localResult,
			});
		},
		rollback(_args, localResult) {
			this.eventStore.events.delete(localResult.event_id);
			this.messageStore.messages.delete(localResult.data.message.message_id);
		},
		onRemoteSuccess() {},
	});

	sendMessage(props: {
		content: string;
		attachments: string[];
		parentMessageId: Message["parent_message_id"];
	}) {
		const newMessage = createMessage({
			content: props.content,
			sessionId: this.sessionUserId,
			parentMessageId: props.parentMessageId,
			attachments: props.attachments,
		});
		return this.eventStore.handleEvent(
			{
				eventType: "sent_message",
				data: {
					session_id: this.sessionUserId,
					message: newMessage,
				},
			},
			() => {
				// If the message has no parent, this means it is in the root message tab
				// And we need to navigate to the message tab
				if (!props.parentMessageId) {
					this.rightSidebarState.messageTab.router.navigate({
						href: `/message/${newMessage.message_id}`,
					});
				}
			},
		);
	}

	// Queries
	getSortedAssistantActivityForSession = computedFn(
		(assistantSessionId: SessionAssistantId): (AssistantEvent | Step)[] => {
			const events =
				this.eventStore.getEventsForAssistantSessionId(assistantSessionId);
			const steps =
				this.stepStore.getStepsForAssistantSessionId(assistantSessionId);

			return [...events, ...steps].sort(
				(a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
			);
		},
	);
}

type AppProviderProps = {
	userId: UserId;
	children: ReactNode;
};

let didInit = false;

export const AppProvider = ({ userId, children }: AppProviderProps) => {
	const { getToken } = useAuth();
	const { user } = useUser();
	const [appState] = useState<AppState>(
		() =>
			new AppState({
				getToken,
				userId,
			}),
	);
	const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

	useEffect(() => {
		runInAction(() => {
			appState.leftSidebarState.setShowSidebar(!isSmallDevice);
		});
	}, [appState, isSmallDevice]);

	useEffect(() => {
		if (!user) {
			return;
		}

		if (!didInit) {
			didInit = true;
			appState.init({ isReconnect: false });
		}
	}, [appState, user]);

	return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};
