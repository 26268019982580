import filePdfIcon from "@/assets/file-pdf.svg";
import { ImageWithFallback } from "@/components/image-with-fallback";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { usePDFViewerContext } from "@/contexts/pdfviewer-context";
import clsx from "clsx";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { type RefObject, useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useInView } from "react-intersection-observer";

const Thumbnail = observer(
	({
		index,
		rootRef,
	}: { index: number; rootRef: RefObject<HTMLDivElement> }) => {
		const pdfViewerContext = usePDFViewerContext();

		const { ref, inView } = useInView({
			root: rootRef.current,
			triggerOnce: true,
			// Prerender pages that are 1024px away from the current page
			rootMargin: "1024px 1024px",
		});

		return (
			<button
				type="button"
				ref={ref}
				key={index}
				className={clsx("flex w-full flex-col items-center")}
				onClick={() => {
					// Use flushSync to ensure the scroll is applied before the state update
					flushSync(() => {
						pdfViewerContext.listRef?.scrollToItem(index);
					});
					// Although this tracks the scrolled position, we set this manually
					// because the scroll position can sometimes indicate the wrong page
					// if the pages are larger than the viewport.
					pdfViewerContext.setCurrentPageIndex(index);
				}}
			>
				<div className="flex w-full items-center justify-center">
					{inView ? (
						<ImageWithFallback
							src={pdfViewerContext.thumbnailUrl(index)}
							alt={`Thumbnail for page ${index}`}
							fallbackSrc={filePdfIcon}
							className={clsx(
								"h-48 min-w-36 max-w-full shadow",
								pdfViewerContext.currentPageIndex === index &&
									"ring-4 ring-neutral-300",
							)}
						/>
					) : (
						<div className="h-48 w-36 animate-pulse bg-neutral-200" />
					)}
				</div>
				<span
					className={clsx(
						"mt-1.5 px-1 text-neutral-500 text-xs",
						pdfViewerContext.currentPageIndex === index && " text-neutral-800",
					)}
				>
					Page {(index + 1).toLocaleString()}
				</span>
			</button>
		);
	},
);

export const PDFSidebar = observer(() => {
	const pdfViewerContext = usePDFViewerContext();
	const rootRef = useRef<HTMLDivElement>(null);

	const toc = pdfViewerContext.toc;

	const [activeTocIndex, setActiveTocIndex] = useState<number | null>(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(
		function syncActiveTocIndex() {
			const dispose = autorun(() => {
				// find the first toc item that matches the current page index
				const index = toc?.findIndex(
					(x) => x.page_idx >= pdfViewerContext.currentPageIndex,
				);
				if (index !== undefined) {
					setActiveTocIndex(index);
				}
			});

			return () => {
				dispose();
			};
		},
		[toc],
	);

	return (
		<div
			className={clsx(
				"flex h-full shrink-0 flex-col border-neutral-200 border-r bg-neutral-50",
				// make this w-0 when the sidebar is hidden so that we still make the request
				// to get the upload data before the user can open the sidebar
				pdfViewerContext.showSidebar ? "w-56" : "w-0 overflow-hidden",
			)}
		>
			<Tabs
				defaultValue="toc"
				className="flex h-full min-h-0 w-full flex-col bg-white"
			>
				<TabsList className="sticky top-0 flex-none bg-white">
					<TabsTrigger value="toc" className="gap-1">
						<span>Outline</span>
					</TabsTrigger>
					<TabsTrigger value="thumbnails" className="gap-1">
						<span>Thumbnails</span>
					</TabsTrigger>
				</TabsList>
				<TabsContent value="toc" asChild className="mt-0">
					{toc ? (
						<div className="flex flex-col overflow-auto pb-3">
							{toc.map((x, index) => {
								return (
									<button
										type="button"
										// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
										key={index}
										className={clsx(
											"w-full px-3 py-1.5 text-left text-xs hover:bg-neutral-50",
											activeTocIndex === index
												? "bg-neutral-100 text-neutral-900"
												: "text-neutral-500",
										)}
										style={{
											paddingLeft: `${x.heading_level * 8 + 8}px`,
										}}
										onClick={() => {
											// Use flushSync to ensure the scroll is applied before the state update
											flushSync(() => {
												pdfViewerContext.listRef?.scrollToItem(x.page_idx);
											});
											// Although this tracks the scrolled position, we set this manually
											// because the scroll position can sometimes indicate the wrong page
											// if the pages are larger than the viewport.
											pdfViewerContext.setCurrentPageIndex(x.page_idx);
											setActiveTocIndex(index);
										}}
									>
										{x.title}
									</button>
								);
							})}
						</div>
					) : null}
				</TabsContent>
				<TabsContent value="thumbnails" asChild className="mt-0">
					{pdfViewerContext.numPages ? (
						<div
							ref={rootRef}
							className="flex flex-col gap-3 overflow-auto bg-neutral-100 p-3 pb-3"
						>
							{Array.from({ length: pdfViewerContext.numPages }).map(
								(_, index) => (
									<Thumbnail
										index={index}
										// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
										key={index}
										rootRef={rootRef}
									/>
								),
							)}
						</div>
					) : null}
				</TabsContent>
			</Tabs>
		</div>
	);
});
