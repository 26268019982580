import {
	getRecordLinkColumn,
	getResourceIndexColumn,
} from "@/components/table/columns";
import type { UploadsStore } from "@/contexts/uploads/stores/upload-store";
import { resourceRefToPath } from "@/lib/paths";
import type { ResourceLink } from "@api/schemas";
import type { ColumnDef } from "@tanstack/react-table";

// Keep this in sync with the index view in the server
interface UploadsIndexRecord {
	link: ResourceLink;
	name: string;
	filename: string;
	subtitle: string | null;
	authors: string;
	publisher: string | null;
	url: string | null;
}

/**
 * This represents the table state for the uploads index.
 */
export class UploadsIndex {
	uploadsStore: UploadsStore;

	constructor(uploadsStore: UploadsStore) {
		this.uploadsStore = uploadsStore;
	}

	get rows(): UploadsIndexRecord[] {
		return this.uploadsStore.map.values().map((upload) => ({
			link: resourceRefToPath({
				resource_id: upload.upload_id,
				type: "upload",
			}),
			name: upload.name,
			filename: upload.name,
			subtitle: upload.subtitle,
			authors: upload.authors.join(", "),
			publisher: upload.publisher,
			url: upload.resolved_url,
		}));
	}

	get columns(): ColumnDef<UploadsIndexRecord>[] {
		return [
			getRecordLinkColumn(),
			getResourceIndexColumn({
				key: "name",
				name: "Name",
				scalarType: "text",
			}),
			getResourceIndexColumn({
				key: "filename",
				name: "Filename",
				scalarType: "text",
			}),
			getResourceIndexColumn({
				key: "subtitle",
				name: "Subtitle",
				scalarType: "text",
			}),
			getResourceIndexColumn({
				key: "authors",
				name: "Authors",
				scalarType: "text",
			}),
			getResourceIndexColumn({
				key: "publisher",
				name: "Publisher",
				scalarType: "text",
			}),
			getResourceIndexColumn({
				key: "url",
				name: "URL",
				scalarType: "text",
			}),
		];
	}
}
