import { smithWaterman } from "@/lib/align";
import type { TextContent, TextItem } from "pdfjs-dist/types/src/display/api";

/**
 * normalizes smart/curly quotes to straight quotes in a string
 *
 * @param str - input string containing potential smart/curly quotes
 * @returns string with all quotes normalized to straight quotes:
 * - single quotes (U+2018, U+2019) -> U+0027
 * - double quotes (U+201C, U+201D) -> U+0022
 */

function normalizeQuotes(str: string) {
	return str.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
}

const cleanTextForAlignment = (text: string) => {
	return normalizeQuotes(text.toLocaleLowerCase().replace(/\s/g, " "));
};

function removeInvisible(str: string) {
	const ranges = [
		"\u0000-\u001F", // Control characters
		"\u007F-\u009F", // Control characters
		"\u00AD", // Soft hyphen
		"\u200B-\u200F", // Zero-width characters
		"\u2060-\u206F", // Unicode formatting characters
		"\uFEFF", // Byte order mark
		"\u00A0", // Non-breaking space
	];

	const pattern = new RegExp(`[${ranges.join("")}]`, "g");

	return str.replace(pattern, "");
}

export interface HighlightResult {
	firstSpan: {
		item: TextItem;
		pageIndex: number;
		itemIndex: number;
	};
	lastSpan: {
		item: TextItem;
		pageIndex: number;
		itemIndex: number;
	};
	firstSpanCharIdx: number;
	lastSpanCharIdx: number;
}

/**
 * Computes highlight positions for text search within paginated content
 *
 * @param textToHighlight - search text to find and highlight
 * @param pageTexts - array of page content objects, each containing:
 *   - pageText: PDF text content with position info
 *   - pageIndex: index of the page
 * @returns highlight result containing:
 *   - firstSpan: {item, pageIndex, itemIndex} of first matched text span
 *   - lastSpan: {item, pageIndex, itemIndex} of last matched text span
 *   - firstSpanCharIdx: character index within first span where highlight starts
 *   - lastSpanCharIdx: character index within last span where highlight ends
 * @throws Error if no matches found
 *
 * Uses smith-waterman sequence alignment to find best approximate match,
 * handling cases where text may span multiple pages/text blocks
 */

export const computePaginatedHighlight = (
	textToHighlight: string,
	pageTexts: Array<{
		pageText: TextContent;
		pageIndex: number;
	}>,
): HighlightResult => {
	const spans: Array<{
		item: TextItem;
		pageIndex: number;
		itemIndex: number;
	}> = [];
	for (const { pageText, pageIndex } of pageTexts) {
		pageText.items.forEach((item, itemIndex) => {
			if ("str" in item && item.str.length > 0) {
				spans.push({ item: item, pageIndex, itemIndex });
			}
		});
	}

	const haystack = spans.map(({ item: text }) => text.str).join("");

	const [highlightAlignment, haystackAlignment] = smithWaterman(
		// We don't care about surrounding spaces for the highlighted text
		cleanTextForAlignment(removeInvisible(textToHighlight))
			.trim()
			.replace(/\s+/g, " "),
		// However, we do care about surrounding spaces for the haystack because they affect span positioning
		cleanTextForAlignment(haystack),
	);

	// find the index of the first character in the haystack
	const firstCharAlignmentIdx = highlightAlignment.findIndex(
		// don't include preceding spaces because they can mess up the alignment
		(x) => x.type === "char" && x.char !== " ",
	);
	// find the index of the last character in the haystack
	const lastCharAlignmentIdx = highlightAlignment.findLastIndex(
		// don't include remaining spaces because they can mess up the alignment
		(x) => x.type === "char" && x.char !== " ",
	);

	let firstCharIdx = -1;
	let lastCharIdx = -1;

	for (let i = firstCharAlignmentIdx; i < haystackAlignment.length; i++) {
		const pos = haystackAlignment[i];
		if (pos.type === "char") {
			firstCharIdx = pos.originalIndex;
			break;
		}
	}
	for (let i = lastCharAlignmentIdx; i >= 0; i--) {
		const pos = haystackAlignment[i];
		if (pos.type === "char") {
			lastCharIdx = pos.originalIndex;
			break;
		}
	}

	if (firstCharIdx === -1 || lastCharIdx === -1) {
		throw new Error("No search results found");
	}

	let firstSpanIdx = -1;
	let lastSpanIdx = -1;
	let firstSpanCharIdx = -1; // index of the first character in the first span
	let lastSpanCharIdx = -1; // index of the last character in the last span

	let cumulativeSpanLength = 0;
	for (let i = 0; i < spans.length; i++) {
		const { item: text } = spans[i];
		if (cumulativeSpanLength + text.str.length > firstCharIdx) {
			firstSpanIdx = i;
			firstSpanCharIdx = firstCharIdx - cumulativeSpanLength;
			break;
		}
		cumulativeSpanLength += text.str.length;
	}

	cumulativeSpanLength = 0;
	for (let i = 0; i < spans.length; i++) {
		const { item: text } = spans[i];
		if (cumulativeSpanLength + text.str.length > lastCharIdx) {
			lastSpanIdx = i;
			lastSpanCharIdx = lastCharIdx - cumulativeSpanLength;
			break;
		}
		cumulativeSpanLength += text.str.length;
	}

	const firstSpan = spans[firstSpanIdx];
	const lastSpan = spans[lastSpanIdx];

	return {
		firstSpan,
		lastSpan,
		firstSpanCharIdx,
		lastSpanCharIdx,
	};
};

/**
 * Computes highlight positions for text with a gap between start and end phrases
 *
 * @param queryStart - text to match at start of highlight
 * @param queryEnd - text to match at end of highlight
 * @param pageTexts - array of page content objects, each containing:
 *   - pageText: PDF text content with position info
 *   - pageIndex: index of the page
 * @returns highlight result spanning from start match to end match:
 *   - firstSpan: {item, pageIndex, itemIndex} of first matched span
 *   - lastSpan: {item, pageIndex, itemIndex} of last matched span
 *   - firstSpanCharIdx: char index in first span where highlight starts
 *   - lastSpanCharIdx: char index in last span where highlight ends
 * @throws Error if either start or end phrase not found
 */

export const computeGappedHighlight = (
	queryStart: string,
	queryEnd: string,
	pageTexts: Array<{
		pageText: TextContent;
		pageIndex: number;
	}>,
): HighlightResult => {
	const startingAlignment = computePaginatedHighlight(queryStart, pageTexts);

	const endingAlignment = computePaginatedHighlight(queryEnd, pageTexts);

	return {
		firstSpan: startingAlignment.firstSpan,
		lastSpan: endingAlignment.lastSpan,
		firstSpanCharIdx: startingAlignment.firstSpanCharIdx,
		lastSpanCharIdx: endingAlignment.lastSpanCharIdx,
	};
};

export function computeHtmlHighlight(
	html: string,
	query: string,
	highlightElement = "mark",
) {
	if (html.length === 0 || query.length === 0) {
		return html;
	}

	const container = document.createElement("div");
	container.innerHTML = html;

	// Traverse the document to extract text nodes and build the plaintext content
	let plainText = "";
	const textNodes: { node: Node; start: number; end: number }[] = [];

	function traverse(node: Node) {
		if (node.nodeType === node.TEXT_NODE) {
			const start = plainText.length;
			const textContent = node.nodeValue;
			plainText += textContent;
			const end = plainText.length;
			textNodes.push({ node, start, end });
		} else {
			// Process child nodes
			node.childNodes.forEach(traverse);
		}
	}

	traverse(container); // Start from body

	// Decode HTML entities in plainText and highlightQuery
	const decoder = document.createElement("div");
	decoder.innerHTML = plainText;
	const decodedPlainText = decoder.textContent || decoder.innerText || "";

	decoder.innerHTML = query;
	const decodedHighlightQuery = decoder.textContent || decoder.innerText || "";

	if (decodedPlainText.length === 0 || decodedHighlightQuery.length === 0) {
		// Return original content if either text is empty
		return html;
	}

	const [highlightAlignment, haystackAlignment] = smithWaterman(
		decodedPlainText,
		decodedHighlightQuery,
	);

	if (highlightAlignment.length === 0 || haystackAlignment.length === 0) {
		// No significant alignment found
		return html;
	}

	// Compute match length
	const matchLength = highlightAlignment.filter((pos, idx) => {
		return pos.type === "char" && haystackAlignment[idx].type === "char";
	}).length;

	// Define a minimum match length or percentage
	const minMatchLength = Math.floor(decodedHighlightQuery.length * 0.5); // At least 50% match

	if (matchLength < minMatchLength) {
		// Match is too small; do not highlight
		return html;
	}

	// Create an array to indicate whether each position in decodedPlainText is a match
	const isMatch = new Array(decodedPlainText.length).fill(false);
	let posInPlainText = 0;

	for (let i = 0; i < highlightAlignment.length; i++) {
		const pos1 = highlightAlignment[i];
		const pos2 = haystackAlignment[i];

		if (pos1.type === "char") {
			if (pos2.type === "char") {
				// Match at this position
				isMatch[pos1.originalIndex] = true;
			}
			posInPlainText++;
		}
	}

	// Now, for each text node, we process its text content, wrapping matched parts with <highlight> tags
	for (const { node, start } of textNodes) {
		const text = node.nodeValue;
		let newText = "";
		let i = 0;
		let inMatch = false;

		if (!text) {
			continue;
		}

		while (i < text.length) {
			const globalPos = start + i;
			if (isMatch[globalPos]) {
				if (!inMatch) {
					// TODO(Tae): we should use some custom identifier for the highlight element
					// newText += `<${highlightElement} data-extract>`;
					newText += `<${highlightElement}>`;
					inMatch = true;
				}
			} else {
				if (inMatch) {
					newText += `</${highlightElement}>`;
					inMatch = false;
				}
			}
			// Preserve the original character, including any special characters
			newText += text[i];
			i++;
		}
		if (inMatch) {
			newText += `</${highlightElement}>`;
			inMatch = false;
		}
		// Replace the text node with new nodes created from newText
		// We need to parse the newText as HTML
		const tempDiv = document.createElement("div");
		tempDiv.innerHTML = newText;
		const parent = node.parentNode;
		const fragment = document.createDocumentFragment();
		while (tempDiv.firstChild) {
			fragment.appendChild(tempDiv.firstChild);
		}
		parent?.replaceChild(fragment, node);
	}

	// Return the innerHTML
	return container.innerHTML;
}
