import {
	TableHeader,
	type TableHeaderProps,
} from "@/components/table/table-header";
import { ADD_COLUMN_ID, useTableSensors } from "@/components/table/utils";
import {
	DndContext,
	type DragEndEvent,
	type DragOverEvent,
	DragOverlay,
	type DragStartEvent,
	pointerWithin,
	useDraggable,
	useDroppable,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import type { Header } from "@tanstack/react-table";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const DraggableHeader = observer(
	<TData, TColumnId extends string>(props: {
		header: Header<TData, unknown>;
		overId: TColumnId | null;
		draggedColumnId: TColumnId | null;
		children: React.ReactElement<TableHeaderProps<TData>>;
	}) => {
		const { setNodeRef: setDropNodeRef } = useDroppable({
			id: props.header.column.id,
		});
		const {
			attributes,
			listeners,
			setNodeRef: setDragNodeRef,
		} = useDraggable({
			id: props.header.column.id,
		});
		const isDragging = props.draggedColumnId === props.header.column.id;
		const isPinned = props.header.column.getIsPinned();

		// Disallow dragging the select and 'create column' headers
		const isReorderable = !isPinned && props.header.column.id !== ADD_COLUMN_ID;
		return (
			<>
				{/* Drop Indicator */}
				{props.overId === props.header.column.id && (
					<div
						key={`drop-indicator-${props.header.column.id}`}
						className="relative z-50 h-8 w-0 p-0 ring-1 ring-blue-500"
					/>
				)}
				{/* Draggable/Droppable Wrapper */}
				<div
					className={clsx(isDragging && "bg-blue-50 opacity-50")}
					ref={(ref) => {
						if (isReorderable) {
							setDragNodeRef(ref);
							setDropNodeRef(ref);
						}
					}}
					{...attributes}
					{...listeners}
				>
					{props.children}
				</div>
			</>
		);
	},
);

export type HeaderDndContextProps<TData, TColumnId extends string> = {
	children: React.ReactElement<TableHeaderProps<TData>>[][];
	moveColumn: (
		columnId: TColumnId,
		precedingColumnId: TColumnId | null,
	) => void;
};

export const HeaderDndContext = observer(
	<TData, TColumnId extends string>(
		props: HeaderDndContextProps<TData, TColumnId>,
	) => {
		const sensors = useTableSensors();
		const [draggedColumnId, setDraggedColumnId] = useState<TColumnId | null>(
			null,
		);
		const [overColumnId, setOverColumnId] = useState<TColumnId | null>(null);

		function handleColumnDragStart(event: DragStartEvent) {
			const activeDroppableId = event.active.id;
			setDraggedColumnId(activeDroppableId as TColumnId);
		}

		function handleColumnDragOver(event: DragOverEvent) {
			const over = event.over;
			if (!over) return;
			const newOverColumnId = over.id as TColumnId;
			setOverColumnId(newOverColumnId);
			document.body.style.setProperty("--over-column-id", newOverColumnId);
		}

		function handleColumnDragEnd(event: DragEndEvent) {
			const { active, over } = event;

			setDraggedColumnId(null);
			setOverColumnId(null);

			document.body.style.removeProperty("--over-column-id");

			if (!over) return;
			const draggedColumnId = active.id as TColumnId;
			const overColumnId = over.id as TColumnId;
			if (draggedColumnId === overColumnId) return;
			props.moveColumn(draggedColumnId, overColumnId);
		}

		return (
			<DndContext
				collisionDetection={pointerWithin}
				modifiers={[restrictToHorizontalAxis]}
				onDragStart={handleColumnDragStart}
				onDragOver={handleColumnDragOver}
				onDragEnd={handleColumnDragEnd}
				sensors={sensors}
			>
				{React.Children.map(props.children.flat(), (child) => {
					if (!React.isValidElement(child) || child.type !== TableHeader)
						return null;

					const header = (child.props as TableHeaderProps<TData>).header;
					return (
						<DraggableHeader
							key={header.id}
							header={header}
							overId={overColumnId}
							draggedColumnId={draggedColumnId}
						>
							{child}
						</DraggableHeader>
					);
				})}
				{/* Drag previews for columns */}
				<DragOverlay>
					{/* Tanstack doesn't have a function to get headers by ID,
								 so we have to do this the hard way */}
					{draggedColumnId &&
						React.Children.map(props.children.flat(), (child) => {
							if (!React.isValidElement(child) || child.type !== TableHeader)
								return child;
							const header = (child.props as TableHeaderProps<TData>).header;
							return header.id === draggedColumnId ? (
								<TableHeader
									key={`drag-preview-${header.id}`}
									header={header}
								/>
							) : null;
						})}
				</DragOverlay>
			</DndContext>
		);
	},
);
