import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { createWriteId } from "@/lib/id-generators";
import { OptimisticAction, type Transaction } from "@/lib/sync/action-executor";
import { ElectricOptimisticMap } from "@/lib/sync/optimistic-map";
import { getSearchRoute, searchRoute } from "@api/fastAPI";
import type {
	SearchFull,
	SearchId,
	SearchResource,
	SearchSynthesis,
} from "@api/schemas";
import { makeAutoObservable, runInAction } from "mobx";
import type { Result } from "neverthrow";

// We get search_id from the tab state so that we can navigate to the new
// search page
export type SearchActionArgs = Omit<
	SearchResource,
	"write_id" | "name" | "requires_synthesis" | "created_at"
>;

class InitiateSearchAction extends OptimisticAction<
	AppState,
	SearchActionArgs,
	SearchResource,
	void
> {
	async local(tx: Transaction, state: AppState) {
		const searchResource: SearchResource = {
			...this.args,
			name: this.args.query,
			requires_synthesis: true,
			write_id: createWriteId(),
			created_at: new Date().toISOString(),
		};
		state.searchStore.map.insert(tx, searchResource);
		return searchResource;
	}

	async remote(props: { localResult: SearchResource }, state: AppState) {
		const res = await searchRoute({
			search_resource: props.localResult,
		});
		state.searchStore.fullResults.set(props.localResult.search_id, res.data);
	}
}

export class SearchStore {
	appState: AppState;
	map: ElectricOptimisticMap<SearchResource, "search_id", "write_id">;

	// Cache for full search results
	fullResults: Map<SearchId, SearchFull> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/search_results`,
			idKey: "search_id",
			writeIdKey: "write_id",
			shapeHash: openapiHashes.SearchResource,
		});
		makeAutoObservable(this);
	}

	getResourceById(search_id: SearchId): Result<SearchResource, Error> {
		return this.map.get(search_id);
	}

	getFullResult(search_id: SearchId): SearchFull | undefined {
		const result = this.fullResults.get(search_id);
		if (!result) {
			getSearchRoute(search_id).then((res) => {
				runInAction(() => {
					this.fullResults.set(search_id, res.data);
				});
			});
			return undefined;
		}
		return result;
	}

	/**
	 * Turns the SearchRequest into a SearchResult with empty results.
	 *
	 * Then, sends a search request to the server and updates the SearchResults
	 * object with the data that gets returned.
	 */
	initiateSearch(searchRequest: SearchActionArgs) {
		const action = new InitiateSearchAction(searchRequest);
		this.appState.actionQueue.run(action);
	}

	handleSynthesisUpdate(update: SearchSynthesis) {
		const searchId = update.search_id;
		const searchResult = this.fullResults.get(searchId);
		if (!searchResult) {
			return;
		}
		searchResult.synthesis = update.synthesis;
	}

	get uniqueSearchHistory() {
		return Array.from(this.fullResults.values());
	}
}
