import type { AppState } from "@/contexts/app-context/app-context";
import { MinisearchIndex } from "@/lib/minisearch-index";
import { resourceRefToPath } from "@/lib/paths";
import type {
	FeedChannelResource,
	FeedItemResource,
	PageResource,
	ResourceLink,
	UploadResource,
} from "@api/schemas";
import type { ResourceRef } from "@api/schemas";

export type LocalSearchableResourceRef = Extract<
	ResourceRef,
	{
		type: "feed-channel" | "feed-item" | "page" | "upload";
	}
>;

export class LocalSearchStore {
	appState: AppState;
	pages: MinisearchIndex<PageResource, "page_id">;
	uploads: MinisearchIndex<UploadResource, "upload_id">;
	feedChannels: MinisearchIndex<FeedChannelResource, "feed_channel_id">;
	feedItems: MinisearchIndex<FeedItemResource, "feed_item_id">;
	constructor(appState: AppState) {
		this.appState = appState;
		this.pages = new MinisearchIndex({
			sourceMap: this.appState.workspace.pages.map.items.streamItems,
			idKey: "page_id",
			indexedFields: ["name", "content"],
		});
		this.uploads = new MinisearchIndex({
			sourceMap: this.appState.workspace.uploads.map.items.streamItems,
			idKey: "upload_id",
			indexedFields: ["filename", "name", "subtitle", "authors"],
		});
		this.feedChannels = new MinisearchIndex({
			sourceMap: this.appState.workspace.feedChannels.map.items.streamItems,
			idKey: "feed_channel_id",
			indexedFields: ["feed_subtitle", "feed_description"],
		});
		this.feedItems = new MinisearchIndex({
			sourceMap: this.appState.workspace.feedItems.map.items.streamItems,
			idKey: "feed_item_id",
			indexedFields: ["description", "original_url", "authors"],
		});
	}

	searchResources(
		query: string,
	): (LocalSearchableResourceRef & { score: number })[] {
		return [
			...this.pages.search({ query }).map(({ id, score }) => ({
				resource_id: id,
				type: "page" as const,
				score,
			})),
			...this.uploads.search({ query }).map(({ id, score }) => ({
				resource_id: id,
				type: "upload" as const,
				score,
			})),
			...this.feedChannels.search({ query }).map(({ id, score }) => ({
				resource_id: id,
				type: "feed-channel" as const,
				score,
			})),
			...this.feedItems.search({ query }).map(({ id, score }) => ({
				resource_id: id,
				type: "feed-item" as const,
				score,
			})),
		];
	}

	searchResourceObjectLink(query: string): {
		id: ResourceLink;
		score: number;
	}[] {
		const results = this.searchResources(query);
		return results.map((result) => ({
			id: resourceRefToPath(result),
			score: result.score,
		}));
	}
}
