import type {
	EventId,
	FeedChannelId,
	FeedItemId,
	FieldId,
	MessageId,
	PageId,
	SearchId,
	SessionUserId,
	StepId,
	TabId,
	TableId,
	UploadId,
	UserId,
	WebSearchId,
	WebpageId,
	WriteId,
} from "@api/schemas";
import { ulid } from "ulidx";

type IdType =
	| "step"
	| "feed-channel"
	| "feed-item"
	| "table"
	| "field"
	| "upload"
	| "web-search"
	| "search"
	| "page"
	// Session types
	| "session-user"
	| "event"
	| "message"
	| "tab"
	| "write"
	| "webpage";

type IdMap = {
	step: StepId;
	"feed-channel": FeedChannelId;
	"feed-item": FeedItemId;
	table: TableId;
	field: FieldId;
	upload: UploadId;
	user: UserId;
	"web-search": WebSearchId;
	search: SearchId;
	page: PageId;
	// Session types
	"session-user": SessionUserId;
	message: MessageId;
	event: EventId;
	tab: TabId;
	write: WriteId;
	webpage: WebpageId;
};

const generateId = <T extends IdType>(prefix: T): IdMap[T] => {
	return `${prefix}_${ulid()}`.toLowerCase() as IdMap[T];
};

export const createStepId = () => generateId("step");
export const createFeedChannelId = () => generateId("feed-channel");
export const createFeedItemId = () => generateId("feed-item");
export const createTableId = () => generateId("table");
export const createFieldId = () => generateId("field");
export const createUploadId = () => generateId("upload");
// Session types
export const createSessionUserId = () => generateId("session-user");
export const createMessageId = () => generateId("message");
export const createEventId = () => generateId("event");
export const createWebSearchId = () => generateId("web-search");
export const createSearchId = () => generateId("search");
export const createPageId = () => generateId("page");
export const createTabId = () => generateId("tab");
export const createWriteId = () => generateId("write");
export const createWebpageId = () => generateId("webpage");
