import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useUploadsStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { FileUploaderBox } from "@/pages/tabs/uploads/-components/file-uploader-box";
import { Question, UploadSimple } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";

export const FileUploadDialog = observer(function FileUploadDialog() {
	const uploadsStore = useUploadsStore();
	const [inferMetadata, setInferMetadata] = useState(true);

	const onDrop = (files?: File[]) => {
		const promises = files?.map((file) => {
			return uploadsStore.createUpload({
				file,
				inferMetadata,
			});
		});
		Promise.all(promises || []);
	};

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button className="gap-2" size="sm" variant="outline">
					<UploadSimple weight="bold" />
					<span>Add</span>
				</Button>
			</DialogTrigger>
			<DialogContent className="max-h-[80vh] overflow-y-scroll">
				<DialogHeader>
					<DialogTitle>Upload files</DialogTitle>
				</DialogHeader>
				<div className="flex items-center gap-2 text-neutral-700">
					<Switch checked={inferMetadata} onCheckedChange={setInferMetadata} />
					<span>Automatically infer metadata</span>
					<Tooltip>
						<TooltipTrigger>
							<Question weight="duotone" className="text-neutral-500 text-xl" />
						</TooltipTrigger>
						<TooltipContent>
							Processes the uploaded document with AI to infer its title,
							author, and other metadata.
						</TooltipContent>
					</Tooltip>
				</div>
				<FileUploaderBox
					description="PDFs and EPUBs supported"
					onDrop={onDrop}
				/>
			</DialogContent>
		</Dialog>
	);
});
