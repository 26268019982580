import { useAppContext } from "@/contexts/app-context/use-app-context";

export const useUploadsIndex = () => {
	const appContext = useAppContext();
	return appContext.resourceIndexes.uploads;
};

export const usePagesIndex = () => {
	const appContext = useAppContext();
	return appContext.resourceIndexes.pages;
};

export const useWebpagesIndex = () => {
	const appContext = useAppContext();
	return appContext.resourceIndexes.webpages;
};
