import type { BaseRendererProps } from "@/components/table/scalar-renderers/renderer-props";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

type NumberRendererProps = BaseRendererProps<"real">;

export const NumberRenderer = observer((props: NumberRendererProps) => {
	const [open, setOpen] = useState(false);
	const [newValue, setNewValue] = useState<string>(
		props.value?.toString() ?? "",
	);
	useEffect(() => {
		setNewValue(props.value?.toString() ?? "");
	}, [props.value]);

	const onSubmit = () => {
		// if the new value is empty, clear the cell
		if (newValue.trim() === "") {
			props.onUpdate?.(null);
			return;
		}
		const newNumber = Number.parseFloat(newValue);
		if (newNumber !== props.value && !Number.isNaN(newNumber)) {
			props.onUpdate?.(newNumber);
		}
	};

	return (
		<Popover
			open={open}
			onOpenChange={(newOpen) => {
				setOpen(newOpen);
				if (!newOpen) {
					onSubmit();
				}
			}}
		>
			<PopoverTrigger
				className={clsx(
					"flex h-full w-full min-w-0 flex-col items-end justify-start truncate p-1 text-right text-neutral-800 text-sm",
					open && "bg-blue-50 ring-2 ring-blue-300",
				)}
				disabled={!props.editable}
			>
				{newValue || props.value}
			</PopoverTrigger>
			<PopoverContent align="start" className="overflow-hidden p-0">
				<input
					value={newValue}
					onChange={(e) => {
						setNewValue(e.target.value);
					}}
					className="w-full p-2 text-sm outline-none focus:outline-none"
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							setOpen(false);
							onSubmit();
						}
					}}
				/>
			</PopoverContent>
		</Popover>
	);
});
