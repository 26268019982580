import { Button } from "@/components/ui/button";
import {
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { X } from "@phosphor-icons/react";
import { getRouteApi } from "@tanstack/react-router";
import { formatDistanceToNow } from "date-fns";
import { matchSorter } from "match-sorter";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { toast } from "sonner";

interface WebSearchComboboxCommandListProps {
	setShowCommandList?: (show: boolean) => void;
}

export const WebSearchComboboxCommandList = observer(
	({ setShowCommandList }: WebSearchComboboxCommandListProps) => {
		const routeApi = getRouteApi("/web-search");
		const { tabState } = routeApi.useLoaderData();
		const { query } = tabState.config;
		const tabStore = tabState.tab.tabStore;

		const filteredSearchHistory = useMemo(
			() =>
				matchSorter(
					tabStore.appState.webSearchStore.uniqueSearchHistory,
					query,
					{
						keys: ["query"],
					},
				),
			[tabStore.appState.webSearchStore.uniqueSearchHistory, query],
		);

		return (
			<CommandList className="max-h-full">
				{query && (
					<CommandGroup className="p-2">
						<CommandItem
							key={"current_search"}
							value={query}
							className="flex w-full items-center gap-2 rounded-md"
							onSelect={() => {
								tabState.handleSearch();
								setShowCommandList?.(false);
							}}
						>
							<div className="w-full min-w-0">
								<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
									Search for "{query}"
								</h3>
							</div>
						</CommandItem>
					</CommandGroup>
				)}

				<CommandGroup
					className="p-2"
					heading={
						<span className="flex items-baseline gap-2">
							<h2 className="flex grow items-center gap-1">
								<span className="text-xs">Recent searches</span>
							</h2>{" "}
							<Button
								className="h-max rounded p-1 text-xs"
								variant="ghost"
								onClick={() => {
									toast.error("Not implemented");
								}}
							>
								Clear
							</Button>
						</span>
					}
				>
					<CommandEmpty>No recent searches</CommandEmpty>
					{/** TODO: This is not actually a search history since it just draws from the search store? */}
					{filteredSearchHistory.map((webSearch, idx) => {
						const { web_search_id, query } = webSearch;

						return (
							<CommandItem
								key={web_search_id}
								// we use the index to ensure that the value is unique,
								// otherwise hovering on one item will highlight all items with the same query
								// (but possibly different search modes)
								value={`${query} ${idx}`}
								className="group flex w-full items-center gap-2 rounded-md"
								onSelect={() => {
									// TODO(John): make this open a new tab again
									tabState.tab.router.navigate({
										to: "/web-search/search/$web-search-id",
										params: {
											"web-search-id": web_search_id,
										},
									});
									setShowCommandList?.(false);
								}}
							>
								<div className="w-full min-w-0">
									<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
										{query}
									</h3>
									{/** Timestamp */}
									<span className="text-xs">
										{formatDistanceToNow(new Date(webSearch.created_at ?? ""))}{" "}
										ago
									</span>
								</div>
								<button
									type="button"
									className="rounded-md p-2 opacity-0 hover:bg-neutral-200 group-hover:opacity-100"
									onClick={(e) => {
										e.stopPropagation();
										toast.error("Not implemented");
									}}
								>
									<X size={16} />
								</button>
							</CommandItem>
						);
					})}
				</CommandGroup>
			</CommandList>
		);
	},
);
