import type { AppState } from "@/contexts/app-context/app-context";
import { DisplayedActionError } from "@/contexts/synced-actions";
import type { UserTableState } from "@/contexts/tables/stores/table-store";
import { createFieldId, createWriteId } from "@/lib/id-generators";
import { OptimisticAction, type Transaction } from "@/lib/sync/action-executor";
import {
	addFieldRoute,
	addRelationshipRoute,
	deleteFieldRoute,
	updateFieldRoute,
	updateSelectFieldPropertiesRoute,
} from "@api/fastAPI";
import type {
	Field,
	FieldId,
	FieldOrderStr,
	RecordUpdate,
	RelationshipField,
	ResourceLink,
	SelectFieldProperties,
	SelectOption,
	SelectOptionColor,
	SelectOptionLabel,
	TableId,
	ViewField,
	WriteId,
} from "@api/schemas";
import { generateJitteredKeyBetween } from "fractional-indexing-jittered";

const DEFAULT_FIELD_WIDTH = 320;

function createField<T extends Field>(props: {
	fieldId?: FieldId;
	name: string;
	tableId: TableId;
	order: FieldOrderStr;
	type: T["type"];
	properties: T["properties"];
}): T {
	return {
		field_id: props.fieldId ?? createFieldId(),
		type: props.type,
		properties: props.properties,
		table_id: props.tableId,
		name: props.name,
		order: props.order,
		width: DEFAULT_FIELD_WIDTH,
		created_at: new Date().toISOString(),
		updated_at: new Date().toISOString(),
		deleted_at: null,
		write_id: createWriteId(),
	} as T;
}

export class AddFieldAction<
	T extends Exclude<Field, RelationshipField | ViewField>,
> extends OptimisticAction<
	AppState,
	{
		name: string;
		type: T["type"];
		properties: T["properties"];
		tableState: UserTableState;
	},
	{
		newField: T;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		newField: T;
	}> {
		const sortedFields = this.args.tableState.sortedFields;
		if (sortedFields.isErr()) {
			throw new DisplayedActionError("Failed to get sorted fields");
		}

		const newField = createField<T>({
			name: this.args.name,
			tableId: this.args.tableState.tableId,
			order: generateJitteredKeyBetween(
				sortedFields.value.regularFields.at(-1)?.[0].order ?? null,
				null,
			) as FieldOrderStr,
			type: this.args.type,
			properties: this.args.properties,
		});

		state.workspace.fields.items.insert(tx, newField);
		return { newField };
	}

	async remote(context: {
		localResult: { newField: T };
	}): Promise<void> {
		await addFieldRoute({
			field: context.localResult.newField,
		});
	}
}

/**
 * Adds a relationship field to the table.
 */
export class AddRelationshipAction extends OptimisticAction<
	AppState,
	{
		name: string;
		foreignTableId: TableId;
		tableState: UserTableState;
	},
	{
		sourceField: RelationshipField;
		foreignField: RelationshipField;
	},
	undefined
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		sourceField: RelationshipField;
		foreignField: RelationshipField;
	}> {
		const sourceFieldId = createFieldId();
		const sourceSortedFields = this.args.tableState.sortedFields;
		if (sourceSortedFields.isErr()) {
			throw new DisplayedActionError(
				`Failed to get sorted fields: ${sourceSortedFields.error.message}`,
			);
		}
		const sourceFieldOrder = generateJitteredKeyBetween(
			sourceSortedFields.value.regularFields.at(-1)?.[0].order ?? null,
			null,
		) as FieldOrderStr;

		const foreignFieldId = createFieldId();
		const foreignSortedFields = state.workspace.tables.getTableStateById(
			this.args.foreignTableId,
		).sortedFields;
		if (foreignSortedFields.isErr()) {
			throw new DisplayedActionError(
				`Failed to get sorted fields: ${foreignSortedFields.error.message}`,
			);
		}
		const foreignFieldOrder = generateJitteredKeyBetween(
			foreignSortedFields.value.regularFields.at(-1)?.[0].order ?? null,
			null,
		) as FieldOrderStr;

		const sourceField = createField<RelationshipField>({
			fieldId: sourceFieldId,
			name: this.args.name,
			tableId: this.args.tableState.tableId,
			order: sourceFieldOrder,
			type: "relationship",
			properties: {
				foreign_table_id: this.args.foreignTableId,
				foreign_field_id: foreignFieldId,
				default_value: null,
				is_primary: false,
			},
		});

		const foreignField = createField<RelationshipField>({
			fieldId: foreignFieldId,
			name: this.args.name,
			tableId: this.args.foreignTableId,
			order: foreignFieldOrder,
			type: "relationship",
			properties: {
				foreign_table_id: this.args.tableState.tableId,
				foreign_field_id: sourceFieldId,
				default_value: null,
				is_primary: false,
			},
		});

		state.workspace.fields.items.insert(tx, sourceField);
		state.workspace.fields.items.insert(tx, foreignField);

		return { sourceField, foreignField };
	}

	async remote(
		context: {
			localResult: {
				sourceField: RelationshipField;
				foreignField: RelationshipField;
			};
		},
		_state: AppState,
	): Promise<undefined> {
		await addRelationshipRoute({
			source_field: context.localResult.sourceField,
			foreign_field: context.localResult.foreignField,
		});

		return undefined;
	}
}

/**
 * Updates a single field's name or width.
 *
 * Only define the fields that should be updated.
 */
export class UpdateFieldAction extends OptimisticAction<
	AppState,
	{
		fieldId: FieldId;
		newName?: string;
		newWidth?: number;
		tableState: UserTableState;
	},
	{
		updatedAt: string;
		writeId: WriteId;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		updatedAt: string;
		writeId: WriteId;
	}> {
		const updatedAt = new Date().toISOString();
		const writeId = createWriteId();
		state.workspace.fields.items.update(tx, this.args.fieldId, {
			updated_at: updatedAt,
			name: this.args.newName ?? undefined,
			width: this.args.newWidth ?? undefined,
			write_id: writeId,
		});
		return { updatedAt, writeId };
	}

	async remote(
		context: {
			localResult: { updatedAt: string; writeId: WriteId };
		},
		_state: AppState,
	): Promise<undefined> {
		await updateFieldRoute({
			field_id: this.args.fieldId,
			write_id: context.localResult.writeId,
			name: this.args.newName ?? null,
			width: this.args.newWidth ?? null,
			updated_at: context.localResult.updatedAt,
		});
	}
}

export class MoveFieldAction extends OptimisticAction<
	AppState,
	{
		fieldId: FieldId;
		precedingFieldId: FieldId | null;
		tableState: UserTableState;
	},
	{
		updatedAt: string;
		newOrder: FieldOrderStr;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		updatedAt: string;
		newOrder: FieldOrderStr;
	}> {
		const sortedFields = this.args.tableState.sortedFields;
		if (sortedFields.isErr()) {
			throw new DisplayedActionError(
				`Failed to get sorted fields: ${sortedFields.error.message}`,
			);
		}

		let lowerOrder: FieldOrderStr | null;
		let upperOrder: FieldOrderStr | null;

		if (this.args.precedingFieldId === null) {
			lowerOrder = null;
			upperOrder = sortedFields.value.regularFields.at(0)?.[0].order ?? null;
		} else {
			const precedingFieldResult = this.args.tableState.getFieldById(
				this.args.precedingFieldId,
			);
			if (precedingFieldResult.isErr()) {
				throw new DisplayedActionError(
					`Failed to get preceding field: ${precedingFieldResult.error.message}`,
				);
			}
			const [precedingField] = precedingFieldResult.value;
			lowerOrder = precedingField.order;

			const nextFieldResult = this.args.tableState.getRegularFieldAfter(
				this.args.precedingFieldId,
			);
			if (nextFieldResult.isErr()) {
				throw new DisplayedActionError(
					`Failed to get next field: ${nextFieldResult.error.message}`,
				);
			}
			const nextField = nextFieldResult.value;
			upperOrder = nextField?.order ?? null;
		}

		const newOrder = generateJitteredKeyBetween(
			lowerOrder,
			upperOrder,
		) as FieldOrderStr;

		const updatedAt = new Date().toISOString();
		const writeId = createWriteId();
		state.workspace.fields.items.update(tx, this.args.fieldId, {
			updated_at: updatedAt,
			order: newOrder,
			write_id: writeId,
		});
		return { newOrder, updatedAt };
	}

	async remote(
		context: {
			localResult: {
				newOrder: FieldOrderStr;
				updatedAt: string;
				writeId: WriteId;
			};
		},
		_state: AppState,
	): Promise<void> {
		await updateFieldRoute({
			field_id: this.args.fieldId,
			write_id: context.localResult.writeId,
			order: context.localResult.newOrder,
			name: null,
			width: null,
			updated_at: context.localResult.updatedAt,
		});
	}
}

export class DeleteFieldAction extends OptimisticAction<
	AppState,
	{
		fieldId: FieldId;
		tableState: UserTableState;
	},
	{
		deletedAt: string;
		writeId: WriteId;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		deletedAt: string;
		writeId: WriteId;
	}> {
		const deletedAt = new Date().toISOString();
		const writeId = createWriteId();
		state.workspace.fields.items.update(tx, this.args.fieldId, {
			deleted_at: deletedAt,
			write_id: writeId,
		});

		return { deletedAt, writeId };
	}

	async remote(
		context: {
			localResult: { deletedAt: string; writeId: WriteId };
		},
		_state: AppState,
	): Promise<void> {
		await deleteFieldRoute({
			field_id: this.args.fieldId,
			write_id: context.localResult.writeId,
			deleted_at: context.localResult.deletedAt,
		});
	}
}

export class AddSelectOptionAction extends OptimisticAction<
	AppState,
	{
		fieldId: FieldId;
		newOption: SelectOption;
		recordLinkToSet?: ResourceLink;
		tableState: UserTableState;
	},
	{
		updatedAt: string;
		writeId: WriteId;
		newProperties: SelectFieldProperties;
		recordUpdates: RecordUpdate[];
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		updatedAt: string;
		writeId: WriteId;
		newProperties: SelectFieldProperties;
		recordUpdates: RecordUpdate[];
	}> {
		const updatedAt = new Date().toISOString();
		const field = this.args.tableState.getFieldById(this.args.fieldId);
		if (field.isErr()) {
			throw new DisplayedActionError(
				`Failed to get field: ${field.error.message}`,
			);
		}
		const selectField = field.value[0];
		if (selectField.type !== "select") {
			throw new Error("Field is not a select field");
		}

		const writeId = createWriteId();
		const newProperties = {
			...selectField.properties,
			options: {
				...selectField.properties.options,
				[this.args.newOption.label]: this.args.newOption,
			},
		} as SelectFieldProperties;

		state.workspace.fields.items.update(tx, this.args.fieldId, {
			updated_at: updatedAt,
			properties: newProperties,
			write_id: writeId,
		});

		const recordUpdates: RecordUpdate[] = [];
		if (this.args.recordLinkToSet !== undefined) {
			const recordLinkToSet = this.args.recordLinkToSet;
			const record = this.args.tableState.getRecordByLink(recordLinkToSet);
			this.args.tableState.records.update(tx, recordLinkToSet, {
				cell_values: {
					...record.cell_values,
					[this.args.fieldId]: this.args.newOption.label,
				},
			});

			recordUpdates.push({
				link: recordLinkToSet,
				order: null,
				cell_values: {
					[this.args.fieldId]: this.args.newOption.label,
				},
			});
		}

		return { updatedAt, writeId, newProperties, recordUpdates };
	}

	async remote(
		context: {
			localResult: {
				updatedAt: string;
				writeId: WriteId;
				newProperties: SelectFieldProperties;
				recordUpdates: RecordUpdate[];
			};
		},
		_state: AppState,
	): Promise<void> {
		await updateSelectFieldPropertiesRoute({
			field_id: this.args.fieldId,
			write_id: context.localResult.writeId,
			updated_at: context.localResult.updatedAt,
			select_field_properties: context.localResult.newProperties,
			record_updates: context.localResult.recordUpdates,
		});
	}
}

export class DeleteSelectOptionAction extends OptimisticAction<
	AppState,
	{
		fieldId: FieldId;
		label: SelectOptionLabel;
		tableState: UserTableState;
	},
	{
		recordUpdates: RecordUpdate[];
		updatedAt: string;
		writeId: WriteId;
		newProperties: SelectFieldProperties;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		recordUpdates: RecordUpdate[];
		updatedAt: string;
		writeId: WriteId;
		newProperties: SelectFieldProperties;
	}> {
		const fieldResult = this.args.tableState.getFieldById(this.args.fieldId);
		if (fieldResult.isErr()) {
			throw new DisplayedActionError(
				`Failed to get field: ${fieldResult.error.message}`,
			);
		}
		const [field] = fieldResult.value;
		if (field.type !== "select") {
			throw new Error("Field is not a select field");
		}
		const newOptions = Object.fromEntries(
			Object.entries(field.properties.options).filter(
				([label]) => label !== this.args.label,
			),
		);
		const newProperties = {
			...field.properties,
			options: newOptions,
		};

		// First, update the rows that currently have that option
		const recordUpdates: RecordUpdate[] = Array.from(
			this.args.tableState.records.values(),
		)
			.filter(
				(record) => record.cell_values[this.args.fieldId] === this.args.label,
			)
			.map((record) => {
				this.args.tableState.records.update(tx, record.link, {
					cell_values: {
						...record.cell_values,
						[this.args.fieldId]: null,
					},
				});
				return {
					link: record.link,
					order: null,
					cell_values: {
						[this.args.fieldId]: null,
					},
					write_id: createWriteId(),
				};
			});

		const updatedAt = new Date().toISOString();
		const writeId = createWriteId();
		state.workspace.fields.items.update(tx, this.args.fieldId, {
			updated_at: updatedAt,
			properties: newProperties,
			write_id: writeId,
		});

		return { recordUpdates, updatedAt, writeId, newProperties };
	}

	async remote(
		context: {
			localResult: {
				recordUpdates: RecordUpdate[];
				updatedAt: string;
				writeId: WriteId;
				newProperties: SelectFieldProperties;
			};
		},
		_state: AppState,
	): Promise<void> {
		await updateSelectFieldPropertiesRoute({
			field_id: this.args.fieldId,
			write_id: context.localResult.writeId,
			updated_at: context.localResult.updatedAt,
			select_field_properties: context.localResult.newProperties,
			record_updates: context.localResult.recordUpdates,
		});
	}
}

export class UpdateSelectOptionLabelAction extends OptimisticAction<
	AppState,
	{
		fieldId: FieldId;
		oldLabel: SelectOptionLabel;
		newLabel: SelectOptionLabel;
		tableState: UserTableState;
	},
	{
		recordUpdates: RecordUpdate[];
		updatedAt: string;
		writeId: WriteId;
		newProperties: SelectFieldProperties;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		recordUpdates: RecordUpdate[];
		updatedAt: string;
		writeId: WriteId;
		newProperties: SelectFieldProperties;
	}> {
		const fieldResult = this.args.tableState.getFieldById(this.args.fieldId);
		if (fieldResult.isErr()) {
			throw new DisplayedActionError(
				`Failed to get field: ${fieldResult.error.message}`,
			);
		}
		const [field] = fieldResult.value;
		if (field.type !== "select") {
			throw new Error("Field is not a select field");
		}

		const oldOption = field.properties.options[this.args.oldLabel];
		if (!oldOption) {
			throw new Error(`Option with label ${this.args.oldLabel} not found`);
		}

		if (this.args.newLabel in field.properties.options) {
			throw new Error(`Option '${this.args.newLabel}' already exists.`);
		}

		const newOptions = { ...field.properties.options };
		delete newOptions[this.args.oldLabel];
		newOptions[this.args.newLabel] = {
			...oldOption,
			label: this.args.newLabel,
		};

		const newProperties = {
			...field.properties,
			options: newOptions,
		};

		// Update all records that had the old label
		const recordUpdates: RecordUpdate[] = Array.from(
			this.args.tableState.records.values(),
		)
			.filter(
				(record) =>
					record.cell_values[this.args.fieldId] === this.args.oldLabel,
			)
			.map((record) => {
				this.args.tableState.records.update(tx, record.link, {
					cell_values: {
						...record.cell_values,
						[this.args.fieldId]: this.args.newLabel,
					},
				});

				return {
					link: record.link,
					order: null,
					cell_values: {
						[this.args.fieldId]: this.args.newLabel,
					},
					write_id: createWriteId(),
				};
			});

		const updatedAt = new Date().toISOString();
		const writeId = createWriteId();
		state.workspace.fields.items.update(tx, this.args.fieldId, {
			updated_at: updatedAt,
			properties: newProperties,
			write_id: writeId,
		});

		return { recordUpdates, updatedAt, writeId, newProperties };
	}

	async remote(
		context: {
			localResult: {
				recordUpdates: RecordUpdate[];
				updatedAt: string;
				writeId: WriteId;
				newProperties: SelectFieldProperties;
			};
		},
		_state: AppState,
	): Promise<void> {
		await updateSelectFieldPropertiesRoute({
			field_id: this.args.fieldId,
			write_id: context.localResult.writeId,
			updated_at: context.localResult.updatedAt,
			select_field_properties: context.localResult.newProperties,
			record_updates: context.localResult.recordUpdates,
		});
	}
}

export class UpdateSelectOptionColorAction extends OptimisticAction<
	AppState,
	{
		fieldId: FieldId;
		label: SelectOptionLabel;
		color: SelectOptionColor;
		tableState: UserTableState;
	},
	{
		updatedAt: string;
		writeId: WriteId;
		newProperties: SelectFieldProperties;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		updatedAt: string;
		writeId: WriteId;
		newProperties: SelectFieldProperties;
	}> {
		const fieldResult = this.args.tableState.getFieldById(this.args.fieldId);
		if (fieldResult.isErr()) {
			throw new DisplayedActionError(
				`Failed to get field: ${fieldResult.error.message}`,
			);
		}
		const [field] = fieldResult.value;
		if (field.type !== "select") {
			throw new Error("Field is not a select field");
		}

		const option = field.properties.options[this.args.label];
		if (!option) {
			throw new Error(`Option with label ${this.args.label} not found`);
		}

		const newProperties = {
			...field.properties,
			options: {
				...field.properties.options,
				[this.args.label]: { ...option, color: this.args.color },
			},
		};

		const updatedAt = new Date().toISOString();
		const writeId = createWriteId();
		state.workspace.fields.items.update(tx, this.args.fieldId, {
			updated_at: updatedAt,
			properties: newProperties,
			write_id: writeId,
		});

		return { updatedAt, writeId, newProperties };
	}

	async remote(
		context: {
			localResult: {
				updatedAt: string;
				writeId: WriteId;
				newProperties: SelectFieldProperties;
			};
		},
		_state: AppState,
	): Promise<void> {
		await updateSelectFieldPropertiesRoute({
			field_id: this.args.fieldId,
			write_id: context.localResult.writeId,
			updated_at: context.localResult.updatedAt,
			select_field_properties: context.localResult.newProperties,
			record_updates: [], // No record updates needed for color changes
		});
	}
}
