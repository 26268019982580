import {
	type FieldTypeIconProps,
	getFieldIcon,
} from "@/components/table/field-type-indicators";
import type { DataType, FieldType } from "@api/schemas";
import { ArrowDown, ArrowUp, Star } from "@phosphor-icons/react";
import type { Column, SortDirection } from "@tanstack/react-table";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

const SortingIcon = ({
	sortDirection,
}: { sortDirection: SortDirection | false }) => {
	if (!sortDirection) {
		return null;
	}

	return (
		<span className="rounded border border-blue-300 bg-blue-100 p-0.5 text-blue-500 text-md">
			{sortDirection === "asc" && <ArrowUp weight="bold" />}
			{sortDirection === "desc" && <ArrowDown weight="bold" />}
		</span>
	);
};

export const RenderFieldColumnIcon = (props: FieldTypeIconProps) => {
	const ColumnIcon = getFieldIcon(props);
	return <ColumnIcon className="shrink-0" />;
};

/**
 * A column header that represents a field in a table.
 */
export const FieldColumnHeader = observer(
	<TData, TValue>(props: {
		column: Column<TData, TValue>;
		name: string;
		fieldType: FieldType;
		dataType: DataType;
		isPrimary: boolean;
	}) => {
		return (
			<span
				className={clsx(
					"flex h-full w-full select-none items-center justify-between gap-2 truncate px-1.5 py-1 font-normal text-neutral-600 hover:bg-neutral-100",
					props.isPrimary && "bg-blue-50",
				)}
			>
				<span className="flex shrink-0 grow items-center gap-1.5 truncate">
					{RenderFieldColumnIcon({
						fieldType: props.fieldType,
						dataType: props.dataType,
					} as FieldTypeIconProps)}
					{/* TODO(John): text should be lighter if untitled */}
					<span className="truncate ">{props.name}</span>
				</span>
				{props.isPrimary && <Star />}
				<button type="button" onClick={props.column.getToggleSortingHandler()}>
					<SortingIcon sortDirection={props.column.getIsSorted()} />
				</button>
			</span>
		);
	},
);
