import type { Tab } from "@/contexts/tabs/tabs-context";
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";

interface RootRouteContext {
	tab: Tab;
}

export const Route = createRootRouteWithContext<RootRouteContext>()({
	component: () => (
		<>
			<Outlet />
			<TanStackRouterDevtools />
		</>
	),
});
