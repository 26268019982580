import { ThreadAssistantsPresenceIndicator } from "@/components/layout/right-sidebar/assistant-presence";
import type { ThreadRootMessage } from "@/contexts/app-context/db-store/message-store";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { ObjectLinkComponent } from "@/plugins/object-link";
import {
	autoUpdate,
	offset,
	safePolygon,
	useFloating,
	useHover,
	useInteractions,
} from "@floating-ui/react";
import { createFileRoute } from "@tanstack/react-router";

import { MessageTabState } from "@/contexts/messages/tab-state";
import { MessageWithMetadataComponent } from "@/pages/tabs/message/-components/message-component";
import { MessageInput } from "@/pages/tabs/message/-components/message-input";
import { ArrowBendDownRight } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

const ThreadPreview = observer(function ThreadPreview(props: {
	thread: ThreadRootMessage;
	scrollContainerRef: React.RefObject<HTMLDivElement>;
}) {
	const [isHovered, setIsHovered] = useState(false);
	const { refs, floatingStyles, context } = useFloating({
		placement: "top-end",
		open: isHovered,
		onOpenChange: setIsHovered,
		whileElementsMounted: autoUpdate,
		middleware: [offset({ mainAxis: -16, crossAxis: -8 })],
	});
	const hover = useHover(context, {
		handleClose: safePolygon(),
	});
	const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

	return (
		<div
			className={clsx(
				"flex w-full flex-col p-2",
				isHovered && "bg-neutral-100",
			)}
			ref={refs.setReference}
			{...getReferenceProps()}
		>
			<MessageWithMetadataComponent message={props.thread} />
			<div className="flex h-6 items-center justify-end gap-2">
				{props.thread.replies.length > 0 && (
					<div className="min-w-0 grow px-2">
						<ObjectLinkComponent
							href={`/message/${props.thread.message_id}`}
							className="flex h-fit w-fit justify-start gap-1 rounded-sm px-2 py-1 font-normal text-neutral-600 hover:bg-white"
						>
							<ArrowBendDownRight size={14} />
							<span className="text-xs">
								{props.thread.replies.length}{" "}
								{props.thread.replies.length === 1 ? "reply" : "replies"}
							</span>
						</ObjectLinkComponent>
					</div>
				)}
				<div className="mr-3">
					{/** Maybe this be show on hover unless there are active sessions */}
					{isHovered && (
						<ThreadAssistantsPresenceIndicator
							messageId={props.thread.message_id}
						/>
					)}
				</div>
			</div>

			{isHovered && (
				<div
					ref={refs.setFloating}
					style={floatingStyles}
					{...getFloatingProps()}
					className="flex h-fit border bg-white"
				>
					<ObjectLinkComponent
						href={`/message/${props.thread.message_id}`}
						className="h-fit justify-start rounded-none px-2 py-1 font-semibold text-neutral-600 text-xs"
					>
						Reply to thread
					</ObjectLinkComponent>
				</div>
			)}
		</div>
	);
});

/**
 * Message home. Shows all threads.
 */
const Threads = observer(function Threads({
	tabState,
}: {
	tabState: MessageTabState;
}) {
	const appContext = useAppContext();
	const messageStore = appContext.messageStore;
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const messagesEndRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (messageStore.threads.length > 0) {
			messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
		}
	}, [messageStore.threads]);

	return (
		<>
			<div className="relative h-full overflow-hidden">
				<div
					className="flex h-full flex-col overflow-y-auto py-2"
					ref={scrollContainerRef}
				>
					{messageStore.threads.map((thread) => (
						<ThreadPreview
							key={thread.message_id}
							thread={thread}
							scrollContainerRef={scrollContainerRef}
						/>
					))}
					<div ref={messagesEndRef} />
				</div>
			</div>
			<MessageInput tabState={tabState} />
		</>
	);
});

export const Route = createFileRoute("/message/")({
	component: () => {
		const { tabState } = Route.useLoaderData();
		return <Threads tabState={tabState} />;
	},
	loader: ({ context: { tab } }) => {
		const tabState = new MessageTabState(tab, null);
		return {
			tabState,
		};
	},
});
