import type { BaseRendererProps } from "@/components/table/scalar-renderers/renderer-props";
import {
	SelectOptionBackgroundColors,
	SelectOptionBorderColors,
	chooseRandomSelectOptionColor,
} from "@/components/table/scalar-renderers/select-colors";
import { Badge } from "@/components/ui/badge";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "@/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import type {
	SelectField,
	SelectOption,
	SelectOptionLabel,
} from "@api/schemas";
import { Check, Plus, X } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

type SelectPopoverContentProps = {
	value: SelectOptionLabel | null;
	options: SelectField["properties"]["options"];
	onUpdate?: (value: SelectOptionLabel | null) => void;
	onAddSelectOption?: (option: SelectOption) => void;
	onClose: () => void;
};

const SelectPopoverContent = observer((props: SelectPopoverContentProps) => {
	const [query, setQuery] = useState("");
	const myOption = props.value ? props.options[props.value] : null;

	return (
		<PopoverContent className="w-48 p-0" align="start">
			<Command>
				<CommandInput
					value={query}
					onValueChange={setQuery}
					placeholder="Search options..."
				/>
				<CommandList>
					<CommandEmpty>No options found.</CommandEmpty>
					<CommandGroup>
						{query && !props.options[query] && (
							<>
								<CommandItem
									value={query}
									onSelect={() => {
										props.onAddSelectOption?.({
											label: query as SelectOptionLabel,
											color: chooseRandomSelectOptionColor(),
										});
										props.onClose();
									}}
								>
									<Plus className={clsx("mr-2 h-4 w-4")} />
									Add "{query}"
								</CommandItem>
								<CommandSeparator />
							</>
						)}

						{Object.values(props.options).map((option) => (
							<CommandItem
								key={option.label}
								value={option.label}
								onSelect={(newValue) => {
									props.onUpdate?.(newValue as SelectOptionLabel);
									props.onClose();
								}}
							>
								<Check
									className={clsx(
										"mr-2 h-4 w-4",
										myOption?.label === option.label
											? "opacity-100"
											: "opacity-0",
									)}
								/>
								<Badge
									className={clsx(
										SelectOptionBackgroundColors[option.color],
										SelectOptionBorderColors[option.color],
										"text-neutral-800",
									)}
								>
									{option.label}
								</Badge>
							</CommandItem>
						))}

						{myOption && (
							<>
								<CommandSeparator className="mt-1 mb-1" />
								<CommandItem
									value={`Remove "${myOption.label}"`}
									onSelect={() => {
										props.onUpdate?.(null);
										props.onClose();
									}}
								>
									<X className={clsx("mr-2 h-4 w-4")} />
									Clear{" "}
									<Badge
										className={clsx(
											SelectOptionBackgroundColors[myOption.color],
											SelectOptionBorderColors[myOption.color],
											"ml-2 text-neutral-800",
										)}
									>
										{myOption.label}
									</Badge>
								</CommandItem>
							</>
						)}
					</CommandGroup>
				</CommandList>
			</Command>
		</PopoverContent>
	);
});

type SelectRendererProps = BaseRendererProps<"select_option"> & {
	options?: SelectField["properties"]["options"];
	onAddSelectOption?: (option: SelectOption) => void;
};

export const SelectRenderer = observer((props: SelectRendererProps) => {
	const [open, setOpen] = useState(false);
	const myOption =
		props.value && props.options ? props.options[props.value] : null;

	const renderValue = () => {
		if (!myOption) return null;
		return (
			<Badge
				variant="outline"
				className={clsx(
					SelectOptionBackgroundColors[myOption.color],
					SelectOptionBorderColors[myOption.color],
					"min-w-0 truncate text-neutral-800",
				)}
			>
				{myOption.label}
			</Badge>
		);
	};

	if (!props.editable || !props.options) {
		return (
			<div className="flex h-full w-full min-w-0 items-start truncate p-1 text-left text-neutral-800 text-sm">
				{renderValue()}
			</div>
		);
	}

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger
				className={clsx(
					"flex h-full w-full min-w-0 items-start truncate p-1 text-left text-neutral-800 text-sm",
					open && "bg-blue-50 ring-2 ring-blue-300",
				)}
			>
				{renderValue()}
			</PopoverTrigger>
			<SelectPopoverContent
				value={props.value}
				options={props.options}
				onUpdate={props.onUpdate}
				onAddSelectOption={props.onAddSelectOption}
				onClose={() => setOpen(false)}
			/>
		</Popover>
	);
});
