import type { ResourceLink, ResourceRef } from "@api/schemas";

export type LinkableResourceRef = Extract<
	ResourceRef,
	{
		type:
			| "feed-channel"
			| "feed-item"
			| "table"
			| "upload"
			| "search-result"
			| "webpage"
			| "web-search-result"
			| "page";
	}
>;
/**
 * Resource paths are constructed in a standard way:
 *
 * /[integration type]/[resource type]/[resource id]
 *
 * We use the router to get resourceRefs backwards. See the `head` property in
 * the tab state for how this is used.
 */
export function resourceRefToPath({
	resource_id,
	type,
}: LinkableResourceRef): ResourceLink {
	switch (type) {
		case "feed-item":
			return `/feeds/feed-item/${resource_id}` as ResourceLink;
		case "feed-channel":
			return `/feeds/feed-channel/${resource_id}` as ResourceLink;
		case "table":
			return `/tables/table/${resource_id}` as ResourceLink;
		case "upload":
			return `/uploads/upload/${resource_id}` as ResourceLink;
		case "search-result":
			return `/search/search-result/${resource_id}` as ResourceLink;
		case "webpage":
			return `/web/webpage/${resource_id}` as ResourceLink;
		case "web-search-result":
			return `/web/web-search-result/${resource_id}` as ResourceLink;
		case "page":
			return `/pages/${resource_id}` as ResourceLink;
	}
}
