import {
	computeGappedHighlight,
	computePaginatedHighlight,
} from "@/lib/highlight";
import type { FeedItemId, TocSection, UploadId, WebpageId } from "@api/schemas";
import range from "lodash.range";
import { flow, makeAutoObservable, runInAction } from "mobx";
import type { PDFDocumentProxy } from "pdfjs-dist";
import type { TextItem } from "pdfjs-dist/types/src/display/api";
import { createContext, useContext, useState } from "react";
import type { VariableSizeList } from "react-window";
import { z } from "zod";

// Pixels to pad each page with
export const PAGE_PADDING = 4;

/**
 * A highlight object that contains the text to highlight and the page indices to search for it.
 *
 * @param textToHighlight - The text to highlight. If `textEnd` is not provided, the highlight is a single span.
 * @param startPageIndex - The page index to start highlighting from.
 * @param endPageIndex - The page index to end highlighting at.
 */
export type HighlightQuery = {
	textToHighlight: {
		textStart: string;
		textEnd?: string;
	};
	startPageIndex: number;
	endPageIndex: number;
};

/**
 * A found highlight containing the exact text spans to highlight
 */
export interface HighlightResult {
	firstSpan: {
		item: TextItem;
		pageIndex: number;
		itemIndex: number;
	};
	lastSpan: {
		item: TextItem;
		pageIndex: number;
		itemIndex: number;
	};
	firstSpanCharIdx: number;
	lastSpanCharIdx: number;
}

export type PdfResourceId = UploadId | WebpageId | FeedItemId;

export type PdfHeaderMetadata = {
	title: string;
	authors: string[];
	description: string | null;
	url: string | null;
};

export const pdfSearchSchema = z.object({
	pageIndex: z
		.number()
		.optional()
		.describe("Page index, when a single page is specified."),
	highlightStartPageIndex: z
		.number()
		.optional()
		.describe(
			"Start page index, when highlights are specified. Overrides pageIndex.",
		),
	highlightEndPageIndex: z
		.number()
		.optional()
		.describe(
			"End page index, when highlights are specified. Overrides pageIndex.",
		),
	highlightStart: z
		.string()
		.optional()
		.describe("Start text, when highlights are specified."),
	highlightEnd: z
		.string()
		.optional()
		.describe("End text, when highlights are specified."),
});

export type PdfSearchParams = z.infer<typeof pdfSearchSchema>;

/**
 * The state of the PDF viewer
 */
export class PDFViewerState {
	currentPageIndex = 0;
	pdfScale = 1;

	resourceId: PdfResourceId;

	// Map of raw highlights
	rawHighlights: Map<string, HighlightQuery> = new Map();
	highlightResults: Map<string, HighlightResult> = new Map();

	// Raw highlights are
	// - start text
	// - start page index
	// - end page index
	rawActiveHighlight: HighlightQuery | null = null;

	/**
	 * Raw PDF bytes to render
	 */
	pdfBytes: { data: Uint8Array } | null = null;

	/**
	 * Thumbnail URL for a given page index
	 */
	thumbnailUrl: (pageIndex: number) => string;

	/**
	 * Table of contents for the PDF
	 */
	toc: TocSection[] | null = null;

	/**
	 * Current PDF document and page dimensions
	 */
	pdf: {
		document: PDFDocumentProxy;
		pageDimensions: Map<number, [number, number]>;
		averagePageHeight: number;
	} | null = null;

	/**
	 * Ref to the virtualized list that renders the PDF pages
	 */
	listRef: VariableSizeList | null = null;

	/**
	 * Whether the sidebar is currently visible
	 */
	showSidebar = false;

	headerMetadata: PdfHeaderMetadata;
	searchParams: PdfSearchParams;
	constructor({
		resourceId,
		loader,
		searchParams,
		tocLoader,
		thumbnailUrl,
		headerMetadata,
	}: {
		resourceId: PdfResourceId;
		loader: () => Promise<Uint8Array>;
		searchParams: PdfSearchParams;
		tocLoader: () => Promise<TocSection[]>;
		thumbnailUrl: (pageIndex: number) => string;
		headerMetadata: PdfHeaderMetadata;
	}) {
		this.resourceId = resourceId;
		this.thumbnailUrl = thumbnailUrl;
		this.headerMetadata = headerMetadata;
		this.searchParams = searchParams;
		loader().then((data) => {
			this.pdfBytes = { data };
		});

		tocLoader().then((toc) => {
			this.toc = toc;
		});

		makeAutoObservable(this);
	}

	toggleSidebar() {
		this.showSidebar = !this.showSidebar;
	}

	/**
	 * Set the highlights to search for
	 */
	setHighlightQueries(highlights: HighlightQuery[]) {
		this.rawHighlights.clear();
		for (const highlight of highlights) {
			this.rawHighlights.set(highlight.textToHighlight.textStart, highlight);
		}
	}

	setCurrentPageIndex(index: number) {
		this.currentPageIndex = index;
	}

	/**
	 * Initialize the PDF document and extract page dimensions for the virtualized list
	 */
	async initPdf(document: PDFDocumentProxy) {
		const pages = await Promise.all(
			[...Array(document.numPages)].map((_, i) => document.getPage(i + 1)),
		);

		// Assuming all pages may have different heights. Otherwise we can just
		// load the first page and use its height for determining all the row
		// heights.
		const pageDimensions = new Map(
			pages.map((page) => [
				page._pageIndex,
				[
					page.view[2] - page.view[0] + PAGE_PADDING * 2,
					page.view[3] - page.view[1] + PAGE_PADDING * 2,
				] as [number, number],
			]),
		);

		const totalHeight = [...pageDimensions.values()].reduce(
			(sum, [_, h]) => sum + h,
			0,
		);

		runInAction(() => {
			this.pdf = {
				document,
				pageDimensions,
				averagePageHeight: totalHeight / document.numPages,
			};
		});
	}

	/**
	 * Compute the highlight results for all the highlights
	 */
	calculateHighlightResults = flow(function* (this: PDFViewerState) {
		if (!this.pdf) {
			return;
		}

		// For each "raw" highlight, where a raw highlight is
		// - start text
		// - start page index
		// - end page index
		// We get the page texts for each page in the range and then compute the highlight result
		for (const [id, rawHighlight] of this.rawHighlights.entries()) {
			const { startPageIndex, endPageIndex, textToHighlight } = rawHighlight;

			const pageTextsPromises = range(startPageIndex, endPageIndex + 1).map(
				async (pageIndex) => {
					if (!this.pdf) throw new Error("PDF not loaded");

					const page = await this.pdf.document.getPage(pageIndex + 1);
					const pageText = await page.getTextContent();
					return { pageIndex, pageText };
				},
			);

			const pageTexts = yield Promise.all(pageTextsPromises);

			const { textStart, textEnd } = textToHighlight;
			const highlightResult = textEnd
				? computeGappedHighlight(textStart, textEnd, pageTexts)
				: computePaginatedHighlight(textStart, pageTexts);

			runInAction(() => {
				this.highlightResults.set(id, highlightResult);
			});
		}
	});

	get activeHighlightResult() {
		if (!this.rawActiveHighlight) return null;
		return (
			this.highlightResults.get(
				this.rawActiveHighlight.textToHighlight.textStart,
			) ?? null
		);
	}

	get numPages() {
		return this.pdf?.document.numPages ?? null;
	}
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const PDFViewerContext = createContext<PDFViewerState>(null as any);

export const usePDFViewerContext = () => {
	const context = useContext(PDFViewerContext);
	if (!context) {
		throw new Error("useViewerContext must be used within a ViewerProvider");
	}
	return context;
};

export const PDFViewerProvider: React.FC<{
	children: React.ReactNode;
	resourceId: PdfResourceId;
	searchParams: PdfSearchParams;
	loader: () => Promise<Uint8Array>;
	tocLoader: () => Promise<TocSection[]>;
	thumbnailUrl: (pageIndex: number) => string;
	headerMetadata: PdfHeaderMetadata;
}> = function PDFViewerProvider({
	children,
	resourceId,
	searchParams,
	loader,
	tocLoader,
	thumbnailUrl,
	headerMetadata,
}) {
	const [viewerState] = useState(
		() =>
			new PDFViewerState({
				resourceId,
				searchParams,
				loader,
				tocLoader,
				thumbnailUrl,
				headerMetadata,
			}),
	);

	return (
		<PDFViewerContext.Provider value={viewerState}>
			{children}
		</PDFViewerContext.Provider>
	);
};
