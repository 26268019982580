import { Cell } from "@/components/table/cell";
import { FieldColumnHeader } from "@/components/table/field-column-header";
import { FieldModifierPopover } from "@/components/table/field-modifier-popover";
import { dataTypeToFieldType } from "@/components/table/field-type-indicators";
import { SELECT_COLUMN_ID } from "@/components/table/utils";
import { Checkbox } from "@/components/ui/checkbox";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { isFieldPrimary } from "@/contexts/tables/stores/field-store";
import { useTableViewContext } from "@/contexts/tables/use-table-context";
import { ObjectLinkContent } from "@/plugins/object-link";
import { ObjectLinkComponent } from "@/plugins/object-link";
import type {
	CellValue,
	DataType,
	Field,
	Record,
	ScalarType,
	SelectOption,
} from "@api/schemas";
import { useDraggable } from "@dnd-kit/core";
import { DotsSixVertical, Plus } from "@phosphor-icons/react";
import { createColumnHelper } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";

/**
 * Leftmost selector column for user tables.
 */
export const getSelectorColumn = () => {
	const columnHelper = createColumnHelper<Record>();
	return columnHelper.display({
		id: SELECT_COLUMN_ID,
		enableResizing: false,
		header: ({ table }) => {
			<div className="flex h-full w-full items-center justify-end pr-2 pl-0.5">
				<Checkbox
					checked={
						table.getIsAllRowsSelected()
							? true
							: table.getIsSomeRowsSelected()
								? "indeterminate"
								: false
					}
					onCheckedChange={(checked) =>
						table.toggleAllRowsSelected(checked === true)
					}
				/>
			</div>;
		},
		cell: ({ row }) => {
			const { attributes, listeners, setNodeRef } = useDraggable({
				id: row.original.link,
			});
			const tableViewState = useTableViewContext();
			return (
				<div
					className="flex h-full min-h-8 w-full items-center justify-between gap-0.5 bg-white pr-2 pl-1.5"
					onClick={(e) => {
						e.stopPropagation();
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.stopPropagation();
						}
					}}
				>
					<Tooltip>
						<TooltipTrigger asChild>
							<button
								type="button"
								className="flex rounded p-0.5 text-base text-neutral-400 opacity-0 hover:bg-neutral-100 hover:text-neutral-700 group-hover/table-row:opacity-100"
								onClick={() => {
									tableViewState.addRow(row.original.link);
								}}
							>
								<Plus weight="bold" />
							</button>
						</TooltipTrigger>
						<TooltipContent>Insert row below</TooltipContent>
					</Tooltip>
					<Tooltip>
						<TooltipTrigger asChild>
							<button
								className="flex rounded text-lg text-neutral-400 opacity-0 hover:bg-neutral-100 hover:text-neutral-700 group-hover/table-row:opacity-100"
								ref={setNodeRef}
								{...attributes}
								{...listeners}
							>
								<DotsSixVertical weight="bold" />
							</button>
						</TooltipTrigger>
						<TooltipContent>Drag to reorder</TooltipContent>
					</Tooltip>
					<Checkbox
						checked={row.getIsSelected()}
						onCheckedChange={(checked) => row.toggleSelected(checked === true)}
						onClick={(e) => {
							e.stopPropagation();
						}}
					/>
				</div>
			);
		},
		size: 72,
		maxSize: 72,
	});
};

/**
 * Column for displaying a record link.
 *
 * Every table should have a link for the row, which represents the row's
 * resource.
 */
export const getRecordLinkColumn = <T extends { link: string }>() => {
	const columnHelper = createColumnHelper<T>();
	return columnHelper.display({
		id: "__record_link",
		header: ({ column }) => (
			<FieldColumnHeader
				column={column}
				name="Link"
				fieldType="text"
				dataType={{ scalar_type: "text", array_depth: 0 }}
				isPrimary={false}
			/>
		),
		cell: observer((props) => {
			return (
				<ObjectLinkComponent href={props.row.original.link}>
					<ObjectLinkContent href={props.row.original.link} />
				</ObjectLinkComponent>
			);
		}),
	});
};

/**
 * Column for a field in a user table.
 */
export const getFieldColumn = (
	field: Field,
	dataType: DataType,
	editable: boolean,
) => {
	const columnHelper = createColumnHelper<Record>();
	return columnHelper.display({
		id: field.field_id,
		header: ({ column }) => {
			const header = (
				<FieldColumnHeader
					column={column}
					isPrimary={isFieldPrimary(field)}
					fieldType={field.type}
					dataType={dataType}
					name={field.name}
				/>
			);
			if (editable) {
				return (
					<FieldModifierPopover field={field}>{header}</FieldModifierPopover>
				);
			}
			return header;
		},
		cell: observer((props) => {
			const tableViewState = useTableViewContext();
			const editableProps = editable
				? {
						onUpdate: (value: CellValue) => {
							tableViewState.tableState.updateCellValue({
								recordLink: props.row.original.link,
								fieldId: field.field_id,
								cellValue: value,
							});
						},
						onAddSelectOption: (option: SelectOption) => {
							tableViewState.tableState.addSelectOption({
								fieldId: field.field_id,
								newOption: option,
								recordLinkToSet: props.row.original.link,
							});
						},
					}
				: undefined;
			return (
				<Cell
					field={field}
					dataType={dataType}
					value={props.row.original.cell_values[field.field_id]}
					editable={editable}
					{...editableProps}
				/>
			);
		}),
	});
};

/**
 * Column for a resource index.
 */
export const getResourceIndexColumn = <T,>(props: {
	key: string & keyof T;
	name: string;
	scalarType: ScalarType;
}) => {
	const columnHelper = createColumnHelper<T>();
	return columnHelper.display({
		id: props.key,
		header: ({ column }) => (
			<FieldColumnHeader
				column={column}
				name={props.name}
				fieldType={dataTypeToFieldType[props.scalarType]}
				dataType={{ scalar_type: props.scalarType, array_depth: 0 }}
				isPrimary={false}
			/>
		),
		cell: observer(({ row }) => {
			return (
				<Cell
					dataType={{ scalar_type: props.scalarType, array_depth: 0 }}
					// TODO(John): is this cast necessary?
					value={row.original[props.key] as CellValue}
					editable={false}
				/>
			);
		}),
	});
};
