import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import { isFieldPrimary } from "@/contexts/tables/stores/field-store";
import {
	AddFieldAction,
	AddRelationshipAction,
	AddSelectOptionAction,
	DeleteFieldAction,
	DeleteSelectOptionAction,
	MoveFieldAction,
	UpdateFieldAction,
	UpdateSelectOptionColorAction,
	UpdateSelectOptionLabelAction,
} from "@/contexts/tables/stores/handlers/fields";
import {
	AddRecordAction,
	DeleteRecordsAction,
	MoveRecordsAction,
	UpdateCellValueAction,
} from "@/contexts/tables/stores/handlers/records";
import {
	CreateComputedTableAction,
	CreateTableAction,
} from "@/contexts/tables/stores/handlers/tables";
import openapiHashes from "@/generated/openapi-hashes.json";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import {
	ElectricOptimisticMap,
	OptimisticMap,
} from "@/lib/sync/optimistic-map";
import { RecordSyncStream } from "@/lib/sync/stream";
import { sortByOrder } from "@/lib/utils";
import type {
	CellValue,
	DataType,
	Field,
	FieldId,
	Record,
	RelationshipField,
	ResourceLink,
	ResourceTableResource,
	ResourceType,
	TableId,
	TableResource,
	UserTableResource,
	ViewField,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { type Result, err, ok } from "neverthrow";

function isUserTable(resource: TableResource): resource is UserTableResource {
	return resource.creator_id !== null;
}

export class TablesStore {
	appState: AppState;
	map: ElectricOptimisticMap<TableResource, "table_id", "write_id">;

	tableStates: Map<TableId, UserTableState | ResourceTableState> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/tables`,
			idKey: "table_id",
			writeIdKey: "write_id",
			shapeHash: openapiHashes.TableResource,
		});
		makeAutoObservable(this);
	}

	getResourceById(tableId: TableId): Result<TableResource, Error> {
		return this.map.get(tableId);
	}

	get sortedResources(): TableResource[] {
		return this.map.keys
			.map((x) => this.getResourceById(x))
			.filter((x) => x.isOk())
			.map((x) => x.value)
			.filter((x) => x.deleted_at === null);
	}

	createTable = (props: CreateTableAction["args"]) => {
		this.appState.actionQueue.run(new CreateTableAction(props));
	};

	createComputedTable = (props: CreateComputedTableAction["args"]) => {
		this.appState.actionQueue.run(new CreateComputedTableAction(props));
	};

	getResourceTableId(
		integrationType: "tables" | "feeds",
		resourceType: ResourceType,
	): TableId {
		const table = this.sortedResources.find(
			(x) =>
				x.integration_type === integrationType &&
				x.resource_type === resourceType,
		);
		if (!table) {
			throw new Error(
				`Table resource for integration type ${integrationType} and resource type ${resourceType} not found`,
			);
		}
		return table.table_id;
	}

	getTableStateById(tableId: TableId): UserTableState | ResourceTableState {
		const tableState = this.tableStates.get(tableId);
		if (tableState) {
			return tableState;
		}
		const tableResource = this.getResourceById(tableId);
		if (tableResource.isErr()) {
			throw new Error(`Table resource for table with ID ${tableId} not found`);
		}
		let newTableState: UserTableState | ResourceTableState;
		if (isUserTable(tableResource.value)) {
			newTableState = new UserTableState({
				tablesStore: this,
				tableResource: tableResource.value,
			});
		} else {
			newTableState = new ResourceTableState({
				tablesStore: this,
				tableResource: tableResource.value,
			});
		}
		this.tableStates.set(tableId, newTableState);
		return newTableState;
	}
}

abstract class TableState {
	tablesStore: TablesStore;
	tableResource: TableResource;
	stream: RecordSyncStream;
	records: OptimisticMap<Record, "link">;
	isLoading = true;

	constructor(props: {
		tablesStore: TablesStore;
		tableResource: TableResource;
	}) {
		this.tablesStore = props.tablesStore;
		this.tableResource = props.tableResource;
		// NOTE(John): remember to start/stop the stream
		this.stream = new RecordSyncStream({
			tableId: this.tableId,
			userSessionId: this.tablesStore.appState.sessionUserId,
			getToken: this.tablesStore.appState.getToken,
		});
		this.records = new OptimisticMap({
			stream: this.stream,
		});
	}

	get type(): "user" | "resource" {
		return isUserTable(this.tableResource) ? "user" : "resource";
	}

	get tableId() {
		return this.tableResource.table_id;
	}

	get fields(): Result<Map<FieldId, [Field, DataType]>, Error> {
		const fields =
			this.tablesStore.appState.workspace.fields.getFieldsByTableId(
				this.tableId,
			);
		const fieldsWithDataType = new Map<FieldId, [Field, DataType]>();
		for (const [fieldId, field] of fields) {
			const dataType =
				this.tablesStore.appState.workspace.fields.getFieldDataType(fieldId);
			dataType.match(
				(dataType) => {
					fieldsWithDataType.set(fieldId, [field, dataType]);
				},
				(error) => {
					return err(error);
				},
			);
		}
		return ok(fieldsWithDataType);
	}

	getFieldById(fieldId: FieldId): Result<[Field, DataType], Error> {
		return this.fields.andThen((fields) => {
			const field = fields.get(fieldId);
			if (!field) {
				return err(new Error(`Field with ID ${fieldId} not found`));
			}
			return ok(field);
		});
	}

	/**
	 * Returns the fields for this table, organized into sections.
	 *
	 * If the table is a computed table, then there are two groups of fields:
	 * the fields of the computed view, which come first and can't be
	 * reordered, and the primitive table's fields.
	 *
	 * If the table is a regular table, then the two groups are the primary
	 * fields, which come first and can be reordered among themselves, and the
	 * regular fields.
	 *
	 * TODO(John): fix the types... this feels weird
	 */
	get sortedFields(): Result<
		| {
				viewFields: [ViewField, DataType][];
				regularFields: [Field, DataType][];
		  }
		| {
				primaryFields: [Field, DataType][];
				regularFields: [Field, DataType][];
		  },
		Error
	> {
		const fields = this.fields;
		if (fields.isErr()) {
			return err(fields.error);
		}
		if (this.tableResource.computed_view_def !== null) {
			const viewFields: [ViewField, DataType][] = [];
			const regularFields = [];
			for (const fieldWithDataType of fields.value.values()) {
				if (fieldWithDataType[0].type === "view") {
					viewFields.push(fieldWithDataType as [ViewField, DataType]);
				} else {
					regularFields.push(fieldWithDataType);
				}
			}
			const sortedViewFields = sortByOrder(
				viewFields,
				([field]) => field.order,
			);
			const sortedRegularFields = sortByOrder(
				regularFields,
				([field]) => field.order,
			);
			return ok({
				viewFields: sortedViewFields,
				regularFields: sortedRegularFields,
			});
		}
		const primaryFields = [];
		const regularFields = [];
		for (const fieldWithDataType of fields.value.values()) {
			if (isFieldPrimary(fieldWithDataType[0])) {
				primaryFields.push(fieldWithDataType);
			} else {
				regularFields.push(fieldWithDataType);
			}
		}
		const sortedPrimaryFields = sortByOrder(
			primaryFields,
			([field]) => field.order,
		);
		const sortedRegularFields = sortByOrder(
			regularFields,
			([field]) => field.order,
		);
		return ok({
			primaryFields: sortedPrimaryFields,
			regularFields: sortedRegularFields,
		});
	}

	getRecordByLink(recordLink: ResourceLink): Record {
		const record = this.records.get(recordLink);
		if (record.isErr()) {
			throw new Error(`Record with link ${recordLink} not found`);
		}
		return record.value;
	}

	getCellValue(recordLink: ResourceLink, field: Field): CellValue {
		return this.getRecordByLink(recordLink).cell_values[field.field_id];
	}

	get sortedRecords(): Record[] {
		return sortByOrder([...this.records.values()], (record) => record.order);
	}

	get recordIndexMap(): Map<ResourceLink, number> {
		return new Map(this.sortedRecords.map((record, i) => [record.link, i]));
	}
}

/**
 * The data for a user table.
 */
export class UserTableState extends TableState {
	constructor(props: {
		tablesStore: TablesStore;
		tableResource: UserTableResource;
	}) {
		super(props);
		makeAutoObservableAbstract(this);
	}

	get isComputedTable() {
		return this.tableResource.computed_view_def !== null;
	}

	getRecordBefore(recordLink: ResourceLink): Record | null {
		const index = this.recordIndexMap.get(recordLink);
		if (index === undefined || index === 0) {
			return null;
		}
		return this.sortedRecords.at(index - 1) ?? null;
	}

	getRecordAfter(recordLink: ResourceLink): Record | null {
		const index = this.recordIndexMap.get(recordLink);
		if (index === undefined || index === this.sortedRecords.length - 1) {
			return null;
		}
		return this.sortedRecords.at(index + 1) ?? null;
	}

	/**
	 * Used for reordering regular fields.
	 *
	 * Returns null if the field is at the beginning.
	 */
	getRegularFieldBefore(fieldId: FieldId): Result<Field | null, Error> {
		return this.sortedFields.andThen((sortedFields) => {
			const index = sortedFields.regularFields.findIndex(
				([field]) => field.field_id === fieldId,
			);
			if (index === -1) {
				return err(new Error(`Field with ID ${fieldId} not found`));
			}
			if (index === 0) {
				return ok(null);
			}
			return ok(sortedFields.regularFields[index - 1][0]);
		});
	}

	/**
	 * Returns null if the field is at the end.
	 */
	getRegularFieldAfter(fieldId: FieldId): Result<Field | null, Error> {
		return this.sortedFields.andThen((sortedFields) => {
			const index = sortedFields.regularFields.findIndex(
				([field]) => field.field_id === fieldId,
			);
			if (index === -1) {
				return err(new Error(`Field with ID ${fieldId} not found`));
			}
			if (index === sortedFields.regularFields.length - 1) {
				return ok(null);
			}
			return ok(sortedFields.regularFields[index + 1][0]);
		});
	}

	/**
	 * Action bindings from table-handlers
	 */
	addRecord = (props: Omit<AddRecordAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new AddRecordAction({
				...props,
				tableState: this,
			}),
		);
	};
	moveRecords = (props: Omit<MoveRecordsAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new MoveRecordsAction({
				...props,
				tableState: this,
			}),
		);
	};
	updateCellValue = (
		props: Omit<UpdateCellValueAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new UpdateCellValueAction({
				...props,
				tableState: this,
			}),
		);
	};
	deleteRecords = (props: Omit<DeleteRecordsAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new DeleteRecordsAction({
				...props,
				tableState: this,
			}),
		);
	};
	addField<T extends Exclude<Field, RelationshipField | ViewField>>(
		props: Omit<AddFieldAction<T>["args"], "tableState">,
	) {
		this.tablesStore.appState.actionQueue.run(
			new AddFieldAction({
				...props,
				tableState: this,
			}),
		);
	}
	addRelationship = (
		props: Omit<AddRelationshipAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new AddRelationshipAction({
				...props,
				tableState: this,
			}),
		);
	};
	updateField = (props: Omit<UpdateFieldAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new UpdateFieldAction({
				...props,
				tableState: this,
			}),
		);
	};
	moveField = (props: Omit<MoveFieldAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new MoveFieldAction({
				...props,
				tableState: this,
			}),
		);
	};
	deleteField = (props: Omit<DeleteFieldAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new DeleteFieldAction({
				...props,
				tableState: this,
			}),
		);
	};
	addSelectOption = (
		props: Omit<AddSelectOptionAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new AddSelectOptionAction({
				...props,
				tableState: this,
			}),
		);
	};
	deleteSelectOption = (
		props: Omit<DeleteSelectOptionAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new DeleteSelectOptionAction({
				...props,
				tableState: this,
			}),
		);
	};
	updateSelectOptionLabel = (
		props: Omit<UpdateSelectOptionLabelAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new UpdateSelectOptionLabelAction({
				...props,
				tableState: this,
			}),
		);
	};
	updateSelectOptionColor = (
		props: Omit<UpdateSelectOptionColorAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new UpdateSelectOptionColorAction({
				...props,
				tableState: this,
			}),
		);
	};
}

export class ResourceTableState extends TableState {
	constructor(props: {
		tablesStore: TablesStore;
		tableResource: ResourceTableResource;
	}) {
		super(props);
		makeAutoObservableAbstract(this);
	}
}
