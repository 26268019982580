import {
	SelectOptionBackgroundColors,
	SelectOptionBorderColors,
	chooseRandomSelectOptionColor,
} from "@/components/table/scalar-renderers/select-colors";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSeparator,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { ResourceTableState } from "@/contexts/tables/stores/table-store";
import { useTableViewContext } from "@/contexts/tables/use-table-context";
import { capitalizeFirstLetter } from "@/lib/formatting";
import {
	type Field,
	type FieldId,
	type SelectField,
	type SelectOption,
	SelectOptionColor,
	type SelectOptionLabel,
} from "@api/schemas";
import {
	Code,
	DotsThree,
	Palette,
	Pencil,
	Plus,
	Trash,
} from "@phosphor-icons/react";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { type ReactNode, useRef, useState } from "react";
import { toast } from "sonner";

const SelectOptionEditor = observer(
	({ option, fieldId }: { option: SelectOption; fieldId: FieldId }) => {
		const tableViewState = useTableViewContext();
		const [editing, setEditing] = useState(false);
		const [newLabel, setNewLabel] = useState(option.label);
		const inputRef = useRef<HTMLInputElement>(null);

		const renameOption = () => {
			if (tableViewState.tableState instanceof ResourceTableState) {
				return;
			}

			if (newLabel === option.label) {
				setEditing(false);
				return;
			}

			if (newLabel.trim().length === 0) {
				setNewLabel(option.label);
				setEditing(false);
				toast.error("Option label can't be empty");
				return;
			}

			tableViewState.tableState.updateSelectOptionLabel({
				fieldId,
				oldLabel: option.label,
				newLabel,
			});
			setEditing(false);
		};

		const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Escape") {
				setEditing(false);
				return;
			}
			if (e.key === "Enter") {
				renameOption();
			}
		};

		return (
			<AlertDialog>
				<div
					key={option.label}
					className="flex items-center justify-between gap-2 px-1 py-1"
				>
					{editing ? (
						<Input
							value={newLabel}
							onChange={(e) => setNewLabel(e.target.value as SelectOptionLabel)}
							onKeyDown={onKeydown}
							onBlur={renameOption}
							ref={inputRef}
						/>
					) : (
						<Badge
							variant="outline"
							className={clsx(
								SelectOptionBackgroundColors[option.color],
								SelectOptionBorderColors[option.color],
								"text-neutral-700",
							)}
						>
							{option.label}
						</Badge>
					)}
					<DropdownMenu>
						<DropdownMenuTrigger className="shrink-0 rounded p-1 hover:bg-neutral-100">
							<DotsThree weight="bold" />
						</DropdownMenuTrigger>
						<DropdownMenuContent
							align="end"
							// Prevent the menu from focusing back on the trigger when it closes,
							// so we can keep the focus on the input field
							onCloseAutoFocus={(e) => e.preventDefault()}
						>
							<DropdownMenuItem
								className="flex items-center gap-2 text-neutral-500"
								onClick={(e) => {
									setEditing(true);
									setTimeout(() => {
										inputRef.current?.focus();
									}, 0);
									e.stopPropagation();
								}}
							>
								<Pencil className="text-lg" /> Rename
							</DropdownMenuItem>

							<DropdownMenuSub>
								<DropdownMenuSubTrigger className="flex items-center gap-2 text-neutral-500">
									<Palette className="text-lg" /> Change color
								</DropdownMenuSubTrigger>
								<DropdownMenuPortal>
									<DropdownMenuSubContent>
										{Object.values(SelectOptionColor).map((color) => (
											<DropdownMenuItem
												key={color}
												className="gap-2"
												onClick={() => {
													if (
														tableViewState.tableState instanceof
														ResourceTableState
													) {
														return;
													}
													tableViewState.tableState.updateSelectOptionColor({
														fieldId,
														label: option.label,
														color,
													});
												}}
											>
												<div
													className={clsx(
														"h-4 w-4 rounded-full",
														SelectOptionBackgroundColors[color],
														SelectOptionBorderColors[color],
													)}
												/>
												{capitalizeFirstLetter(color)}
											</DropdownMenuItem>
										))}
									</DropdownMenuSubContent>
								</DropdownMenuPortal>
							</DropdownMenuSub>

							<AlertDialogTrigger asChild>
								<DropdownMenuItem
									className="flex items-center gap-2 text-neutral-500"
									onClick={() => {
										// TODO: Implement this
										// tableContext.removeColumnCategory({
										// 	columnId,
										// 	categoryToRemove: category.value,
										// });
									}}
								>
									<Trash className="text-lg" /> Delete
								</DropdownMenuItem>
							</AlertDialogTrigger>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
				{/* Deletion confirmation dialog */}
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Delete option?</AlertDialogTitle>
						<AlertDialogDescription>
							This will permanently delete the option from the column. Cells
							with this option will be set to empty.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel className="w-1/2">Cancel</AlertDialogCancel>
						<AlertDialogAction className="w-1/2">
							Delete option
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		);
	},
);

const SelectOptionFieldEditor = observer(
	({
		selectField,
	}: {
		selectField: SelectField;
	}) => {
		const [newLabel, setNewLabel] = useState("");
		const [showAddOption, setShowAddOption] = useState(false);
		const tableViewState = useTableViewContext();

		const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Escape") {
				setShowAddOption(false);
				return;
			}

			if (e.key === "Enter") {
				if (tableViewState.tableState instanceof ResourceTableState) {
					return;
				}
				tableViewState.tableState.addSelectOption({
					fieldId: selectField.field_id,
					newOption: {
						label: newLabel as SelectOptionLabel,
						color: chooseRandomSelectOptionColor(),
					},
				});

				setNewLabel("");
			}
		};

		return (
			<div className="mt-2">
				<div className="flex items-center justify-between">
					<Label className="text-sm">Options</Label>
					{!showAddOption && (
						<button
							type="button"
							className="flex w-full max-w-max items-center gap-2 rounded-md p-2 text-sm hover:bg-neutral-100"
							onClick={() => setShowAddOption(true)}
						>
							<Plus />
						</button>
					)}
				</div>
				{showAddOption && (
					<Input
						value={newLabel}
						onChange={(e) => setNewLabel(e.target.value)}
						onKeyDown={handleKeyDown}
						placeholder="Add an option..."
					/>
				)}
				<div className="mt-1">
					{Object.values(selectField.properties.options)
						.slice()
						.sort()
						.map((option) => (
							<SelectOptionEditor
								key={option.label}
								option={option}
								fieldId={selectField.field_id}
							/>
						))}
					{!showAddOption && (
						<Button
							variant="outline"
							className="flex w-full items-center gap-2 text-sm"
							onClick={() => setShowAddOption(true)}
						>
							<Plus />
							Add an option
						</Button>
					)}
				</div>
			</div>
		);
	},
);

export const DeleteColumnDialogContent = observer(
	({
		fieldId,
	}: {
		fieldId: FieldId;
	}) => {
		const tableViewState = useTableViewContext();

		return (
			<>
				<AlertDialogHeader>
					<AlertDialogTitle>Delete field</AlertDialogTitle>
					<AlertDialogDescription>
						This will permanently delete the field and its cells. Are you sure
						you want to proceed?
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel className="w-1/2">Cancel</AlertDialogCancel>
					<AlertDialogAction
						onClick={() => {
							if (tableViewState.tableState instanceof ResourceTableState) {
								return;
							}
							tableViewState.tableState.deleteField({
								fieldId,
							});
						}}
						className="w-1/2"
					>
						Delete field
					</AlertDialogAction>
				</AlertDialogFooter>
			</>
		);
	},
);

/**
 * A popover that allows you to modify field details.
 */
export const FieldModifierPopover = observer(
	(props: {
		children: ReactNode;
		field: Field;
		devMode?: boolean;
	}) => {
		const [fieldName, setFieldName] = useState(props.field.name);
		const tableViewState = useTableViewContext();
		return (
			<Popover>
				<PopoverTrigger className="h-full w-full">
					{props.children}
				</PopoverTrigger>

				<PopoverContent align="start" className="w-72 p-2">
					{props.devMode && (
						<button
							type="button"
							onClick={() => {
								navigator.clipboard.writeText(props.field.field_id);
								toast.success("Field ID copied to clipboard");
							}}
							className="mb-2 flex max-w-max items-center gap-1 break-all rounded-md border border-neutral-200 bg-neutral-100 px-1 py-0.5 text-neutral-600 text-xs"
						>
							<Code className="text-lg" /> {props.field.field_id}
						</button>
					)}

					<div className="">
						<Label>Label</Label>
						<Input
							value={fieldName}
							onChange={(e) => setFieldName(e.target.value)}
							onBlur={() => {
								if (fieldName === props.field.name) {
									return;
								}
								if (fieldName.trim().length === 0) {
									setFieldName(props.field.name);
									toast.error("Field name can't be empty");
									return;
								}
								tableViewState.renameField({
									fieldId: props.field.field_id,
									newName: fieldName,
								});
							}}
							placeholder="Label..."
						/>
					</div>
					<div>
						{props.field.type === "select" && (
							<SelectOptionFieldEditor selectField={props.field} />
						)}
					</div>

					<DropdownMenuSeparator className="mt-4" />

					<AlertDialog>
						<AlertDialogTrigger
							onClick={(e) => {
								e.stopPropagation();
							}}
							asChild
						>
							<Button
								variant="outline"
								className="mt-2 flex w-full items-center gap-2 text-neutral-700"
							>
								<Trash />
								Delete column
							</Button>
						</AlertDialogTrigger>
						<AlertDialogContent>
							<DeleteColumnDialogContent fieldId={props.field.field_id} />
						</AlertDialogContent>
					</AlertDialog>
				</PopoverContent>
			</Popover>
		);
	},
);
