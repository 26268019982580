import {
	ADD_COLUMN_ID,
	sanitizeForCSSVariableName,
} from "@/components/table/utils";
import { type Header, flexRender } from "@tanstack/react-table";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

// TODO(John): think about what role this has
export type TableHeaderProps<TData> = {
	header: Header<TData, unknown>;
};

export const TableHeader = observer(
	<TData,>(props: TableHeaderProps<TData>) => {
		const isPinned = props.header.column.getIsPinned();
		return (
			<div
				aria-label="th"
				className={clsx(
					"relative z-10 h-8 shrink-0 whitespace-nowrap border-neutral-100 border-b bg-white p-0 text-left font-semibold text-sm",
					props.header.column.id !== ADD_COLUMN_ID && "border-r",
				)}
				style={{
					width: `calc(var(--header-${sanitizeForCSSVariableName(
						props.header.id,
					)}-size) * 1px)`,
					position: isPinned ? "sticky" : "relative",
					zIndex: isPinned ? 10 : 1,
					left: isPinned ? `${props.header.column.getStart("left")}px` : 0,
				}}
			>
				{props.header.isPlaceholder
					? null
					: flexRender(
							props.header.column.columnDef.header,
							props.header.getContext(),
						)}
				{/* Resize handle */}
				{props.header.column.id !== ADD_COLUMN_ID && (
					<button
						{...{
							onDoubleClick: () => props.header.column.resetSize(),
							onMouseDown: props.header.getResizeHandler(),
							onTouchStart: props.header.getResizeHandler(),
							className: `h-full cursor-ew-resize	 absolute right-0 z-20 w-1 top-0 bg-neutral-500 opacity-0 hover:opacity-100 ${
								props.header.column.getIsResizing() ? "opacity-100" : ""
							}`,
						}}
					/>
				)}
			</div>
		);
	},
);
