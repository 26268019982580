import { getColorForSession } from "@/components/layout/right-sidebar/assistant-presence";
import type { AppState } from "@/contexts/app-context/app-context";
import { SearchTabState } from "@/contexts/search/tab-state";
import type { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { FocusedUploadSearchResult } from "@/contexts/uploads/tab-state";
import { createTabId } from "@/lib/id-generators";
import { routeTree } from "@/pages/routeTree.gen";
import type { SessionAssistantId, TabId } from "@api/schemas";
import {
	type LinkProps,
	type ParsedLocation,
	type RouterEvents,
	createMemoryHistory,
	createRouter,
} from "@tanstack/react-router";
import {
	type Action,
	Actions,
	DockLocation,
	type IJsonModel,
	type IJsonRowNode,
	type IJsonTabNode,
	type IJsonTabSetNode,
	Model,
	TabNode,
	TabSetNode,
} from "flexlayout-react";
import { autorun, makeAutoObservable, runInAction } from "mobx";
import { ulid } from "ulidx";

// top level, just for type safety
const memoryHistory = createMemoryHistory();
// Register the router instance for type safety
const router = createRouter({
	routeTree,
	history: memoryHistory,
	context: {
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		tab: undefined!,
	},
});

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

type JsonNodeType = IJsonRowNode | IJsonTabSetNode | IJsonTabNode;

export class Tab {
	tabStore: TabStore;
	id: TabId;
	router: typeof router;
	state: BaseTabState;

	// Used to track the "active path" between all tabs
	parsedLocation: ParsedLocation | null;

	constructor(props: {
		tabsState: TabStore;
		initialLocation: LinkProps;
		initialState?: FocusedUploadSearchResult;
		tabId?: TabId;
	}) {
		makeAutoObservable(this);
		this.id = props.tabId ?? createTabId();
		const memoryHistory = createMemoryHistory();
		this.router = createRouter({
			routeTree,
			history: memoryHistory,
			context: {
				tab: this,
			},
		});
		// This should immediately get overwritten by the router navigate
		// loader
		this.state = new SearchTabState(this, null);
		this.parsedLocation = null;
		this.router.navigate(props.initialLocation);

		const eventType: keyof RouterEvents = "onResolved";
		this.router.subscribe(eventType, ({ toLocation }) => {
			runInAction(() => {
				this.parsedLocation = toLocation;
			});
		});

		this.tabStore = props.tabsState;
	}

	setState(state: BaseTabState) {
		this.state = state;
	}
}

export class TabStore {
	appState: AppState;

	/**
	 * Flex-layout model responsible for managing the hierarchy of tab sets and tabs
	 */
	layoutModel: Model;

	/**
	 * Flat map of all tags in the store and their contained state
	 */
	tabs: Map<TabId, Tab> = new Map();

	// Map from tabSetId (window) to assistantSessionId
	trackedAssistantSessions: Map<string, SessionAssistantId> = new Map();
	assistantTabsetStyles: Map<string, HTMLStyleElement> = new Map();

	constructor(appState: AppState) {
		makeAutoObservable(
			this,
			{},
			{
				autoBind: true,
			},
		);
		this.appState = appState;

		this.tabs = new Map<TabId, Tab>();

		// Default to the search tab
		const newTab = new Tab({
			tabsState: this,
			initialLocation: {
				to: "/search",
			},
		});
		this.tabs.set(newTab.id, newTab);
		this.activeTab = newTab;

		const initialTabSetId = this.getNextTabSetId();
		const initalModelState: IJsonModel = {
			global: {
				tabSetEnableMaximize: false,
				tabSetEnableDeleteWhenEmpty: true,
				tabEnableRename: false,
				tabDragSpeed: 0,
			},
			borders: [],
			layout: {
				type: "row",
				weight: 100,
				children: [
					{
						id: initialTabSetId,
						type: "tabset",
						name: initialTabSetId,
						weight: 50,
						enableDrag: true,
						enableDrop: true,
						active: true,
						children: [
							{
								id: newTab.id,
								type: "tab",
								name: newTab.id,
								component: "button",
							},
						],
					},
				],
			},
		};
		this.layoutModel = Model.fromJson(initalModelState);

		this.layoutModel.addChangeListener((action: Action) => {
			// handle tabset deletion first
			if (action.type === Actions.DELETE_TABSET) {
				const activeTabSet = this.layoutModel.getActiveTabset();
				if (!activeTabSet) {
					// no active tabset, find any available tabset
					const findAnyTabset = (node: JsonNodeType): string | null => {
						if (node.type === "tabset" && node.id) {
							return node.id;
						}
						if ("children" in node && Array.isArray(node.children)) {
							for (const child of node.children as JsonNodeType[]) {
								const found = findAnyTabset(child);
								if (found) return found;
							}
						}
						return null;
					};

					const anyTabsetId = findAnyTabset(this.layoutModel.toJson().layout);
					if (anyTabsetId) {
						this.layoutModel.doAction(Actions.setActiveTabset(anyTabsetId));
					}
				}
			}

			// then handle active tab updates
			const activeTabSet = this.layoutModel.getActiveTabset();
			if (!activeTabSet) {
				runInAction(() => {
					this.activeTab = null;
				});
				return;
			}

			const activeTabNode = activeTabSet.getSelectedNode() as
				| TabNode
				| undefined;
			if (!activeTabNode) {
				runInAction(() => {
					this.activeTab = null;
				});
				return;
			}

			const activeTabId = activeTabNode.getId();
			const activeTab = this.tabs.get(activeTabId as TabId);

			runInAction(() => {
				if (!activeTab) {
					this.activeTab = null;
				} else {
					this.activeTab = activeTab;
				}
			});

			// finally handle style updates
			if (this.trackedAssistantSessions.size > 0) {
				if (
					action.type === Actions.MOVE_NODE ||
					action.type === Actions.ADD_NODE ||
					action.type === Actions.DELETE_TAB ||
					action.type === Actions.DELETE_TABSET ||
					action.type === Actions.ADJUST_WEIGHTS
				) {
					requestAnimationFrame(() => {
						this.updateAllAssistantTabsetStyles();
					});
				}
			}
		});

		autorun(() => {
			for (const [tabSetId, sessionAssistantId] of this
				.trackedAssistantSessions) {
				const virtualizedTabs =
					this.appState.assistantSessionStore.getTabs(sessionAssistantId);

				const openTabs = Array.from(virtualizedTabs.entries()).filter(
					([_, tab]) => tab.closedAt === null,
				);

				// const closedTabs = Array.from(virtualizedTabs.entries()).filter(
				// 	([_, tab]) => tab.closedAt !== null,
				// );

				// For each tab in the tabset, get the node
				// If the node exists, check the path
				// If the path is not the same, update the path
				// If the node does not exist, create it

				// What about when tabs are deleted?
				// We need to remove the tab from the tabset and the tab from the tabStore

				const tabSet = this.layoutModel.getNodeById(tabSetId);
				if (!tabSet) throw new Error("No tabset found");
				const tabSetTabs = tabSet.getChildren();
				for (const tab of tabSetTabs) {
					if (openTabs.some(([tabId, _]) => tabId === tab.getId())) {
						continue;
					}
					this.layoutModel.doAction(Actions.deleteTab(tab.getId() as TabId));
					this.tabs.delete(tab.getId() as TabId);
				}

				for (const [tabId, tab] of openTabs) {
					const node = this.layoutModel.getNodeById(tabId);
					if (!node) {
						// TODO(Tae): How do we know if we're here because of a potential problem?
						const newTab = new Tab({
							tabsState: this,
							initialLocation: {
								href: tab.path,
							},
							tabId: tabId,
						});

						this.tabs.set(newTab.id, newTab);

						// These should not be draggable or droppable
						this.layoutModel.doAction(
							Actions.addNode(
								{
									id: tabId,
									type: "tab",
									name: tabId,
									component: "button",
									enableDrag: false,
									enableDrop: false,
								},
								tabSetId,
								DockLocation.CENTER,
								-1,
							),
						);
						continue;
					}
					// Check path to see if we need to navigate
					const tabHref = tab.path;
					const currentHref = this.tabs.get(tabId)?.router.state.location.href;
					if (tabHref !== currentHref) {
						this.tabs.get(tabId)?.router.navigate({
							href: tabHref,
						});
					}
					// No changes needed, do nothing
				}
			}
		});
	}

	updateAllAssistantTabsetStyles = () => {
		// clean up all existing styles
		for (const styleTag of this.assistantTabsetStyles.values()) {
			styleTag.remove();
		}
		this.assistantTabsetStyles.clear();

		// regenerate styles for all assistant tabsets
		for (const [tabsetId, sessionAssistantId] of this
			.trackedAssistantSessions) {
			const node = this.layoutModel.getNodeById(tabsetId);
			if (node) {
				const path = node.getPath();
				const color = getColorForSession(sessionAssistantId, "border");
				const styleTag = document.createElement("style");
				styleTag.textContent = `
                    .flexlayout__tabset[data-layout-path="${path}"] {
                        border: 2px solid ${color.cssVariable};
                    }
                `;
				document.head.appendChild(styleTag);
				this.assistantTabsetStyles.set(tabsetId, styleTag);
			}
		}
	};

	activeTab: Tab | null = null;

	get activeTabLocation(): ParsedLocation | null {
		const activeTab = this.activeTab;
		if (!activeTab) return null;

		// make this reactive to routerState changes
		const parsedLocation = activeTab.parsedLocation;
		return parsedLocation;
	}

	selectTab(tabId: TabId) {
		this.layoutModel.doAction(Actions.selectTab(tabId));
	}

	/**
	 * Handles navigation when clicks happen outside of a tab (e.g. one of the
	 * sidebars).
	 */
	navigateFromOutsideTab(location: LinkProps, openInNewTab = false) {
		let activeTab = this.activeTab;

		// if active tab is an assistant's tab, always create new tab in user tabset
		if (activeTab && this.isAssistantTab(activeTab.id)) {
			const newTab = this.createTabInUserTabSet(location);
			newTab.router.navigate(location);
			return;
		}

		// only look for existing tabs in user tabsets
		const existingUserTab = this.findTabWithMatchingLocation(location, {
			excludeAssistantTabs: true,
		});
		if (existingUserTab) {
			this.selectTab(existingUserTab.id);
			return;
		}

		if (openInNewTab || activeTab === null) {
			activeTab = this.createTabInUserTabSet(location);
			activeTab.router.navigate(location);
			return;
		}

		activeTab.router.navigate(location);
	}

	findTabWithMatchingLocation(
		location: LinkProps,
		options: { excludeAssistantTabs?: boolean } = {},
	) {
		const href = router.buildLocation(location).href;
		return Array.from(this.tabs.values()).find((tab: Tab) => {
			if (options.excludeAssistantTabs && this.isAssistantTab(tab.id)) {
				return false;
			}
			return tab.router.state.location.href === href;
		});
	}

	// helper to check if a tab belongs to an assistant
	isAssistantTab(tabId: TabId) {
		const tabNode = this.layoutModel.getNodeById(tabId);
		if (!tabNode) return false;

		const tabsetId = tabNode.getParent()?.getId();
		return tabsetId ? this.trackedAssistantSessions.has(tabsetId) : false;
	}

	/**
	 * Creates a new tab in the active tab set.
	 */
	createTabInActiveTabSet = (
		initialLocation: LinkProps,
		initialState?: FocusedUploadSearchResult,
	): Tab => {
		const activeTabSet = this.layoutModel.getActiveTabset();
		if (!activeTabSet) throw new Error("No active tab set found");

		const activeTabSetId = activeTabSet.getId();
		const newTab = new Tab({
			tabsState: this,
			initialLocation: initialLocation,
			initialState: initialState,
		});

		this.tabs.set(newTab.id, newTab);
		const location = DockLocation.CENTER;
		const index = 0;

		this.layoutModel.doAction(
			Actions.addNode(
				{
					id: newTab.id,
					type: "tab",
					name: newTab.id,
					component: "button",
				},
				activeTabSetId,
				location,
				index,
			),
		);
		return newTab;
	};

	// TODO(Tae): See if we need these handlers

	// Actions.ADD_NODE

	// Actions.MOVE_NODE

	// Actions.DELETE_TAB
	removeTab = (tabId: TabId) => {
		this.tabs.delete(tabId);

		this.layoutModel.doAction(Actions.deleteTab(tabId));
	};

	// Actions.DELETE_TABSET
	removeTabsOfTabSetNode = (tabSetId: string) => {
		const tabSet = this.layoutModel.getNodeById(tabSetId);
		if (!tabSet || !(tabSet instanceof TabSetNode)) return;
		const tabs = tabSet.getChildren();
		for (const tab of tabs) {
			if (tab instanceof TabNode) {
				this.removeTab(tab.getName() as TabId);
			}
		}
		this.layoutModel.doAction(Actions.deleteTabset(tabSetId));
	};
	// Actions.SELECT_TAB
	// Actions.SET_ACTIVE_TABSET
	// Actions.CLOSE_WINDOW
	// Actions.CREATE_WINDOW

	// Utility Methods
	// Currently we only use this for the very first tabset
	// Since it is difficult to create a tabset while giving it an id in flexlayout
	getNextTabSetId() {
		return `container_${ulid()}`;
	}

	/**
	 * Used by Object Link. Loads a fake Tab and returns its head.
	 */
	async getTabHead(href: string): Promise<BaseTabState["head"]> {
		const tab = new Tab({
			tabsState: this,
			initialLocation: {
				href: href,
			},
		});
		const match = tab.router.state.matches.at(-1);
		if (!match) throw new Error(`No match found for ${href}`);

		await match.loadPromise;
		// I'm not exactly sure why, but I need to re-access the match after
		// loadPromise; otherwise, the loaderData is undefined. My guess is
		// that the match is being re-created, so the match above is not the
		// same as the match below.
		const loadedMatch = tab.router.state.matches.at(-1);
		if (!loadedMatch) throw new Error(`No loaded match found for ${href}`);

		const loaderData = loadedMatch.loaderData;
		if (!loaderData)
			throw new Error(
				`No loader data found for ${href}! This could be because loading the tabState failed.`,
			);
		return loaderData.tabState.head;
	}

	initializeAssistantSessionTabSet = (
		sessionAssistantId: SessionAssistantId,
	) => {
		// This returns all tabs
		const virtualizedTabs =
			this.appState.assistantSessionStore.getTabs(sessionAssistantId);

		// Exclude closed tabs
		const openTabs = Array.from(virtualizedTabs.entries()).filter(
			([_, tab]) => tab.closedAt === null,
		);

		// We can either make it impossible to track an assistant session until there are tabs
		// Or we can return early here and listen for tabs in the computed fn and create the tabset then.
		if (openTabs.length === 0) return "";

		// First create all the tabs in an existing tabset if any exists
		// or in a new tabset if it doesn't

		// Flexlayout always has an active tabset
		// since if you close all tabsets, it will immediately create a new one

		// Therefore, we should always be able to get the id of the active tabset
		// and add to that.

		// If the active tabset has no tabs, or in other words,
		// the user has no tabsets and no tabs, we can skip the logic below to move the tabs.
		// But we need to make sure the tabset is stored as the assistant session's tabset

		// What if this tabset is not a user tabset?
		// It should theoretically be fine since we only use it
		// to temporarily store tabs before moving them out
		// because creating a tabset is impossible.

		// There may be weird race conditions since we serially add tabs
		// I will investigate this more later.

		const activeTabSet = this.layoutModel.getActiveTabset();

		// We have a minor bug where if you have at least two tabsets and you focus, then close a tabset,
		// the active tabset will be null
		if (!activeTabSet) throw new Error("No active tab set found");

		// Check if activeTabSet is empty
		const activeTabSetIsEmpty = activeTabSet.getChildren().length === 0;

		// Probably a better way to track
		const addedNodes: Map<TabId, TabNode> = new Map();
		for (const [tabId, tab] of openTabs) {
			const initialLocation: LinkProps = {
				href: tab.path,
			};

			// We probably want to pass in an ID
			// Probably worse but doable is a map from ids of virtualized tabs to ids of tabs
			const newTab = new Tab({
				tabsState: this,
				initialLocation: initialLocation,
				tabId,
			});

			// Mirror tabs in the tabStore
			this.tabs.set(newTab.id, newTab);

			const tabConfig = {
				id: newTab.id,
				type: "tab",
				name: newTab.id,
				component: "button",
			};

			const addedNode = this.layoutModel.doAction(
				Actions.addNode(
					tabConfig,
					activeTabSet.getId(),
					DockLocation.CENTER,
					-1,
				),
			);
			addedNodes.set(newTab.id, addedNode);
		}

		if (activeTabSetIsEmpty) {
			return activeTabSet.getId();
		}

		// get the first tab we created
		const firstTabId: TabId | null = addedNodes.keys().next().value ?? null;
		if (!firstTabId) throw new Error("No first tab id found");

		// move it to bottom, which should create the new structure
		// I believe the toNodeId should be the existing tabset id.
		// This emulates "dragging" the tab to the bottom of its tabset
		this.layoutModel.doAction(
			Actions.moveNode(
				firstTabId,
				activeTabSet.getId(),
				DockLocation.BOTTOM,
				-1,
			),
		);

		// We need to get the id of the new tabset that was created
		const newTabSetId = this.layoutModel
			.getNodeById(firstTabId)
			?.getParent()
			?.getId();

		// This should never happen
		if (!newTabSetId) throw new Error("No new tabset id found");

		// move remaining tabs to the new tabset
		for (const [tabId, _] of addedNodes) {
			// Skip the first tab since it is already in the new tabset
			if (tabId !== firstTabId) {
				this.layoutModel.doAction(
					Actions.moveNode(tabId, newTabSetId, DockLocation.CENTER, -1),
				);
			}
		}

		// Make sure we can know that this new tabset is an assistant session tabset
		// And that the relationship between the tabset and the assistant session is stored

		// I don't think flexlayout lets you store custom attributes like tabset type in the model.
		// I think we use the map of tabIds to assistantSessionIds to check if a tabset is an assistant session tabset.
		// For tabs, we get their parent tabsetId and check if it is an assistant session tabset.

		return newTabSetId;
	};

	/**
	 * Creates a new tabset by adding a tab to an existing tabset and moving it.
	 * Returns the id of the new tabset.
	 */
	private createNewTabsetWithTab = (
		tab: Tab,
		existingTabsetId: string,
	): string => {
		// Add tab to existing tabset
		this.layoutModel.doAction(
			Actions.addNode(
				{
					id: tab.id,
					type: "tab",
					name: tab.id,
					component: "button",
				},
				existingTabsetId,
				DockLocation.CENTER,
				-1,
			),
		);

		// Move it to create new tabset
		this.layoutModel.doAction(
			Actions.moveNode(tab.id, existingTabsetId, DockLocation.BOTTOM, -1),
		);

		// Get new tabset id
		const newTabSetId = this.layoutModel
			.getNodeById(tab.id)
			?.getParent()
			?.getId();

		if (!newTabSetId) throw new Error("Failed to create new tabset");
		return newTabSetId;
	};

	private findTabsetId = (
		predicate: (node: JsonNodeType, nodeId: string) => boolean,
	): string | null => {
		const findTabset = (node: JsonNodeType): string | null => {
			if (
				node.type === "tabset" &&
				node.id && // check exists before passing to predicate
				predicate(node, node.id)
			) {
				return node.id;
			}

			if ("children" in node && Array.isArray(node.children)) {
				for (const child of node.children as JsonNodeType[]) {
					const found = findTabset(child);
					if (found) return found;
				}
			}
			return null;
		};

		return findTabset(this.layoutModel.toJson().layout);
	};

	/**
	 * Finds a user tabset that can be used for new tabs.
	 * Returns null if no user tabset exists.
	 */
	private findUserTabsetId = (): string | null => {
		return this.findTabsetId(
			(_, nodeId) => !this.trackedAssistantSessions.has(nodeId),
		);
	};

	private findAlternativeTabset = (tabSetIdToDelete: string): string | null => {
		return this.findTabsetId(
			(_, nodeId) =>
				nodeId !== tabSetIdToDelete &&
				!this.trackedAssistantSessions.has(nodeId),
		);
	};

	/**
	 * Creates a new tab in a user tabset.
	 * If no user tabset exists, creates one.
	 */
	createTabInUserTabSet = (
		initialLocation: LinkProps,
		initialState?: FocusedUploadSearchResult,
	): Tab => {
		const newTab = new Tab({
			tabsState: this,
			initialLocation,
			initialState,
		});
		this.tabs.set(newTab.id, newTab);

		// Find existing user tabset or use active tabset as base for new one
		const userTabsetId = this.findUserTabsetId();
		const activeTabSet = this.layoutModel.getActiveTabset();

		if (!activeTabSet) throw new Error("No active tabset found");

		if (userTabsetId) {
			// Add to existing user tabset
			this.layoutModel.doAction(
				Actions.addNode(
					{
						id: newTab.id,
						type: "tab",
						name: newTab.id,
						component: "button",
					},
					userTabsetId,
					DockLocation.CENTER,
					0,
				),
			);
		} else {
			// Create new tabset using active tabset as base
			const newTabsetId = this.createNewTabsetWithTab(
				newTab,
				activeTabSet.getId(),
			);
			// Make it active
			this.layoutModel.doAction(Actions.setActiveTabset(newTabsetId));
		}

		return newTab;
	};

	/**
	 * Tracks tabs for a given assistant session
	 */
	trackTabsForAssistantSession = (sessionAssistantId: SessionAssistantId) => {
		const newTabSetId =
			this.initializeAssistantSessionTabSet(sessionAssistantId);

		// Disable drag and drop for the new tabset
		this.layoutModel.doAction(
			Actions.updateNodeAttributes(newTabSetId, {
				enableDrag: true,
				enableDrop: false,
				config: {
					testing: "test",
				},
			}),
		);

		const newTabSet = this.layoutModel.getNodeById(newTabSetId);

		// And for all tabs in the new tabset, disable drag and drop
		if (!newTabSet) throw new Error("No new tabset found");
		const newTabSetTabs = newTabSet.getChildren();
		for (const tab of newTabSetTabs) {
			this.layoutModel.doAction(
				Actions.updateNodeAttributes(tab.getId(), {
					enableDrag: false,
					enableDrop: false,
				}),
			);
		}

		// Add the new tabset to the map
		this.trackedAssistantSessions.set(newTabSetId, sessionAssistantId);

		// initial style application
		requestAnimationFrame(() => {
			this.updateAllAssistantTabsetStyles();
		});
	};

	stopTrackingAssistantSession = (sessionAssistantId: SessionAssistantId) => {
		const tabSetIdToSessionId = Array.from(
			this.trackedAssistantSessions.entries(),
		).find(([_, value]) => value === sessionAssistantId);
		if (!tabSetIdToSessionId) throw new Error("No tabset id found");

		const tabSetIdToDelete = tabSetIdToSessionId[0];

		// find alternative BEFORE deleting anything
		const alternativeTabsetId = this.findAlternativeTabset(tabSetIdToDelete);

		// if we found an alternative, set it active first
		if (alternativeTabsetId) {
			this.layoutModel.doAction(Actions.setActiveTabset(alternativeTabsetId));
		}

		// then clean up
		this.trackedAssistantSessions.delete(tabSetIdToDelete);
		this.layoutModel.doAction(Actions.deleteTabset(tabSetIdToDelete));

		const styleTag = this.assistantTabsetStyles?.get(tabSetIdToDelete);
		if (styleTag) {
			styleTag.remove();
			this.assistantTabsetStyles.delete(tabSetIdToDelete);
		}

		requestAnimationFrame(() => {
			this.updateAllAssistantTabsetStyles();
		});
	};
}
