import { useAppContext } from "@/contexts/app-context/use-app-context";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { cn } from "@/lib/utils";
import { CustomReactRenderer } from "@/plugins/custom-react-renderer";
import type { ResourceLink, SessionAssistantId } from "@api/schemas";
import {
	FloatingPortal,
	type VirtualElement,
	autoUpdate,
	offset,
	useFloating,
} from "@floating-ui/react";
import { Eyeglasses, Globe, type Icon, Question } from "@phosphor-icons/react";
import Link, { type LinkOptions } from "@tiptap/extension-link";
import Mention, {
	type MentionOptions as BaseMentionOptions,
	type MentionNodeAttrs,
} from "@tiptap/extension-mention";
import {
	type NodeViewProps,
	NodeViewWrapper,
	ReactNodeViewRenderer,
	mergeAttributes,
} from "@tiptap/react";
import { observer } from "mobx-react-lite";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { FixedSizeList } from "react-window";

// John: Did these type inferences as an exercise. They work, trust.
type SuggestionItemType = {
	id: ResourceLink;
};
type MentionOptions = BaseMentionOptions<SuggestionItemType, MentionNodeAttrs>;
type SuggestionOptions = MentionOptions["suggestion"];
type SuggestionProps = Parameters<
	NonNullable<ReturnType<NonNullable<SuggestionOptions["render"]>>["onStart"]>
>[0];

interface ObjectListRef {
	setReference: (element: VirtualElement | null) => void;
	onKeyDown: (event: KeyboardEvent) => boolean;
}

export const ObjectRow = ({
	index,
	data,
	style,
}: {
	index: number;
	data: {
		items: SuggestionItemType[];
		highlightedIndex: number;
		selectHandler: (index: number) => void;
	};
	style: React.CSSProperties;
}) => {
	return (
		<button
			type="button"
			style={style}
			data-highlighted={index === data.highlightedIndex}
			className="block h-fit w-full truncate rounded-none p-1 text-left font-normal text-sm data-[highlighted=true]:bg-neutral-100"
			onClick={() => {
				data.selectHandler(index);
			}}
		>
			<ObjectLinkContent href={data.items[index].id} />
		</button>
	);
};

const ObjectList = observer<SuggestionProps, ObjectListRef>(
	forwardRef((props, ref) => {
		const { refs, floatingStyles } = useFloating({
			placement: "top-start",
			whileElementsMounted: autoUpdate,
			strategy: "fixed", // add this
			middleware: [
				offset(5), // optional, adds some spacing
			],
		});
		const [highlightedIndex, setHighlightedIndex] = useState<number>(0);

		const selectHandler = (index: number) => {
			props.command({
				id: props.items[index].id,
			});
		};

		useImperativeHandle(ref, () => ({
			setReference: refs.setReference,
			onKeyDown: (event: KeyboardEvent) => {
				if (event.key === "ArrowUp") {
					setHighlightedIndex(Math.max(0, highlightedIndex - 1));
					return true;
				}
				if (event.key === "ArrowDown") {
					setHighlightedIndex(
						Math.min(props.items.length - 1, highlightedIndex + 1),
					);
					return true;
				}
				if (event.key === "Enter") {
					selectHandler(highlightedIndex);
					return true;
				}
				return false;
			},
		}));

		const listRef = useRef<FixedSizeList>(null);

		useEffect(() => {
			if (listRef.current) {
				listRef.current.scrollToItem(highlightedIndex);
			}
		}, [highlightedIndex]);

		return (
			<FloatingPortal root={document.body}>
				<div
					ref={refs.setFloating}
					style={floatingStyles}
					className="w-96 overflow-y-auto border bg-white"
				>
					{props.items.length > 0 ? (
						<FixedSizeList
							ref={listRef}
							itemData={{
								items: props.items,
								highlightedIndex,
								selectHandler,
							}}
							itemSize={28}
							height={400}
							itemCount={props.items.length}
							width={"100%"}
						>
							{ObjectRow}
						</FixedSizeList>
					) : (
						<div className="p-2 text-neutral-500 text-sm">No objects found</div>
					)}
				</div>
			</FloatingPortal>
		);
	}),
);

export const getSuggestion = (
	items: SuggestionOptions["items"],
): SuggestionOptions => ({
	char: "++",
	allowSpaces: true,
	items: items,
	render: () => {
		let component: CustomReactRenderer<ObjectListRef, SuggestionProps> | null =
			null;
		let virtualElement: VirtualElement | null = null;

		return {
			onStart: (props) => {
				component = new CustomReactRenderer(ObjectList, {
					props,
					editor: props.editor,
				});

				if (!props.clientRect) {
					console.error("No client rect function.");
					return;
				}
				const clientRect = props.clientRect();
				if (!clientRect) {
					console.error("No client rect.");
					return;
				}
				virtualElement = {
					getBoundingClientRect: () => clientRect,
				};
				if (!component.ref) {
					console.error("No component ref.");
					return;
				}
				component.ref.setReference(virtualElement);
			},
			onUpdate(props) {
				// biome-ignore lint/style/noNonNullAssertion: component is set in onStart
				component = component!;
				// biome-ignore lint/style/noNonNullAssertion: component.ref is set in onStart
				component.ref = component.ref!;
				// biome-ignore lint/style/noNonNullAssertion: virtualElement is set in onStart
				virtualElement = virtualElement!;

				component.updateProps(props);
				if (!props.clientRect) {
					console.error("No client rect function.");
					return;
				}
				const clientRect = props.clientRect();
				if (!clientRect) {
					console.error("No client rect.");
					return;
				}
				virtualElement.getBoundingClientRect = () => clientRect;
				component.ref.setReference(virtualElement);
			},
			onKeyDown(props) {
				// biome-ignore lint/style/noNonNullAssertion: component is set in onStart
				component = component!;
				// biome-ignore lint/style/noNonNullAssertion: component.ref is set in onStart
				component.ref = component.ref!;
				return component.ref.onKeyDown(props.event);
			},
			onExit() {
				// biome-ignore lint/style/noNonNullAssertion: component is set in onStart
				component = component!;
				component.destroy();
			},
		};
	},
});

type ObjectLinkComponentProps = {
	// TODO(John): messages and assistant-session don't work with the routing
	// system yet
	href: string;
	className?: string;
	children: React.ReactNode;
};

const isAbsoluteUrl = (href: string): boolean => {
	try {
		new URL(href);
		return true;
	} catch {
		return false;
	}
};

export const ObjectLinkComponent = observer(function ObjectLinkComponent({
	href,
	className,
	children,
}: ObjectLinkComponentProps) {
	const appContext = useAppContext();
	const tabStore = useTabStore();
	let handler: (event: React.MouseEvent<HTMLAnchorElement>) => void;

	const isAbsolute = isAbsoluteUrl(href);

	if (isAbsolute) {
		// absolute URLs open in a new browser tab instead of in-app
		// maybe we'll want to resolve these to a resource, but this
		// gets tricky if the resource doesn't exist...
		return (
			<a
				href={href}
				target="_blank"
				rel="noopener noreferrer"
				className={className}
			>
				{children}
			</a>
		);
	}

	if (href.startsWith("/message")) {
		handler = (event) => {
			event.preventDefault();
			appContext.rightSidebarState.messageTab.router.navigate({
				href: href,
			});
		};
	} else if (href.startsWith("/assistant-session")) {
		handler = (event) => {
			event.preventDefault();
			const sessionAssistantId = href.split("/")[-1];
			appContext.rightSidebarState.activityViewerActiveSessionAssistantId =
				sessionAssistantId as SessionAssistantId;
			appContext.rightSidebarState.rightSidebarTab = "assistant_activity";
		};
	} else {
		handler = (event) => {
			event.preventDefault();
			tabStore.navigateFromOutsideTab({
				href: href,
			});
		};
	}
	return (
		<a href={href} onClick={handler} className={className}>
			{children}
		</a>
	);
});

type ObjectLinkContentProps = {
	href: string;
	label?: string;
	showIcon?: boolean;
	className?: string;
};

type IconAndLabel = {
	Icon: Icon;
	defaultLabel: string;
};

export const useGetIconAndLabel = (href: string): IconAndLabel => {
	const tabStore = useTabStore();
	const [iconAndLabel, setIconAndLabel] = useState<IconAndLabel>({
		Icon: Question,
		defaultLabel: "Loading...",
	});

	useEffect(() => {
		if (isAbsoluteUrl(href)) {
			setIconAndLabel({ Icon: Globe, defaultLabel: href });
			return;
		}

		const loadIconAndLabel = async () => {
			try {
				const head = await tabStore.getTabHead(href);
				setIconAndLabel({ Icon: head.icon, defaultLabel: head.label });
			} catch (error) {
				if (href.startsWith("/assistant-session")) {
					setIconAndLabel({
						Icon: Eyeglasses,
						defaultLabel: `Session ${href.slice(-4)}`,
					});
				} else {
					console.error(`Got invalid object link: ${href}`, error);
					setIconAndLabel({ Icon: Question, defaultLabel: "Unknown" });
				}
			}
		};

		void loadIconAndLabel();
	}, [href, tabStore]);

	return iconAndLabel;
};

export const ObjectLinkContent = observer(function ObjectLinkContent({
	href,
	label,
	showIcon = true,
	className,
}: ObjectLinkContentProps) {
	const { Icon, defaultLabel } = useGetIconAndLabel(href);
	return (
		<span
			className={cn(
				"inline-flex min-w-0 max-w-full items-center gap-1 px-0.5",
				className,
			)}
		>
			{showIcon && Icon && (
				<Icon size={12} className="flex-none text-neutral-400" />
			)}
			<span className="min-w-0 truncate">{label ? label : defaultLabel}</span>
		</span>
	);
});

const ObjectLinkNodeView = (props: NodeViewProps) => {
	const href = props.node.attrs.id;

	return (
		<NodeViewWrapper as="span">
			<ObjectLinkComponent href={href}>
				<ObjectLinkContent
					href={href}
					label={props.node.attrs.label}
					className="rounded-sm hover:bg-neutral-100 hover:underline"
				/>
			</ObjectLinkComponent>
		</NodeViewWrapper>
	);
};

export const ObjectLink = Mention.extend({
	priority: 1002, // Higher priority than Link (1000) to process the nodes first
	addAttributes() {
		return {
			id: {
				default: null,
				parseHTML: (element) => element.getAttribute("href"),
				// TODO(John): fix this attribute handling
				renderHTML: (attributes) => {
					if (!attributes.id) {
						return {};
					}
					return {
						href: attributes.id,
					};
				},
			},
			label: {
				default: "",
				parseHTML: (element) => element.innerText,
				// Label is rendered within the node and not as an attribute
				renderHTML: () => {
					return {};
				},
			},
		};
	},
	parseHTML() {
		return [
			{
				tag: `a[href^="/"]`,
			},
		];
	},
	renderHTML({ node, HTMLAttributes }) {
		return ["a", mergeAttributes(HTMLAttributes), node.attrs.label];
	},
	renderText({ node }) {
		return node.attrs.label;
	},
	addNodeView() {
		return ReactNodeViewRenderer(ObjectLinkNodeView);
	},
});

export const getObjectLinkExtension = (
	search: (query: string) => SuggestionItemType[],
) => {
	return ObjectLink.configure({
		suggestion: getSuggestion(({ query }) => search(query)),
	});
};

// From the Link extension.
// From DOMPurify
// https://github.com/cure53/DOMPurify/blob/main/src/regexp.js
// eslint-disable-next-line no-control-regex
const ATTR_WHITESPACE =
	// biome-ignore lint/suspicious/noControlCharactersInRegex: <explanation>
	/[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205F\u3000]/g;
function isAllowedUri(
	uri: string | undefined,
	protocols?: LinkOptions["protocols"],
) {
	const allowedProtocols: string[] = [
		"http",
		"https",
		"ftp",
		"ftps",
		"mailto",
		"tel",
		"callto",
		"sms",
		"cid",
		"xmpp",
	];

	if (protocols) {
		for (const protocol of protocols) {
			const nextProtocol =
				typeof protocol === "string" ? protocol : protocol.scheme;

			if (nextProtocol) {
				allowedProtocols.push(nextProtocol);
			}
		}
	}

	return (
		!uri ||
		uri
			.replace(ATTR_WHITESPACE, "")
			.match(
				new RegExp(
					`^(?:(?:${allowedProtocols.join("|")}):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))`,
					"i",
				),
			)
	);
}

export const CustomLink = Link.extend({
	parseHTML() {
		return [
			{
				tag: "a[href]:not([href^='/'])",
				getAttrs: (dom) => {
					const href = (dom as HTMLElement).getAttribute("href");

					// prevent XSS attacks
					if (!href || !isAllowedUri(href, this.options.protocols)) {
						return false;
					}
					return null;
				},
			},
		];
	},
});
