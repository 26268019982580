import { PDFViewer } from "@/components/pdf/pdfviewer";
import { API_ENDPOINT_HTTP } from "@/config";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { FeedChannelTabState } from "@/contexts/feeds/tab-state";
import { getFeedItemPdf, getFeedItemToc } from "@api/fastAPI";
import { type FeedItemId, PageResolution } from "@api/schemas";
import { observer } from "mobx-react-lite";

export const FeedItemViewer = observer(function FeedItemViewer({
	feedItemId,
	tabState,
}: { feedItemId: FeedItemId; tabState: FeedChannelTabState }) {
	const appContext = useAppContext();
	const feedItem = appContext.workspace.feedItems.getResourceById(feedItemId);

	if (feedItem.isErr()) {
		return <div>Feed item not found</div>;
	}

	return (
		<PDFViewer
			resourceId={feedItem.value.feed_item_id}
			searchParams={tabState.searchParams}
			thumbnailUrl={(pageIndex) => {
				return `${API_ENDPOINT_HTTP}/feeds/page_image/${feedItem.value.feed_item_id}/${pageIndex}/${PageResolution.thumbnail}.jpg`;
			}}
			loader={async () => {
				const resp = await getFeedItemPdf(feedItem.value.feed_item_id, {
					responseType: "arraybuffer",
				});
				return new Uint8Array(resp.data as ArrayBuffer);
			}}
			tocLoader={async () => {
				const resp = await getFeedItemToc(feedItem.value.feed_item_id);
				return resp.data;
			}}
			headerMetadata={{
				title: feedItem.value.name,
				authors: feedItem.value.authors,
				description: feedItem.value.description,
				url: feedItem.value.original_url,
			}}
		/>
	);
});
