import {
	getFieldColumn,
	getRecordLinkColumn,
	getSelectorColumn,
} from "@/components/table/columns";
import { FieldCreatorPopover } from "@/components/table/field-creator-popover";
import { ADD_COLUMN_ID } from "@/components/table/utils";
import { Button } from "@/components/ui/button";
import {
	ResourceTableState,
	type UserTableState,
} from "@/contexts/tables/stores/table-store";
import { TableViewContext } from "@/contexts/tables/use-table-context";
import type { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import type {
	FieldId,
	Record,
	ResourceLink,
	TableId,
	TableResource,
} from "@api/schemas";
import { Code, Copy, Plus, Table } from "@phosphor-icons/react";
import { type ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { makeAutoObservable } from "mobx";
import { toast } from "sonner";

/**
 * View settings for a user table within a tab.
 */
export class UserTableViewState {
	tableState: UserTableState;
	devMode = false;
	view: "table" | "record" = "table";

	// the record ID of the current record in record view mode
	#visibleRecordLink: ResourceLink | null = null;

	constructor(props: {
		tableState: UserTableState;
		initialVisibleRecordLink?: ResourceLink;
	}) {
		this.tableState = props.tableState;
		if (props.initialVisibleRecordLink) {
			const rowIndex = this.tableState.sortedRecords.findIndex(
				(row) => row.link === props.initialVisibleRecordLink,
			);
			if (rowIndex !== -1) {
				this.view = "record";
				this.#visibleRecordLink = props.initialVisibleRecordLink;
			}
		}
		makeAutoObservable(this);
	}

	setView(view: "table" | "record") {
		this.view = view;
	}

	get visibleRecordLink(): ResourceLink | null {
		if (this.#visibleRecordLink === null) {
			return null;
		}
		if (!this.tableState.records.has(this.#visibleRecordLink)) {
			this.#visibleRecordLink = null;
			this.view = "table";
		}
		return this.#visibleRecordLink;
	}

	setVisibleRecordLink(recordLink: ResourceLink): void {
		this.#visibleRecordLink = recordLink;
	}

	get visibleRecordRowIndex(): number | null {
		if (this.visibleRecordLink === null) {
			return null;
		}
		return this.tableState.recordIndexMap.get(this.visibleRecordLink) ?? null;
	}

	/**
	 * Props for the table component
	 */

	getRowId = (row: Record): ResourceLink => {
		return row.link;
	};

	resizeHandler = (columnId: FieldId, columnSize: number) => {
		this.tableState.updateField({
			fieldId: columnId,
			newWidth: columnSize,
		});
	};

	addRow = (precedingRowLink: ResourceLink | null) => {
		this.tableState.addRecord({
			precedingRecordLink: precedingRowLink,
		});
	};

	moveRows = (
		rowLinks: Set<ResourceLink>,
		precedingRowLink: ResourceLink | null,
	) => {
		this.tableState.moveRecords({
			recordLinks: rowLinks,
			precedingRecordLink: precedingRowLink,
		});
	};

	deleteRows = (rowLinks: Set<ResourceLink>) => {
		this.tableState.deleteRecords({
			recordLinks: rowLinks,
		});
	};

	moveColumn = (columnId: FieldId, precedingColumnId: FieldId | null) => {
		this.tableState.moveField({
			fieldId: columnId,
			precedingFieldId: precedingColumnId,
		});
	};

	renameField = (params: {
		fieldId: FieldId;
		newName: string;
	}) => {
		this.tableState.updateField(params);
	};

	get rows(): Record[] {
		return this.tableState.sortedRecords;
	}

	// TODO(John): special handling for primary vs view vs regular fields
	get columns() {
		const columnHelper = createColumnHelper<Record>();
		if (this.tableState.sortedFields.isErr()) {
			return [];
		}
		const sortedFields = this.tableState.sortedFields.value;

		const columns: ColumnDef<Record>[] = [
			getSelectorColumn(),
			getRecordLinkColumn(),
		];

		// Must be a computed table
		if ("viewFields" in sortedFields) {
			sortedFields.viewFields.map(([field, dataType]) => {
				columns.push(getFieldColumn(field, dataType, false));
			});
			sortedFields.regularFields.map(([field, dataType]) => {
				columns.push(getFieldColumn(field, dataType, true));
			});
		} else {
			sortedFields.primaryFields.map(([field, dataType]) => {
				columns.push(getFieldColumn(field, dataType, true));
			});
			sortedFields.regularFields.map(([field, dataType]) => {
				columns.push(getFieldColumn(field, dataType, true));
			});
		}

		columns.push(
			columnHelper.display({
				id: ADD_COLUMN_ID,
				header: () => (
					<FieldCreatorPopover>
						<Button
							variant="ghost"
							size="icon"
							className="rounded-md p-1.5 hover:bg-neutral-100"
						>
							<Plus className="text-lg " />
						</Button>
					</FieldCreatorPopover>
				),
			}),
		);

		if (this.devMode) {
			columns.splice(
				0,
				0,
				columnHelper.display({
					id: "recordId",
					header: () => (
						<div className="flex h-full w-full select-none items-center gap-2 truncate p-1 font-normal hover:bg-neutral-100">
							<Code />
							Record ID
						</div>
					),
					cell: ({ row }) => (
						<div className="flex items-center gap-1 p-1 text-xs">
							<button
								type="button"
								className="rounded-md border p-1 hover:bg-neutral-100"
								onClick={() => {
									navigator.clipboard.writeText(row.original.link);
									toast.success("Record link copied to clipboard");
								}}
							>
								<Copy />
							</button>
							<pre className="text-neutral-500">{row.original.link}</pre>
						</div>
					),
					size: 300,
				}),
			);
		}
		return columns;
	}
}

/**
 * The state of a table tab.
 */
export class TableTabState implements BaseTabState {
	tab: Tab;
	tableResource: TableResource;
	tableViewState: UserTableViewState;

	constructor(tab: Tab, tableId: TableId) {
		this.tab = tab;
		this.tab.setState(this);

		const tableResource =
			tab.tabStore.appState.workspace.tables.getResourceById(tableId);

		if (tableResource.isErr()) {
			throw new Error("Table not found");
		}

		this.tableResource = tableResource.value;
		const tableState =
			this.tab.tabStore.appState.workspace.tables.getTableStateById(
				this.tableResource.table_id,
			);

		// TODO(John): think about how to deal with resource tables.
		if (tableState instanceof ResourceTableState) {
			throw new Error("Table is not a user table");
		}

		this.tableViewState = new UserTableViewState({
			tableState,
		});
		makeAutoObservable(this);
	}

	get head() {
		return {
			icon: Table,
			label: this.tableResource.name,
			resourceRef: {
				type: "table" as const,
				resource_id: this.tableResource.table_id,
			},
		};
	}
}

export const TableViewProvider = ({
	tableViewState,
	children,
}: {
	tableViewState: UserTableViewState;
	children: React.ReactNode;
}) => {
	return (
		<TableViewContext.Provider value={tableViewState}>
			{children}
		</TableViewContext.Provider>
	);
};

/**
 * Tab state for the tables home page.
 */
export class TablesHomeTabState implements BaseTabState {
	tab: Tab;

	constructor(tab: Tab) {
		this.tab = tab;
		this.tab.setState(this);

		makeAutoObservable(this);
	}

	get head() {
		return {
			icon: Table,
			label: "Tables",
		};
	}
}
