import { TableComponent } from "@/components/table/table-component";
import { Button } from "@/components/ui/button";
import { usePageStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { PagesIndexTabState } from "@/contexts/pages/tab-state";
import { usePagesIndex } from "@/contexts/resource-indexes/hooks";
import { Plus } from "@phosphor-icons/react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

const PagesHomePage = observer(function PagesHomePage() {
	const pagesIndex = usePagesIndex();
	const pagesStore = usePageStore();
	const navigate = useNavigate();

	const createPageHandler = () => {
		pagesStore.createPage({
			onLocalSuccess: (newPage) => {
				navigate({
					to: "/pages/$page-id",
					params: {
						"page-id": newPage.page_id,
					},
				});
			},
		});
	};

	return (
		<div className="flex min-h-0 w-full grow flex-col gap-4 p-4">
			<h1 className="font-bold text-2xl">Pages</h1>
			<div className="flex">
				<Button
					variant="outline"
					onClick={createPageHandler}
					className="gap-2"
					size="sm"
				>
					<Plus weight="bold" />
					<span>Create</span>
				</Button>
			</div>
			<TableComponent
				getRowId={(row) => row.link}
				columns={pagesIndex.columns}
				data={pagesIndex.rows}
			/>
		</div>
	);
});

export const Route = createFileRoute("/pages/")({
	component: PagesHomePage,
	loader: ({ context: { tab } }) => {
		const tabState = new PagesIndexTabState(tab);
		return { tabState };
	},
});
