import { useAppContext } from "@/contexts/app-context/use-app-context";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import "@/pages/tabs.css";
import type { TabId } from "@api/schemas";
import { Plus, X } from "@phosphor-icons/react";
import clsx from "clsx";
import {
	Actions,
	type BorderNode,
	type ITabRenderValues,
	type ITabSetRenderValues,
	Layout,
	TabNode,
	TabSetNode,
	type TabSetPlaceHolderCallback,
} from "flexlayout-react";
import "flexlayout-react/style/light.css";
import {
	AssistantPresenceIndicator,
	ResourceAssistantsPresenceIndicator,
	getColorForSession,
} from "@/components/layout/right-sidebar/assistant-presence";
import { IS_DEV } from "@/config";
import { RouterProvider } from "@tanstack/react-router";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { toast } from "sonner";

interface TabHandleProps {
	tabId: TabId;
}

const TabLeading = observer(({ tabId }: TabHandleProps) => {
	const tabStore = useTabStore();
	const { tabs } = tabStore;
	const tab = tabs.get(tabId);
	if (!tab) return null;
	const head = tab.state.head;
	return (
		<div className="flex max-w-48 items-center gap-2 pl-1 text-neutral-700 text-sm">
			<head.icon className="h-4 w-4 shrink-0 text-neutral-700" />
			<span className="truncate">{head.label}</span>
			{head.resourceRef && (
				<ResourceAssistantsPresenceIndicator resourceRef={head.resourceRef} />
			)}
		</div>
	);
});

const TabContent = observer(function TabContent({ tabId }: { tabId: TabId }) {
	const appContext = useAppContext();
	const tabStore = useTabStore();
	const tab = tabStore.tabs.get(tabId);
	if (!tab) return null;
	return (
		<div key={tabId} className={clsx("flex h-full flex-col bg-white")}>
			{IS_DEV && appContext.devSettings.showTabUrls ? (
				<div className="flex w-full shrink-0 overflow-x-auto">
					<div className="flex h-6 w-full whitespace-nowrap border-t border-b bg-neutral-50 px-2 py-1 font-mono text-neutral-500 text-xs">
						{tab.parsedLocation?.href}
					</div>
				</div>
			) : null}

			<RouterProvider router={tab.router} />
		</div>
	);
});

export const Tabs = observer(() => {
	const appContext = useAppContext();
	const layoutRef = useRef<Layout>(null);
	const tabStore = useTabStore();

	const onAddFromTabSetButton = () => {
		appContext.setCmdKOpen(true);
	};

	const onCloseTabSetButton = (node: TabSetNode) => {
		tabStore.removeTabsOfTabSetNode(node.getId());
	};

	const onCloseTabButton = (node: TabNode) => {
		tabStore.removeTab(node.getName() as TabId);
	};

	const onRenderTabSet = (
		node: TabSetNode | BorderNode,
		renderValues: ITabSetRenderValues,
	) => {
		if (node instanceof TabSetNode) {
			// Check if the tab is in an assistant session tabset
			const tabSetId = node.getId();
			const isAssistantSessionTabSet = tabSetId
				? tabStore.trackedAssistantSessions.has(tabSetId)
				: false;

			const sessionAssistantId =
				tabStore.trackedAssistantSessions.get(tabSetId);
			const session = sessionAssistantId
				? appContext.assistantSessionStore.assistantSessions.get(
						sessionAssistantId,
					)
				: null;

			if (isAssistantSessionTabSet && sessionAssistantId && session) {
				renderValues.buttons.push(
					<button
						key={`assistant-presence-${sessionAssistantId}`}
						type="button"
						className="mr-1 shrink-0 text-nowrap rounded text-white text-xs"
						onClick={() => {
							runInAction(() => {
								appContext.rightSidebarState.activityViewerActiveSessionAssistantId =
									sessionAssistantId;
							});
						}}
					>
						<AssistantPresenceIndicator
							sessionAssistantId={sessionAssistantId}
							active={session.ended_at === null}
						/>
					</button>,
				);
				// Button to stop tracking
				renderValues.buttons.push(
					<button
						key={`stop-tracking-${sessionAssistantId}`}
						type="button"
						className={clsx(
							"mr-1 shrink-0 text-nowrap rounded text-xs",
							getColorForSession(sessionAssistantId, "text").className,
						)}
						onClick={() => {
							appContext.tabStore.stopTrackingAssistantSession(
								sessionAssistantId,
							);
						}}
					>
						<X
							className="h-4 w-4"
							// Make bold
							weight="bold"
						/>
					</button>,
				);
				return;
			}
		}

		if (node instanceof TabSetNode && node.getChildren().length > 0) {
			renderValues.buttons.push(
				<button
					key={`add-tab-${node.getId()}`}
					type="button"
					onClick={onAddFromTabSetButton}
					className="shrink-0 text-nowrap p-1 hover:bg-neutral-100"
				>
					<Plus className="h-4 w-4" />
				</button>,
			);

			renderValues.buttons.push(
				<button
					key={`close-tabset-${node.getId()}`}
					type="button"
					onClick={() => onCloseTabSetButton(node)}
					className="mr-1 shrink-0 text-nowrap p-1 hover:bg-neutral-100"
				>
					<X className="h-4 w-4" />
				</button>,
			);
		}

		if (node instanceof TabSetNode && node.getChildren().length === 0) {
			renderValues.stickyButtons = [];
			renderValues.buttons.push(
				<button
					key={`add-tab-${node.getId()}`}
					type="button"
					onClick={onAddFromTabSetButton}
					className="shrink-0 text-nowrap p-1 hover:bg-neutral-100"
				>
					<Plus className="h-4 w-4" />
				</button>,
			);
		}
	};

	const onTabSetPlaceHolder: TabSetPlaceHolderCallback = () => {
		return (
			<div className="flex h-full w-full items-center justify-center bg-neutral-100 text-neutral-400 text-sm" />
		);
	};

	const onRenderTab = (node: TabNode, renderValues: ITabRenderValues) => {
		if (node instanceof TabNode) {
			// This should not be rendering if the tabSet has been removed
			const tabId = node.getName() as TabId;
			renderValues.content = <TabLeading tabId={tabId} />;
		}
	};

	const tabFactory = (node: TabNode) => {
		const newTabId = node.getName();

		return <TabContent tabId={newTabId as TabId} />;
	};

	return (
		<div className="flex h-full w-full flex-col bg-neutral-50">
			<div className="relative min-h-0 grow ">
				<Layout
					model={tabStore.layoutModel}
					factory={tabFactory}
					onRenderTabSet={onRenderTabSet}
					onRenderTab={onRenderTab}
					onTabSetPlaceHolder={onTabSetPlaceHolder}
					classNameMapper={(defaultClassName) => {
						if (defaultClassName === "flexlayout__tabset_tabbar_outer") {
							return clsx(
								defaultClassName,
								"!p-0 flex border-neutral-200 bg-neutral-50 opacity-50",
							);
						}

						return defaultClassName;
					}}
					ref={layoutRef}
					icons={{
						// We hide close button for assistant session here
						close: (node) => {
							const tabSetId = node.getParent()?.getId();
							const isAssistantSessionTabSet = tabSetId
								? tabStore.trackedAssistantSessions.has(tabSetId)
								: false;

							if (isAssistantSessionTabSet) {
								return null;
							}

							return (
								<button
									key={`close-tab-${node.getId()}`}
									type="button"
									onClick={() => onCloseTabButton(node)}
									className={clsx(
										"shrink-0 text-nowrap p-1 ",
										node.isSelected()
											? "hover:bg-neutral-100"
											: "opacity-0 hover:opacity-100",
									)}
									// disabled={!node.isSelected()}
								>
									<X className="h-4 w-4" />
								</button>
							);
						},
					}}
					onAction={(action) => {
						if (action.type === Actions.MOVE_NODE) {
							const fromNode = tabStore.layoutModel.getNodeById(
								action.data.fromNode,
							);
							const location = action.data.location;

							if (
								fromNode?.getType() === "tabset" &&
								tabStore.trackedAssistantSessions.has(fromNode.getId())
							) {
								// only prevent if trying to merge (CENTER location)
								if (location === "center") {
									toast.warning(
										"Assistant windows cannot be merged with other windows",
									);
									return undefined;
								}
								// allow BOTTOM, RIGHT, LEFT, TOP for repositioning
							}
						}
						return action;
					}}
				/>
			</div>
		</div>
	);
});
