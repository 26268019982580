import { TextEditor } from "@/components/editor";
import type { BaseRendererProps } from "@/components/table/scalar-renderers/renderer-props";

import { observer } from "mobx-react-lite";

type TextRendererProps = BaseRendererProps<"text">;

export const TextRenderer = observer((props: TextRendererProps) => {
	const currentValue = props.value ?? "";

	return (
		<TextEditor
			className="h-full w-full"
			options={{
				content: currentValue,
				editable: props.editable,
				onBlur: ({ editor }) => {
					props.onUpdate?.(editor.getHTML());
				},
				editorProps: {
					attributes: {
						class: "h-full w-full p-1",
					},
				},
			}}
		/>
	);
});
