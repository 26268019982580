import {
	ADD_COLUMN_ID,
	sanitizeForCSSVariableName,
} from "@/components/table/utils";
import { type Row, flexRender } from "@tanstack/react-table";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";

export type TableRowProps<TData> = {
	row: Row<TData>;
};

export const TableRow = observer(<TData,>(props: TableRowProps<TData>) => {
	const { row } = props;
	return (
		<div
			aria-label="tr"
			className={clsx(
				"flex cursor-pointer items-stretch",
				row.getIsSelected() ? "bg-blue-50" : "",
				"group/table-row",
			)}
			key={`row-body-${row.id}`}
		>
			{row.getVisibleCells().map((cell) => {
				const isPinned = cell.column.getIsPinned();
				return (
					<Fragment key={`cell-${cell.id}`}>
						{/* Marker if dragged column is hovering over this column */}
						{/* Made visible by HeaderDndContext and index.css */}
						<div
							data-drag-indicator-column-id={cell.column.id}
							className="z-10 hidden w-0 p-0 ring-1 ring-blue-500"
						/>
						<div
							aria-label="td"
							className={clsx(
								// a set height is required to have the cell contents use height: 100%
								// without this, the cell contents will be vertically centered
								"whitespace-break-space flex shrink-0 border-neutral-100 border-b bg-white p-0",
								cell.column.id !== ADD_COLUMN_ID && "border-r",
							)}
							style={{
								width: `calc(var(--col-${sanitizeForCSSVariableName(
									cell.column.id,
								)}-size) * 1px)`,
								position: isPinned ? "sticky" : "relative",
								zIndex: isPinned ? 1 : 0,
								left: isPinned ? `${cell.column.getStart("left")}px` : 0,
							}}
						>
							{flexRender(cell.column.columnDef.cell, cell.getContext())}
						</div>
					</Fragment>
				);
			})}
		</div>
	);
});
