import { ToggleLeftSidebarButton } from "@/components/layout/toggle-left-sidebar-button";
import { ToggleRightSidebarButton } from "@/components/layout/toggle-right-sidebar-button";
import { TopBarSearch } from "@/components/layout/top-bar-search";
import { Button } from "@/components/ui/button";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Switch } from "@/components/ui/switch";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { IS_DEV } from "@/config";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { Bug } from "@phosphor-icons/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";

export const TopBar = observer(function TopBar() {
	const appContext = useAppContext();

	return (
		<div className="flex w-full shrink-0 items-center justify-between gap-2 border-b bg-white px-4 py-1.5">
			<div className="flex items-center gap-2">
				<ToggleLeftSidebarButton />

				{IS_DEV && (
					<Popover>
						<Tooltip>
							<TooltipTrigger asChild>
								<PopoverTrigger asChild>
									<Button variant="ghost">
										<Bug />
									</Button>
								</PopoverTrigger>
							</TooltipTrigger>
							<TooltipContent>Dev tools</TooltipContent>
						</Tooltip>
						<PopoverContent align="start" className="flex w-96 flex-col gap-2">
							<div className="flex items-center justify-between gap-2 font-mono">
								<div className="text-neutral-500 text-sm">Show tab URLs</div>
								<Switch
									checked={appContext.devSettings.showTabUrls}
									onCheckedChange={(checked) => {
										runInAction(() => {
											appContext.devSettings.showTabUrls = checked;
										});
									}}
								/>
							</div>
							<div className="flex items-center justify-between gap-2 font-mono">
								<div className="text-neutral-500 text-sm">
									Show search result scores
								</div>
								<Switch
									checked={appContext.devSettings.showSearchResultScores}
									onCheckedChange={(checked) => {
										runInAction(() => {
											appContext.devSettings.showSearchResultScores = checked;
										});
									}}
								/>
							</div>
						</PopoverContent>
					</Popover>
				)}
			</div>
			<div className="flex max-w-3xl grow items-center gap-2">
				{/* <button
					type="button"
					onClick={() => {
						console.log(toJS(tabsContext.appState));
					}}
					className="text-nowrap text-neutral-500 text-xs"
				>
					<span>Log AppState</span>
				</button> */}
				{/* <button
					type="button"
					onClick={() => {
						navigate(-1 as To);
					}}
					className="disabled:opacity-50"
					// disabled={tabsContext.activeTab?.router.window?.history.length === 1}
				>
					<ArrowLeft />
				</button>
				<button
					type="button"
					onClick={() => {
						navigate(1 as To);
					}}
					className="disabled:opacity-50"
					// disabled={tabsContext.activeTab?.router.window?.history.length === 1}
				>
					<ArrowRight />
				</button> */}
				<TopBarSearch />
			</div>
			<ToggleRightSidebarButton />
		</div>
	);
});
