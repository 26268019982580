import { TextEditor } from "@/components/editor";
import { usePageStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { PageTabState } from "@/contexts/pages/tab-state";
import type { PageId } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";
import debounce from "lodash.debounce";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

const PagePage = observer(function PagePage() {
	const { tabState } = Route.useLoaderData();
	const page = tabState.page;
	const pageStore = usePageStore();

	const titleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
		pageStore.updatePage({ pageId: page.page_id, name: e.target.value });
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: need to include pageStore in deps
	const debouncedEditorUpdate = useCallback(
		debounce((content: string) => {
			pageStore.updatePage({ pageId: page.page_id, content });
		}, 1000),
		[pageStore],
	);

	return (
		<div className="flex min-h-0 w-full grow flex-col">
			{/* Header */}
			<div>
				<input
					className="mt-4 w-full bg-transparent px-4 font-medium text-xl focus:outline-none"
					defaultValue={page.name}
					onBlur={titleUpdate}
				/>
			</div>
			{/* Editor */}
			<TextEditor
				className="min-w-full grow p-4"
				options={{
					content: page.content,
					editable: true,
					onUpdate: ({ editor }) => {
						debouncedEditorUpdate(editor.getHTML());
					},
				}}
			/>
		</div>
	);
});

export const Route = createFileRoute("/pages/$page-id")({
	component: PagePage,
	loader: ({ params, context: { tab } }) => {
		const tabState = new PageTabState(tab, params["page-id"] as PageId);
		return { tabState };
	},
});
