import {
	getRecordLinkColumn,
	getResourceIndexColumn,
} from "@/components/table/columns";
import type { PageStore } from "@/contexts/pages/page-store";
import { resourceRefToPath } from "@/lib/paths";
import type { ResourceLink } from "@api/schemas";
import type { ColumnDef } from "@tanstack/react-table";

interface PagesIndexRecord {
	link: ResourceLink;
	name: string;
	createdAt: string;
	updatedAt: string;
}

export class PagesIndex {
	pageStore: PageStore;

	constructor(pageStore: PageStore) {
		this.pageStore = pageStore;
	}

	get rows(): PagesIndexRecord[] {
		return this.pageStore.map.values().map((page) => ({
			link: resourceRefToPath({
				resource_id: page.page_id,
				type: "page",
			}),
			name: page.name,
			createdAt: page.created_at,
			updatedAt: page.updated_at,
		}));
	}

	get columns(): ColumnDef<PagesIndexRecord>[] {
		return [
			getRecordLinkColumn(),
			getResourceIndexColumn({
				key: "name",
				name: "Name",
				scalarType: "text",
			}),
			getResourceIndexColumn({
				key: "createdAt",
				name: "Created At",
				scalarType: "text",
			}),
			getResourceIndexColumn({
				key: "updatedAt",
				name: "Updated At",
				scalarType: "text",
			}),
		];
	}
}
