import { FeedChannelTabState } from "@/contexts/feeds/tab-state";
import type { HighlightQuery } from "@/contexts/pdfviewer-context";
import { FeedChannelPage } from "@/pages/tabs/feeds/feed-channel/$feed-channel-id";
import type { FeedItemId } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/feeds/feed-item/$feed-item-id")({
	component: () => {
		const { tabState } = Route.useLoaderData();
		return <FeedChannelPage tabState={tabState} />;
	},
	validateSearch: (
		search: Record<string, unknown>,
	): HighlightQuery | Record<string, unknown> => {
		// if no required fields, return null
		if (!search.start) {
			return {};
		}

		// TODO(Tae): Revisit this validation
		const highlightQuery: HighlightQuery = {
			textToHighlight: {
				textStart: String(search.start),
				...(search.end ? { textEnd: String(search.end) } : {}),
			},
			startPageIndex: search.startPageIndex ? Number(search.startPageIndex) : 0,
			endPageIndex: search.endPageIndex ? Number(search.endPageIndex) : 0,
		};

		return highlightQuery;
	},
	loaderDeps: ({ search }) => ({ search }),
	loader: ({ params, context: { tab }, deps }) => {
		const hasHighlight = "textToHighlight" in deps.search;

		// If already a FeedChannelTabState, we need to get the feed channel id from it
		if (tab.state instanceof FeedChannelTabState) {
			tab.state.setActiveFeedItem(params["feed-item-id"] as FeedItemId);
			return {
				tabState: tab.state,
			};
		}
		const tabState = new FeedChannelTabState({
			tab,
			activeFeedItemId: params["feed-item-id"] as FeedItemId,
			highlightQuery: hasHighlight
				? (deps.search as HighlightQuery)
				: undefined,
		});
		return {
			tabState,
		};
	},
});
