import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { SearchModeMeta } from "@/contexts/search/tab-state";
import { SearchMode } from "@api/schemas";
import { ArrowRight } from "@phosphor-icons/react";
import { Close } from "@radix-ui/react-popover";
import { getRouteApi } from "@tanstack/react-router";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";

export const SearchSettings = observer(() => {
	const routeApi = getRouteApi("/search");
	const { tabState } = routeApi.useLoaderData();
	const { search_mode } = tabState.config;

	return (
		<Popover>
			<PopoverTrigger className="mt-2 mb-2 flex flex-wrap items-center gap-1 text-neutral-500 text-sm hover:text-black hover:underline">
				<span className="flex max-w-max items-center gap-1 border px-1 py-0.5 text-sm">
					{SearchModeMeta[search_mode].icon}
					<span className="text-xs">{SearchModeMeta[search_mode].title}</span>
				</span>
			</PopoverTrigger>
			<PopoverContent align="start" className="mt-0 w-[28rem] p-4">
				<div className="flex items-center justify-between gap-8">
					<Label className="text-neutral-800 text-sm">Search mode</Label>
					<Select
						value={search_mode}
						onValueChange={(v) => {
							runInAction(() => {
								tabState.config.search_mode = v as SearchMode;
							});
						}}
					>
						<SelectTrigger className="w-48 bg-white">
							<SelectValue placeholder="Search type">
								<div className="flex items-center gap-3">
									<span className="text-xl">
										{SearchModeMeta[search_mode].icon}
									</span>{" "}
									<span className="text-sm">
										{SearchModeMeta[search_mode].title}
									</span>
								</div>
							</SelectValue>
						</SelectTrigger>
						<SelectContent>
							{Object.values(SearchMode).map((mode) => (
								<SelectItem key={mode} value={mode}>
									<div className="flex items-center gap-4 px-2">
										<span className="text-2xl">
											{SearchModeMeta[mode].icon}
										</span>
										<div>
											<h2 className="font-semibold">
												{SearchModeMeta[mode].title}
											</h2>
											<p className="text-neutral-500">
												{SearchModeMeta[mode].description}
											</p>
										</div>
									</div>
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>

				<div className="mt-4 flex justify-end gap-4">
					<Close type="button" asChild>
						<Button variant="ghost">Cancel</Button>
					</Close>
					<Close asChild>
						<Button
							className="flex items-center gap-2"
							onClick={() => tabState.handleSearch()}
						>
							Rerun search <ArrowRight weight="bold" />
						</Button>
					</Close>
				</div>
			</PopoverContent>
		</Popover>
	);
});
