import type { PageTreeState } from "@/components/tree/tree-state";
import { type LinkableResourceRef, resourceRefToPath } from "@/lib/paths";
import { useGetIconAndLabel } from "@/plugins/object-link";
import { DragOverlay, type Modifier } from "@dnd-kit/core";
import { getEventCoordinates } from "@dnd-kit/utilities";
import { Files } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import type { ReactNode } from "react";

const snapTopLeftToCursor: Modifier = ({
	activatorEvent,
	draggingNodeRect,
	transform,
}) => {
	if (draggingNodeRect && activatorEvent) {
		const activatorCoordinates = getEventCoordinates(activatorEvent);

		if (!activatorCoordinates) {
			return transform;
		}

		const offsetX = activatorCoordinates.x - draggingNodeRect.left;
		const offsetY = activatorCoordinates.y - draggingNodeRect.top;

		return {
			...transform,
			x: transform.x + offsetX,
			y: transform.y + offsetY,
		};
	}

	return transform;
};

const DropTargetLabel = observer(
	(props: { dropTarget: LinkableResourceRef }) => {
		const { Icon, defaultLabel } = useGetIconAndLabel(
			resourceRefToPath(props.dropTarget),
		);
		return (
			<span className="inline-flex items-center gap-2 text-sm">
				<span>Move into</span>
				<span className="inline-flex items-center gap-1">
					<Icon className=" text-neutral-500" />
					<span>{defaultLabel}</span>
				</span>
			</span>
		);
	},
);

const SingleDragTargetLabel = observer(
	(props: { dragTarget: LinkableResourceRef }) => {
		const { Icon, defaultLabel } = useGetIconAndLabel(
			resourceRefToPath(props.dragTarget),
		);
		return (
			<>
				<Icon className="h-4 w-4 shrink-0 text-neutral-500" />
				<span className="text-sm">{defaultLabel}</span>
			</>
		);
	},
);

interface DragOverlayProps {
	treeState: PageTreeState;
}

export const DragOverlayComponent = observer((props: DragOverlayProps) => {
	const { dropOverNode, draggingNodes, descendantsOfDraggingNodes } =
		props.treeState;

	let DraggingNodesInfo: ReactNode = null;
	if (draggingNodes.size === 1) {
		const dragTarget = Array.from(draggingNodes.values())[0];
		DraggingNodesInfo = <SingleDragTargetLabel dragTarget={dragTarget} />;
	} else if (draggingNodes.size > 1) {
		DraggingNodesInfo = (
			<>
				<Files weight="bold" className="h-4 w-4 shrink-0 text-neutral-500" />
				<span className="text-sm">{`${draggingNodes.size} files`}</span>
			</>
		);
	}

	let DropTargetInfo: ReactNode;
	if (
		dropOverNode === null ||
		descendantsOfDraggingNodes.has(dropOverNode.resource_id)
	) {
		DropTargetInfo = null;
	} else {
		DropTargetInfo = <DropTargetLabel dropTarget={dropOverNode} />;
	}

	return (
		<DragOverlay
			className="flex flex-col gap-2 border border-neutral-300 bg-neutral-50 p-1"
			style={{
				height: "fit-content",
				width: "fit-content",
			}}
			modifiers={[snapTopLeftToCursor]}
			dropAnimation={null}
		>
			<div className="flex items-center gap-2">{DraggingNodesInfo}</div>
			{DropTargetInfo}
		</DragOverlay>
	);
});
