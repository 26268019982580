import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { SquareHalf } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";

export const ToggleLeftSidebarButton = observer(() => {
	const appContext = useAppContext();

	return (
		<Tooltip>
			<TooltipTrigger asChild>
				<button
					type="button"
					className="flex shrink-0 items-center justify-center p-1 text-neutral-500 hover:bg-neutral-100 hover:text-neutral-900"
					onClick={() =>
						appContext.leftSidebarState.setShowSidebar(
							!appContext.leftSidebarState.showSidebar,
						)
					}
				>
					<SquareHalf weight="fill" size={16} className="scale-x-[-1]" />
				</button>
			</TooltipTrigger>
			<TooltipContent side="right">Toggle sidebar</TooltipContent>
		</Tooltip>
	);
});
