import { HeaderDndContext } from "@/components/table/dnd/header-dnd";
import { RowDndContext } from "@/components/table/dnd/row-dnd";
import {
	TableHeader,
	type TableHeaderProps,
} from "@/components/table/table-header";
import { TableRow, type TableRowProps } from "@/components/table/table-row";
import { sanitizeForCSSVariableName } from "@/components/table/utils";
import { Plus, Trash } from "@phosphor-icons/react";
import {
	type ColumnDef,
	type RowSelectionState,
	type TableState,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { motion } from "motion/react";
import { useEffect, useMemo, useRef, useState } from "react";

// from https://github.com/TanStack/table/discussions/2498#discussioncomment-8649218
export const useResizeObserver = <TColumnId extends string>(
	state: TableState,
	callback: (columnId: TColumnId, columnSize: number) => void,
) => {
	// This Ref will contain the id of the column being resized or undefined
	const columnResizeRef = useRef<string | false>();
	useEffect(() => {
		// We are interested in calling the resize event only when "state.columnResizingInfo?.isResizingColumn" changes from
		// a string to false, because it indicates that it WAS resizing but it no longer is.
		if (
			state.columnSizingInfo &&
			!state.columnSizingInfo?.isResizingColumn &&
			columnResizeRef.current
		) {
			// Trigger resize event
			callback(
				columnResizeRef.current as TColumnId,
				state.columnSizing[columnResizeRef.current],
			);
		}
		columnResizeRef.current = state.columnSizingInfo?.isResizingColumn;
	}, [callback, state.columnSizingInfo, state.columnSizing]);
};

/**
 * Props to define if a table is editable. Editable means that we allow rows
 * and columns to be dragged and rows to be added (adding columns is handled by
 * the column definition).
 */
interface TableEditableProps<TRowId extends string, TColumnId extends string> {
	addRow: (precedingRowId: TRowId | null) => void;
	moveRows: (rowIds: Set<TRowId>, precedingRowId: TRowId | null) => void;
	deleteRows: (rowIds: Set<TRowId>) => void;
	moveColumn: (
		columnId: TColumnId,
		precedingColumnId: TColumnId | null,
	) => void;
}

export const TableComponent = observer(
	<TData, TRowId extends string, TColumnId extends string>(props: {
		columns: ColumnDef<TData>[];
		data: TData[];
		getRowId?: (row: TData) => TRowId;
		editableProps?: TableEditableProps<TRowId, TColumnId>;
		resizeHandler?: (columnId: TColumnId, columnSize: number) => void;
	}) => {
		const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
		const table = useReactTable({
			data: props.data,
			columns: props.columns,
			getCoreRowModel: getCoreRowModel(),
			enableRowSelection: true,
			onRowSelectionChange: setRowSelection,
			state: {
				rowSelection,
			},
			getRowId: props.getRowId,
			enableColumnResizing: true,
			columnResizeMode: "onChange",
			defaultColumn: {
				minSize: 60,
				maxSize: 800,
			},
		});

		useResizeObserver<TColumnId>(table.getState(), (columnId, columnSize) => {
			props.resizeHandler?.(columnId, columnSize);
		});

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const selectedRowIds: Set<TRowId> = useMemo(() => {
			return new Set(
				table.getSelectedRowModel().rows.map((row) => row.id as TRowId),
			);
		}, [rowSelection]);

		// From https://tanstack.com/table/v8/docs/framework/react/examples/column-resizing-performant
		/**
		 * Instead of calling `column.getSize()` on every render for every header
		 * and especially every data cell (very expensive),
		 * we will calculate all column sizes at once at the root table level in a useMemo
		 * and pass the column sizes down as CSS variables to the <table> element.
		 *
		 * Note that this means that column IDs should not have spaces.
		 */
		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const columnSizeVars = useMemo(() => {
			const headers = table.getFlatHeaders();
			const colSizes: { [key: string]: number } = {};
			for (let i = 0; i < headers.length; i++) {
				const header = headers[i];
				colSizes[`--header-${sanitizeForCSSVariableName(header.id)}-size`] =
					header.getSize();
				colSizes[`--col-${sanitizeForCSSVariableName(header.column.id)}-size`] =
					header.column.getSize();
			}
			return colSizes;
		}, [
			table.getState().columnSizingInfo,
			table.getState().columnSizing,
			table.getAllColumns(),
		]);

		const rowData = table.getRowModel().rows;

		const HeaderWrapper = ({
			children,
		}: {
			children: React.ReactElement<TableHeaderProps<TData>>[][];
		}) => {
			if (props.editableProps !== undefined) {
				return (
					<HeaderDndContext moveColumn={props.editableProps.moveColumn}>
						{children}
					</HeaderDndContext>
				);
			}
			return <>{children}</>;
		};
		const RowWrapper = ({
			children,
		}: {
			children: React.ReactElement<TableRowProps<TData>>[];
		}) => {
			if (props.editableProps !== undefined) {
				return (
					<RowDndContext
						table={table}
						selectedRowIds={selectedRowIds}
						moveRows={props.editableProps.moveRows}
					>
						{children}
					</RowDndContext>
				);
			}
			return <>{children}</>;
		};

		function newRowHandler() {
			const lastRowId = rowData.at(-1)?.id as TRowId | null;
			// biome-ignore lint/style/noNonNullAssertion: Handler can't be run if not editable
			props.editableProps!.addRow(lastRowId);
		}

		function deleteRowsHandler() {
			table.resetRowSelection();
			// biome-ignore lint/style/noNonNullAssertion: <explanation>
			props.editableProps!.deleteRows(selectedRowIds);
		}

		return (
			<>
				<div className="relative h-full w-full overflow-auto">
					<div
						aria-label="table"
						className="border-collapse"
						style={{
							...columnSizeVars,
							width: table.getCenterTotalSize(),
						}}
					>
						<div
							aria-label="thead"
							className="sticky top-0 z-10 flex bg-white text-neutral-700"
						>
							<HeaderWrapper>
								{table
									.getHeaderGroups()
									.map((headerGroup) =>
										headerGroup.headers.map((header) => (
											<TableHeader key={header.id} header={header} />
										)),
									)}
							</HeaderWrapper>
						</div>
						<div aria-label="tbody">
							<RowWrapper>
								{rowData.map((row) => (
									<TableRow key={`row-${row.id}`} row={row} />
								))}
							</RowWrapper>
							{/* New row button */}
							{props.editableProps !== undefined && (
								<button
									type="button"
									onClick={newRowHandler}
									className="flex w-full items-center gap-1 text-left text-neutral-500 hover:bg-neutral-100 hover:text-neutral-900"
								>
									<span className="flex h-8 w-8 items-center justify-center">
										<Plus weight="bold" />
									</span>
									<span className="text-sm">New row</span>
								</button>
							)}
						</div>
					</div>
				</div>
				{props.editableProps !== null && selectedRowIds.size > 0 ? (
					<motion.div
						initial={{ opacity: 0, y: 25 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 25 }}
						transition={{ duration: 0.15 }}
						className="absolute bottom-4 flex w-full justify-center"
					>
						<div className="flex items-center gap-2 rounded-lg border bg-white p-2 shadow-md">
							<div className="rounded-md rounded-l-lg border border-blue-200 bg-blue-50 px-4 py-1 text-blue-500 text-sm shadow-inner">
								{selectedRowIds.size} row
								{selectedRowIds.size > 1 ? "s" : ""} selected
							</div>
							<button
								type="button"
								onClick={deleteRowsHandler}
								className="flex h-full items-center gap-2 rounded-md px-3 text-sm hover:bg-neutral-100"
							>
								<Trash weight="bold" /> Delete
							</button>
						</div>
					</motion.div>
				) : null}
			</>
		);
	},
);
