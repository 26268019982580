import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import { ResourceStatus } from "@/contexts/pending-resources";
import { createSyncedAction } from "@/contexts/synced-actions";
import openapiHashes from "@/generated/openapi-hashes.json";
import { createWebpageId } from "@/lib/id-generators";
import { ElectricOptimisticMap } from "@/lib/sync/optimistic-map";
import { indexWebpageRoute } from "@api/fastAPI";
import type { WebpageId, WebpageResource } from "@api/schemas";
import { makeAutoObservable } from "mobx";
import type { Result } from "neverthrow";
export const indexWebpageAction = createSyncedAction<
	WebpageStore,
	{
		url: string;
	},
	WebpageId,
	void
>({
	async local({ url }): Promise<WebpageId> {
		const webpageId = createWebpageId();
		this.appState.pendingResources.resources.set(webpageId, {
			resource: {
				resource_id: webpageId,
				type: "webpage",
			},
			name: url,
			status: ResourceStatus.pending,
		});

		return webpageId;
	},
	async remote({ url }, webpageId): Promise<void> {
		await indexWebpageRoute({
			url,
			webpage_id: webpageId,
		});
	},
	rollback(_, localResult) {
		const recentWebpage =
			this.appState.pendingResources.resources.get(localResult);
		if (recentWebpage) {
			recentWebpage.status = ResourceStatus.failed;
		}
	},
});

export class WebpageStore {
	appState: AppState;
	map: ElectricOptimisticMap<WebpageResource, "webpage_id", "write_id">;
	constructor(appState: AppState) {
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/webpages`,
			idKey: "webpage_id",
			writeIdKey: "write_id",
			shapeHash: openapiHashes.WebpageResource,
		});
		makeAutoObservable(this);
	}

	getResourceById(webpageId: WebpageId): Result<WebpageResource, Error> {
		return this.map.get(webpageId);
	}

	indexWebpage = indexWebpageAction.bind(this);
}
