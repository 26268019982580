/**
 * Figure out how to fix pending stuff
 */

import type { AppState } from "@/contexts/app-context/app-context";
import type { PendingResourceRef } from "@api/schemas";
import { makeAutoObservable } from "mobx";

export enum ResourceStatus {
	pending = "pending",
	success = "success",
	failed = "failed",
}

type PendingResource = {
	resource: PendingResourceRef;
	name: string;
	status: ResourceStatus;
};

export class PendingResourcesStore {
	appState: AppState;
	resources: Map<PendingResourceRef["resource_id"], PendingResource>;

	constructor(appState: AppState) {
		this.appState = appState;
		this.resources = new Map();
		makeAutoObservable(this);
	}
}
