import { PageTreeState } from "@/components/tree/tree-state";
import type { AppState } from "@/contexts/app-context/app-context";
import { resourceRefToPath } from "@/lib/paths";
import { makeAutoObservable } from "mobx";

export class LeftSidebarState {
	appState: AppState;
	#treeState: PageTreeState | null = null;
	showSidebar = true;

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
	}

	get treeState() {
		if (!this.#treeState) {
			this.appState.workspace.pages.homePage.match(
				(page) => {
					this.#treeState = new PageTreeState({
						appState: this.appState,
						rootNode: {
							resource_id: page.page_id,
							type: "page",
						},
						getChildren: (resourceRef) => {
							return resourceRef.type === "page"
								? this.appState.workspace.pages
										.getChildResourceLinks(resourceRef.resource_id)
										.match(
											(children) => children,
											() => [],
										)
								: [];
						},
						handleNodeNavigation: (node) => {
							this.appState.tabStore.navigateFromOutsideTab({
								href: resourceRefToPath(node),
							});
						},
						isDndEnabled: false,
						isMultiSelect: true,
					});
				},
				() => {},
			);
		}
		return this.#treeState;
	}

	setShowSidebar = (show: boolean) => {
		this.showSidebar = show;
	};
}
