import type { BaseRendererProps } from "@/components/table/scalar-renderers/renderer-props";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { X } from "@phosphor-icons/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

type DatetimeRendererProps = BaseRendererProps<"timestamptz">;

export const DatetimeRenderer = observer((props: DatetimeRendererProps) => {
	const [open, setOpen] = useState(false);

	// TODO(John): fix casts!
	const [newValue, setNewValue] = useState(
		props.value ? dayjs.tz(props.value, "UTC") : null,
	);
	useEffect(() => {
		setNewValue(props.value ? dayjs.tz(props.value, "UTC") : null);
	}, [props.value]);

	return (
		<Popover
			open={open}
			onOpenChange={(newOpen) => {
				setOpen(newOpen);
				if (!newOpen && newValue) {
					if (dayjs(props.value).isSame(newValue)) return;
					props.onUpdate?.(newValue ? newValue.utc().toISOString() : null);
				}
			}}
		>
			<PopoverTrigger
				disabled={!props.editable}
				className={clsx(
					"flex h-full w-full min-w-0 justify-start truncate p-1 text-neutral-600 text-sm",
					open && "bg-blue-50 ring-2 ring-blue-300",
				)}
			>
				{newValue ? newValue.local().format("YYYY-MM-DD HH:mm:ss") : null}
			</PopoverTrigger>
			<PopoverContent className="w-72 p-2">
				<Calendar
					selectedDate={newValue}
					setSelectedDate={setNewValue}
					showTime
				/>
				<Button
					onClick={() => {
						props.onUpdate?.(null);
					}}
					variant="ghost"
					className="mt-1 flex w-full items-center justify-start gap-1"
				>
					<X className="" />
					Clear date
				</Button>
			</PopoverContent>
		</Popover>
	);
});
