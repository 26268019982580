import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useTablesStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { TablesHomeTabState } from "@/contexts/tables/tab-state";
import { CreateComputedTableDialog } from "@/pages/tabs/tables/-components/computed-table-creator/computed-table-creator-dialog";
import {
	ArrowsMerge,
	File,
	Plus,
	Table as TableIcon,
} from "@phosphor-icons/react";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const TablesEmptyState = observer(() => {
	const tablesStore = useTablesStore();
	const { tabState } = Route.useLoaderData();

	return (
		<div className="flex h-full flex-col items-center justify-center gap-4">
			<h1 className="px-4 text-center text-neutral-500">
				Add a table to get started!
			</h1>
			<Button
				onClick={async () => {
					await tablesStore.createTable({
						name: "New table",
						afterLocalCallback: (newTableResource) => {
							tabState.tab.router.navigate({
								to: "/tables/table/$table-id",
								params: {
									"table-id": newTableResource.table_id,
								},
							});
						},
					});
				}}
			>
				Add table
			</Button>
		</div>
	);
});

export const TablesHomePage = observer(() => {
	const [tablesQuery, setTablesQuery] = useState("");
	const tablesStore = useTablesStore();
	const [tab, setTab] = useState<"table" | "canvas">("table");
	const [showCreateComputedTableDialog, setShowCreateComputedTableDialog] =
		useState(false);

	return (
		<div className="relative min-h-0 w-full grow">
			{showCreateComputedTableDialog && (
				<CreateComputedTableDialog setOpen={setShowCreateComputedTableDialog} />
			)}
			<div
				className={clsx(
					"flex h-full w-full flex-col",
					showCreateComputedTableDialog ? "hidden" : "",
				)}
			>
				<div className="flex h-14 w-full shrink-0 items-center px-2">
					<h1 className="px-2 font-semibold text-lg">All tables</h1>
				</div>
				<div className="flex w-full shrink-0 items-center gap-2 bg-white/90 px-4 pb-4 backdrop-blur">
					<Input
						placeholder="Search tables..."
						value={tablesQuery}
						onChange={(e) => setTablesQuery(e.target.value)}
						className="min-w-0 grow"
					/>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button className="flex flex-shrink-0 items-center gap-2">
								<Plus className="text-lg" weight="regular" />
								<span>Create new</span>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent>
							<Tooltip>
								<TooltipTrigger asChild>
									<DropdownMenuItem
										onClick={async () => {
											await tablesStore.createTable({
												name: "New table",
											});
										}}
										className="flex items-center gap-2"
									>
										<File />
										New Table
									</DropdownMenuItem>
								</TooltipTrigger>
								<TooltipContent align="start" side="left">
									Analyze multiple documents in parallel.
								</TooltipContent>
							</Tooltip>
							<Tooltip>
								<TooltipTrigger asChild>
									<DropdownMenuItem
										onSelect={() => {
											setShowCreateComputedTableDialog(true);
										}}
										className="flex items-center gap-2"
									>
										<ArrowsMerge />
										Computed table
									</DropdownMenuItem>
								</TooltipTrigger>
								<TooltipContent align="start" side="left">
									Combine or transform existing tables to build a new one.
								</TooltipContent>
							</Tooltip>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>

				<Tabs
					value={tab}
					onValueChange={(value) => {
						setTab(value as "table" | "canvas");
					}}
					className="flex h-full w-full flex-col bg-white"
				>
					<TabsList className="flex-none px-1">
						<TabsTrigger value="table" className="gap-1">
							<TableIcon size={16} />
							<span>Table</span>
						</TabsTrigger>
					</TabsList>
					<TabsContent
						value="table"
						className="mt-0 flex min-h-0 grow flex-col data-[state=inactive]:hidden"
					>
						{tablesStore.sortedResources.length === 0 ? (
							<TablesEmptyState />
						) : (
							<div>Coming soon! -- John</div>
						)}
					</TabsContent>
				</Tabs>
			</div>
		</div>
	);
});

export const Route = createFileRoute("/tables/")({
	component: TablesHomePage,
	loader: ({ context: { tab } }) => {
		const tabState = new TablesHomeTabState(tab);
		return {
			tabState,
		};
	},
});
