import { BooleanRenderer } from "@/components/table/scalar-renderers/boolean-renderer";
import { DatetimeRenderer } from "@/components/table/scalar-renderers/datetime-renderer";
import { NumberRenderer } from "@/components/table/scalar-renderers/number-renderer";
import { RelationshipRenderer } from "@/components/table/scalar-renderers/relationship-renderer";
import { SelectRenderer } from "@/components/table/scalar-renderers/select-renderer";
import { TextRenderer } from "@/components/table/scalar-renderers/text-renderer";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import type {
	DataType,
	Field,
	RelationshipField,
	ResourceLink,
	SelectField,
	SelectOption,
	SelectOptionLabel,
} from "@api/schemas";
import type { CellValue } from "@api/schemas";
import { CaretRight } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";

/**
 * Aggregate cell component for all types of cells.
 *
 * May or may not be rendered as a field value; is sufficient to render with
 * just a data type and a value.
 *
 * TODO(John): the props can definitely be improved;
 * editable/field/onUpdate/onAddSelectOption are related...
 */
export const Cell = observer(
	(props: {
		dataType: DataType;
		value: CellValue;
		editable: boolean;
		field?: Field;
		onUpdate?: (value: CellValue) => void;
		onAddSelectOption?: (option: SelectOption) => void;
	}) => {
		return (
			<div className="h-full w-full p-1">
				<CellValueRenderer
					dataType={props.dataType}
					value={props.value}
					editable={props.editable}
					field={props.field}
					onUpdate={props.onUpdate}
					onAddSelectOption={props.onAddSelectOption}
				/>
			</div>
		);
	},
);

/**
 * Array data is placed inside a collapsible. For nested array types (e.g.
 * TEXT[][]), collapsibles will be placed inside each other.
 */
export const ArrayDataCollapsible = ({
	children,
	defaultOpen,
}: {
	children: React.ReactNode[];
	defaultOpen?: boolean;
}) => {
	return (
		<Collapsible
			className="group flex flex-col gap-2"
			defaultOpen={defaultOpen}
		>
			<CollapsibleTrigger className="flex items-center gap-1 text-neutral-500 text-xs">
				<span>{children.length} items</span>
				<CaretRight className="group-data-[state=open]:rotate-90" />
			</CollapsibleTrigger>
			<CollapsibleContent className="flex w-full flex-col pl-2">
				{children}
			</CollapsibleContent>
		</Collapsible>
	);
};

/**
 * TODO(John): think more carefully about nullability here
 *
 * Note that any renderers within arrays are always not editable.
 */
const CellValueRenderer = (props: {
	dataType: DataType;
	value: CellValue;
	editable: boolean;
	field?: Field;
	onUpdate?: (value: CellValue) => void;
	onAddSelectOption?: (option: SelectOption) => void;
}) => {
	switch (props.dataType.scalar_type) {
		case "boolean": {
			switch (props.dataType.array_depth) {
				case 0:
					if (props.value === null || typeof props.value !== "boolean") {
						throw new Error(
							`Expected boolean value in boolean cell, got ${props.value} with type ${typeof props.value}`,
						);
					}
					return (
						<BooleanRenderer
							value={props.value}
							editable={props.editable}
							onUpdate={props.onUpdate}
						/>
					);
				case 1:
					if (
						!Array.isArray(props.value) ||
						!props.value.every((item) => typeof item === "boolean")
					) {
						throw new Error(
							`Expected boolean array in boolean[] cell, got ${props.value}`,
						);
					}
					return (
						<ArrayDataCollapsible defaultOpen={true}>
							{props.value.map((item, index) => (
								<BooleanRenderer
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									key={index}
									value={item}
									editable={false}
								/>
							))}
						</ArrayDataCollapsible>
					);
				default:
					return (
						<div className="h-full w-full p-1 font-mono text-neutral-500 text-sm">
							BOOLEAN{"[]".repeat(props.dataType.array_depth)}
						</div>
					);
			}
		}

		case "real": {
			switch (props.dataType.array_depth) {
				case 0:
					if (props.value !== null && typeof props.value !== "number") {
						throw new Error(
							`Expected number value in real cell, got ${props.value} with type ${typeof props.value}`,
						);
					}
					return (
						<NumberRenderer
							value={props.value}
							editable={props.editable}
							onUpdate={props.onUpdate}
						/>
					);
				case 1:
					if (
						!Array.isArray(props.value) ||
						!props.value.every(
							(item) => typeof item === "number" || item === null,
						)
					) {
						throw new Error(
							`Expected number[] or null in real[] cell, got ${props.value}`,
						);
					}
					return (
						<ArrayDataCollapsible defaultOpen={true}>
							{props.value.map((item, index) => (
								<NumberRenderer
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									key={index}
									value={item}
									editable={false}
								/>
							))}
						</ArrayDataCollapsible>
					);
				default:
					return (
						<div className="h-full w-full p-1 font-mono text-neutral-500 text-sm">
							REAL{"[]".repeat(props.dataType.array_depth)}
						</div>
					);
			}
		}

		case "text": {
			switch (props.dataType.array_depth) {
				case 0:
					if (props.value !== null && typeof props.value !== "string") {
						throw new Error(
							`Expected string value in text cell, got ${props.value} with type ${typeof props.value}`,
						);
					}
					return (
						<TextRenderer
							value={props.value}
							editable={props.editable}
							onUpdate={props.onUpdate}
						/>
					);
				case 1:
					if (
						!Array.isArray(props.value) ||
						!props.value.every(
							(item) => typeof item === "string" || item === null,
						)
					) {
						throw new Error(
							`Expected string[] or null in text[] cell, got ${props.value}`,
						);
					}
					return (
						<ArrayDataCollapsible defaultOpen={true}>
							{props.value.map((item, index) => (
								<TextRenderer
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									key={index}
									value={item}
									editable={false}
								/>
							))}
						</ArrayDataCollapsible>
					);
				default:
					return (
						<div className="h-full w-full p-1 font-mono text-neutral-500 text-sm">
							TEXT{"[]".repeat(props.dataType.array_depth)}
						</div>
					);
			}
		}

		case "timestamptz": {
			switch (props.dataType.array_depth) {
				case 0:
					if (props.value !== null && typeof props.value !== "string") {
						throw new Error(
							`Expected string or null in timestamptz cell, got ${props.value} with type ${typeof props.value}`,
						);
					}
					return (
						<DatetimeRenderer
							value={props.value}
							editable={props.editable}
							onUpdate={props.onUpdate}
						/>
					);
				case 1:
					if (
						!Array.isArray(props.value) ||
						!props.value.every(
							(item) => typeof item === "string" || item === null,
						)
					) {
						throw new Error(
							`Expected string[] or null in timestamptz[] cell, got ${props.value}`,
						);
					}
					return (
						<ArrayDataCollapsible defaultOpen={true}>
							{props.value.map((item, index) => (
								<DatetimeRenderer
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									key={index}
									value={item}
									editable={false}
								/>
							))}
						</ArrayDataCollapsible>
					);
				default:
					return (
						<div className="h-full w-full p-1 font-mono text-neutral-500 text-sm">
							TIMESTAMPTZ{"[]".repeat(props.dataType.array_depth)}
						</div>
					);
			}
		}

		case "record_link": {
			let relationshipField: RelationshipField | undefined;
			if (props.field?.type === "relationship") {
				relationshipField = props.field;
			}
			switch (props.dataType.array_depth) {
				case 0: {
					if (props.value !== null && typeof props.value !== "string") {
						throw new Error(
							`Expected string or null in record_link cell, got ${props.value} with type ${typeof props.value}`,
						);
					}
					return (
						<RelationshipRenderer
							value={props.value as ResourceLink | null}
							editable={props.editable}
							onUpdate={props.onUpdate}
							foreignTableId={relationshipField?.properties.foreign_table_id}
							foreignFieldId={relationshipField?.properties.foreign_field_id}
						/>
					);
				}

				case 1: {
					// We handle to-many relationships gracefully
					// TODO(John): think about the difference between an array
					// of record_links as one to-many relationship, vs. an
					// array of record_links from many to-one relationships
					if (
						props.value !== null &&
						(!Array.isArray(props.value) ||
							!props.value.every((item) => typeof item === "string"))
					) {
						throw new Error(
							`Expected string[] or null in record_link[] cell, got ${props.value}`,
						);
					}
					return (
						<RelationshipRenderer
							value={props.value as ResourceLink[] | null}
							editable={props.editable}
							onUpdate={props.onUpdate}
							foreignTableId={relationshipField?.properties.foreign_table_id}
							foreignFieldId={relationshipField?.properties.foreign_field_id}
						/>
					);
				}

				case 2: {
					if (
						!Array.isArray(props.value) ||
						!props.value.every((item) => Array.isArray(item))
					) {
						throw new Error(
							`Expected string[][] or null in record_link[][] cell, got ${props.value}`,
						);
					}
					return (
						<ArrayDataCollapsible defaultOpen={true}>
							{props.value.map((item, index) => (
								<RelationshipRenderer
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									key={index}
									value={item as ResourceLink[]}
									editable={false}
								/>
							))}
						</ArrayDataCollapsible>
					);
				}
				default:
					return (
						<div className="h-full w-full p-1 font-mono text-neutral-500 text-sm">
							RECORD_LINK{"[]".repeat(props.dataType.array_depth)}
						</div>
					);
			}
		}

		case "select_option": {
			let selectField: SelectField | undefined;
			if (props.field?.type === "select") {
				selectField = props.field;
			}
			switch (props.dataType.array_depth) {
				case 0:
					return (
						<SelectRenderer
							value={props.value as SelectOptionLabel | null}
							editable={props.editable}
							options={selectField?.properties.options}
							onUpdate={props.onUpdate}
							onAddSelectOption={props.onAddSelectOption}
						/>
					);
				case 1:
					if (
						!Array.isArray(props.value) ||
						!props.value.every(
							(item) => typeof item === "string" || item === null,
						)
					) {
						throw new Error(
							`Expected string[] or null in select_option[] cell, got ${props.value}`,
						);
					}
					return (
						<ArrayDataCollapsible defaultOpen={true}>
							{props.value.map((item, index) => (
								<SelectRenderer
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									key={index}
									value={item as SelectOptionLabel | null}
									editable={false}
									options={selectField?.properties.options}
									onAddSelectOption={props.onAddSelectOption}
								/>
							))}
						</ArrayDataCollapsible>
					);
				default:
					return (
						<div className="h-full w-full p-1 font-mono text-neutral-500 text-sm">
							SELECT_OPTION{"[]".repeat(props.dataType.array_depth)}
						</div>
					);
			}
		}
		default: {
			const exhaustiveCheck: never = props.dataType.scalar_type;
			return exhaustiveCheck;
		}
	}
};
