import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { LocalSearchStore } from "@/contexts/local-search-store";
import { markdownToTiptap } from "@/lib/serializers";
import { cn } from "@/lib/utils";
import { ExtractCustomMark } from "@/plugins/extract-custom-mark";
import { ImageResize } from "@/plugins/image-resize";
import {
	CustomLink,
	ObjectLink,
	getObjectLinkExtension,
} from "@/plugins/object-link";
import { UniqueId } from "@/plugins/unique-id";
import Blockquote from "@tiptap/extension-blockquote";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Code from "@tiptap/extension-code";
import CodeBlock from "@tiptap/extension-code-block";
import Document from "@tiptap/extension-document";
import Dropcursor from "@tiptap/extension-dropcursor";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import ListKeymap from "@tiptap/extension-list-keymap";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { DOMParser, type Node, type Schema } from "@tiptap/pm/model";
import { EditorState } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";
import {
	type Content,
	type Editor,
	EditorContent,
	createDocument,
	elementFromString,
	getSchema,
	useEditor,
} from "@tiptap/react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ulid } from "ulidx";

export const COMMON_EXTENSIONS = [
	Document,
	Paragraph,
	Text,
	Bold.configure({
		HTMLAttributes: {
			class: "font-semibold",
		},
	}),
	Underline,
	Italic,
	Strike,
	Code,
	CodeBlock,
	Heading,
	Blockquote,
	TextAlign.configure({
		types: ["heading", "paragraph"],
		alignments: ["left", "center", "right", "justify"],
		defaultAlignment: "left",
	}),
	BulletList,
	ListItem,
	OrderedList,
	TaskList,
	TaskItem.configure({
		nested: true,
	}),
	ListKeymap,
	ImageResize,
	Dropcursor,
	Table.configure({
		resizable: true,
	}),
	TableRow,
	TableHeader,
	TableCell,
	HorizontalRule,
	HardBreak,
	// Highlight,
	// Our extensions
	CustomLink,
	ExtractCustomMark,
	UniqueId.configure({
		attributeName: "id",
		types: ["heading"],
		generateID: () => ulid(),
	}),
];

function getEditorClassName(className?: string) {
	return cn(
		"prose prose-sm prose-neutral prose-p:text-neutral-900",
		// Remove the default margin for paragraphs and lists
		"prose-p:my-0",
		"prose-ul:my-0",
		"prose-ol:my-0",
		// For the child content to take up the full size of the editor
		"*:h-full *:w-full *:outline-none",
		className,
	);
}

export const getEditorExtensions = (localSearchStore: LocalSearchStore) => [
	...COMMON_EXTENSIONS,
	getObjectLinkExtension((query) =>
		localSearchStore.searchResourceObjectLink(query),
	),
];

/**
 * A custom editor that uses the same extensions as the view-only editor.
 */
export const TextEditor = observer(function TextEditor(props: {
	className?: string;
	options: Omit<
		NonNullable<Parameters<typeof useEditor>[0]>,
		// we omit extensions because we set them manually.
		"extensions"
	>;
	onEditorReady?: (editor: Editor) => void;
}) {
	const appContext = useAppContext();
	const localSearchStore = appContext.localSearchStore;
	const editor = useEditor(
		{
			extensions: getEditorExtensions(localSearchStore),
			...props.options,
		},
		[],
	);

	useEffect(() => {
		if (props.onEditorReady && editor) {
			props.onEditorReady(editor);
		}
	}, [editor, props.onEditorReady]);

	return (
		<EditorContent
			editor={editor}
			className={getEditorClassName(props.className)}
		/>
	);
});

/**
 * Given a Tiptap HTML string, return a string of HTML that can be rendered
 * directly in the DOM.
 *
 * If we want to be more aggressive with optimization, we can technically create
 * the view globally, and replace the node within the function with:
 *
 * 	view.dispatch(
 * 		view.state.tr.replaceWith(0, view.state.doc.content.size, node.content),
 * 	);
 *
 * But the approach below is safer and seems to be about as fast.
 */
export function parseHtmlContent(
	content: string | Content,
	schema: Schema,
	parser: DOMParser,
) {
	let node: Node;

	if (typeof content === "string") {
		const htmlElement = elementFromString(content);
		node = parser.parse(htmlElement);
	} else {
		node = createDocument(content, schema);
	}

	const bufferDiv = document.createElement("div");
	const view = new EditorView(bufferDiv, {
		state: EditorState.create({ doc: node }),
		editable: () => false,
	});
	const html = bufferDiv.innerHTML;
	view.destroy();

	return { html, doc: node };
}

/**
 * Creates a memoized schema generator for view-only TipTap editors. Generating the schema is
 * expensive and will cause editor-heavy components such as the assistant activity viewer to
 * lag.
 *
 * Returns a function that accepts a resourceStore and returns a Schema instance.
 *
 * @returns {(resourceStore: ResourceStore): Schema} A function that generates/retrieves
 *          the cached schema for a given resourceStore
 */
function createViewOnlySchema() {
	let cached: { schema: Schema; parser: DOMParser } | null = null;

	return () => {
		if (cached) return cached;

		const schema = getSchema([...COMMON_EXTENSIONS, ObjectLink]);
		const parser = DOMParser.fromSchema(schema);
		cached = { schema, parser };
		return cached;
	};
}

const getViewOnlySchema = createViewOnlySchema();

/**
 * For when you want to display HTML content but don't need an editor instance.
 *
 * @remarks
 * Note that this editor does not support rich extensions that use React components.
 * In most cases, you should use TextEditor instead.
 */
export const StaticHtmlRenderer = observer(function StaticHtmlRenderer(props: {
	className?: string;
	content: string | Content;
	onParsed?: (doc: Node) => void;
}) {
	const { schema, parser } = getViewOnlySchema();
	const { html, doc } = parseHtmlContent(props.content, schema, parser);

	useEffect(() => {
		if (props.onParsed && doc) {
			props.onParsed(doc);
		}
	}, [doc, props.onParsed]);

	return (
		<div
			className={getEditorClassName(props.className)}
			// biome-ignore lint/security/noDangerouslySetInnerHtml: content is sanitized
			dangerouslySetInnerHTML={{ __html: html }}
		/>
	);
});

/**
 * A streaming editor that updates the content as it is being synthesized.
 */
export const StreamingEditor = observer(function StreamingEditor(props: {
	className?: string;
	options: Omit<
		NonNullable<Parameters<typeof useEditor>[0]>,
		"extensions" | "content"
	> & {
		content: string | null;
	};
	onEditorReady?: (editor: Editor) => void;
}) {
	const appContext = useAppContext();
	const localSearchStore = appContext.localSearchStore;
	const editor = useEditor(
		{
			extensions: [
				...COMMON_EXTENSIONS,
				getObjectLinkExtension((query) =>
					localSearchStore.searchResourceObjectLink(query),
				),
			],
			...props.options,
		},
		[],
	);

	useEffect(() => {
		if (props.onEditorReady && editor) {
			props.onEditorReady(editor);
		}
	}, [editor, props.onEditorReady]);

	useEffect(() => {
		if (!editor) return;

		const tiptapContent = markdownToTiptap(props.options.content || "");
		const emitUpdate = true;
		editor.commands.setContent(tiptapContent, emitUpdate);
	}, [editor, props.options.content]);

	return (
		<EditorContent
			editor={editor}
			className={getEditorClassName(props.className)}
		/>
	);
});
