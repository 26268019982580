import {
	getRecordLinkColumn,
	getResourceIndexColumn,
} from "@/components/table/columns";
import type { WebpageStore } from "@/contexts/web/stores/webpage-store";
import { resourceRefToPath } from "@/lib/paths";
import type { ResourceLink } from "@api/schemas";
import type { ColumnDef } from "@tanstack/react-table";

// Keep this in sync with the index view in the server
interface WebpagesIndexRecord {
	link: ResourceLink;
	name: string;
	original_url: string;
	word_count: number | null;
	description: string | null;
}

/**
 * This represents the table state for the webpages index.
 */
export class WebpagesIndex {
	webpagesStore: WebpageStore;

	constructor(webpagesStore: WebpageStore) {
		this.webpagesStore = webpagesStore;
	}

	get rows(): WebpagesIndexRecord[] {
		return this.webpagesStore.map.values().map((webpage) => ({
			link: resourceRefToPath({
				resource_id: webpage.webpage_id,
				type: "webpage",
			}),
			name: webpage.name,
			original_url: webpage.original_url,
			word_count: webpage.word_count,
			description: webpage.description,
		}));
	}

	get columns(): ColumnDef<WebpagesIndexRecord>[] {
		return [
			getRecordLinkColumn(),
			getResourceIndexColumn({
				key: "name",
				name: "Name",
				scalarType: "text",
			}),
			getResourceIndexColumn({
				key: "original_url",
				name: "URL",
				scalarType: "text",
			}),
			getResourceIndexColumn({
				key: "word_count",
				name: "Word Count",
				scalarType: "real",
			}),
			getResourceIndexColumn({
				key: "description",
				name: "Description",
				scalarType: "text",
			}),
		];
	}
}
