import { type DataType, FieldType, ScalarType } from "@api/schemas";
import {
	CaretCircleDown,
	CheckSquare,
	Clock,
	Hash,
	type IconProps,
	Link,
	MagnifyingGlass,
	TextAa,
} from "@phosphor-icons/react";

export const dataTypeToFieldType = {
	[ScalarType.text]: FieldType.text,
	[ScalarType.select_option]: FieldType.select,
	[ScalarType.real]: FieldType.number,
	[ScalarType.timestamptz]: FieldType.datetime,
	[ScalarType.boolean]: FieldType.boolean,
	[ScalarType.record_link]: FieldType.relationship,
};

export type FieldTypeIconProps =
	| {
			fieldType: Exclude<FieldType, "view">;
			dataType?: never;
	  }
	| {
			fieldType: "view";
			dataType: DataType;
	  };

export const getFieldIcon = (
	props: FieldTypeIconProps,
): ((props: IconProps) => JSX.Element) => {
	if (props.fieldType === FieldType.view) {
		const mappedFieldType = dataTypeToFieldType[props.dataType.scalar_type];
		return getFieldIcon({
			fieldType: mappedFieldType,
		});
	}

	switch (props.fieldType) {
		case FieldType.text:
			return (props: IconProps) => <TextAa {...props} />;
		case FieldType.boolean:
			return (props: IconProps) => <CheckSquare {...props} />;
		case FieldType.select:
			return (props: IconProps) => <CaretCircleDown {...props} />;
		case FieldType.datetime:
			return (props: IconProps) => <Clock {...props} />;
		case FieldType.number:
			return (props: IconProps) => <Hash {...props} />;
		case FieldType.relationship:
			return (props: IconProps) => <Link {...props} />;
		case FieldType.lookup:
			return (props: IconProps) => <MagnifyingGlass {...props} />;
	}
};

export const FieldTypeLabels: {
	[key in Exclude<FieldType, "view">]: string;
} = {
	[FieldType.text]: "Text",
	[FieldType.boolean]: "Checkbox",
	[FieldType.select]: "Select",
	[FieldType.datetime]: "Time",
	[FieldType.number]: "Number",
	[FieldType.relationship]: "Relationship",
	[FieldType.lookup]: "Lookup",
};
