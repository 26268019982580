import type { AppState } from "@/contexts/app-context/app-context";
import type { LinkableResourceRef } from "@/lib/paths";
import type {
	DragEndEvent,
	DragOverEvent,
	DragStartEvent,
} from "@dnd-kit/core";
import { makeAutoObservable } from "mobx";
import type { MouseEvent as ReactMouseEvent } from "react";

export class PageTreeState {
	appState: AppState;
	isDndEnabled: boolean;
	isMultiSelect: boolean;

	rootNode: LinkableResourceRef;
	getChildren: (node: LinkableResourceRef) => LinkableResourceRef[];
	handleNodeNavigation: (node: LinkableResourceRef) => void;

	rightClickedNode: LinkableResourceRef | null = null;
	selectedNodes: Map<LinkableResourceRef["resource_id"], LinkableResourceRef> =
		new Map();
	expandedNodes: Map<LinkableResourceRef["resource_id"], LinkableResourceRef> =
		new Map();
	draggingNodes: Map<LinkableResourceRef["resource_id"], LinkableResourceRef> =
		new Map();
	dropOverNode: LinkableResourceRef | null = null;

	searchQuery = "";

	constructor(props: {
		appState: AppState;
		rootNode: LinkableResourceRef;
		getChildren: (node: LinkableResourceRef) => LinkableResourceRef[];
		handleNodeNavigation: (node: LinkableResourceRef) => void;
		isDndEnabled: boolean;
		isMultiSelect: boolean;
	}) {
		makeAutoObservable(this);

		this.appState = props.appState;
		this.rootNode = props.rootNode;
		this.getChildren = props.getChildren;
		this.handleNodeNavigation = props.handleNodeNavigation;
		this.isDndEnabled = props.isDndEnabled;
		this.isMultiSelect = props.isMultiSelect;
	}

	setSelectedNodes = (
		nodes: Map<LinkableResourceRef["resource_id"], LinkableResourceRef>,
	) => {
		this.selectedNodes = nodes;
	};

	setRightClickedNode = (node: LinkableResourceRef | null) => {
		this.rightClickedNode = node;
	};

	/**
	 * Returns all nodes in the tree in depth-first traversal order.
	 *
	 * Note that the tree could be cyclic, so we want to stop traversing
	 * when we see a node that we've already seen.
	 */
	get flattenedNodes() {
		const nodes: LinkableResourceRef[] = [];
		const seenNodes: Set<LinkableResourceRef["resource_id"]> = new Set();
		const stack: LinkableResourceRef[] = [this.rootNode];
		while (stack.length > 0) {
			const node = stack.pop();
			if (!node || seenNodes.has(node.resource_id)) {
				continue;
			}
			nodes.push(node);
			seenNodes.add(node.resource_id);
			stack.push(...this.getChildren(node));
		}
		return nodes;
	}

	/**
	 * Returns all nodes between node1 and node2, inclusive.
	 */
	nodesBetween = (node1: LinkableResourceRef, node2: LinkableResourceRef) => {
		const index1 = this.flattenedNodes.findIndex(
			(node) => node.resource_id === node1.resource_id,
		);
		const index2 = this.flattenedNodes.findIndex(
			(node) => node.resource_id === node2.resource_id,
		);
		return this.flattenedNodes.slice(
			Math.min(index1, index2),
			Math.max(index1, index2) + 1,
		);
	};

	/**
	 * When a node is clicked, set it as selected, and navigate to the file.
	 *
	 * When a node is shift-clicked, select all nodes from the last selected
	 * node to that node, and don't navigate to the file.
	 */
	handleNodeClick = (
		e: ReactMouseEvent<HTMLDivElement, MouseEvent>,
		node: LinkableResourceRef,
	) => {
		// If we are not in multi-select mode, just select the node and trigger the navigation handler
		if (!this.isMultiSelect) {
		}

		if (e.shiftKey) {
			// When a node is shift-clicked, if there is already a selection, select
			// all nodes from the last selected node to that node, and don't
			// navigate to the file. If there is no selection, just select the node.
			const lastSelectedNode = Array.from(this.selectedNodes.values()).at(-1);
			if (!lastSelectedNode) {
				this.selectedNodes = new Map([[node.resource_id, node]]);
			} else {
				this.selectedNodes = new Map(
					this.nodesBetween(lastSelectedNode, node).map((n) => [
						n.resource_id,
						n,
					]),
				);
			}
		} else if (e.metaKey || e.ctrlKey) {
			if (this.selectedNodes.has(node.resource_id)) {
				this.selectedNodes.delete(node.resource_id);
			} else {
				this.selectedNodes.set(node.resource_id, node);
			}
		} else {
			// Otherwise, just select the node, and navigate to the file.
			this.selectedNodes = new Map([[node.resource_id, node]]);
			this.handleNodeNavigation(node);
		}
		this.setRightClickedNode(null);
	};

	handleDragStart = (event: DragStartEvent) => {
		const node = event.active.data.current?.node as
			| LinkableResourceRef
			| undefined;
		if (!node) {
			return;
		}
		// If the node we are dragging is also selected, we should drag the
		// entire selection
		if (this.selectedNodes.has(node.resource_id)) {
			this.draggingNodes = new Map(this.selectedNodes);
		} else {
			this.draggingNodes = new Map([[node.resource_id, node]]);
		}
	};

	handleDragOver = (event: DragOverEvent) => {
		const over = event.over;
		if (!over) {
			this.dropOverNode = null;
			return;
		}
		const node = over.data.current?.node as LinkableResourceRef | undefined;
		if (!node) {
			this.dropOverNode = null;
			return;
		}
		this.dropOverNode = node;
	};

	/**
	 * Returns all descendants of nodes being dragged.
	 *
	 * We can't drag nodes into their descendants, so this is used to disable
	 * droppables.
	 */
	get descendantsOfDraggingNodes(): Set<LinkableResourceRef["resource_id"]> {
		const allDescendants: Set<LinkableResourceRef["resource_id"]> = new Set();
		for (const draggingNode of this.draggingNodes.values()) {
			const descendants = this.getChildren(draggingNode);
			for (const descendant of descendants) {
				allDescendants.add(descendant.resource_id);
			}
		}
		return allDescendants;
	}

	handleDragEnd = (event: DragEndEvent) => {
		const overNode = event.over?.data.current?.node as
			| LinkableResourceRef
			| undefined;
		if (overNode) {
			if (this.descendantsOfDraggingNodes.has(overNode.resource_id)) {
				console.warn("Tried to move files into one of their descendants");
			}
			this.expandedNodes.set(overNode.resource_id, overNode);
		}
		// If we were dragging multiple nodes, clear the multi-select
		if (this.draggingNodes.size > 1) {
			this.selectedNodes.clear();
		}
		this.draggingNodes.clear();
		this.dropOverNode = null;
	};

	toggleExpandedNode = (node: LinkableResourceRef) => {
		if (this.expandedNodes.has(node.resource_id)) {
			this.expandedNodes.delete(node.resource_id);
		} else {
			this.expandedNodes.set(node.resource_id, node);
		}
	};

	setSearchQuery = (query: string) => {
		this.searchQuery = query;
	};
}
