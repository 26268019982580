/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export type EventType = typeof EventType[keyof typeof EventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventType = {
  sent_message: 'sent_message',
  opened_thread: 'opened_thread',
  ended_session: 'ended_session',
  created_assistant_session: 'created_assistant_session',
  created_user_session: 'created_user_session',
  opened_tab: 'opened_tab',
  navigated_tab: 'navigated_tab',
  closed_tab: 'closed_tab',
} as const;
