import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { ResourceStatus } from "@/contexts/pending-resources";
import type { ResourceType } from "@api/schemas";
import {
	CaretRight,
	FilePdf,
	Globe,
	Rss,
	Spinner,
	X,
} from "@phosphor-icons/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { AnimatePresence, motion } from "motion/react";
import { useState } from "react";

const ResourceStatusIcon = ({
	status,
	resourceType,
}: {
	status: ResourceStatus;
	resourceType: ResourceType;
}) => {
	switch (status) {
		case ResourceStatus.pending:
			return <Spinner className="animate shrink-0 animate-spin" />;
		case ResourceStatus.success:
			switch (resourceType) {
				case "webpage":
					return <Globe />;
				case "upload":
					return <FilePdf />;
				case "feed-channel":
					return <Rss />;
				case "feed-item":
					return <Rss />;
				default: {
					throw new Error("Invalid resource type");
				}
			}
		case ResourceStatus.failed:
			return <X className="shrink-0" />;
	}
};

/**
 * Drawer that shows pending resources.
 */
export const PendingResourcesDrawer = observer(
	function PendingResourcesDrawer() {
		const appState = useAppContext();
		const [isOpen, setIsOpen] = useState(false);

		if (appState.pendingResources.resources.size === 0) {
			return null;
		}

		const numPending = Array.from(
			appState.pendingResources.resources.values(),
		).filter((resource) => resource.status === ResourceStatus.pending).length;

		const allDone = numPending === 0;

		return (
			<motion.div
				initial={{ opacity: 0, y: 50 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: 50 }}
				transition={{ duration: 0.3 }}
				className="fixed right-4 bottom-4 z-50 w-[32rem] overflow-hidden border shadow"
			>
				<Collapsible open={isOpen} onOpenChange={setIsOpen}>
					<CollapsibleTrigger className="group flex w-full items-center gap-2 bg-white px-4 py-3">
						<motion.button
							type="button"
							animate={{ rotate: isOpen ? 90 : 0 }}
							transition={{ duration: 0.2 }}
						>
							<CaretRight
								className="text-lg text-neutral-500 group-hover:text-neutral-800"
								weight="bold"
							/>
						</motion.button>
						<span className="grow text-left text-neutral-600 text-sm">
							{allDone ? (
								<>All resources processed.</>
							) : (
								<>
									Processing {numPending} resource{numPending > 1 ? "s" : ""}
								</>
							)}
						</span>
						{allDone && (
							<button
								type="button"
								onClick={() => {
									runInAction(() => {
										appState.pendingResources.resources = new Map();
									});
								}}
								className="p-1 text-lg text-neutral-500 hover:bg-neutral-100 hover:text-neutral-800"
							>
								<X />
							</button>
						)}
					</CollapsibleTrigger>
					<AnimatePresence>
						{isOpen && (
							<CollapsibleContent forceMount asChild>
								<motion.div
									initial={{ opacity: 0, height: 0 }}
									animate={{ opacity: 1, height: "auto" }}
									exit={{ opacity: 0, height: 0 }}
									transition={{ duration: 0.15 }}
								>
									<div className="flex max-h-[50vh] flex-col gap-0.5 overflow-y-scroll border-t bg-neutral-100 px-4 py-2">
										{Array.from(
											appState.pendingResources.resources.values(),
										).map((resource) => (
											<motion.div
												key={resource.resource.resource_id}
												initial={{ opacity: 0, x: -20 }}
												animate={{ opacity: 1, x: 0 }}
												exit={{ opacity: 0, x: -20 }}
												transition={{ duration: 0.2 }}
												className="flex items-center gap-2.5 px-0.5 py-1 text-sm"
											>
												<ResourceStatusIcon
													status={resource.status}
													resourceType={resource.resource.type}
												/>
												<span className="grow truncate text-left text-neutral-600">
													{resource.name}
												</span>
											</motion.div>
										))}
									</div>
								</motion.div>
							</CollapsibleContent>
						)}
					</AnimatePresence>
				</Collapsible>
			</motion.div>
		);
	},
);
