import { AssistantActivityViewer } from "@/components/layout/right-sidebar/assistant-activity-viewer";
import { GlobalAssistantsPresenceIndicator } from "@/components/layout/right-sidebar/assistant-presence";
// import { Chat, MessageInput } from "@/components/layout/right-sidebar/messages";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { ChatTeardropDots, ClockCounterClockwise } from "@phosphor-icons/react";
import { RouterProvider } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

/**
 * The right sidebar, which shows messages and session activity.
 */
export const RightSidebar = observer(() => {
	const appContext = useAppContext();
	return (
		appContext.rightSidebarState.showRightSidebar && (
			<div className="flex h-full w-full flex-col gap-4 bg-white py-2">
				{/* Header */}
				<div className="flex justify-between px-2">
					<GlobalAssistantsPresenceIndicator />
				</div>
				<Tabs
					value={appContext.rightSidebarState.rightSidebarTab}
					onValueChange={(value) => {
						appContext.rightSidebarState.rightSidebarTab = value as
							| "messages"
							| "assistant_activity";
					}}
					className="flex h-full min-h-0 w-full flex-col bg-white"
				>
					<TabsList className="flex-none">
						<TabsTrigger value="messages" className="gap-1">
							<ChatTeardropDots size={16} />
							<span>Chat</span>
						</TabsTrigger>
						<TabsTrigger value="assistant_activity" className="gap-1">
							<ClockCounterClockwise size={16} />
							<span>Sessions</span>
						</TabsTrigger>
					</TabsList>
					{/* Radix keeps inactive tabs around in the DOM, so we need to
				hide them with data-[state=inactive]:hidden to make sure the
				children's grows don't interfere. */}
					{/* Also, these min-h-0's are important. Otherwise, the "grow"
				elements have a min-h of their contents, which causes them to
				grow beyond the bounds of their parents if their children are
				large. */}
					<TabsContent
						forceMount
						value="messages"
						className="mt-0 flex min-h-0 grow flex-col data-[state=inactive]:hidden"
					>
						<RouterProvider
							router={appContext.rightSidebarState.messageTab.router}
						/>
					</TabsContent>
					<TabsContent
						forceMount
						value="assistant_activity"
						className="mt-0 min-h-0 grow text-sm data-[state=inactive]:hidden"
					>
						<AssistantActivityViewer />
					</TabsContent>
				</Tabs>
			</div>
		)
	);
});
