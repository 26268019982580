import type { BaseRendererProps } from "@/components/table/scalar-renderers/renderer-props";
import { Checkbox } from "@/components/ui/checkbox";
import { observer } from "mobx-react-lite";

type BooleanRendererProps = BaseRendererProps<"boolean">;

export const BooleanRenderer = observer((props: BooleanRendererProps) => {
	return (
		<Checkbox
			className="mt-2 ml-2 flex"
			checked={!!props.value}
			disabled={!props.editable}
			onCheckedChange={(checked) => {
				props.onUpdate?.(checked === true);
			}}
		/>
	);
});
