import type { HighlightQuery } from "@/contexts/pdfviewer-context";
import type { EnrichedResourceResult } from "@/contexts/search/tab-state";
import type { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import type { UploadId, UploadResource } from "@api/schemas";
import { FileArrowUp } from "@phosphor-icons/react";
import { makeAutoObservable } from "mobx";

/**
 * This is used when the user opens an upload from a search result. Focuses on
 * a single chunk from the search result.
 */
export type FocusedUploadSearchResult = {
	uploadSearchResults: EnrichedResourceResult;
	// The index of the chunk in the uploadSearchResults.result.chunks array,
	// not the chunk_index.
	activeChunkIndex: number;
};

export class UploadTabState implements BaseTabState {
	tab: Tab;
	upload: UploadResource;
	highlightQuery: HighlightQuery | null;

	constructor(
		tab: Tab,
		uploadId: UploadId,
		highlightQuery: HighlightQuery | null,
	) {
		makeAutoObservable(this);

		this.tab = tab;
		this.tab.setState(this);

		const uploadResource =
			tab.tabStore.appState.workspace.uploads.getResourceById(uploadId);
		if (uploadResource.isErr()) {
			throw new Error("Upload not found");
		}
		this.upload = uploadResource.value;
		this.highlightQuery = highlightQuery;
	}

	get head() {
		return {
			icon: FileArrowUp,
			label: this.upload.name,
			resourceRef: {
				type: "upload" as const,
				resource_id: this.upload.upload_id,
			},
		};
	}
}

export class UploadsHomeTabState implements BaseTabState {
	tab: Tab;

	constructor(tab: Tab) {
		this.tab = tab;
		this.tab.setState(this);
	}

	get head() {
		return {
			icon: FileArrowUp,
			label: "Uploads",
		};
	}
}
