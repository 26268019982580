import type { AppState } from "@/contexts/app-context/app-context";
import { createTableId, createWriteId } from "@/lib/id-generators";
import { OptimisticAction, type Transaction } from "@/lib/sync/action-executor";
import { createComputedTableRoute, createTableRoute } from "@api/fastAPI";
import type { UserId, UserTableResource, ViewDef } from "@api/schemas";

function createTableResource(
	name: string,
	userId: UserId,
	viewDef: ViewDef | null,
): UserTableResource {
	const newTableId = createTableId();
	const createdAt = new Date().toISOString();
	const tableResource: UserTableResource = {
		table_id: newTableId,
		name: name,
		created_at: createdAt,
		creator_id: userId,
		integration_type: null,
		resource_type: null,
		computed_view_def: viewDef,
		deleted_at: null,
		write_id: createWriteId(),
	};
	return tableResource;
}

export class CreateTableAction extends OptimisticAction<
	AppState,
	{
		name: string;
		afterLocalCallback?: (newTableResource: UserTableResource) => void;
	},
	{
		newTableResource: UserTableResource;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		newTableResource: UserTableResource;
	}> {
		const newTableResource = createTableResource(
			this.args.name,
			state.userId,
			null,
		);
		state.workspace.tables.map.insert(tx, newTableResource);
		if (this.args.afterLocalCallback) {
			this.args.afterLocalCallback(newTableResource);
		}
		return { newTableResource };
	}

	async remote(context: {
		localResult: { newTableResource: UserTableResource };
	}): Promise<void> {
		await createTableRoute({
			table: context.localResult.newTableResource,
		});
	}
}

export class CreateComputedTableAction extends OptimisticAction<
	AppState,
	{
		viewDef: ViewDef;
	},
	{
		newTableResource: UserTableResource;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		newTableResource: UserTableResource;
	}> {
		const fromTable = state.workspace.tables.getResourceById(
			this.args.viewDef.from_table_id,
		);
		if (fromTable.isErr()) {
			throw new Error(
				`Table with ID ${this.args.viewDef.from_table_id} not found`,
			);
		}
		const fromTableName = fromTable.value.name;
		const newTableResource: UserTableResource = createTableResource(
			`Computed Table (from ${fromTableName})`,
			state.userId,
			this.args.viewDef,
		);
		state.workspace.tables.map.insert(tx, newTableResource);
		return { newTableResource };
	}

	async remote(context: {
		localResult: { newTableResource: UserTableResource };
	}): Promise<void> {
		await createComputedTableRoute({
			table: context.localResult.newTableResource,
		});
	}
}
