import {
	type EnrichedResourceResult,
	type SearchResultLoadingState,
	SearchTabState,
} from "@/contexts/search/tab-state";
import { Link, createFileRoute } from "@tanstack/react-router";
import { formatDistanceToNow } from "date-fns";

import { StaticHtmlRenderer, StreamingEditor } from "@/components/editor";
import { Favicon } from "@/components/favicon";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { UploadCoverImage } from "@/components/upload/upload-cover-image";
import { API_ENDPOINT_HTTP } from "@/config";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { resourceRefToPath } from "@/lib/paths";
import { formatAuthors, formatDate, formatUploadTitle } from "@/lib/utils";
import { useGetIconAndLabel } from "@/plugins/object-link";
import {
	getGetFeedItemExtractedImageRouteQueryKey,
	getGetFeedItemPageImageRouteQueryKey,
	getGetUploadExtractedImageRouteQueryKey,
	getGetUploadPageImageRouteQueryKey,
	getGetWebpageExtractedImageRouteQueryKey,
	getGetWebpagePageImageRouteQueryKey,
} from "@api/fastAPI";
import {
	type ImageSearchResult,
	PageResolution,
	type Resource,
	type ResourceResult,
	type SearchId,
	type Snippet,
	type UploadId,
} from "@api/schemas";
import { ArrowDown, ArrowUp } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useId, useMemo, useState } from "react";

interface SearchAnswerProps {
	synthesis: string | null;
	loadingState: SearchResultLoadingState;
}

const SearchAnswer = observer(
	({ synthesis, loadingState }: SearchAnswerProps) => {
		const [showMore, setShowMore] = useState(false);

		const editorOptions = useMemo(() => {
			return {
				content: synthesis,
				editable: false,
				editorProps: {},
			};
		}, [synthesis]);

		return (
			<div className="relative flex flex-col">
				<div
					className={clsx(
						"flex min-h-48 w-full shrink-0 flex-col gap-3 overflow-y-hidden bg-neutral-50 p-4",
						showMore ? "h-auto" : "h-48",
					)}
				>
					<div className="flex flex-col gap-1">
						{loadingState === "loading" && (
							<h3 className="text-neutral-500 text-sm">Conducting search...</h3>
						)}
						{loadingState === "synthesizing" && (
							<h3 className="text-neutral-500 text-sm">
								Forming a response...
							</h3>
						)}
						{loadingState === "synthesizing" || loadingState === "completed" ? (
							<StreamingEditor
								className="w-full max-w-full"
								options={editorOptions}
							/>
						) : (
							<></>
						)}
					</div>
				</div>
				<button
					className="h-5 w-full border-neutral-100 border-t bg-neutral-50 text-center text-neutral-500 text-xs"
					type="button"
					onClick={() => setShowMore(!showMore)}
				>
					{showMore ? "Show less" : "Show more"}
				</button>
			</div>
		);
	},
);

// TODO(John): fix - search should probably be more differentiated by resource
const Subtitle = observer(({ resource }: { resource: Resource }) => {
	if ("feed_item_id" in resource) {
		return (
			<div className="text-neutral-500 text-xs">
				{formatAuthors(resource.authors)}
				{resource.date_published && `, ${formatDate(resource.date_published)}`}
			</div>
		);
	}

	if ("feed_channel_id" in resource) {
		return (
			<div className="text-neutral-500 text-xs">{resource.feed_subtitle}</div>
		);
	}

	if ("table_id" in resource) {
		return <div className="text-neutral-500 text-xs">{resource.name}</div>;
	}

	if ("upload_id" in resource) {
		return (
			<div className="flex text-neutral-500 text-xs">
				{formatAuthors(resource.authors ?? [])}

				{resource.date_published && `, ${formatDate(resource.date_published)}`}
			</div>
		);
	}
	return null;
});

const Title = observer(({ resource }: { resource: Resource }) => {
	if ("upload_id" in resource) {
		return (
			<h1 className="text-blue-600">
				{formatUploadTitle({
					name: resource.name,
					subtitle: resource.subtitle,
					filename: resource.filename,
				})}
			</h1>
		);
	}

	return <h1 className="text-blue-600">{resource.name}</h1>;
});

const ResourceImage = observer(({ resource }: { resource: Resource }) => {
	if ("upload_id" in resource) {
		return (
			<UploadCoverImage
				upload_id={resource.upload_id as UploadId}
				resolution={PageResolution.thumbnail}
				className="h-12 max-w-12 rounded-xs shadow"
			/>
		);
	}
	if ("feed_item_id" in resource) {
		if (resource.og_image) {
			return (
				<img
					src={resource.og_image}
					alt={resource.name ?? ""}
					className="flex h-12 max-w-12 shrink-0 object-cover"
				/>
			);
		}
		return null;
	}

	if ("feed_channel_id" in resource) {
		return (
			<Favicon
				url={resource.feed_link || ""}
				alt={resource.name || ""}
				className="h-12 max-w-12 rounded-xs shadow"
			/>
		);
	}

	return null;
});

const getMarkedTextFromSnippet = (snippet: Snippet) => {
	const fullText = snippet.full_text;
	const extractStart = snippet.extract_range[0];
	const extractEnd = snippet.extract_range[1];
	const extractText = fullText.slice(extractStart, extractEnd);
	// explicitly use our data attribute
	return `${fullText.slice(0, extractStart)}<mark data-extract>${extractText}</mark>${fullText.slice(extractEnd)}`;
};

const ImageResultItem = observer(
	({
		imageResult,
		result,
	}: { imageResult: ImageSearchResult; result: ResourceResult }) => {
		const appContext = useAppContext();

		let imageUrl: string;
		let pageUrl: string;
		let linkComponent: React.ReactNode;

		// Is there a better way to template image URLs besides using query keys?
		switch (result.resource_ref.type) {
			case "upload":
				imageUrl = getGetUploadExtractedImageRouteQueryKey(
					result.resource_ref.resource_id,
					imageResult.image.page_index,
					imageResult.image.index_in_page,
				)[0];
				pageUrl = getGetUploadPageImageRouteQueryKey(
					result.resource_ref.resource_id,
					imageResult.image.page_index,
					PageResolution.high,
				)[0];
				linkComponent = (
					<Link
						to={"/uploads/upload/$upload-id"}
						params={{
							"upload-id": result.resource_ref.resource_id,
						}}
						search={{
							startPageIndex: imageResult.image.page_index,
							endPageIndex: imageResult.image.page_index + 1,
						}}
					>
						View in PDF
					</Link>
				);
				break;
			case "webpage":
				imageUrl = getGetWebpageExtractedImageRouteQueryKey(
					result.resource_ref.resource_id,
					imageResult.image.page_index,
					imageResult.image.index_in_page,
				)[0];
				pageUrl = getGetWebpagePageImageRouteQueryKey(
					result.resource_ref.resource_id,
					imageResult.image.page_index,
					PageResolution.high,
				)[0];
				linkComponent = (
					<Link
						to={"/web/webpage/$webpage-id"}
						params={{
							"webpage-id": result.resource_ref.resource_id,
						}}
					>
						View in browser
					</Link>
				);
				break;
			case "feed-item":
				imageUrl = getGetFeedItemExtractedImageRouteQueryKey(
					result.resource_ref.resource_id,
					imageResult.image.page_index,
					imageResult.image.index_in_page,
				)[0];
				pageUrl = getGetFeedItemPageImageRouteQueryKey(
					result.resource_ref.resource_id,
					imageResult.image.page_index,
					PageResolution.high,
				)[0];
				linkComponent = (
					<Link
						to={"/feeds/feed-item/$feed-item-id"}
						params={{
							"feed-item-id": result.resource_ref.resource_id,
						}}
					>
						View in feed
					</Link>
				);
				break;
			default: {
				const _exhaustiveCheck: never = result.resource_ref;
				throw new Error(`Unhandled resource type: ${_exhaustiveCheck}`);
			}
		}

		return (
			<Dialog>
				<DialogTrigger asChild>
					<button
						type="button"
						key={`${imageResult.image.page_index}-${imageResult.image.index_in_page}`}
						className="flex flex-col items-center justify-between gap-2 border p-2 hover:border-blue-200 hover:bg-blue-50"
					>
						<img
							src={`${API_ENDPOINT_HTTP}${imageUrl}`}
							alt="Search result"
							className="max-h-48 max-w-48"
						/>
						<div className="w-full max-w-max text-center text-neutral-500 text-xs">
							Page {imageResult.image.page_index + 1}
							{appContext.devSettings.showSearchResultScores && (
								<div className="mt-1 max-w-max border bg-neutral-50 px-1 py-0.5 text-neutral-500 text-xs">
									Score: {Math.round(imageResult.score * 100) / 100}
								</div>
							)}
						</div>
					</button>
				</DialogTrigger>
				<DialogContent className="min-h-48 max-w-screen-md">
					{linkComponent}
					<div className="flex flex-col items-center justify-center">
						<div className="relative max-h-max max-w-max">
							<img src={`${API_ENDPOINT_HTTP}${pageUrl}`} alt="Search result" />
							<div
								className="absolute border border-blue-300"
								style={{
									left: `${imageResult.image.xmin_frac * 100 - 1}%`,
									top: `${imageResult.image.ymin_frac * 100 - 1}%`,
									width: `${(imageResult.image.xmax_frac - imageResult.image.xmin_frac) * 100 + 2}%`,
									height: `${(imageResult.image.ymax_frac - imageResult.image.ymin_frac) * 100 + 2}%`,
								}}
							/>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		);
	},
);

const SearchResult = observer(
	({ enrichedResult }: { enrichedResult: EnrichedResourceResult }) => {
		const appContext = useAppContext();
		const { tabState: searchState } = Route.useLoaderData();
		const { result, resource } = enrichedResult;
		const [snippetSortingStrategy, setSnippetSortingStrategy] = useState<
			"order" | "order-desc" | "relevance" | "relevance-desc"
		>("relevance");

		// TODO(Tae): Move to mobx
		const sortedSnippets: Snippet[] = useMemo(() => {
			const snippets = [...result.snippets];
			switch (snippetSortingStrategy) {
				case "order":
					return snippets;
				case "order-desc":
					return snippets.reverse();
				case "relevance":
					return snippets.sort((a, b) => b.score - a.score);
				case "relevance-desc":
					return snippets.sort((a, b) => a.score - b.score);
			}
		}, [result.snippets, snippetSortingStrategy]);

		const path = resourceRefToPath(result.resource_ref);
		const { Icon } = useGetIconAndLabel(path);

		return (
			<div className="flex flex-col gap-2">
				{/* Header */}
				<div className="flex flex-col gap-2">
					{/* Resource path */}
					<div className="text-blue-600 text-xs">{path}</div>
					<div className="flex gap-2">
						<ResourceImage resource={resource} />
						<div className="flex flex-col gap-1">
							<div className="flex items-center gap-1">
								{appContext.devSettings.showSearchResultScores ? (
									<div className="w-fit border border-neutral-200 bg-neutral-50 px-1 py-0.5 font-medium text-neutral-500 text-xs">
										{/* Truncate to 2 decimal places */}
										Score: {Math.round(result.score * 100) / 100}
									</div>
								) : null}
								<Icon className="h-4 w-4" />
								{/* This should be a link */}
								<button
									type="button"
									className="text-left text-base text-neutral-950 hover:underline"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										searchState.tab.tabStore.createTabInActiveTabSet({
											href: path,
										});
									}}
								>
									<Title resource={resource} />
								</button>
							</div>
							<Subtitle resource={resource} />
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-2">
					{/* 
					For now, we render snippets and figures as separate tabs per resource.

					Alternatively, we could make the tab a top-level toggle similar to Google,
					or find a way to interleave figures and snippets in a single tab.
					*/}
					<Tabs defaultValue="snippets">
						<TabsList asChild>
							{/* Snippets */}
							<div className="flex w-full justify-between border-neutral-200 border-b pt-1">
								<div className="flex">
									<TabsTrigger
										value="snippets"
										className="flex items-center gap-1.5"
									>
										Snippets{" "}
										<span className="text-neutral-400">
											{result.snippets.length}
										</span>
									</TabsTrigger>
									<TabsTrigger
										value="figures"
										className="flex items-center gap-1.5"
									>
										Figures{" "}
										<span className="text-neutral-400">
											{result.images.length}
										</span>
									</TabsTrigger>
								</div>
								<div className="flex gap-1">
									<div className="text-neutral-500 text-xs">Sort by:</div>
									<button
										className={clsx(
											"flex items-center text-neutral-500 text-xs",
											snippetSortingStrategy === "order" ||
												snippetSortingStrategy === "order-desc"
												? "text-neutral-950"
												: "text-neutral-500",
										)}
										type="button"
										onClick={() => {
											if (snippetSortingStrategy === "order") {
												setSnippetSortingStrategy("order-desc");
											} else {
												setSnippetSortingStrategy("order");
											}
										}}
									>
										{snippetSortingStrategy === "order" && (
											<ArrowDown className="h-3 w-3" />
										)}
										{snippetSortingStrategy === "order-desc" && (
											<ArrowUp className="h-3 w-3" />
										)}
										Order
									</button>
									<button
										className={clsx(
											"flex items-center text-neutral-500 text-xs",
											snippetSortingStrategy === "relevance" ||
												snippetSortingStrategy === "relevance-desc"
												? "text-neutral-950"
												: "text-neutral-500",
										)}
										type="button"
										onClick={() => {
											if (snippetSortingStrategy === "relevance") {
												setSnippetSortingStrategy("relevance-desc");
											} else {
												setSnippetSortingStrategy("relevance");
											}
										}}
									>
										{snippetSortingStrategy === "relevance" && (
											<ArrowDown className="h-3 w-3" />
										)}
										{snippetSortingStrategy === "relevance-desc" && (
											<ArrowUp className="h-3 w-3" />
										)}
										Relevance
									</button>
								</div>
							</div>
						</TabsList>
						<TabsContent value="snippets">
							<div className="flex flex-col gap-4 py-2">
								{sortedSnippets.map((snippet, index) => {
									return (
										<div
											// biome-ignore lint/suspicious/noArrayIndexKey: the index represents the rank of the snippet
											key={index}
											className="flex flex-col gap-2"
										>
											<div className="flex items-center gap-1 ">
												{appContext.devSettings.showSearchResultScores ? (
													<div className="w-fit border border-neutral-200 bg-neutral-50 px-1 py-0.5 font-medium text-neutral-500 text-xs">
														{/* Truncate to 2 decimal places */}
														Score: {Math.round(snippet.score * 100) / 100}
													</div>
												) : null}
												<button
													type="button"
													className="text-blue-600 text-xs hover:underline"
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														// TODO(Tae): This doesn't actually work. We should be passing in search_ids if anything\
														const textMatch = snippet.full_text.slice(
															snippet.extract_range[0],
															snippet.extract_range[1],
														);
														searchState.tab.tabStore.createTabInActiveTabSet({
															href: `${path}?start=${encodeURIComponent(
																textMatch,
															)}`,
														});
													}}
												>
													{`${resource.name}${
														snippet.page_range
															? ` / Page ${snippet.page_range[0] ?? 0}`
															: ""
													}`}
												</button>
											</div>
											<div className="border-neutral-200 border-l-2 pl-3">
												<StaticHtmlRenderer
													content={getMarkedTextFromSnippet(snippet)}
													className="prose-headings:font-medium prose-h1:text-base prose-h2:text-sm prose-h3:text-sm prose-h4:text-sm prose-headings:text-neutral-500 prose-p:text-neutral-500 prose-p:text-xs"
												/>
											</div>
										</div>
									);
								})}
							</div>
						</TabsContent>
						<TabsContent value="figures">
							<div className="flex flex-wrap gap-2">
								{result.images.map((imageResult) => {
									return (
										<ImageResultItem
											key={`${imageResult.image.page_index}-${imageResult.image.index_in_page}`}
											imageResult={imageResult}
											result={result}
										/>
									);
								})}
							</div>
						</TabsContent>
					</Tabs>

					{/* <div className="flex justify-end">
						<button
							className="flex items-center gap-1 text-neutral-500 text-xs underline"
							type="button"
							onClick={() => {}}
						>
							<Plus className="h-4 w-4" />
							Find more snippets
						</button>
					</div> */}
				</div>
			</div>
		);
	},
);

const SearchResults = observer(() => {
	const containerId = useId();

	const { tabState } = Route.useLoaderData();

	const enrichedSearchResult = tabState.enrichedResults;
	const loadingState = tabState.loadingState;
	if (loadingState === null) {
		throw new Error(
			"No loading state. Should be impossible, since this route is only rendered after the search result is set in the loader.",
		);
	}

	let SearchBody = null;
	if (loadingState === "loading") {
		SearchBody = (
			<div className="relative flex grow flex-col overflow-y-auto bg-white">
				{[...Array(1)].map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="flex w-full flex-col space-y-2 p-6"
					>
						<div className="flex space-x-2">
							<Skeleton className="h-8 w-6" />
							<div className="flex min-w-0 grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full max-w-48" />
							</div>
						</div>
						<div className="flex w-full flex-col">
							<div className="flex grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
							</div>
						</div>
					</div>
				))}
			</div>
		);
	} else if (enrichedSearchResult.length === 0) {
		SearchBody = (
			<div className="flex h-full w-full items-center justify-center">
				<h1 className="text-neutral-500">No results found</h1>
			</div>
		);
	} else {
		SearchBody = (
			<div className="flex flex-col gap-4 p-4">
				<div className="text-neutral-500 text-xs">
					searched{" "}
					{formatDistanceToNow(
						new Date(tabState.searchResource?.created_at ?? ""),
					)}{" "}
					ago
				</div>
				{enrichedSearchResult.map((enrichedResult, index) => {
					return (
						<SearchResult
							// biome-ignore lint/suspicious/noArrayIndexKey: the index represents the rank of the result
							key={index}
							enrichedResult={enrichedResult}
						/>
					);
				})}
				{/* <div className="flex justify-end">
					<button
						className="flex items-center gap-2 rounded-full px-3 py-1.5 font-medium text-neutral-600 text-sm transition-colors hover:bg-neutral-50 hover:text-neutral-700"
						type="button"
						onClick={() => {}}
					>
						<Plus className="h-4 w-4" />
						Find more resources
					</button>
				</div> */}
			</div>
		);
	}

	return (
		<div
			// the key forces a rerender and a scroll reset when we switch between grouped and ungrouped results
			// or when the results change as indicated by result_id
			key={"search-results"}
			className="relative flex grow flex-col gap-2 overflow-y-auto bg-white"
			id={containerId}
		>
			{tabState.searchResource?.requires_synthesis && (
				<SearchAnswer
					synthesis={tabState.fullResult?.synthesis ?? null}
					loadingState={loadingState}
				/>
			)}

			{SearchBody}
		</div>
	);
});

export const Route = createFileRoute("/search/result/$search-id")({
	component: SearchResults,
	loader: ({ params, context: { tab } }) => {
		// If the tab's state is already a SearchTabState, just return it
		if (tab.state instanceof SearchTabState) {
			tab.state.loadSearchResult(params["search-id"] as SearchId);
			return {
				tabState: tab.state,
			};
		}
		return {
			tabState: new SearchTabState(tab, null),
		};
	},
});
