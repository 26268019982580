import type { PdfSearchParams } from "@/contexts/pdfviewer-context";
import type { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import type { WebpageId } from "@api/schemas";
import { Globe } from "@phosphor-icons/react";
import { makeAutoObservable } from "mobx";

export class WebpageTabState implements BaseTabState {
	tab: Tab;
	searchParams: PdfSearchParams;
	webpageId: WebpageId;

	constructor(tab: Tab, webpageId: WebpageId, searchParams: PdfSearchParams) {
		this.tab = tab;
		this.webpageId = webpageId;
		this.searchParams = searchParams;
		this.tab.setState(this);
		makeAutoObservable(this);
	}

	get head() {
		return {
			icon: Globe,
			label: "Webpage",
			resourceRef: {
				type: "webpage" as const,
				resource_id: this.webpageId,
			},
		};
	}
}
