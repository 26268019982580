import { Button } from "@/components/ui/button";
import type { Icon } from "@phosphor-icons/react";
import type { LinkProps } from "@tanstack/react-router";
import clsx from "clsx";

export const SidebarNavigationButton = ({
	ButtonIcon,
	label,
	location,
	navigate,
}: {
	ButtonIcon: Icon;
	label: string;
	location: LinkProps;
	navigate: (location: LinkProps) => void;
}) => {
	return (
		<Button
			variant="ghost"
			className={clsx(
				"flex h-8 w-full shrink-0 items-center justify-start gap-2 truncate px-2 py-1.5 font-medium text-neutral-700 text-sm hover:bg-neutral-100",
			)}
			onClick={() => {
				navigate(location);
			}}
		>
			<ButtonIcon weight="regular" className="shrink-0 text-base" />
			<span className="truncate">{label}</span>
		</Button>
	);
};
