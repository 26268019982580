import type { AppState } from "@/contexts/app-context/app-context";
import { isFieldPrimary } from "@/contexts/tables/stores/field-store";
import { runViewDefRoute } from "@api/fastAPI";
import type {
	AggregateField,
	AndFilterGroup,
	DataType,
	Field,
	FieldId,
	Filter,
	FilterExpression,
	GroupByViewDef,
	OrFilterGroup,
	QueryResult,
	SelectViewDef,
	TableId,
	ViewDef,
} from "@api/schemas";
import { autorun, makeAutoObservable, toJS } from "mobx";

// TODO(John): fix!
export const getDataTypeOperators = (dataType: DataType): Filter["type"][] => {
	// For now, I won't handle arrays.
	if (dataType.array_depth > 0) {
		return [];
	}
	switch (dataType.scalar_type) {
		case "text":
			return ["text_equals", "text_not_equals", "text_contains"];
		case "real":
			return ["number_equals", "number_not_equals"];
		case "timestamptz":
			return ["datetime_equals", "datetime_not_equals"];
		case "boolean":
			return ["boolean_equals", "boolean_not_equals"];
		case "record_link":
			return [];
		// TODO(John): replace with the correct filters
		case "select_option":
			return ["text_equals", "text_not_equals"];
	}
};

// Copied from server/server/postgres/orm/integrations/tables/computed_tables.py
export const SELECT_VIEW_PRIMARY_COLUMN = "Original Record";

const DEFAULT_WHERE_EXPRESSION: AndFilterGroup = {
	type: "and",
	expressions: [],
};

export class ViewCreatorState {
	appState: AppState;

	fromTableId: TableId;
	where: AndFilterGroup | OrFilterGroup = DEFAULT_WHERE_EXPRESSION;

	viewType: "select" | "group_by" = "select";

	// If viewType is "select"
	selectedFields: Set<FieldId> = new Set();

	// If viewType is "group_by"
	groupingFields: Set<FieldId> = new Set();
	aggregateFields: Array<AggregateField> = [];

	#disposer: () => void;

	queryResult: QueryResult | null = null;

	constructor(appState: AppState, fromTableId: TableId) {
		// NOTE(John): it is IMPORTANT that makeAutoObservable is called first,
		// especially before the autorun is created, or else the autorun will
		// not watch the class attributes correctly.
		makeAutoObservable(this);

		this.appState = appState;
		this.fromTableId = fromTableId;

		// Default selected fields are all the from table's fields
		this.selectedFields = new Set(Array.from(this.fromTableFields.keys()));
		// Set default grouping field
		const firstField = Array.from(this.fromTableFields.keys())[0];
		if (firstField) {
			this.groupingFields.add(firstField);
		}
		this.where.expressions.push(this.defaultNewFilter);

		this.#disposer = autorun(
			() => {
				this.setQueryResult(null);
				console.log("Sending view def", this.viewDef);
				runViewDefRoute({
					view_def: this.viewDef,
				}).then((response) => {
					this.setQueryResult(response.data.result);
				});
			},
			{
				name: "update-view-query-records",
			},
		);
	}

	get viewDef(): ViewDef {
		if (this.viewType === "select") {
			const viewDef: SelectViewDef = {
				from_table_id: this.fromTableId,
				where: toJS(this.where),
				selected_fields: Array.from(this.selectedFields),
			};
			return viewDef;
		}

		const viewDef: GroupByViewDef = {
			from_table_id: this.fromTableId,
			where: toJS(this.where),
			grouping_fields: Array.from(this.groupingFields),
			aggregate_fields: toJS(this.aggregateFields),
		};
		return viewDef;
	}

	setViewType(type: "select" | "group_by") {
		this.viewType = type;
	}

	setQueryResult(queryResult: QueryResult | null) {
		this.queryResult = queryResult;
	}

	setSelectedFields(fieldIds: Set<FieldId>) {
		this.selectedFields = fieldIds;
	}

	toggleSelectedField(fieldId: FieldId) {
		if (this.selectedFields.has(fieldId)) {
			this.selectedFields.delete(fieldId);
		} else {
			this.selectedFields.add(fieldId);
		}
	}

	toggleGroupingField(fieldId: FieldId) {
		if (this.groupingFields.has(fieldId)) {
			// Don't allow removing the last grouping field
			if (this.groupingFields.size > 1) {
				this.groupingFields.delete(fieldId);
			}
		} else {
			this.groupingFields.add(fieldId);
		}
	}

	addAggregateField(field: AggregateField) {
		this.aggregateFields.push(field);
	}

	removeAggregateField(index: number) {
		this.aggregateFields.splice(index, 1);
	}

	updateAggregateField(index: number, field: AggregateField) {
		this.aggregateFields[index] = field;
	}

	get fromTableFields(): Map<FieldId, [Field, DataType]> {
		const fields = this.appState.workspace.fields.getFieldsByTableId(
			this.fromTableId,
		);
		const fieldsWithDataType = new Map<FieldId, [Field, DataType]>();
		for (const [fieldId, field] of fields) {
			this.appState.workspace.fields.getFieldDataType(fieldId).match(
				(dataType) => {
					fieldsWithDataType.set(fieldId, [field, dataType]);
				},
				(error) => {
					console.error(error);
				},
			);
		}
		return fieldsWithDataType;
	}

	get defaultNewFilter(): FilterExpression {
		const firstPrimaryField = Array.from(this.fromTableFields.values()).find(
			([field]) => isFieldPrimary(field),
		);

		if (!firstPrimaryField) {
			throw new Error("No primary fields found");
		}
		const dataType = this.appState.workspace.fields.getFieldDataType(
			firstPrimaryField[0].field_id,
		);

		if (dataType.isErr()) {
			throw new Error("Could not get data type for primary field");
		}

		const operators = getDataTypeOperators(dataType.value);

		return {
			type: operators[0],
			field_id: firstPrimaryField[0].field_id,
			value: null,
			array_op: null,
		};
	}

	async createComputedTable() {
		if (!this.queryResult) {
			throw new Error("You must select a table!");
		}
		await this.appState.workspace.tables.createComputedTable({
			viewDef: this.viewDef,
		});
	}

	dispose() {
		this.#disposer();
	}
}
