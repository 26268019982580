import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import { ResourceStatus } from "@/contexts/pending-resources";
import { createSyncedAction } from "@/contexts/synced-actions";
import openapiHashes from "@/generated/openapi-hashes.json";
import { createFeedChannelId, createFeedItemId } from "@/lib/id-generators";
import { ElectricOptimisticMap } from "@/lib/sync/optimistic-map";
import { indexFeedChannelRoute } from "@api/fastAPI";
import type {
	FeedChannelId,
	FeedChannelResource,
	FeedItemId,
	FeedItemResource,
	ParsedFeed,
	ParsedFeedChannel,
	ParsedFeedItem,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import type { Result } from "neverthrow";

const addFeedChannelAction = createSyncedAction<
	FeedChannelsStore,
	{ feedChannelPreview: ParsedFeed },
	{
		channelId: FeedChannelId;
		channel: ParsedFeedChannel;
		itemsById: Map<FeedItemId, ParsedFeedItem>;
	},
	boolean
>({
	async local({ feedChannelPreview }) {
		const feedChannelId = createFeedChannelId();

		const itemsById = new Map<FeedItemId, ParsedFeedItem>();
		for (const item of feedChannelPreview.feed_items) {
			itemsById.set(createFeedItemId(), item);
		}

		for (const [feedItemId, item] of itemsById.entries()) {
			this.appState.pendingResources.resources.set(feedItemId, {
				resource: {
					resource_id: feedItemId,
					type: "feed-item",
				},
				name: item.feed_item_title ?? "Untitled article",
				status: ResourceStatus.pending,
			});
		}
		this.appState.pendingResources.resources.set(feedChannelId, {
			resource: {
				resource_id: feedChannelId,
				type: "feed-channel",
			},
			name: feedChannelPreview.feed_channel.feed_channel_title,
			status: ResourceStatus.pending,
		});

		return {
			channelId: feedChannelId,
			channel: feedChannelPreview.feed_channel,
			itemsById,
		};
	},
	async remote(_, { channelId, channel, itemsById }) {
		const res = await indexFeedChannelRoute({
			feed_channel: channel,
			channel_id: channelId,
			items_by_id: Object.fromEntries(itemsById),
		});
		return res.data;
	},
	rollback(_, { itemsById }) {
		for (const [feedItemId, _] of itemsById) {
			const recentFeedItem =
				this.appState.pendingResources.resources.get(feedItemId);
			if (recentFeedItem) {
				recentFeedItem.status = ResourceStatus.failed;
			}
		}
	},
});

export class FeedChannelsStore {
	appState: AppState;
	map: ElectricOptimisticMap<
		FeedChannelResource,
		"feed_channel_id",
		"write_id"
	>;
	constructor(appState: AppState) {
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/feed_channels`,
			idKey: "feed_channel_id",
			writeIdKey: "write_id",
			shapeHash: openapiHashes.FeedChannelResource,
		});
		makeAutoObservable(this);
	}

	getResourceById(id: FeedChannelId): Result<FeedChannelResource, Error> {
		return this.map.get(id);
	}

	get sortedResources(): FeedChannelResource[] {
		return this.map.keys
			.map((x) => this.getResourceById(x))
			.filter((x) => x.isOk())
			.map((x) => x.value)
			.sort((a, b) => a.name.localeCompare(b.name));
	}

	addFeedChannel = addFeedChannelAction.bind(this);
}

export class FeedItemsStore {
	appState: AppState;
	map: ElectricOptimisticMap<FeedItemResource, "feed_item_id", "write_id">;
	constructor(appState: AppState) {
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/feed_items`,
			idKey: "feed_item_id",
			writeIdKey: "write_id",
			shapeHash: openapiHashes.FeedItemResource,
		});
		makeAutoObservable(this);
	}

	getResourceById(id: FeedItemId): Result<FeedItemResource, Error> {
		return this.map.get(id);
	}

	feedItemsForChannel(feedChannelId: FeedChannelId): FeedItemResource[] {
		const items = this.map.keys
			.map((x) => this.getResourceById(x))
			.filter((x) => x.isOk())
			.map((x) => x.value)
			.filter((x) => x.feed_channel_id === feedChannelId);
		return items;
	}
}
