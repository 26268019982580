import type { AppState } from "@/contexts/app-context/app-context";
import { ResourceStatus } from "@/contexts/pending-resources";
import type { WorkspaceUpdate } from "@api/schemas";
import * as Sentry from "@sentry/react";
import { toast } from "sonner";

const MAX_RECONNECT_ATTEMPTS = 5;

export function attemptReconnect(this: AppState) {
	Sentry.captureMessage("WebSocket connection lost", "error");

	if (this.reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
		console.error("Max reconnection attempts reached");
		toast.error("Unable to reconnect. Please refresh the page.");
		return;
	}
	toast.error("Connection lost. Reconnecting...");

	const delay = Math.min(1000 * 2 ** this.reconnectAttempts, 30000);

	this.reconnectTimeoutId = setTimeout(() => {
		this.reconnectAttempts++;
		this.init({
			isReconnect: true,
		});
	}, delay);
}

export function handleWorkspaceUpdate(this: AppState, update: WorkspaceUpdate) {
	switch (update.type) {
		case "web_search_synthesis": {
			this.webSearchStore.handleSynthesisUpdate(update);
			break;
		}

		// TODO(Tae): Reconsider this
		case "search_reranking": {
			break;
		}

		case "search_synthesis": {
			this.searchStore.handleSynthesisUpdate(update);
			break;
		}

		case "resource_success": {
			const resource = this.pendingResources.resources.get(
				update.resource.resource_id,
			);
			if (resource) {
				resource.status = ResourceStatus.success;
			}
			break;
		}

		case "resource_failure": {
			const resource = this.pendingResources.resources.get(
				update.resource.resource_id,
			);
			if (resource) {
				resource.status = ResourceStatus.failed;
			}
			break;
		}

		default: {
			const _exhaustiveCheck: never = update;
			return _exhaustiveCheck;
		}
	}
}
