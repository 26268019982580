import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { MinisearchIndex } from "@/lib/minisearch-index";
import { OptimisticMap } from "@/lib/sync/optimistic-map";
import { ElectricSyncStream } from "@/lib/sync/stream";
import type { User } from "@api/schemas";

export class UserStore {
	appState: AppState;
	items: OptimisticMap<User, "user_id">;
	searchIndex: MinisearchIndex<User, "user_id">;

	constructor(appState: AppState) {
		this.appState = appState;
		this.items = new OptimisticMap<User, "user_id">({
			stream: new ElectricSyncStream<User, "user_id", "write_id">({
				shapeUrl: `${API_ENDPOINT_HTTP}/shapes/users`,
				shapeHash: openapiHashes.User,
				idKey: "user_id",
				writeIdKey: "write_id",
			}),
		});
		this.searchIndex = new MinisearchIndex<User, "user_id">({
			sourceMap: this.items.streamItems,
			idKey: "user_id",
			indexedFields: ["user_first_name", "user_last_name"],
		});
	}
}
