import type { AppState } from "@/contexts/app-context/app-context";
import { Tab } from "@/contexts/tabs/tabs-context";
import type { MessageId, ResourceRef, SessionAssistantId } from "@api/schemas";
import { makeAutoObservable } from "mobx";

export class RightSidebarState {
	appState: AppState;

	showRightSidebar = true;
	rightSidebarTab: "messages" | "assistant_activity" = "messages";

	// Filters for the top-level assistant activity viewer
	resourceFilterValue: ResourceRef | null = null;
	threadIdFilterValue: MessageId | null = null;
	sessionSearchQuery = "";

	activeSessionsDialogOpen = false;

	// The session assistant ID that is currently active in the assistant activity viewer
	activityViewerActiveSessionAssistantId: SessionAssistantId | null = null;

	messageTab: Tab;

	constructor(appState: AppState) {
		this.appState = appState;

		this.messageTab = new Tab({
			tabsState: appState.tabStore,
			initialLocation: {
				to: "/message",
			},
		});
		makeAutoObservable(this);
	}

	setActiveSessionsDialogOpen = (open: boolean) => {
		this.activeSessionsDialogOpen = open;
	};

	toggleActiveSessionsDialog = () => {
		this.activeSessionsDialogOpen = !this.activeSessionsDialogOpen;
	};

	toggleRightSidebar = () => {
		this.showRightSidebar = !this.showRightSidebar;
	};

	_resetToAssistantActivity = () => {
		this.rightSidebarTab = "assistant_activity";
		this.activityViewerActiveSessionAssistantId = null;
	};

	setFilterToResource = (resourceRef: ResourceRef) => {
		this.resourceFilterValue = resourceRef;
		this.threadIdFilterValue = null;
		this._resetToAssistantActivity();
	};

	setFilterToThread = (threadId: MessageId) => {
		this.threadIdFilterValue = threadId;
		this.resourceFilterValue = null;
		this._resetToAssistantActivity();
	};
}
