import { useAppContext } from "@/contexts/app-context/use-app-context";
import { ViewCreatorContext } from "@/pages/tabs/tables/-components/computed-table-creator/use-view-creator-state";
import { ViewCreatorState } from "@/pages/tabs/tables/-components/computed-table-creator/view-creator-state";
import type { TableId } from "@api/schemas";
import { useEffect, useState } from "react";

export const ViewCreatorProvider = ({
	fromTableId,
	children,
}: {
	fromTableId: TableId | null;
	children: React.ReactNode;
}) => {
	const appContext = useAppContext();
	const [viewCreatorState, setViewCreatorState] =
		useState<null | ViewCreatorState>(null);

	/**
	 * NOTE(John): I'm doing the state creation this way because of Strict Mode.
	 * Basically, in Strict Mode, React runs the render code twice, and also
	 * runs all useEffects twice. The problem is that the useEffects run after
	 * the component is mounted.
	 *
	 * Before, we created the viewCreatorState inside the useState, like this:
	 *
	 * const [viewCreatorState] = useState(() => new ViewCreatorState(appContext));
	 *
	 * This would create the viewCreatorState twice, but then only afterwards
	 * run the useEffect twice. In between the useEffect runs, the cleanup
	 * function would get called, which would run the .dispose() method and make
	 * the autorun stop working.
	 *
	 * The solution I've come up with is to manage the state creation within the
	 * useEffect as well. The downside is that this means there's an extra
	 * re-render, since on component mount, the state is null.
	 *
	 * Let me know if there's better solutions though.
	 */
	useEffect(() => {
		if (!fromTableId) {
			setViewCreatorState(null);
			return;
		}

		const newState = new ViewCreatorState(appContext, fromTableId);
		setViewCreatorState(newState);

		// Return cleanup function
		return () => {
			newState.dispose();
		};
	}, [appContext, fromTableId]);

	return (
		<ViewCreatorContext.Provider value={viewCreatorState}>
			{children}
		</ViewCreatorContext.Provider>
	);
};
