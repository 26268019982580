import { PDFViewer } from "@/components/pdf/pdfviewer";
import { API_ENDPOINT_HTTP } from "@/config";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { WebpageTabState } from "@/contexts/webpages/tab-state";
import { getWebpagePdf, getWebpageToc } from "@api/fastAPI";
import { PageResolution, type WebpageId } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

const RouteComponent = observer(function RouteComponent() {
	const { tabState } = Route.useLoaderData();
	const appContext = useAppContext();

	const webpage = appContext.workspace.webpages.getResourceById(
		tabState.webpageId,
	);

	if (webpage.isErr()) {
		return <div>Webpage not found</div>;
	}

	return (
		<PDFViewer
			resourceId={webpage.value.webpage_id}
			highlights={[]}
			activeHighlight={undefined}
			loader={async () => {
				const resp = await getWebpagePdf(webpage.value.webpage_id, {
					responseType: "arraybuffer",
				});
				return new Uint8Array(resp.data as ArrayBuffer);
			}}
			tocLoader={async () => {
				const resp = await getWebpageToc(webpage.value.webpage_id);
				return resp.data;
			}}
			headerMetadata={{
				title: webpage.value.name,
				authors: webpage.value.authors,
				description: webpage.value.description,
				url: webpage.value.original_url,
			}}
			thumbnailUrl={(pageIndex) => {
				return `${API_ENDPOINT_HTTP}/webpages/page_image/${webpage.value.webpage_id}/${pageIndex}/${PageResolution.thumbnail}.jpg`;
			}}
		/>
	);
});

export const Route = createFileRoute("/web/webpage/$webpage-id")({
	component: RouteComponent,
	loader: ({ params, context: { tab } }): { tabState: WebpageTabState } => {
		return {
			tabState: new WebpageTabState(tab, params["webpage-id"] as WebpageId),
		};
	},
});
