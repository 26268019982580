/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export type SearchMode = typeof SearchMode[keyof typeof SearchMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchMode = {
  keyword: 'keyword',
  hybrid: 'hybrid',
} as const;
