import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useWebpagesStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { Plus } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";

export const AddWebpageDialog = observer(function AddWebpageDialog() {
	const [url, setUrl] = useState("");
	const webpageStore = useWebpagesStore();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!url) return;
		webpageStore.indexWebpage({
			url,
		});
	};

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button className="gap-2" size="sm" variant="outline">
					<Plus weight="bold" />
					<span>Add</span>
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Add webpage</DialogTitle>
				</DialogHeader>

				<form onSubmit={handleSubmit} className="flex flex-col gap-4">
					<Input
						placeholder="Enter webpage URL..."
						value={url}
						onChange={(e) => setUrl(e.target.value)}
						type="url"
						required
					/>
					<Button type="submit">Add webpage</Button>
				</form>
			</DialogContent>
		</Dialog>
	);
});
