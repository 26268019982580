import { createWriteId } from "@/lib/id-generators";
import type { Transaction } from "@/lib/sync/action-executor";
import type {
	LocalDelete,
	LocalInsert,
	LocalUpdate,
	LocalWrite,
} from "@/lib/sync/local-write";
import { ElectricSyncStream, type SyncStream } from "@/lib/sync/stream";
import type { WriteId } from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { type Result, err, ok } from "neverthrow";

/**
 * The OptimisticMap maintains a Map of LocalWrite objects, which we create for
 * optimistic updates. When reading from the OptimisticMap, we process the
 * LocalWrites such that the query results are as if those writes were already
 * committed.
 *
 * The OptimisticMap also holds a reference to a SyncStream, which provides the
 * base data upon which the LocalWrites are performed and the matchWrite
 * function that determines when a write has been persisted.
 */
export class OptimisticMap<
	TItem extends object,
	TIdKey extends keyof TItem & string,
> {
	private stream: SyncStream<TItem, TIdKey>;
	private writes = new Map<WriteId, LocalWrite<TItem, TIdKey>>();
	private writeIndex = 0;

	constructor(props: { stream: SyncStream<TItem, TIdKey> }) {
		makeAutoObservable(this);
		this.stream = props.stream;
	}

	#registerWrite(tx: Transaction, write: LocalWrite<TItem, TIdKey>): void {
		this.writes.set(write.id, write);
		tx.addRollback(() => {
			this.writes.delete(write.id);
		});
		this.stream.matchWrite(write).match(
			() => {
				this.writes.delete(write.id);
			},
			(error) => {
				console.error("Write did not match", error);
				this.writes.delete(write.id);
			},
		);
	}

	insert(tx: Transaction, item: TItem): void {
		const { [this.stream.idKey]: itemId, ...value } = item;
		const write: LocalInsert<TItem, TIdKey> = {
			id: createWriteId(),
			index: this.writeIndex++,
			operation: "insert",
			itemId,
			value,
		};
		this.#registerWrite(tx, write);
	}

	// TODO(John): make this typed so that value must include write_id
	update(
		tx: Transaction,
		itemId: TItem[TIdKey],
		value: LocalUpdate<TItem, TIdKey>["value"],
	): void {
		const write: LocalUpdate<TItem, TIdKey> = {
			id: createWriteId(),
			index: this.writeIndex++,
			operation: "update",
			itemId,
			value,
		};
		this.#registerWrite(tx, write);
	}

	delete(tx: Transaction, itemId: TItem[TIdKey]): void {
		const write: LocalDelete<TItem, TIdKey> = {
			id: createWriteId(),
			index: this.writeIndex++,
			operation: "delete",
			itemId,
		};
		this.#registerWrite(tx, write);
	}

	/**
	 * Get all pending local writes.
	 * Useful for debugging or implementing custom cleanup logic.
	 */
	get pendingWrites(): LocalWrite<TItem, TIdKey>[] {
		return Array.from(this.writes.values()).sort((a, b) => a.index - b.index);
	}

	/**
	 * Get the value for a given id, coalescing any local writes over the base value.
	 */
	get(id: TItem[TIdKey]): Result<TItem, Error> {
		const baseValue = this.stream.items.get(id);

		// Get all writes for this id, sorted by writeIndex
		const relevantWrites = Array.from(this.writes.values())
			.filter((w) => w.itemId === id)
			.sort((a, b) => a.index - b.index);

		// If no base value and no writes, return an error
		if (!baseValue && !relevantWrites.length)
			return err(new Error("No value found"));

		if (!relevantWrites.length) {
			if (!baseValue) {
				return err(new Error("No value found"));
			}
			return ok(baseValue);
		}

		let result: TItem;

		// If no base value, then the first write must be an insert
		if (!baseValue) {
			// Check that the first write is an insert
			const firstWrite = relevantWrites[0];
			if (firstWrite.operation !== "insert") {
				return err(
					new Error(
						`Incorrect write order: first write must be an insert, found ${firstWrite.operation}`,
					),
				);
			}
			// Construct the first value written
			result = {
				...firstWrite.value,
				[this.stream.idKey]: firstWrite.itemId,
			} as TItem;
		} else {
			// Otherwise, start with the base value and apply writes in order
			result = { ...baseValue };
		}

		// Apply writes in order, making sure to update the writeId
		for (const write of relevantWrites) {
			if (write.operation === "delete")
				return err(
					new Error("Tried to get item that has been locally deleted"),
				);
			result = {
				...result,
				...write.value,
			};
		}

		return ok(result);
	}

	/**
	 * Get all valid keys in the map, coalescing pending local writes.
	 */
	get keys(): TItem[TIdKey][] {
		// Get all base keys from shape map
		const baseKeys = new Set(this.stream.items.keys());

		for (const write of this.writes.values()) {
			// Add keys from pending inserts
			if (write.operation === "insert") {
				baseKeys.add(write.itemId);
			}
			// Remove keys from pending deletes
			else if (write.operation === "delete") {
				baseKeys.delete(write.itemId);
			}
		}
		return Array.from(baseKeys);
	}

	/**
	 * Get all values in the map, coalescing pending local writes.
	 *
	 * TODO(John): can this be more efficient?
	 */
	values(): TItem[] {
		const values: TItem[] = [];
		for (const key of this.keys) {
			const value = this.get(key);
			if (value.isOk()) {
				values.push(value.value);
			}
		}
		return values;
	}

	has(id: TItem[TIdKey]): boolean {
		return this.get(id).isOk();
	}

	get streamItems(): Map<TItem[TIdKey], TItem> {
		return this.stream.items;
	}
}

/**
 * Wrapper around OptimisticMap that uses ElectricSyncStream.
 *
 * Useful so we can enforce that update values include a new write_id field.
 *
 * TODO(John): do this
 */
export class ElectricOptimisticMap<
	TItem extends object,
	TIdKey extends keyof TItem & string,
	TWriteIdKey extends keyof TItem & string,
> {
	stream: ElectricSyncStream<TItem, TIdKey, TWriteIdKey>;
	items: OptimisticMap<TItem, TIdKey>;

	constructor(props: {
		shapeUrl: string;
		idKey: TIdKey;
		writeIdKey: TWriteIdKey;
		shapeHash: string;
	}) {
		makeAutoObservable(this);
		this.stream = new ElectricSyncStream({
			shapeUrl: props.shapeUrl,
			idKey: props.idKey,
			writeIdKey: props.writeIdKey,
			shapeHash: props.shapeHash,
		});
		this.items = new OptimisticMap({ stream: this.stream });
	}

	insert(tx: Transaction, item: TItem): void {
		this.items.insert(tx, item);
	}

	update(
		tx: Transaction,
		itemId: TItem[TIdKey],
		value: LocalUpdate<TItem, TIdKey>["value"],
	): void {
		this.items.update(tx, itemId, value);
	}

	delete(tx: Transaction, itemId: TItem[TIdKey]): void {
		this.items.delete(tx, itemId);
	}

	get(id: TItem[TIdKey]): Result<TItem, Error> {
		return this.items.get(id);
	}

	get keys(): TItem[TIdKey][] {
		return this.items.keys;
	}

	values(): TItem[] {
		return this.items.values();
	}
}
