import { Favicon } from "@/components/favicon";
import { Button } from "@/components/ui/button";
import { Drawer, DrawerContent } from "@/components/ui/drawer";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Input } from "@/components/ui/input";
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Skeleton } from "@/components/ui/skeleton";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useFeedItemsStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { FeedChannelTabState } from "@/contexts/feeds/tab-state";
import { cleanUrlForDisplay, cleanXmlText } from "@/lib/formatting";
import { FeedItemViewer } from "@/pages/tabs/feeds/feed-item/-components/feed-item-viewer";
import { refetchFeedChannel } from "@api/fastAPI";
import type { FeedChannelId, FeedChannelResource } from "@api/schemas";
import {
	ArrowUpRight,
	ArrowsClockwise,
	DotOutline,
} from "@phosphor-icons/react";
import { createFileRoute } from "@tanstack/react-router";
import { useMediaQuery } from "@uidotdev/usehooks";
import clsx from "clsx";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useState } from "react";

/**
 * @remarks
 * See FeedChannelPage for why we don't use Route.useLoaderData() here
 */
const FeedItems = observer(
	({ tabState }: { tabState: FeedChannelTabState }) => {
		const feedChannel = tabState.feedChannel;
		const feedItemsStore = useFeedItemsStore();
		const feedItems = feedItemsStore.feedItemsForChannel(
			feedChannel.feed_channel_id,
		);
		const [searchQuery, setSearchQuery] = useState("");
		const appState = useAppContext();
		const searchResults = appState.localSearchStore.feedItems.search({
			query: searchQuery,
		});

		let renderedItems: React.ReactNode;

		if (feedItems === undefined) {
			renderedItems = (
				<div className="h-full w-full min-w-0 overflow-y-scroll">
					<div className="flex w-full items-center gap-2 border-transparent border-l-4 p-2">
						<Skeleton className="h-16 w-24 shrink-0 rounded-md bg-neutral-200" />
						<div className="flex flex-col gap-1">
							<Skeleton className="h-4 w-36 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-48 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-24 shrink-0 rounded-md bg-neutral-200" />
						</div>
					</div>
					<div className="flex w-full items-center gap-2 border-transparent border-l-4 p-2">
						<Skeleton className="h-16 w-24 shrink-0 rounded-md bg-neutral-200" />
						<div className="flex flex-col gap-1">
							<Skeleton className="h-4 w-36 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-48 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-24 shrink-0 rounded-md bg-neutral-200" />
						</div>
					</div>
					<div className="flex w-full items-center gap-2 border-transparent border-l-4 p-2">
						<Skeleton className="h-16 w-24 shrink-0 rounded-md bg-neutral-200" />
						<div className="flex flex-col gap-1">
							<Skeleton className="h-4 w-36 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-48 shrink-0 rounded-md bg-neutral-200" />
							<Skeleton className="h-4 w-24 shrink-0 rounded-md bg-neutral-200" />
						</div>
					</div>
				</div>
			);
		} else {
			renderedItems = (
				<div className="max-h-max min-h-0 w-full min-w-0 overflow-y-scroll">
					{feedItems
						.filter((x) => {
							if (searchQuery.trim() === "") {
								return true;
							}
							return searchResults.some(
								(result) => result.id === x.feed_item_id,
							);
						})
						.map((item) => (
							<button
								type="button"
								key={item.feed_item_id}
								className={clsx(
									"flex w-full items-center gap-2 border-l-4 p-2",
									"border-transparent hover:bg-blue-50",
								)}
								onClick={() => {
									tabState.tab.router.navigate({
										to: "/feeds/feed-item/$feed-item-id",
										params: {
											"feed-item-id": item.feed_item_id,
										},
									});
								}}
							>
								{item.og_image ? (
									<img
										src={item.og_image}
										alt={item.name}
										className="flex h-16 w-24 shrink-0 items-center justify-center rounded object-cover text-xs"
									/>
								) : (
									<div className="flex h-16 w-24 shrink-0 items-center justify-center rounded bg-neutral-300 text-neutral-500 text-xs" />
								)}
								<div className="grpw flex w-full min-w-0 flex-col items-start">
									<h2 className="flex w-full min-w-0 items-center gap-2 truncate text-left font-semibold text-neutral-800 text-sm">
										<span className="min-w-0 truncate">
											{cleanXmlText(item.name)}
										</span>
									</h2>
									<h3 className="w-full min-w-0 truncate text-left text-neutral-600 text-sm">
										{cleanXmlText(item.description ?? "")}
									</h3>
									<span className="flex w-full min-w-0 items-center gap-0.5 truncate text-left text-neutral-500 text-sm">
										<span>{cleanXmlText(item.authors.join(", ") ?? "")}</span>{" "}
										<DotOutline className="shrink-0" weight="fill" />
										<span>
											{dayjs(item.date_published ?? item.created_at).format(
												"MMM D, YYYY h:mm A",
											)}
										</span>
										{item.word_count && (
											<>
												<DotOutline weight="fill" className="shrink-0" />
												<span>
													{Intl.NumberFormat().format(item.word_count)} words
												</span>
											</>
										)}
									</span>
								</div>
							</button>
						))}
				</div>
			);
		}
		return (
			<>
				<div className="border-b bg-neutral-50 p-3">
					<Input
						placeholder={
							feedItems
								? `Search ${feedItems?.length ?? 0} articles...`
								: "Search articles..."
						}
						className="w-full bg-white p-2"
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
				</div>
				{renderedItems}
			</>
		);
	},
);

const FeedChannelHeader = observer(
	({ feedChannel }: { feedChannel: FeedChannelResource }) => {
		const [refreshing, setRefreshing] = useState(false);

		return (
			<div
				className={clsx(
					"flex h-14 w-full shrink-0 items-center justify-between gap-2 border-b bg-white/90 px-3 backdrop-blur",
				)}
			>
				{feedChannel ? (
					<>
						<HoverCard>
							<HoverCardTrigger className="flex items-center gap-2 truncate text-sm">
								{feedChannel.feed_link ? (
									<Favicon
										url={feedChannel.feed_link}
										alt={feedChannel.name}
										className="h-10 w-10 shrink-0 rounded-md bg-neutral-200"
									/>
								) : (
									<div className="h-10 w-10 shrink-0 rounded-md bg-neutral-200" />
								)}
								<div>
									<h1 className="min-w-0 truncate font-semibold text-neutral-700">
										{feedChannel.name}
									</h1>
									{feedChannel.feed_link && (
										<h2>
											<a
												href={feedChannel.feed_link}
												target="_blank"
												rel="noreferrer"
												className="flex items-center gap-1 truncate text-blue-500 hover:underline"
											>
												{cleanUrlForDisplay(feedChannel.feed_link)}
												<ArrowUpRight weight="bold" />
											</a>
										</h2>
									)}
								</div>
							</HoverCardTrigger>
							<HoverCardContent
								align="start"
								className="flex w-96 gap-3 text-sm"
							>
								{feedChannel.feed_link ? (
									<Favicon
										url={feedChannel.feed_link}
										alt={feedChannel.name}
										className="h-16 w-16 shrink-0 rounded-md bg-neutral-200"
									/>
								) : (
									<div className="h-16 w-16 shrink-0 rounded-md bg-neutral-200" />
								)}
								<div>
									<h1 className="min-w-0 truncate font-semibold text-neutral-700">
										{feedChannel.name}
									</h1>
									{feedChannel.feed_subtitle && (
										<h1 className="min-w-0 truncate font-semibold text-neutral-700">
											{feedChannel.feed_subtitle}
										</h1>
									)}
									{feedChannel.feed_link && (
										<h2>
											<a
												href={feedChannel.feed_link}
												target="_blank"
												rel="noreferrer"
												className="flex items-center truncate text-blue-500 hover:underline"
											>
												{cleanUrlForDisplay(feedChannel.feed_link)}
												<ArrowUpRight weight="bold" />
											</a>
										</h2>
									)}
									{feedChannel.feed_description && (
										<p className="mt-2 min-w-0 text-neutral-500">
											{feedChannel.feed_description}
										</p>
									)}
								</div>
							</HoverCardContent>
						</HoverCard>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="ghost"
									className={clsx("text-lg text-neutral-700")}
									disabled={refreshing}
									onClick={() => {
										setRefreshing(true);
										refetchFeedChannel({
											feed_channel_id: feedChannel.feed_channel_id,
										}).finally(() => {
											setRefreshing(false);
										});
									}}
								>
									<ArrowsClockwise
										weight="bold"
										className={clsx(refreshing && "animate-spin")}
									/>
								</Button>
							</TooltipTrigger>

							<TooltipContent>Refresh feed</TooltipContent>
						</Tooltip>
					</>
				) : (
					<>
						<Skeleton className="h-10 w-10 shrink-0 rounded-md bg-neutral-200" />
						<Skeleton className="h-6 w-48 shrink-0 rounded-md bg-neutral-200" />
					</>
				)}
			</div>
		);
	},
);

/**
 * @remarks
 * We don't grab the tab state from within the component itself because
 * we share this component with the feed channels page, and getting
 * Route.useLoaderData() would error, because the root route would not have
 * access to this file's Route.
 */
export const FeedChannelPage = observer(
	({ tabState }: { tabState: FeedChannelTabState }) => {
		const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

		const Layout = isSmallDevice ? (
			<div className="max-h-full min-h-0 w-full min-w-0 grow">
				<FeedChannelHeader feedChannel={tabState.feedChannel} />
				<FeedItems tabState={tabState} />
				<Drawer
					open={!!tabState.activeFeedItem}
					onOpenChange={(open) => {
						if (!open) {
							//
						}
					}}
				>
					<DrawerContent className="h-[90%]">
						<div
							className={
								"relative mt-4 flex h-full min-h-0 flex-col items-center"
							}
						>
							{tabState.activeFeedItem ? (
								<FeedItemViewer
									feedItemId={tabState.activeFeedItem.feed_item_id}
									highlightQuery={tabState.highlightQuery}
								/>
							) : (
								<div className="flex h-full items-center justify-center text-neutral-300">
									Open an article to view it here
								</div>
							)}
						</div>
					</DrawerContent>
				</Drawer>
			</div>
		) : (
			// Important: the min-w-0 and relative classes are necessary to make the
			// resizable panels work correctly with the toggleable sidebar
			<ResizablePanelGroup
				direction="horizontal"
				className="h-full max-h-full min-h-0 grow"
			>
				<ResizablePanel minSize={25} order={1} className="flex flex-col">
					<FeedChannelHeader feedChannel={tabState.feedChannel} />
					<FeedItems tabState={tabState} />
				</ResizablePanel>
				<ResizableHandle />
				<ResizablePanel minSize={25} order={2}>
					<div className="relative flex h-full max-h-full min-h-0 w-full min-w-0 flex-col items-center">
						{tabState.activeFeedItem ? (
							<FeedItemViewer
								feedItemId={tabState.activeFeedItem.feed_item_id}
								highlightQuery={tabState.highlightQuery}
							/>
						) : (
							<div className="flex h-full w-full grow items-center justify-center bg-neutral-50 text-neutral-300">
								Open an article to view it here
							</div>
						)}
					</div>
				</ResizablePanel>
			</ResizablePanelGroup>
		);

		return (
			<div className="flex h-full max-h-full min-h-0 grow flex-col">
				{Layout}
			</div>
		);
	},
);

export const Route = createFileRoute("/feeds/feed-channel/$feed-channel-id")({
	component: () => {
		const { tabState } = Route.useLoaderData();
		return <FeedChannelPage tabState={tabState} />;
	},
	loader: ({ params, context: { tab } }) => {
		const tabState = new FeedChannelTabState({
			tab,
			feedChannelId: params["feed-channel-id"] as FeedChannelId,
		});
		return {
			tabState,
		};
	},
});
