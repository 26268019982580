import type {
	HighlightQuery,
	PdfSearchParams,
} from "@/contexts/pdfviewer-context";
import type { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import type {
	FeedChannelId,
	FeedChannelResource,
	FeedItemId,
	FeedItemResource,
} from "@api/schemas";
import { Rss } from "@phosphor-icons/react";
import { makeAutoObservable } from "mobx";

/**
 * You only pass in either a feed channel or a feed item, not both, because we
 * can get the feed channel from the feed item, and this is how the paths work.
 */
type FeedChannelTabStateProps =
	| {
			tab: Tab;
			feedChannelId: FeedChannelId;
			activeFeedItemId?: never;
			highlightQuery?: never;
			searchParams: PdfSearchParams;
	  }
	| {
			tab: Tab;
			feedChannelId?: never;
			activeFeedItemId: FeedItemId;
			highlightQuery?: HighlightQuery;
			searchParams: PdfSearchParams;
	  };

export class FeedsHomeTabState implements BaseTabState {
	tab: Tab;

	constructor(tab: Tab) {
		this.tab = tab;
		this.tab.setState(this);
		makeAutoObservable(this);
	}

	get head() {
		return {
			icon: Rss,
			label: "Feeds",
		};
	}
}

export class FeedChannelTabState implements BaseTabState {
	tab: Tab;
	feedChannel: FeedChannelResource;
	activeFeedItem: FeedItemResource | null = null;
	highlightQuery: HighlightQuery | null = null;
	searchParams: PdfSearchParams;

	constructor(props: FeedChannelTabStateProps) {
		makeAutoObservable(this);
		this.tab = props.tab;
		this.tab.setState(this);
		this.searchParams = props.searchParams;
		this.highlightQuery = props.highlightQuery ?? null;

		if (props.feedChannelId) {
			const feedChannel =
				this.tab.tabStore.appState.workspace.feedChannels.getResourceById(
					props.feedChannelId,
				);
			if (feedChannel.isErr()) {
				throw new Error("Feed channel not found");
			}
			this.feedChannel = feedChannel.value;
		} else if (props.activeFeedItemId) {
			const feedItem =
				this.tab.tabStore.appState.workspace.feedItems.getResourceById(
					props.activeFeedItemId,
				);
			if (feedItem.isErr()) {
				throw new Error("Feed item not found");
			}
			this.activeFeedItem = feedItem.value;

			const feedChannel =
				this.tab.tabStore.appState.workspace.feedChannels.getResourceById(
					this.activeFeedItem.feed_channel_id,
				);
			if (feedChannel.isErr()) {
				throw new Error("Feed channel not found");
			}
			this.feedChannel = feedChannel.value;
		} else {
			throw new Error(
				"Either feedChannelId or activeFeedItemId must be provided",
			);
		}
		console.log("Built FeedChannelTabState", this);
	}

	get head() {
		if (this.activeFeedItem) {
			return {
				icon: Rss,
				label: this.activeFeedItem.name,
				resourceRef: {
					resource_id: this.activeFeedItem.feed_item_id,
					type: "feed-item" as const,
				},
			};
		}
		return {
			icon: Rss,
			label: this.feedChannel.name,
			resourceRef: {
				resource_id: this.feedChannel.feed_channel_id,
				type: "feed-channel" as const,
			},
		};
	}

	getFeedChannel(feedChannelId: FeedChannelId) {
		const feedChannel =
			this.tab.tabStore.appState.workspace.feedChannels.getResourceById(
				feedChannelId,
			);
		if (!feedChannel) {
			throw new Error("Feed channel not found");
		}
		return feedChannel;
	}

	getFeedItem(feedItemId: FeedItemId) {
		const feedItem =
			this.tab.tabStore.appState.workspace.feedItems.getResourceById(
				feedItemId,
			);
		if (!feedItem) {
			throw new Error("Feed item not found");
		}
		return feedItem;
	}

	setActiveFeedItem(feedItemId: FeedItemId) {
		const feedItem = this.getFeedItem(feedItemId);
		if (feedItem.isErr()) {
			return;
		}
		if (feedItem.value.feed_channel_id !== this.feedChannel.feed_channel_id) {
			const feedChannel = this.getFeedChannel(feedItem.value.feed_channel_id);
			if (feedChannel.isErr()) {
				return;
			}
			this.feedChannel = feedChannel.value;
		}
		this.activeFeedItem = feedItem.value;
	}
}
