import { ResourceAssistantsPresenceIndicator } from "@/components/layout/right-sidebar/assistant-presence";
import { PageTreeContextMenuWrapper } from "@/components/tree/context-menu";
import { DragOverlayComponent } from "@/components/tree/drag-overlay";
import type { PageTreeState } from "@/components/tree/tree-state";
import { type LinkableResourceRef, resourceRefToPath } from "@/lib/paths";
import { useGetIconAndLabel } from "@/plugins/object-link";
import type { PageResourceRef } from "@api/schemas";
import {
	DndContext,
	MouseSensor,
	TouchSensor,
	pointerWithin,
	useDraggable,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { createPortal } from "react-dom";

/**
 * Indentation for each depth level
 */
const DepthIndent = (props: { depth: number }) => {
	const { depth } = props;
	return (
		<>
			{(new Array(depth).fill(0) as number[]).map((_, i) => (
				// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
				<div key={i} className="ml-2 h-8 w-3 shrink-0 border-l" />
			))}
		</>
	);
};

/**
 * Computes the background color for a node, factoring in selection state and hover/droppable states
 */
function getNodeBgColor(
	isSelected: boolean,
	isMultiSelected: boolean,
	isHover: boolean,
	isOver?: boolean,
): string {
	// Priority: droppable "isOver" uses a different color
	if (isOver) {
		if (isSelected) {
			return "bg-blue-200";
		}
		return "bg-neutral-200";
	}

	if (isMultiSelected) {
		return isHover ? "bg-blue-100" : "bg-blue-100";
	}

	if (isSelected) {
		return isHover ? "bg-blue-100" : "bg-blue-100";
	}

	return isHover ? "bg-neutral-100" : "bg-white";
}

/**
 * Returns the selection status of a node.
 */
function getNodeSelectionStatus(
	node: LinkableResourceRef,
	treeState: PageTreeState,
) {
	const isSelected = treeState.selectedNodes.has(node.resource_id);
	const isMultiSelected = isSelected && treeState.selectedNodes.size > 1;
	const isRightClicked =
		treeState.rightClickedNode?.resource_id === node.resource_id;
	return { isSelected, isMultiSelected, isRightClicked };
}

/**
 * Node for resources.
 */
const ResourceNode = observer(
	(props: {
		resourceRef: Exclude<LinkableResourceRef, PageResourceRef>;
		treeState: PageTreeState;
		depth: number;
	}) => {
		const resourceId = props.resourceRef.resource_id;
		const { isSelected, isMultiSelected, isRightClicked } =
			getNodeSelectionStatus(props.resourceRef, props.treeState);
		const [isHover, setIsHover] = useState(false);

		const {
			setNodeRef: setDraggableNodeRef,
			listeners,
			attributes,
		} = useDraggable({
			id: resourceId,
			data: {
				node: props.resourceRef,
			},
			disabled: props.treeState.isDndEnabled,
		});

		const bgColor = getNodeBgColor(isSelected, isMultiSelected, isHover);
		const { Icon, defaultLabel } = useGetIconAndLabel(
			resourceRefToPath(props.resourceRef),
		);

		return (
			<div
				className={clsx(
					"flex h-8 items-center px-2",
					bgColor,
					props.treeState.draggingNodes.size > 0 && "cursor-default",
					isRightClicked &&
						"-outline-offset-1 outline outline-1 outline-blue-300",
				)}
				onClick={(e) => props.treeState.handleNodeClick(e, props.resourceRef)}
				onContextMenu={() => {
					props.treeState.setRightClickedNode(props.resourceRef);
				}}
				ref={setDraggableNodeRef}
				{...listeners}
				{...attributes}
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
			>
				<DepthIndent depth={props.depth} />
				<Icon className="h-4 w-4 shrink-0 text-neutral-700" />
				<h2
					className={clsx(
						"ml-2 w-full min-w-0 select-none truncate text-neutral-700 text-sm",
					)}
				>
					{defaultLabel}
				</h2>
				<ResourceAssistantsPresenceIndicator resourceRef={props.resourceRef} />
			</div>
		);
	},
);

/**
 * Node for pages.
 *
 * Pages are expandable and have children, so we handle them separately.
 */
const PageNode = observer(
	(props: {
		pageRef: PageResourceRef;
		treeState: PageTreeState;
		depth: number;
	}) => {
		const { isSelected, isMultiSelected, isRightClicked } =
			getNodeSelectionStatus(props.pageRef, props.treeState);
		const isExpanded = props.treeState.expandedNodes.has(
			props.pageRef.resource_id,
		);
		const { Icon, defaultLabel } = useGetIconAndLabel(
			resourceRefToPath(props.pageRef),
		);
		const children = props.treeState.getChildren(props.pageRef);

		// TODO(John): for some reason, hovering is flickering whenever it's a drop target
		// We'll get around this by combining it with isOver.
		const [isHover, setIsHover] = useState(false);

		const { setNodeRef: setDroppableNodeRef, isOver } = useDroppable({
			id: props.pageRef.resource_id,
			disabled: props.treeState.isDndEnabled,
			data: {
				node: props.pageRef,
			},
		});

		const {
			setNodeRef: setDraggableNodeRef,
			listeners,
			attributes,
		} = useDraggable({
			id: props.pageRef.resource_id,
			data: {
				node: props.pageRef,
			},
			disabled: props.treeState.isDndEnabled,
		});

		const bgColor = getNodeBgColor(
			isSelected,
			isMultiSelected,
			isHover,
			isOver,
		);

		return (
			<>
				<div
					className={clsx(
						"flex h-8 items-center px-2",
						bgColor,
						props.treeState.draggingNodes.size > 0 && "cursor-default",
						isRightClicked &&
							"-outline-offset-1 outline outline-1 outline-blue-300",
					)}
					onClick={(e) => props.treeState.handleNodeClick(e, props.pageRef)}
					onContextMenu={() => {
						props.treeState.setRightClickedNode(props.pageRef);
					}}
					ref={(el) => {
						setDraggableNodeRef(el);
						setDroppableNodeRef(el);
					}}
					{...listeners}
					{...attributes}
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
				>
					<DepthIndent depth={props.depth} />
					{isHover || isOver ? (
						<CaretRight
							weight="regular"
							className={clsx(
								"h-4 w-4 shrink-0 bg-white text-neutral-500 hover:bg-neutral-200 hover:text-neutral-950",
								isExpanded && "rotate-90",
							)}
							onClick={(e) => {
								props.treeState.toggleExpandedNode(props.pageRef);
								e.stopPropagation();
							}}
						/>
					) : (
						<Icon className="h-4 w-4 shrink-0 text-neutral-500" />
					)}
					<h2
						className={clsx(
							"ml-2 w-full min-w-0 select-none truncate text-neutral-800 text-sm",
						)}
					>
						{defaultLabel}
					</h2>
				</div>
				{isExpanded &&
					(children.length > 0 ? (
						children.map((child) => {
							return (
								<Node
									key={child.resource_id}
									node={child}
									treeState={props.treeState}
									depth={props.depth + 1}
								/>
							);
						})
					) : (
						<div className="flex h-8 items-center">
							<DepthIndent depth={props.depth + 1} />
							<span className="text-neutral-500 text-sm italic">No items</span>
						</div>
					))}
			</>
		);
	},
);

const Node = (props: {
	node: LinkableResourceRef;
	treeState: PageTreeState;
	depth: number;
}) => {
	if (props.node.type === "page") {
		return (
			<PageNode
				pageRef={props.node}
				treeState={props.treeState}
				depth={props.depth}
			/>
		);
	}
	return (
		<ResourceNode
			resourceRef={props.node}
			treeState={props.treeState}
			depth={props.depth}
		/>
	);
};

export const PageTree = observer(
	(props: {
		treeState: PageTreeState;
	}) => {
		const mouseSensor = useSensor(MouseSensor, {
			// Require the mouse to move by 10 pixels before activating
			activationConstraint: {
				distance: 10,
			},
		});
		const touchSensor = useSensor(TouchSensor, {
			// Press delay of 250ms, with tolerance of 5px of movement
			activationConstraint: {
				delay: 250,
				tolerance: 5,
			},
		});
		const sensors = useSensors(mouseSensor, touchSensor);
		return (
			<DndContext
				collisionDetection={pointerWithin}
				sensors={sensors}
				onDragStart={props.treeState.handleDragStart}
				onDragOver={props.treeState.handleDragOver}
				onDragEnd={props.treeState.handleDragEnd}
			>
				<PageTreeContextMenuWrapper treeState={props.treeState}>
					<Node
						node={props.treeState.rootNode}
						treeState={props.treeState}
						depth={0}
					/>
					{createPortal(
						<DragOverlayComponent treeState={props.treeState} />,
						document.body,
					)}
				</PageTreeContextMenuWrapper>
			</DndContext>
		);
	},
);
