import { getFieldIcon } from "@/components/table/field-type-indicators";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import {
	useFieldsStore,
	useTablesStore,
} from "@/contexts/app-context/db-store/db-store-hooks";
import { cn } from "@/lib/utils";
import { ViewPreview } from "@/pages/tabs/tables/-components/computed-table-creator/computed-view-table";
import { FilterBuilder } from "@/pages/tabs/tables/-components/computed-table-creator/filter-builder";
import { useViewCreatorState } from "@/pages/tabs/tables/-components/computed-table-creator/use-view-creator-state";
import { ViewCreatorProvider } from "@/pages/tabs/tables/-components/computed-table-creator/view-creator-provider";
import type { AggregateType, Field, FieldId, TableId } from "@api/schemas";
import { Plus, Spinner, X } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const FromTableSelect = observer(
	({ onSelect }: { onSelect: (tableId: TableId) => void }) => {
		const tablesStore = useTablesStore();
		if (tablesStore.map.keys.length === 0) {
			return null;
		}

		return (
			<Select onValueChange={(value) => onSelect(value as TableId)}>
				<SelectTrigger>
					<SelectValue placeholder="Select table..." />
				</SelectTrigger>
				<SelectContent>
					{tablesStore.sortedResources.map((table) => {
						return (
							<SelectItem key={table.table_id} value={table.table_id}>
								<span>{table.name}</span>
							</SelectItem>
						);
					})}
				</SelectContent>
			</Select>
		);
	},
);

const FieldToggle = (props: {
	field: Field;
	selected: boolean;
	onToggle: () => void;
}) => {
	const viewCreatorState = useViewCreatorState();
	if (!viewCreatorState) return null;

	const fieldsStore = useFieldsStore();
	const dataType = fieldsStore.getFieldDataType(props.field.field_id).match(
		(dataType) => dataType,
		() => {
			throw new Error("Field not found");
		},
	);
	// @ts-expect-error TODO(John): fix
	const Icon = getFieldIcon({
		fieldType: props.field.type,
		dataType: dataType,
	});

	return (
		<button
			type="button"
			className="flex cursor-pointer items-center gap-2 rounded px-2 py-1 hover:bg-neutral-100"
			onClick={props.onToggle}
		>
			<Checkbox
				className="justify-self-center"
				checked={props.selected}
				// Prevent checkbox click from triggering parent div click
				onClick={(e) => e.stopPropagation()}
			/>
			<span className={cn("flex items-center gap-2 text-neutral-700 text-sm")}>
				<Icon />
				<span>{props.field.name}</span>
			</span>
		</button>
	);
};

const SelectFieldsCheckboxes = observer(() => {
	const viewCreatorState = useViewCreatorState();
	if (!viewCreatorState) return null;

	const fields = Array.from(viewCreatorState.fromTableFields?.values() ?? []);

	if (!fields) return null;

	return (
		<div className="flex flex-col">
			{fields.map(([field]) => (
				<FieldToggle
					key={field.field_id}
					field={field}
					selected={viewCreatorState.selectedFields.has(field.field_id)}
					onToggle={() => viewCreatorState.toggleSelectedField(field.field_id)}
				/>
			))}
		</div>
	);
});

const AGGREGATE_TYPE_LABELS: Record<AggregateType, string> = {
	count: "Count",
	sum: "Sum",
	avg: "Average",
	min: "Minimum",
	max: "Maximum",
	array_agg: "Collect",
};

const GroupByFields = observer(() => {
	const viewCreatorState = useViewCreatorState();
	if (!viewCreatorState) return null;

	const fields = Array.from(viewCreatorState.fromTableFields?.values() ?? []);
	const fieldsForAggregation = fields.filter(
		([field]) => !viewCreatorState.groupingFields.has(field.field_id),
	);

	if (!fields) return null;

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col">
				{fields.map(([field]) => (
					<FieldToggle
						key={field.field_id}
						field={field}
						selected={viewCreatorState.groupingFields.has(field.field_id)}
						onToggle={() =>
							viewCreatorState.toggleGroupingField(field.field_id)
						}
					/>
				))}
			</div>

			<div className="flex flex-col gap-2">
				<h2 className="font-semibold text-neutral-950 text-sm">Aggregations</h2>
				<div className="flex flex-col gap-2 border p-2">
					{viewCreatorState.aggregateFields.map((aggregateField, index) => (
						<div
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							key={index}
							className="flex items-center gap-2"
						>
							{/* Field to aggregate */}
							<Select
								value={aggregateField.field_id}
								onValueChange={(value) => {
									viewCreatorState.updateAggregateField(index, {
										...aggregateField,
										field_id: value as FieldId,
									});
								}}
							>
								<SelectTrigger className="w-[180px]">
									<SelectValue placeholder="Select field..." />
								</SelectTrigger>
								<SelectContent>
									{fieldsForAggregation.map(([field]) => (
										<SelectItem key={field.field_id} value={field.field_id}>
											{field.name}
										</SelectItem>
									))}
								</SelectContent>
							</Select>

							{/* Aggregate function */}
							<Select
								value={aggregateField.aggregate_type}
								onValueChange={(value) => {
									viewCreatorState.updateAggregateField(index, {
										...aggregateField,
										aggregate_type: value as AggregateType,
									});
								}}
							>
								<SelectTrigger className="w-[120px]">
									<SelectValue placeholder="Function..." />
								</SelectTrigger>
								<SelectContent>
									{Object.entries(AGGREGATE_TYPE_LABELS).map(([key, label]) => (
										<SelectItem key={key} value={key}>
											{label}
										</SelectItem>
									))}
								</SelectContent>
							</Select>

							{/* Remove button */}
							<Button
								variant="ghost"
								className="h-6 w-6 p-1"
								onClick={() => viewCreatorState.removeAggregateField(index)}
							>
								<X />
							</Button>
						</div>
					))}
					<Button
						variant="ghost"
						size="sm"
						className="justify-start gap-1 px-1"
						onClick={() =>
							viewCreatorState.addAggregateField({
								field_id: fieldsForAggregation[0][0].field_id,
								aggregate_type: "count",
							})
						}
					>
						<Plus />
						Add Aggregation
					</Button>
				</div>
			</div>
		</div>
	);
});

const CreateComputedTableDialogContent = observer(
	({
		setFromTableId,
		setOpen,
	}: {
		setFromTableId: (tableId: TableId) => void;
		setOpen: (open: boolean) => void;
	}) => {
		const viewCreatorState = useViewCreatorState();
		const [isLoadingNewTable, setIsLoadingNewTable] = useState(false);

		async function createButtonHandler() {
			if (!viewCreatorState) return;

			setIsLoadingNewTable(true);
			try {
				await viewCreatorState.createComputedTable();
				setOpen(false);
			} catch (e) {
				console.error(e);
			} finally {
				setIsLoadingNewTable(false);
			}
		}

		return (
			<div className="absolute inset-0 flex flex-col">
				{/* Header */}
				<section className="flex items-center justify-between border-neutral-200 border-b p-4">
					<h1 className="font-semibold text-base">Create new computed table</h1>
					<div className="flex gap-2">
						<Button variant={"outline"} onClick={() => setOpen(false)}>
							Cancel
						</Button>
						<Button
							onClick={createButtonHandler}
							disabled={isLoadingNewTable || !viewCreatorState?.fromTableId}
						>
							{isLoadingNewTable ? (
								<Spinner className="animate-spin" />
							) : (
								"Create"
							)}
						</Button>
					</div>
				</section>
				{/* Main */}
				<section className="flex min-h-0 min-w-0 grow">
					{/* Configuration Sidebar */}
					<div className="flex h-full w-[400px] flex-col overflow-y-auto border-neutral-200 border-r p-4">
						<div className="flex flex-col gap-2">
							<div className="flex flex-col gap-2">
								<h2 className="font-semibold text-neutral-950 text-sm">
									Source
								</h2>
								<FromTableSelect
									onSelect={(tableId) => {
										setFromTableId(tableId);
									}}
								/>
							</div>

							{viewCreatorState?.fromTableId && (
								<>
									<hr className="my-2" />

									<div className="flex flex-col gap-2">
										<h2 className="font-semibold text-neutral-950 text-sm">
											Filter
										</h2>
										<FilterBuilder />
									</div>

									<hr className="my-2" />

									<div className="flex flex-col gap-2">
										<Select
											value={viewCreatorState.viewType}
											onValueChange={(value) =>
												viewCreatorState.setViewType(
													value as "select" | "group_by",
												)
											}
										>
											<SelectTrigger className="mb-2 w-full text-left font-semibold">
												<SelectValue placeholder="View type..." />
											</SelectTrigger>
											<SelectContent>
												<SelectItem value="select">Select</SelectItem>
												<SelectItem value="group_by">Group By</SelectItem>
											</SelectContent>
										</Select>
										{viewCreatorState.viewType === "select" ? (
											<SelectFieldsCheckboxes />
										) : (
											<GroupByFields />
										)}
									</div>
								</>
							)}
						</div>
					</div>

					{/* Query Result Preview */}
					<div className="flex min-h-0 min-w-0 grow bg-neutral-50 p-4">
						{viewCreatorState?.fromTableId ? (
							viewCreatorState.queryResult ? (
								<ViewPreview />
							) : (
								<div className="flex h-full w-full flex-col items-center justify-center">
									<Spinner className="h-16 w-16 animate-spin text-neutral-300" />
								</div>
							)
						) : (
							<div className="flex h-full w-full flex-col items-center justify-center text-neutral-500">
								Select a source table to begin
							</div>
						)}
					</div>
				</section>
			</div>
		);
	},
);

/**
 * NOTE(John): this was giving me a ton of trouble to make an actual dialog, so
 * now it behaves more like a page in the tab
 */
export const CreateComputedTableDialog = observer(
	({
		setOpen,
	}: {
		setOpen: (open: boolean) => void;
	}) => {
		const [fromTableId, setFromTableId] = useState<TableId | null>(null);
		return (
			<ViewCreatorProvider fromTableId={fromTableId}>
				<CreateComputedTableDialogContent
					setFromTableId={setFromTableId}
					setOpen={setOpen}
				/>
			</ViewCreatorProvider>
		);
	},
);
