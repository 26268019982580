import { StreamingEditor } from "@/components/editor";
import { Skeleton } from "@/components/ui/skeleton";
import {
	type WebSearchResultLoadingState,
	WebSearchTabState,
} from "@/contexts/web/tab-state";
import type { WebSearchId, WebsiteResult } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { formatDistanceToNow } from "date-fns";
import DOMPurify from "dompurify";
import { observer } from "mobx-react-lite";
import { useId, useMemo, useState } from "react";

const SafeHtmlContent = ({ htmlString }: { htmlString: string }) => {
	const sanitizedHtml = DOMPurify.sanitize(htmlString);
	// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
	return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

const WebsiteResultComponent = observer(
	({ result }: { result: WebsiteResult }) => {
		return (
			<div className="flex w-full gap-4">
				<div className="flex min-w-0 grow flex-col gap-1">
					<div className="flex w-full items-center gap-2">
						<div className="flex-shrink-0 overflow-clip rounded-full border border-neutral-200 bg-neutral-100 p-1">
							<img
								className="text-neutral-500 text-xs"
								src={`https://www.google.com/s2/favicons?domain=${result.url}`}
								alt="favicon"
								width={12}
								height={12}
							/>
						</div>
						<div className="flex min-w-0 grow flex-col">
							<a
								className="text-neutral-500 text-sm"
								href={result.url}
								target="_blank"
								rel="noreferrer"
							>
								{result.site_name}
							</a>
							<a
								className="truncate text-wrap break-words text-neutral-500 text-xs"
								href={result.url}
								target="_blank"
								rel="noreferrer"
							>
								<SafeHtmlContent htmlString={result.display_url} />
							</a>
						</div>
					</div>

					<a
						className="text-base text-blue-600 visited:text-purple-600"
						href={result.url}
						target="_blank"
						rel="noreferrer"
					>
						<SafeHtmlContent htmlString={result.title} />
					</a>
					<div className="text-neutral-500 text-sm">
						{/* {result.snippet} */}
						<SafeHtmlContent htmlString={result.snippet} />
					</div>
				</div>
				{result.primary_image_url && (
					<div
						className="flex-shrink-0 overflow-clip rounded-md"
						style={{
							width: 80,
							height: 80,
						}}
					>
						<img
							src={result.primary_image_url}
							alt="Bing thumbnail"
							width={80}
							height={80}
						/>
					</div>
				)}
			</div>
		);
	},
);

interface WebSearchAnswerProps {
	synthesis: string | null;
	loadingState: WebSearchResultLoadingState;
}

const WebSearchAnswer = observer(
	({ synthesis, loadingState }: WebSearchAnswerProps) => {
		const [showMore, setShowMore] = useState(false);

		const editorOptions = useMemo(() => {
			return {
				content: synthesis,
				editable: false,
				editorProps: {},
			};
		}, [synthesis]);

		return (
			<div className="relative flex flex-col">
				<div
					className={clsx(
						"flex min-h-48 w-full shrink-0 flex-col gap-3 overflow-y-hidden bg-neutral-50 p-4",
						showMore ? "h-auto" : "h-48",
					)}
				>
					<div className="flex flex-col gap-1">
						{loadingState === "loading" && (
							<h3 className="text-neutral-500 text-sm">Conducting search...</h3>
						)}
						{loadingState === "synthesizing" && (
							<h3 className="text-neutral-500 text-sm">
								Forming a response...
							</h3>
						)}
						{loadingState === "synthesizing" || loadingState === "completed" ? (
							<StreamingEditor
								className="w-full max-w-full"
								options={editorOptions}
							/>
						) : (
							<></>
						)}
					</div>
				</div>
				{/* {!showMore && (
					<div className="pointer-events-none absolute inset-x-0 bottom-5 h-4 bg-gradient-to-t from-neutral-200 to-transparent" />
				)} */}
				<button
					className="h-5 w-full border-neutral-100 border-t bg-neutral-50 text-center text-neutral-500 text-xs"
					type="button"
					onClick={() => setShowMore(!showMore)}
				>
					{showMore ? "Show less" : "Show more"}
				</button>
			</div>
		);
	},
);

const WebSearchResults = observer(() => {
	const containerId = useId();
	const { tabState } = Route.useLoaderData();
	const webSearchResult = tabState.fullResult;
	const loadingState = tabState.loadingState;
	if (loadingState === null) {
		throw new Error(
			"No loading state. Should be impossible, since this route is only rendered after the web search result is set in the loader.",
		);
	}

	let WebSearchBody = null;
	if (!webSearchResult || loadingState === "loading") {
		WebSearchBody = (
			<div className="relative flex grow flex-col overflow-y-auto bg-white">
				{[...Array(1)].map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="flex w-full flex-col space-y-2 p-6"
					>
						<div className="flex space-x-2">
							<Skeleton className="h-8 w-6" />
							<div className="flex min-w-0 grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full max-w-48" />
							</div>
						</div>
						<div className="flex w-full flex-col">
							<div className="flex grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
							</div>
						</div>
					</div>
				))}
			</div>
		);
	} else if (webSearchResult.search_results.length === 0) {
		WebSearchBody = (
			<div className="flex h-full w-full items-center justify-center">
				<h1 className="text-neutral-500">No results found</h1>
			</div>
		);
	} else {
		WebSearchBody = (
			<div className="flex grow flex-col gap-4 p-4">
				<div className=" text-neutral-500 text-xs">
					searched{" "}
					{formatDistanceToNow(
						new Date(tabState.searchResource?.created_at ?? ""),
					)}{" "}
					ago
				</div>
				{webSearchResult.search_results.map((result) => {
					return <WebsiteResultComponent key={result.url} result={result} />;
				})}
			</div>
		);
	}
	return (
		<div
			// the key forces a rerender and a scroll reset when we switch between grouped and ungrouped results
			// or when the results change as indicated by result_id
			key={"web-search-results"}
			className="relative flex grow flex-col overflow-y-auto bg-white"
			id={containerId}
		>
			<WebSearchAnswer
				synthesis={webSearchResult?.synthesis ?? null}
				loadingState={loadingState}
			/>
			{WebSearchBody}
		</div>
	);
});

export const Route = createFileRoute("/web-search/search/$web-search-id")({
	component: WebSearchResults,
	loader: ({ params, context: { tab } }) => {
		if (tab.state instanceof WebSearchTabState) {
			tab.state.loadWebSearchResult(params["web-search-id"] as WebSearchId);
			return {
				tabState: tab.state,
			};
		}
		return {
			tabState: new WebSearchTabState(tab, null),
		};
	},
});
