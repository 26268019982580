import { getFieldIcon } from "@/components/table/field-type-indicators";
import { BooleanRenderer } from "@/components/table/scalar-renderers/boolean-renderer";
import { DatetimeRenderer } from "@/components/table/scalar-renderers/datetime-renderer";
import { NumberRenderer } from "@/components/table/scalar-renderers/number-renderer";
import { SelectRenderer } from "@/components/table/scalar-renderers/select-renderer";
import { Button } from "@/components/ui/button";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { useViewCreatorState } from "@/pages/tabs/tables/-components/computed-table-creator/use-view-creator-state";
import { getDataTypeOperators } from "@/pages/tabs/tables/-components/computed-table-creator/view-creator-state";
import {
	type AndFilterGroup,
	type DataType,
	type Field,
	type FieldId,
	type Filter,
	type FilterExpression,
	type OrFilterGroup,
	ScalarType,
	type SelectField,
	type SelectOptionLabel,
} from "@api/schemas";
import { X } from "@phosphor-icons/react";
import { PlusIcon } from "@radix-ui/react-icons";
import debounce from "lodash.debounce";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useMemo, useState } from "react";

const OPERATOR_TEXT_MAP: Record<Filter["type"], string> = {
	text_equals: "is",
	text_not_equals: "is not",
	text_contains: "contains",
	number_equals: "is",
	number_not_equals: "is not",
	datetime_equals: "is",
	datetime_not_equals: "is not",
	boolean_equals: "is",
	boolean_not_equals: "is not",
	array_contains_array: "contains",
};

const FieldOption = ({
	field,
	dataType,
}: { field: Field; dataType?: DataType }) => (
	<div className="flex items-center justify-start gap-2">
		{/* @ts-expect-error TODO(John): fix */}
		{getFieldIcon({
			fieldType: field.type,
			dataType,
		})({
			className: "w-4 h-4",
		})}
		<span>{field.name}</span>
	</div>
);

/**
 * Text input that debounces updates to the parent component.
 *
 * We use this instead of the editor because we don't want complex content in
 * our text filter...
 */
const TextInput = observer(
	(props: {
		value: string | null;
		onUpdate: (value: string | null) => void;
	}) => {
		const [localValue, setLocalValue] = useState<string>(props.value ?? "");

		// Update local value when prop changes
		useEffect(() => {
			setLocalValue(props.value ?? "");
		}, [props.value]);

		// Debounced update function
		const debouncedUpdate = useMemo(
			() =>
				debounce((value: string) => {
					props.onUpdate(value === "" ? null : value);
				}, 1000),
			[props.onUpdate],
		);

		// Cleanup debounce on unmount
		useEffect(() => {
			return () => {
				debouncedUpdate.cancel();
			};
		}, [debouncedUpdate]);

		return (
			<input
				className="h-full w-full px-1 text-sm"
				type="text"
				value={localValue}
				onChange={(e) => {
					setLocalValue(e.target.value);
					debouncedUpdate(e.target.value);
				}}
			/>
		);
	},
);

const FilterComponent = observer(
	(props: { comparison: Filter; onRemove?: () => void }) => {
		const viewCreatorState = useViewCreatorState();
		if (!viewCreatorState) return null;
		const fromTableFields = viewCreatorState.fromTableFields;
		// biome-ignore lint/style/noNonNullAssertion: TODO(John): fix!
		const [field, dataType] = fromTableFields.get(props.comparison.field_id)!;
		if (!field) {
			throw new Error(`Field ${props.comparison.field_id} not found`);
		}
		const value = props.comparison.value;
		const operators = getDataTypeOperators(dataType);

		function handleFieldChange(newFieldId: FieldId) {
			runInAction(() => {
				// TODO(John): this is broken lol
				props.comparison.field_id = newFieldId;
				// Set operator to default
				props.comparison.type = operators[0];
				props.comparison.value = null;
			});
		}

		function handleOperatorChange(newOperator: Filter["type"]) {
			runInAction(() => {
				props.comparison.type = newOperator;
			});
		}

		function handleValueChange(newValue: Filter["value"]) {
			console.log("FilterComponent", props.comparison);
			console.log("handleValueChange", newValue);
			runInAction(() => {
				props.comparison.value = newValue;
			});
		}

		return (
			<div className="flex h-full w-full items-center gap-2">
				{/* Select field to apply operator to */}
				<Select
					value={props.comparison.field_id}
					onValueChange={handleFieldChange}
				>
					<SelectTrigger className="flex w-32">
						<SelectValue>
							<FieldOption field={field} dataType={dataType} />
						</SelectValue>
					</SelectTrigger>
					<SelectContent>
						{Array.from(fromTableFields.values()).map(
							([tableField, dataType]) => (
								<SelectItem
									key={tableField.field_id}
									value={tableField.field_id}
								>
									<FieldOption field={tableField} dataType={dataType} />
								</SelectItem>
							),
						)}
					</SelectContent>
				</Select>
				{/* Select operator */}
				<Select
					value={props.comparison.type}
					onValueChange={handleOperatorChange}
				>
					<SelectTrigger className="w-fit gap-2">
						<SelectValue placeholder="Select comparison..." />
					</SelectTrigger>
					<SelectContent>
						{operators.length > 0 ? (
							operators.map((operator) => (
								<SelectItem key={operator} value={operator} className="">
									{OPERATOR_TEXT_MAP[operator]}
								</SelectItem>
							))
						) : (
							// TODO(John): better informational message.
							<div>
								No operators available for this field, which has a nested array
								data type.
							</div>
						)}
					</SelectContent>
				</Select>
				{/* Value input */}
				<div className="flex h-full grow border bg-neutral-50">
					{(() => {
						switch (dataType?.scalar_type) {
							case ScalarType.boolean:
								return (
									<BooleanRenderer
										value={value as boolean}
										editable={true}
										onUpdate={handleValueChange}
									/>
								);
							case ScalarType.real:
								return (
									<NumberRenderer
										value={value as number}
										editable={true}
										onUpdate={handleValueChange}
									/>
								);
							case ScalarType.text:
								return (
									<TextInput
										value={value as string | null}
										onUpdate={handleValueChange}
									/>
								);
							case ScalarType.timestamptz:
								return (
									<DatetimeRenderer
										value={value as string}
										editable={true}
										onUpdate={handleValueChange}
									/>
								);
							case ScalarType.select_option:
								return (
									<SelectRenderer
										value={value as SelectOptionLabel}
										editable={true}
										options={(field as SelectField).properties.options}
										onUpdate={handleValueChange}
									/>
								);
							case ScalarType.record_link:
								// Not handled yet
								return null;
							default: {
								const _exhaustiveCheck: never = dataType?.scalar_type;
								return _exhaustiveCheck;
							}
						}
					})()}
				</div>
				{props.onRemove && (
					<Button
						variant="ghost"
						className="h-6 w-6 p-1"
						onClick={props.onRemove}
					>
						<X />
					</Button>
				)}
			</div>
		);
	},
);

const FilterPrefix = observer(
	(props: {
		index: number;
		groupType: "and" | "or";
		onGroupTypeChange: (newType: "and" | "or") => void;
	}) => {
		if (props.index === 0) {
			return (
				<div className="flex h-8 w-16 items-center px-2 text-sm">Where</div>
			);
		}
		if (props.index === 1) {
			return (
				<Select
					value={props.groupType}
					onValueChange={(value: "and" | "or") =>
						props.onGroupTypeChange(value)
					}
				>
					<SelectTrigger className="w-16 px-2">
						<SelectValue>
							{props.groupType === "and" ? "And" : "Or"}
						</SelectValue>
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="and">
							<div className="flex flex-col gap-1">
								<div>And</div>
								<div className="text-neutral-500 text-xs">
									All conditions must match
								</div>
							</div>
						</SelectItem>
						<SelectItem value="or">
							<div className="flex flex-col gap-1">
								<div>Or</div>
								<div className="text-neutral-500 text-xs">
									Any condition may match
								</div>
							</div>
						</SelectItem>
					</SelectContent>
				</Select>
			);
		}
		return (
			<div className="flex h-8 w-16 items-center px-2.5 text-sm">
				{props.groupType === "and" ? "And" : "Or"}
			</div>
		);
	},
);

const FilterList = observer(
	(props: {
		expressions: FilterExpression[];
		groupType: "and" | "or";
		onGroupTypeChange: (newType: "and" | "or") => void;
	}) => {
		const viewCreatorState = useViewCreatorState();
		if (!viewCreatorState) return null;

		return (
			<div className="grid grid-cols-[auto_1fr] items-center gap-x-2 gap-y-2">
				{props.expressions.map((expression, index) => {
					const prefix = (
						// biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
						<FilterPrefix
							index={index}
							groupType={props.groupType}
							onGroupTypeChange={props.onGroupTypeChange}
						/>
					);

					switch (expression.type) {
						case "and":
						case "or":
							return (
								// biome-ignore lint/suspicious/noArrayIndexKey: handled by parent component
								<Fragment key={index}>
									{prefix}
									<FilterGroupComponent expressionGroup={expression} />
								</Fragment>
							);
						default:
							return (
								// biome-ignore lint/suspicious/noArrayIndexKey: handled by parent component
								<Fragment key={index}>
									{prefix}
									<FilterComponent
										comparison={expression}
										onRemove={() => {
											runInAction(() => {
												props.expressions.splice(index, 1);
											});
										}}
									/>
								</Fragment>
							);
					}
				})}
			</div>
		);
	},
);

const FilterGroupComponent = observer(
	(props: { expressionGroup: AndFilterGroup | OrFilterGroup }) => {
		const viewCreatorState = useViewCreatorState();
		if (!viewCreatorState) return null;
		const handleGroupTypeChange = (newType: "and" | "or") => {
			runInAction(() => {
				props.expressionGroup.type = newType;
			});
		};

		return (
			<div className="flex w-full flex-col gap-1 border p-2">
				<FilterList
					expressions={props.expressionGroup.expressions}
					groupType={props.expressionGroup.type}
					onGroupTypeChange={handleGroupTypeChange}
				/>
				<Button
					variant="ghost"
					className="justify-start gap-1 px-1"
					size="sm"
					onClick={() => {
						runInAction(() => {
							props.expressionGroup.expressions.push(
								viewCreatorState.defaultNewFilter,
							);
						});
					}}
				>
					<PlusIcon />
					<span>Add Filter</span>
				</Button>
			</div>
		);
	},
);

export const FilterBuilder = observer(() => {
	const viewCreatorState = useViewCreatorState();
	if (!viewCreatorState) return null;
	return <FilterGroupComponent expressionGroup={viewCreatorState.where} />;
});
