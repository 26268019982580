import { createFileRoute, redirect } from "@tanstack/react-router";

/**
 * Redirects to the home page.
 */
export const Route = createFileRoute("/pages/home")({
	loader: ({ context: { tab } }) => {
		tab.tabStore.appState.workspace.pages.homePageId.match(
			(homePageId) => {
				throw redirect({
					to: "/pages/$page-id",
					params: { "page-id": homePageId },
				});
			},
			(error) => {
				throw error;
			},
		);
	},
});
