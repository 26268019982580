import { PDFViewer } from "@/components/pdf/pdfviewer";
import { API_ENDPOINT_HTTP } from "@/config";
import { pdfSearchSchema } from "@/contexts/pdfviewer-context";
import { UploadTabState } from "@/contexts/uploads/tab-state";
import { getUploadPdf, getUploadToc } from "@api/fastAPI";
import { PageResolution, type UploadId } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-adapter";
import { observer } from "mobx-react-lite";

export const UploadPage = observer(() => {
	const { tabState } = Route.useLoaderData();

	return (
		<PDFViewer
			resourceId={tabState.upload.upload_id}
			searchParams={tabState.searchParams}
			loader={async () => {
				const resp = await getUploadPdf(tabState.upload.upload_id, {
					responseType: "arraybuffer",
				});
				return new Uint8Array(resp.data as ArrayBuffer);
			}}
			tocLoader={async () => {
				const resp = await getUploadToc(tabState.upload.upload_id);
				return resp.data;
			}}
			headerMetadata={{
				title: tabState.upload.name,
				authors: tabState.upload.authors,
				description: tabState.upload.subtitle,
				url: tabState.upload.original_url,
			}}
			thumbnailUrl={(pageIndex: number) =>
				`${API_ENDPOINT_HTTP}/uploads/page_image/${tabState.upload.upload_id}/${pageIndex}/${PageResolution.thumbnail}.jpg`
			}
		/>
	);
});

export const Route = createFileRoute("/uploads/upload/$upload-id")({
	component: UploadPage,
	validateSearch: zodValidator(pdfSearchSchema),
	loaderDeps: ({ search }) => ({ search }),
	loader: ({ params, context: { tab }, deps }) => {
		const tabState = new UploadTabState(
			tab,
			params["upload-id"] as UploadId,
			deps.search,
		);
		return { tabState };
	},
});
