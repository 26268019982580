/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * Enum values represent how these types are referenced in the database.
 */
export type ScalarType = typeof ScalarType[keyof typeof ScalarType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScalarType = {
  text: 'text',
  select_option: 'select_option',
  real: 'real',
  timestamptz: 'timestamptz',
  boolean: 'boolean',
  record_link: 'record_link',
} as const;
