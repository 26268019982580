import type { SearchActionArgs } from "@/contexts/search/stores/search-store";
import type { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { createSearchId } from "@/lib/id-generators";
import {
	type Resource,
	type ResourceResult,
	type SearchFull,
	type SearchId,
	SearchMode,
	type SearchResource,
} from "@api/schemas";
import { Circuitry, Key, MagnifyingGlass } from "@phosphor-icons/react";
import * as Sentry from "@sentry/react";
import { makeAutoObservable } from "mobx";
import type { Result } from "neverthrow";

export const SearchModeMeta: Record<
	SearchMode,
	{
		title: string;
		description: string;
		icon: React.ReactNode;
	}
> = {
	[SearchMode.keyword]: {
		title: "Keyword",
		description: "Matches exact quotes and phrases",
		icon: <Key className=" text-neutral-500" weight="duotone" />,
	},
	[SearchMode.hybrid]: {
		title: "Neural",
		description: "Finds content based on meaning",
		icon: <Circuitry className="text-neutral-500" weight="duotone" />,
	},
};

// TODO(John): steal from server
export interface EnrichedResourceResult {
	resource: Resource;
	result: ResourceResult;
}

export enum SearchResultLoadingState {
	Loading = "loading",
	Synthesizing = "synthesizing",
	Completed = "completed",
	Errored = "errored",
}

export class SearchTabState implements BaseTabState {
	tab: Tab;

	// Search config state in the UI, which the user can edit before initiating
	// a search
	config: Omit<SearchActionArgs, "search_id" | "requested_at"> = {
		query: "",
		max_results: null,
		search_mode: SearchMode.hybrid,
	};

	// The search result currently being displayed, if the path specifies a
	// searchId.
	searchResource: SearchResource | null = null;

	get fullResult(): SearchFull | null {
		if (!this.searchResource) {
			return null;
		}
		return (
			this.tab.tabStore.appState.searchStore.getFullResult(
				this.searchResource.search_id,
			) ?? null
		);
	}

	// UI components
	showCommandList = false;
	searchInputElement: HTMLInputElement | null = null;

	constructor(tab: Tab, searchId: SearchId | null) {
		this.tab = tab;
		this.tab.setState(this);

		if (searchId) {
			this.loadSearchResult(searchId);
		}
		makeAutoObservable(this);
	}

	get head() {
		return {
			icon: MagnifyingGlass,
			label: "Search",
			resourceRef: this.searchResource
				? {
						type: "search-result" as const,
						resource_id: this.searchResource.search_id,
					}
				: undefined,
		};
	}

	setShowCommandList(show: boolean) {
		this.showCommandList = show;
	}

	setQuery(query: string) {
		this.config.query = query;
	}

	loadSearchResult(searchId: SearchId) {
		const searchResult =
			this.tab.tabStore.appState.searchStore.getResourceById(searchId);
		if (searchResult.isErr()) {
			throw new Error("Search result not found");
		}
		this.searchResource = searchResult.value;
		this.config.query = searchResult.value.query;
	}

	/**
	 * Initiates a search request by requesting the store, then navigates to
	 * the saved search's page.
	 */
	handleSearch() {
		const newSearchRequest: SearchActionArgs = {
			...this.config,
			search_id: createSearchId(),
			requested_at: new Date().toISOString(),
		};
		this.tab.tabStore.appState.searchStore.initiateSearch(newSearchRequest);
		// TODO(John): add id
		this.tab.router.navigate({
			to: "/search/result/$search-id",
			params: {
				"search-id": newSearchRequest.search_id,
			},
		});
	}

	/**
	 * For each of the loaded search result's ResourceResults, provide the
	 * actual resource specified.
	 */
	get enrichedResults(): EnrichedResourceResult[] {
		if (this.fullResult === null) {
			return [];
		}
		const results = this.fullResult.aggregated_results;
		const enrichedResults: EnrichedResourceResult[] = [];
		for (const result of results) {
			let resource: Result<Resource, Error>;
			switch (result.resource_ref.type) {
				case "feed-item":
					resource =
						this.tab.tabStore.appState.workspace.feedItems.getResourceById(
							result.resource_ref.resource_id,
						);
					break;
				case "upload":
					resource =
						this.tab.tabStore.appState.workspace.uploads.getResourceById(
							result.resource_ref.resource_id,
						);
					break;
				case "webpage":
					resource =
						this.tab.tabStore.appState.workspace.webpages.getResourceById(
							result.resource_ref.resource_id,
						);
					break;
				default: {
					const _exhaustiveCheck: never = result.resource_ref;
					throw new Error(`Unhandled resource type: ${_exhaustiveCheck}`);
				}
			}
			if (resource.isErr()) {
				Sentry.captureException(resource.error);
				continue;
			}
			enrichedResults.push({
				result,
				resource: resource.value,
			});
		}
		return enrichedResults;
	}

	/**
	 * Returns the state of the loaded search result.
	 *
	 * If there's no loaded search result, returns null.
	 */
	get loadingState(): SearchResultLoadingState | null {
		if (!this.searchResource) {
			return null;
		}
		if (this.fullResult === null) {
			return SearchResultLoadingState.Loading;
		}
		if (!this.fullResult.synthesis) {
			return SearchResultLoadingState.Synthesizing;
		}
		return SearchResultLoadingState.Completed;
	}
}
