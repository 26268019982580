/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './tabs/__root'
import { Route as WebSearchRouteImport } from './tabs/web-search/route'
import { Route as SearchRouteImport } from './tabs/search/route'
import { Route as WebIndexImport } from './tabs/web/index'
import { Route as UploadsIndexImport } from './tabs/uploads/index'
import { Route as TablesIndexImport } from './tabs/tables/index'
import { Route as PagesIndexImport } from './tabs/pages/index'
import { Route as MessageIndexImport } from './tabs/message/index'
import { Route as FeedsIndexImport } from './tabs/feeds/index'
import { Route as PagesHomeImport } from './tabs/pages/home'
import { Route as PagesPageIdImport } from './tabs/pages/$page-id'
import { Route as MessageMessageIdImport } from './tabs/message/$message-id'
import { Route as WebWebpageWebpageIdImport } from './tabs/web/webpage.$webpage-id'
import { Route as WebSearchSearchWebSearchIdImport } from './tabs/web-search/search.$web-search-id'
import { Route as UploadsUploadUploadIdImport } from './tabs/uploads/upload.$upload-id'
import { Route as TablesTableTableIdImport } from './tabs/tables/table.$table-id'
import { Route as SearchResultSearchIdImport } from './tabs/search/result.$search-id'
import { Route as FeedsFeedItemFeedItemIdImport } from './tabs/feeds/feed-item/$feed-item-id'
import { Route as FeedsFeedChannelFeedChannelIdImport } from './tabs/feeds/feed-channel/$feed-channel-id'

// Create/Update Routes

const WebSearchRouteRoute = WebSearchRouteImport.update({
  id: '/web-search',
  path: '/web-search',
  getParentRoute: () => rootRoute,
} as any)

const SearchRouteRoute = SearchRouteImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => rootRoute,
} as any)

const WebIndexRoute = WebIndexImport.update({
  id: '/web/',
  path: '/web/',
  getParentRoute: () => rootRoute,
} as any)

const UploadsIndexRoute = UploadsIndexImport.update({
  id: '/uploads/',
  path: '/uploads/',
  getParentRoute: () => rootRoute,
} as any)

const TablesIndexRoute = TablesIndexImport.update({
  id: '/tables/',
  path: '/tables/',
  getParentRoute: () => rootRoute,
} as any)

const PagesIndexRoute = PagesIndexImport.update({
  id: '/pages/',
  path: '/pages/',
  getParentRoute: () => rootRoute,
} as any)

const MessageIndexRoute = MessageIndexImport.update({
  id: '/message/',
  path: '/message/',
  getParentRoute: () => rootRoute,
} as any)

const FeedsIndexRoute = FeedsIndexImport.update({
  id: '/feeds/',
  path: '/feeds/',
  getParentRoute: () => rootRoute,
} as any)

const PagesHomeRoute = PagesHomeImport.update({
  id: '/pages/home',
  path: '/pages/home',
  getParentRoute: () => rootRoute,
} as any)

const PagesPageIdRoute = PagesPageIdImport.update({
  id: '/pages/$page-id',
  path: '/pages/$page-id',
  getParentRoute: () => rootRoute,
} as any)

const MessageMessageIdRoute = MessageMessageIdImport.update({
  id: '/message/$message-id',
  path: '/message/$message-id',
  getParentRoute: () => rootRoute,
} as any)

const WebWebpageWebpageIdRoute = WebWebpageWebpageIdImport.update({
  id: '/web/webpage/$webpage-id',
  path: '/web/webpage/$webpage-id',
  getParentRoute: () => rootRoute,
} as any)

const WebSearchSearchWebSearchIdRoute = WebSearchSearchWebSearchIdImport.update(
  {
    id: '/search/$web-search-id',
    path: '/search/$web-search-id',
    getParentRoute: () => WebSearchRouteRoute,
  } as any,
)

const UploadsUploadUploadIdRoute = UploadsUploadUploadIdImport.update({
  id: '/uploads/upload/$upload-id',
  path: '/uploads/upload/$upload-id',
  getParentRoute: () => rootRoute,
} as any)

const TablesTableTableIdRoute = TablesTableTableIdImport.update({
  id: '/tables/table/$table-id',
  path: '/tables/table/$table-id',
  getParentRoute: () => rootRoute,
} as any)

const SearchResultSearchIdRoute = SearchResultSearchIdImport.update({
  id: '/result/$search-id',
  path: '/result/$search-id',
  getParentRoute: () => SearchRouteRoute,
} as any)

const FeedsFeedItemFeedItemIdRoute = FeedsFeedItemFeedItemIdImport.update({
  id: '/feeds/feed-item/$feed-item-id',
  path: '/feeds/feed-item/$feed-item-id',
  getParentRoute: () => rootRoute,
} as any)

const FeedsFeedChannelFeedChannelIdRoute =
  FeedsFeedChannelFeedChannelIdImport.update({
    id: '/feeds/feed-channel/$feed-channel-id',
    path: '/feeds/feed-channel/$feed-channel-id',
    getParentRoute: () => rootRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/search': {
      id: '/search'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof SearchRouteImport
      parentRoute: typeof rootRoute
    }
    '/web-search': {
      id: '/web-search'
      path: '/web-search'
      fullPath: '/web-search'
      preLoaderRoute: typeof WebSearchRouteImport
      parentRoute: typeof rootRoute
    }
    '/message/$message-id': {
      id: '/message/$message-id'
      path: '/message/$message-id'
      fullPath: '/message/$message-id'
      preLoaderRoute: typeof MessageMessageIdImport
      parentRoute: typeof rootRoute
    }
    '/pages/$page-id': {
      id: '/pages/$page-id'
      path: '/pages/$page-id'
      fullPath: '/pages/$page-id'
      preLoaderRoute: typeof PagesPageIdImport
      parentRoute: typeof rootRoute
    }
    '/pages/home': {
      id: '/pages/home'
      path: '/pages/home'
      fullPath: '/pages/home'
      preLoaderRoute: typeof PagesHomeImport
      parentRoute: typeof rootRoute
    }
    '/feeds/': {
      id: '/feeds/'
      path: '/feeds'
      fullPath: '/feeds'
      preLoaderRoute: typeof FeedsIndexImport
      parentRoute: typeof rootRoute
    }
    '/message/': {
      id: '/message/'
      path: '/message'
      fullPath: '/message'
      preLoaderRoute: typeof MessageIndexImport
      parentRoute: typeof rootRoute
    }
    '/pages/': {
      id: '/pages/'
      path: '/pages'
      fullPath: '/pages'
      preLoaderRoute: typeof PagesIndexImport
      parentRoute: typeof rootRoute
    }
    '/tables/': {
      id: '/tables/'
      path: '/tables'
      fullPath: '/tables'
      preLoaderRoute: typeof TablesIndexImport
      parentRoute: typeof rootRoute
    }
    '/uploads/': {
      id: '/uploads/'
      path: '/uploads'
      fullPath: '/uploads'
      preLoaderRoute: typeof UploadsIndexImport
      parentRoute: typeof rootRoute
    }
    '/web/': {
      id: '/web/'
      path: '/web'
      fullPath: '/web'
      preLoaderRoute: typeof WebIndexImport
      parentRoute: typeof rootRoute
    }
    '/feeds/feed-channel/$feed-channel-id': {
      id: '/feeds/feed-channel/$feed-channel-id'
      path: '/feeds/feed-channel/$feed-channel-id'
      fullPath: '/feeds/feed-channel/$feed-channel-id'
      preLoaderRoute: typeof FeedsFeedChannelFeedChannelIdImport
      parentRoute: typeof rootRoute
    }
    '/feeds/feed-item/$feed-item-id': {
      id: '/feeds/feed-item/$feed-item-id'
      path: '/feeds/feed-item/$feed-item-id'
      fullPath: '/feeds/feed-item/$feed-item-id'
      preLoaderRoute: typeof FeedsFeedItemFeedItemIdImport
      parentRoute: typeof rootRoute
    }
    '/search/result/$search-id': {
      id: '/search/result/$search-id'
      path: '/result/$search-id'
      fullPath: '/search/result/$search-id'
      preLoaderRoute: typeof SearchResultSearchIdImport
      parentRoute: typeof SearchRouteImport
    }
    '/tables/table/$table-id': {
      id: '/tables/table/$table-id'
      path: '/tables/table/$table-id'
      fullPath: '/tables/table/$table-id'
      preLoaderRoute: typeof TablesTableTableIdImport
      parentRoute: typeof rootRoute
    }
    '/uploads/upload/$upload-id': {
      id: '/uploads/upload/$upload-id'
      path: '/uploads/upload/$upload-id'
      fullPath: '/uploads/upload/$upload-id'
      preLoaderRoute: typeof UploadsUploadUploadIdImport
      parentRoute: typeof rootRoute
    }
    '/web-search/search/$web-search-id': {
      id: '/web-search/search/$web-search-id'
      path: '/search/$web-search-id'
      fullPath: '/web-search/search/$web-search-id'
      preLoaderRoute: typeof WebSearchSearchWebSearchIdImport
      parentRoute: typeof WebSearchRouteImport
    }
    '/web/webpage/$webpage-id': {
      id: '/web/webpage/$webpage-id'
      path: '/web/webpage/$webpage-id'
      fullPath: '/web/webpage/$webpage-id'
      preLoaderRoute: typeof WebWebpageWebpageIdImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

interface SearchRouteRouteChildren {
  SearchResultSearchIdRoute: typeof SearchResultSearchIdRoute
}

const SearchRouteRouteChildren: SearchRouteRouteChildren = {
  SearchResultSearchIdRoute: SearchResultSearchIdRoute,
}

const SearchRouteRouteWithChildren = SearchRouteRoute._addFileChildren(
  SearchRouteRouteChildren,
)

interface WebSearchRouteRouteChildren {
  WebSearchSearchWebSearchIdRoute: typeof WebSearchSearchWebSearchIdRoute
}

const WebSearchRouteRouteChildren: WebSearchRouteRouteChildren = {
  WebSearchSearchWebSearchIdRoute: WebSearchSearchWebSearchIdRoute,
}

const WebSearchRouteRouteWithChildren = WebSearchRouteRoute._addFileChildren(
  WebSearchRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '/search': typeof SearchRouteRouteWithChildren
  '/web-search': typeof WebSearchRouteRouteWithChildren
  '/message/$message-id': typeof MessageMessageIdRoute
  '/pages/$page-id': typeof PagesPageIdRoute
  '/pages/home': typeof PagesHomeRoute
  '/feeds': typeof FeedsIndexRoute
  '/message': typeof MessageIndexRoute
  '/pages': typeof PagesIndexRoute
  '/tables': typeof TablesIndexRoute
  '/uploads': typeof UploadsIndexRoute
  '/web': typeof WebIndexRoute
  '/feeds/feed-channel/$feed-channel-id': typeof FeedsFeedChannelFeedChannelIdRoute
  '/feeds/feed-item/$feed-item-id': typeof FeedsFeedItemFeedItemIdRoute
  '/search/result/$search-id': typeof SearchResultSearchIdRoute
  '/tables/table/$table-id': typeof TablesTableTableIdRoute
  '/uploads/upload/$upload-id': typeof UploadsUploadUploadIdRoute
  '/web-search/search/$web-search-id': typeof WebSearchSearchWebSearchIdRoute
  '/web/webpage/$webpage-id': typeof WebWebpageWebpageIdRoute
}

export interface FileRoutesByTo {
  '/search': typeof SearchRouteRouteWithChildren
  '/web-search': typeof WebSearchRouteRouteWithChildren
  '/message/$message-id': typeof MessageMessageIdRoute
  '/pages/$page-id': typeof PagesPageIdRoute
  '/pages/home': typeof PagesHomeRoute
  '/feeds': typeof FeedsIndexRoute
  '/message': typeof MessageIndexRoute
  '/pages': typeof PagesIndexRoute
  '/tables': typeof TablesIndexRoute
  '/uploads': typeof UploadsIndexRoute
  '/web': typeof WebIndexRoute
  '/feeds/feed-channel/$feed-channel-id': typeof FeedsFeedChannelFeedChannelIdRoute
  '/feeds/feed-item/$feed-item-id': typeof FeedsFeedItemFeedItemIdRoute
  '/search/result/$search-id': typeof SearchResultSearchIdRoute
  '/tables/table/$table-id': typeof TablesTableTableIdRoute
  '/uploads/upload/$upload-id': typeof UploadsUploadUploadIdRoute
  '/web-search/search/$web-search-id': typeof WebSearchSearchWebSearchIdRoute
  '/web/webpage/$webpage-id': typeof WebWebpageWebpageIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/search': typeof SearchRouteRouteWithChildren
  '/web-search': typeof WebSearchRouteRouteWithChildren
  '/message/$message-id': typeof MessageMessageIdRoute
  '/pages/$page-id': typeof PagesPageIdRoute
  '/pages/home': typeof PagesHomeRoute
  '/feeds/': typeof FeedsIndexRoute
  '/message/': typeof MessageIndexRoute
  '/pages/': typeof PagesIndexRoute
  '/tables/': typeof TablesIndexRoute
  '/uploads/': typeof UploadsIndexRoute
  '/web/': typeof WebIndexRoute
  '/feeds/feed-channel/$feed-channel-id': typeof FeedsFeedChannelFeedChannelIdRoute
  '/feeds/feed-item/$feed-item-id': typeof FeedsFeedItemFeedItemIdRoute
  '/search/result/$search-id': typeof SearchResultSearchIdRoute
  '/tables/table/$table-id': typeof TablesTableTableIdRoute
  '/uploads/upload/$upload-id': typeof UploadsUploadUploadIdRoute
  '/web-search/search/$web-search-id': typeof WebSearchSearchWebSearchIdRoute
  '/web/webpage/$webpage-id': typeof WebWebpageWebpageIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/search'
    | '/web-search'
    | '/message/$message-id'
    | '/pages/$page-id'
    | '/pages/home'
    | '/feeds'
    | '/message'
    | '/pages'
    | '/tables'
    | '/uploads'
    | '/web'
    | '/feeds/feed-channel/$feed-channel-id'
    | '/feeds/feed-item/$feed-item-id'
    | '/search/result/$search-id'
    | '/tables/table/$table-id'
    | '/uploads/upload/$upload-id'
    | '/web-search/search/$web-search-id'
    | '/web/webpage/$webpage-id'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/search'
    | '/web-search'
    | '/message/$message-id'
    | '/pages/$page-id'
    | '/pages/home'
    | '/feeds'
    | '/message'
    | '/pages'
    | '/tables'
    | '/uploads'
    | '/web'
    | '/feeds/feed-channel/$feed-channel-id'
    | '/feeds/feed-item/$feed-item-id'
    | '/search/result/$search-id'
    | '/tables/table/$table-id'
    | '/uploads/upload/$upload-id'
    | '/web-search/search/$web-search-id'
    | '/web/webpage/$webpage-id'
  id:
    | '__root__'
    | '/search'
    | '/web-search'
    | '/message/$message-id'
    | '/pages/$page-id'
    | '/pages/home'
    | '/feeds/'
    | '/message/'
    | '/pages/'
    | '/tables/'
    | '/uploads/'
    | '/web/'
    | '/feeds/feed-channel/$feed-channel-id'
    | '/feeds/feed-item/$feed-item-id'
    | '/search/result/$search-id'
    | '/tables/table/$table-id'
    | '/uploads/upload/$upload-id'
    | '/web-search/search/$web-search-id'
    | '/web/webpage/$webpage-id'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  SearchRouteRoute: typeof SearchRouteRouteWithChildren
  WebSearchRouteRoute: typeof WebSearchRouteRouteWithChildren
  MessageMessageIdRoute: typeof MessageMessageIdRoute
  PagesPageIdRoute: typeof PagesPageIdRoute
  PagesHomeRoute: typeof PagesHomeRoute
  FeedsIndexRoute: typeof FeedsIndexRoute
  MessageIndexRoute: typeof MessageIndexRoute
  PagesIndexRoute: typeof PagesIndexRoute
  TablesIndexRoute: typeof TablesIndexRoute
  UploadsIndexRoute: typeof UploadsIndexRoute
  WebIndexRoute: typeof WebIndexRoute
  FeedsFeedChannelFeedChannelIdRoute: typeof FeedsFeedChannelFeedChannelIdRoute
  FeedsFeedItemFeedItemIdRoute: typeof FeedsFeedItemFeedItemIdRoute
  TablesTableTableIdRoute: typeof TablesTableTableIdRoute
  UploadsUploadUploadIdRoute: typeof UploadsUploadUploadIdRoute
  WebWebpageWebpageIdRoute: typeof WebWebpageWebpageIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  SearchRouteRoute: SearchRouteRouteWithChildren,
  WebSearchRouteRoute: WebSearchRouteRouteWithChildren,
  MessageMessageIdRoute: MessageMessageIdRoute,
  PagesPageIdRoute: PagesPageIdRoute,
  PagesHomeRoute: PagesHomeRoute,
  FeedsIndexRoute: FeedsIndexRoute,
  MessageIndexRoute: MessageIndexRoute,
  PagesIndexRoute: PagesIndexRoute,
  TablesIndexRoute: TablesIndexRoute,
  UploadsIndexRoute: UploadsIndexRoute,
  WebIndexRoute: WebIndexRoute,
  FeedsFeedChannelFeedChannelIdRoute: FeedsFeedChannelFeedChannelIdRoute,
  FeedsFeedItemFeedItemIdRoute: FeedsFeedItemFeedItemIdRoute,
  TablesTableTableIdRoute: TablesTableTableIdRoute,
  UploadsUploadUploadIdRoute: UploadsUploadUploadIdRoute,
  WebWebpageWebpageIdRoute: WebWebpageWebpageIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/search",
        "/web-search",
        "/message/$message-id",
        "/pages/$page-id",
        "/pages/home",
        "/feeds/",
        "/message/",
        "/pages/",
        "/tables/",
        "/uploads/",
        "/web/",
        "/feeds/feed-channel/$feed-channel-id",
        "/feeds/feed-item/$feed-item-id",
        "/tables/table/$table-id",
        "/uploads/upload/$upload-id",
        "/web/webpage/$webpage-id"
      ]
    },
    "/search": {
      "filePath": "search/route.tsx",
      "children": [
        "/search/result/$search-id"
      ]
    },
    "/web-search": {
      "filePath": "web-search/route.tsx",
      "children": [
        "/web-search/search/$web-search-id"
      ]
    },
    "/message/$message-id": {
      "filePath": "message/$message-id.tsx"
    },
    "/pages/$page-id": {
      "filePath": "pages/$page-id.tsx"
    },
    "/pages/home": {
      "filePath": "pages/home.tsx"
    },
    "/feeds/": {
      "filePath": "feeds/index.tsx"
    },
    "/message/": {
      "filePath": "message/index.tsx"
    },
    "/pages/": {
      "filePath": "pages/index.tsx"
    },
    "/tables/": {
      "filePath": "tables/index.tsx"
    },
    "/uploads/": {
      "filePath": "uploads/index.tsx"
    },
    "/web/": {
      "filePath": "web/index.tsx"
    },
    "/feeds/feed-channel/$feed-channel-id": {
      "filePath": "feeds/feed-channel/$feed-channel-id.tsx"
    },
    "/feeds/feed-item/$feed-item-id": {
      "filePath": "feeds/feed-item/$feed-item-id.tsx"
    },
    "/search/result/$search-id": {
      "filePath": "search/result.$search-id.tsx",
      "parent": "/search"
    },
    "/tables/table/$table-id": {
      "filePath": "tables/table.$table-id.tsx"
    },
    "/uploads/upload/$upload-id": {
      "filePath": "uploads/upload.$upload-id.tsx"
    },
    "/web-search/search/$web-search-id": {
      "filePath": "web-search/search.$web-search-id.tsx",
      "parent": "/web-search"
    },
    "/web/webpage/$webpage-id": {
      "filePath": "web/webpage.$webpage-id.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
