import type { AppState } from "@/contexts/app-context/app-context";
import { createMessageId } from "@/lib/id-generators";
import type {
	AgentType,
	Message,
	MessageId,
	MessageWithMetadata,
	SessionId,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";

export function createMessage(props: {
	content: string;
	attachments: string[];
	sessionId: SessionId;
	parentMessageId: MessageId | null;
}): Message {
	return {
		message_id: createMessageId(),
		created_at: new Date().toISOString(),
		session_id: props.sessionId,
		content: props.content,
		attachments: props.attachments,
		parent_message_id: props.parentMessageId,
	};
}

// Thoughts:
// - We create a Store for each database table.
// - Each store manages things like indices for the map if we need faster updates.
// - The indices would be updated whenever we add a message to the messages map.
// - We don't construct graphs; we instead use caching (computedFn) + indices,
// if necessary.
// - Eventually... we'll just be able to use SQL...

export type ThreadRootMessage = Omit<
	MessageWithMetadata,
	"parent_message_id"
> & {
	parent_message_id: null;
};

export class MessageStore {
	appState: AppState;

	messages: Map<MessageId, Message> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	// Mutations
	createMessageLocally = (message: Message) => {
		this.messages.set(message.message_id, message);
	};

	// Queries
	getMessageByIdWithMetadata = computedFn(
		(messageId: MessageId): MessageWithMetadata => {
			const message = this.messages.get(messageId);
			if (!message) {
				throw new Error(`Message ${messageId} not found`);
			}
			const session = this.appState.getSessionById(message.session_id);
			if (!session) {
				throw new Error(`Session ${message.session_id} not found`);
			}
			const agentType: AgentType =
				"session_assistant_id" in session ? "assistant" : "user";
			const user = this.appState.userStore.items.get(session.user_id);
			if (user.isErr()) {
				throw new Error(`User ${session.user_id} not found`);
			}

			// TODO(John): right now, this only gets replies if the message is a
			// thread root.
			const replies =
				message.parent_message_id === null
					? this.getThreadReplies(message.message_id)
					: [];
			return {
				...message,
				agent_type: agentType,
				user: user.value,
				replies,
			};
		},
	);

	getThreadReplies = computedFn(
		(threadId: MessageId): MessageWithMetadata[] => {
			return Array.from(this.messages.values())
				.filter((message) => message.parent_message_id === threadId)
				.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at))
				.map((message) => this.getMessageByIdWithMetadata(message.message_id));
		},
	);

	get threads(): ThreadRootMessage[] {
		return Array.from(this.messages.values())
			.filter(
				(message): message is Message & { parent_message_id: null } =>
					message.parent_message_id === null,
			)
			.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at))
			.map((message) =>
				this.getMessageByIdWithMetadata(message.message_id),
			) as ThreadRootMessage[];
	}
}
