import { TableComponent } from "@/components/table/table-component";
import { useWebpagesIndex } from "@/contexts/resource-indexes/hooks";
import { UploadsHomeTabState } from "@/contexts/uploads/tab-state";
import { AddWebpageDialog } from "@/pages/tabs/web/-components/add-webpage-dialog";
import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

const WebpagesHomePage = observer(function WebpagesHomePage() {
	const webpagesIndex = useWebpagesIndex();
	return (
		<div className="flex min-h-0 w-full grow flex-col gap-4 p-4">
			<h1 className="font-bold text-2xl">Webpages</h1>
			<div className="flex">
				<AddWebpageDialog />
			</div>
			<TableComponent
				getRowId={(row) => row.link}
				columns={webpagesIndex.columns}
				data={webpagesIndex.rows}
			/>
		</div>
	);
});

export const Route = createFileRoute("/web/")({
	component: WebpagesHomePage,
	loader: ({ context: { tab } }) => {
		const tabState = new UploadsHomeTabState(tab);
		return { tabState };
	},
});
