import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import type { Transaction } from "@/lib/sync/action-executor";
import { OptimisticAction } from "@/lib/sync/action-executor";
import { ElectricOptimisticMap } from "@/lib/sync/optimistic-map";
import { webSearchRoute } from "@api/fastAPI";
import type {
	WebSearchFull,
	WebSearchId,
	WebSearchRequest,
	WebSearchResource,
	WebSearchSynthesis,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import type { Result } from "neverthrow";
class InitiateWebSearchAction extends OptimisticAction<
	AppState,
	WebSearchRequest,
	null,
	void
> {
	async local(tx: Transaction, state: AppState) {
		const webSearchResource: WebSearchResource = {
			...this.args,
			name: this.args.query,
			created_at: new Date().toISOString(),
		};
		state.webSearchStore.map.insert(tx, webSearchResource);
		return null;
	}

	async remote(_: { localResult: null }, state: AppState) {
		const res = await webSearchRoute(this.args);
		state.webSearchStore.fullResults.set(
			this.args.web_search_id,
			res.data.web_search,
		);
	}
}

export class WebSearchStore {
	appState: AppState;
	map: ElectricOptimisticMap<WebSearchResource, "web_search_id", "write_id">;

	// Cache for full web search results
	fullResults: Map<WebSearchId, WebSearchFull> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/web_search_results`,
			idKey: "web_search_id",
			writeIdKey: "write_id",
			shapeHash: openapiHashes.WebSearchResource,
		});
		makeAutoObservable(this);
	}

	getResourceById(webSearchId: WebSearchId): Result<WebSearchResource, Error> {
		return this.map.get(webSearchId);
	}

	getFullResult(webSearchId: WebSearchId): WebSearchFull | undefined {
		return this.fullResults.get(webSearchId);
	}

	async initiateWebSearch(webSearchRequest: WebSearchRequest) {
		const action = new InitiateWebSearchAction(webSearchRequest);
		this.appState.actionQueue.run(action);
	}

	handleSynthesisUpdate(update: WebSearchSynthesis) {
		const webSearchId = update.web_search_id;
		const webSearchResult = this.fullResults.get(webSearchId);
		if (!webSearchResult) {
			throw new Error("Web search result not found");
		}
		webSearchResult.synthesis = update.synthesis;
	}

	get uniqueSearchHistory(): WebSearchFull[] {
		return Array.from(this.fullResults.values()); // Reverse to show most recent searches first
	}
}
