import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { OptimisticMap } from "@/lib/sync/optimistic-map";
import { ElectricSyncStream } from "@/lib/sync/stream";
import type { DataType, Field, FieldId, TableId } from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import { type Result, err, ok } from "neverthrow";

export function isFieldPrimary(field: Field): boolean {
	return "is_primary" in field.properties && field.properties.is_primary;
}

export class FieldsStore {
	appState: AppState;
	items: OptimisticMap<Field, "field_id">;

	constructor(appState: AppState) {
		this.appState = appState;
		this.items = new OptimisticMap<Field, "field_id">({
			stream: new ElectricSyncStream<Field, "field_id", "write_id">({
				shapeUrl: `${API_ENDPOINT_HTTP}/shapes/fields`,
				idKey: "field_id",
				writeIdKey: "write_id",
				shapeHash: openapiHashes.Field,
			}),
		});

		makeAutoObservable(this);
	}

	getFieldById(id: FieldId): Result<Field, Error> {
		return this.items.get(id);
	}

	/**
	 * Returns the non-deleted fields for a table, sorted by their order.
	 */
	getFieldsByTableId(tableId: TableId): Map<FieldId, Field> {
		const entries = this.items.keys
			.map((key) => {
				const field = this.items.get(key);
				if (
					field.isErr() ||
					field.value.deleted_at !== null ||
					field.value.table_id !== tableId
				)
					return undefined;
				return [key, field.value];
			})
			.filter((field) => field !== undefined) as [FieldId, Field][];
		return new Map(entries);
	}

	/**
	 * Gets the expected data type for a field.
	 *
	 * Replicate logic in server/server/postgres/orm/integrations/tables/queries.py
	 */
	getFieldDataType = computedFn((fieldId: FieldId): Result<DataType, Error> => {
		const field = this.items.get(fieldId);
		if (field.isErr()) {
			return err(new Error(`Field with ID ${fieldId} not found`));
		}
		switch (field.value.type) {
			case "text":
				return ok({ scalar_type: "text", array_depth: 0 });

			case "number":
				return ok({ scalar_type: "real", array_depth: 0 });

			case "datetime":
				return ok({ scalar_type: "timestamptz", array_depth: 0 });

			case "boolean":
				return ok({ scalar_type: "boolean", array_depth: 0 });

			case "select":
				return ok({ scalar_type: "select_option", array_depth: 0 });

			case "relationship":
				// TODO(John): fix
				return ok({ scalar_type: "record_link", array_depth: 1 });

			case "lookup": {
				return this.getFieldDataType(field.value.properties.target_field_id);
			}
			case "view":
				return ok(field.value.properties.data_type);

			default: {
				const _exhaustiveCheck: never = field.value;
				return err(new Error(`Unknown field type: ${_exhaustiveCheck}`));
			}
		}
	});
}
