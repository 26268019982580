import { makeAutoObservable } from "mobx";

export class DevSettings {
	showTabUrls = false;
	showSearchResultScores = false;

	constructor() {
		makeAutoObservable(this);
	}
}
