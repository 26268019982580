import type { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { createWebSearchId, createWriteId } from "@/lib/id-generators";
import type {
	WebSearchFull,
	WebSearchId,
	WebSearchRequest,
	WebSearchResource,
} from "@api/schemas";
import { Globe } from "@phosphor-icons/react";
import { makeAutoObservable } from "mobx";

export enum WebSearchResultLoadingState {
	Loading = "loading",
	Synthesizing = "synthesizing",
	Completed = "completed",
	Errored = "errored",
}

export class WebSearchTabState implements BaseTabState {
	tab: Tab;

	// Search config state in the UI, which the user can edit before initiating
	config: Omit<
		WebSearchRequest,
		"web_search_id" | "requested_at" | "write_id"
	> = {
		query: "",
	};
	searchResource: WebSearchResource | null = null;
	get fullResult(): WebSearchFull | null {
		if (!this.searchResource) {
			return null;
		}
		return (
			this.tab.tabStore.appState.webSearchStore.getFullResult(
				this.searchResource.web_search_id,
			) ?? null
		);
	}

	// UI components
	showCommandList = false;
	searchInputElement: HTMLInputElement | null = null;

	constructor(tab: Tab, webSearchId: WebSearchId | null) {
		this.tab = tab;
		this.tab.setState(this);

		if (webSearchId) {
			this.loadWebSearchResult(webSearchId);
		}
		makeAutoObservable(this);
	}

	get head() {
		return {
			icon: Globe,
			label: "Web Search",
			resourceRef: this.searchResource
				? {
						type: "web-search-result" as const,
						resource_id: this.searchResource.web_search_id,
					}
				: undefined,
		};
	}

	setQuery(query: string) {
		this.config.query = query;
	}

	setShowCommandList(show: boolean) {
		this.showCommandList = show;
	}

	loadWebSearchResult(webSearchId: WebSearchId) {
		const webSearchResult =
			this.tab.tabStore.appState.webSearchStore.getResourceById(webSearchId);
		if (webSearchResult.isErr()) {
			throw new Error("Web search result not found");
		}
		this.searchResource = webSearchResult.value;
		this.config.query = webSearchResult.value.query;
	}

	handleSearch() {
		const newWebSearchRequest: WebSearchRequest = {
			...this.config,
			web_search_id: createWebSearchId(),
			requested_at: new Date().toISOString(),
			write_id: createWriteId(),
		};
		this.tab.tabStore.appState.webSearchStore.initiateWebSearch(
			newWebSearchRequest,
		);
		this.tab.router.navigate({
			to: "/web-search/search/$web-search-id",
			params: {
				"web-search-id": newWebSearchRequest.web_search_id,
			},
		});
	}

	get loadingState(): WebSearchResultLoadingState | null {
		if (!this.searchResource) {
			return null;
		}
		if (this.fullResult === null) {
			return WebSearchResultLoadingState.Loading;
		}
		if (!this.fullResult.synthesis) {
			return WebSearchResultLoadingState.Synthesizing;
		}
		return WebSearchResultLoadingState.Completed;
	}
}
