import { TextEditor } from "@/components/editor";
import {
	AssistantPresenceIndicator,
	EventComponent,
	SessionPreviewWithEvents,
	StatusDot,
	StepComponent,
	getColorForSession,
} from "@/components/layout/right-sidebar/assistant-presence";

import { Button } from "@/components/ui/button";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { LocalSearchableResourceRef } from "@/contexts/local-search-store";
import { resourceRefToPath } from "@/lib/paths";
import {
	ObjectLinkComponent,
	ObjectLinkContent,
	useGetIconAndLabel,
} from "@/plugins/object-link";
import type { SessionAssistantId } from "@api/schemas";
import { CaretDown, Chat, File, FunnelSimple, X } from "@phosphor-icons/react";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { AnimatePresence, motion } from "motion/react";
import { useEffect, useRef, useState } from "react";

interface SingleSessionViewerProps {
	sessionAssistantId: SessionAssistantId;
}

const SingleSessionViewer = observer(
	({ sessionAssistantId }: SingleSessionViewerProps) => {
		const [openAll, setOpenAll] = useState(true);
		const bottomRef = useRef<HTMLDivElement>(null);
		const appContext = useAppContext();

		const { sessionAssistant: activeAssistantSession, tabs } =
			appContext.assistantSessionStore.getAggregate(sessionAssistantId);

		const activeAssistantActivity =
			appContext.getSortedAssistantActivityForSession(sessionAssistantId);

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			bottomRef.current?.scrollIntoView({ behavior: "smooth" });
		}, [activeAssistantActivity]);

		if (!activeAssistantActivity || !activeAssistantSession) {
			return (
				<div className="flex h-full w-full flex-col p-2">
					<div className="flex flex-col gap-2 p-2">
						<div className="flex flex-none items-center justify-between gap-2">
							<span className="font-semibold text-base">Session</span>
							<Button
								variant="ghost"
								onClick={() => {
									appContext.rightSidebarState.activityViewerActiveSessionAssistantId =
										null;
								}}
								className="h-min w-min items-start p-1"
							>
								<X size={16} />
							</Button>
						</div>
					</div>
					<div className="flex min-h-0 grow flex-col overflow-y-auto">
						<div className="p-2 text-neutral-500">
							Could not find assistant activity
						</div>
					</div>
				</div>
			);
		}

		const openTabs = Array.from(tabs.entries()).filter(
			([_, tab]) => tab.closedAt === null,
		);

		const closedTabs = Array.from(tabs.entries()).filter(
			([_, tab]) => tab.closedAt !== null,
		);

		const isActive =
			appContext.assistantSessionStore.sessionIsActive(sessionAssistantId);

		if (activeAssistantSession.agent_type === "assistant") {
			return (
				<div className="flex h-full w-full flex-col">
					{/* Header */}
					<div className="flex flex-col gap-2 border-neutral-100 border-b p-4">
						<div className="flex flex-none items-center justify-between gap-2">
							<span className="font-semibold text-base text-neutral-800">
								Session
							</span>
							<Button
								variant="ghost"
								onClick={() => {
									appContext.rightSidebarState.activityViewerActiveSessionAssistantId =
										null;
								}}
								className="h-min w-min items-start p-1"
							>
								<X size={16} />
							</Button>
						</div>
						<div className="flex items-start gap-2">
							<AssistantPresenceIndicator
								sessionAssistantId={sessionAssistantId}
								active={isActive}
							/>
							<div className="flex flex-col gap-1">
								<span className="font-medium text-neutral-500 text-xs">
									{new Date(activeAssistantSession.started_at).toLocaleString(
										"en-US",
										{
											month: "numeric",
											day: "numeric",
											hour: "numeric",
											minute: "numeric",
										},
									)}
								</span>
								<TextEditor
									className="[&_.ProseMirror]:text-sm"
									options={{
										content: activeAssistantSession?.goal,
										editable: false,
									}}
								/>
							</div>
						</div>
					</div>
					{/* Activity */}
					<div className="flex min-h-0 grow flex-col overflow-y-auto ">
						<div className="border-neutral-100 border-b p-2">
							<div className="flex items-center justify-between p-2">
								<span className="font-semibold text-neutral-800 text-sm">
									Tabs
								</span>
								{tabs.size > 0 ? (
									Array.from(
										appContext.tabStore.trackedAssistantSessions.values(),
									).includes(sessionAssistantId) ? (
										<button
											type="button"
											className={clsx(
												"rounded px-1 py-0.5 text-white text-xs",
												getColorForSession(sessionAssistantId).className,
											)}
											onClick={() => {
												appContext.tabStore.stopTrackingAssistantSession(
													sessionAssistantId,
												);
											}}
										>
											Stop tracking
										</button>
									) : (
										<button
											type="button"
											className="px-1 py-0.5 text-neutral-500 text-xs"
											onClick={() => {
												appContext.tabStore.trackTabsForAssistantSession(
													sessionAssistantId,
												);
											}}
										>
											Track tabs
										</button>
									)
								) : (
									<></>
								)}
							</div>
							<div className="flex flex-col gap-2 p-2">
								{tabs.size === 0 && (
									<div className="text-neutral-500">No tabs open</div>
								)}
								{openTabs.length > 0 && (
									<div className="flex flex-col gap-1">
										<div className="p-1">
											<span className="font-semibold text-neutral-500 text-xs">
												Open
											</span>
										</div>
										{openTabs.map(([tabId, tab]) => (
											<ObjectLinkComponent
												key={tabId}
												href={tab.path}
												className="rounded-sm border bg-neutral-50 p-1 font-normal text-neutral-600 hover:bg-white"
											>
												<ObjectLinkContent href={tab.path} />
											</ObjectLinkComponent>
										))}
									</div>
								)}
								{closedTabs.length > 0 && (
									<div className="flex flex-col gap-2">
										<span className="font-semibold text-neutral-500 text-xs">
											Closed
										</span>
										{closedTabs.map(([tabId, tab]) => (
											<ObjectLinkComponent
												key={tabId}
												href={tab.path}
												className="rounded-sm border bg-neutral-50 p-1 font-normal text-neutral-600 hover:bg-white"
											>
												<ObjectLinkContent href={tab.path} label="Tab" />
											</ObjectLinkComponent>
										))}
									</div>
								)}
							</div>
						</div>

						<div className="flex items-center justify-between border-neutral-50 border-b p-4">
							<span className="font-semibold text-neutral-800 text-sm">
								Activity
							</span>
							<button
								type="button"
								className="px-1 py-0.5 text-neutral-500 text-xs"
								onClick={() => {
									setOpenAll((prev) => !prev);
								}}
							>
								{openAll ? "Collapse all" : "Expand all"}
							</button>
						</div>
						<div className="flex max-h-screen min-h-0 w-full grow flex-col gap-2 overflow-y-auto p-4">
							<AnimatePresence>
								{activeAssistantActivity.map((eventOrStep) => {
									if ("event_id" in eventOrStep) {
										return (
											<motion.div
												key={eventOrStep.event_id}
												layout
												initial={
													isActive
														? {
																opacity: 0,
																backgroundColor: "rgb(245 245 245 / 1)", // bg-neutral-100
															}
														: false
												}
												animate={
													isActive
														? {
																opacity: 1,
																backgroundColor: "rgb(255 255 255 / 0)", // transparent
																transition: {
																	backgroundColor: {
																		duration: 1,
																	},
																},
															}
														: false
												}
												exit={{ opacity: 0 }}
											>
												<EventComponent
													key={eventOrStep.event_id}
													event={eventOrStep}
													globalOpen={openAll}
												/>
											</motion.div>
										);
									}
									return (
										<motion.div
											key={eventOrStep.step_id}
											layout
											initial={
												isActive
													? {
															opacity: 0,
															backgroundColor: "rgb(245 245 245 / 1)", // bg-neutral-100
														}
													: false
											}
											animate={
												isActive
													? {
															opacity: 1,
															backgroundColor: "rgb(255 255 255 / 0)", // transparent
															transition: {
																backgroundColor: {
																	duration: 1,
																},
															},
														}
													: false
											}
											exit={{ opacity: 0 }}
										>
											<StepComponent
												key={eventOrStep.step_id}
												step={eventOrStep}
												globalOpen={openAll}
											/>
										</motion.div>
									);
								})}
							</AnimatePresence>
							{/* invisible div at bottom for scroll target */}
							<div ref={bottomRef} />
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="flex h-full w-full flex-col gap-2 p-2">
				<div className="flex flex-col gap-2 border-neutral-100 border-b p-2">
					<span className="font-semibold text-neutral-800 text-sm">
						User session
					</span>
				</div>
			</div>
		);
	},
);

const ResourceFilterItem = observer(function ResourceFilterItem({
	resourceRef,
}: {
	resourceRef: LocalSearchableResourceRef;
}) {
	const { Icon, defaultLabel } = useGetIconAndLabel(
		resourceRefToPath(resourceRef),
	);
	return (
		<DropdownMenuItem className="flex h-8 w-full items-center px-2">
			<Icon className="h-4 w-4 shrink-0 text-neutral-700" />
			<h2
				className={clsx(
					"ml-2 w-full min-w-0 select-none truncate text-neutral-700 text-sm",
				)}
			>
				{defaultLabel}
			</h2>
		</DropdownMenuItem>
	);
});

const ResourceFilterSubmenu = observer(() => {
	const appContext = useAppContext();
	const [resourceSearchValue, setResourceSearchValue] = useState("");

	const filteredResources =
		appContext.localSearchStore.searchResources(resourceSearchValue);

	return (
		<DropdownMenuSubContent className="flex w-48 flex-col p-0">
			<div className="flex w-full items-center gap-2 p-1">
				<Input
					// AutoFocus doesn't work
					autoFocus
					type="text"
					placeholder="Search resources"
					className="w-full border-none"
					value={resourceSearchValue}
					onChange={(e) => {
						setResourceSearchValue(e.target.value);
					}}
				/>
			</div>
			<div className="flex w-full flex-col gap-1 border-neutral-100 border-t p-1">
				<div className="flex w-full flex-col items-center p-1">
					{filteredResources.map((result) => {
						return (
							<ResourceFilterItem
								key={result.resource_id}
								resourceRef={result}
							/>
						);
					})}
					{filteredResources.length === 0 && (
						<span className="text-neutral-500 text-xs">No resources found</span>
					)}
				</div>
			</div>
		</DropdownMenuSubContent>
	);
});

const ThreadFilterSubmenu = observer(() => {
	const appContext = useAppContext();
	const [threadSearchValue, setThreadSearchValue] = useState("");

	const threads = appContext.messageStore.threads;
	const filteredThreads = threads.filter((thread) =>
		thread.content.toLowerCase().includes(threadSearchValue.toLowerCase()),
	);
	return (
		<DropdownMenuSubContent className="flex w-48 flex-col p-0">
			<div className="flex w-full items-center gap-2 p-1">
				<Input
					// AutoFocus doesn't work
					autoFocus
					type="text"
					placeholder="Search threads"
					className="w-full border-none"
					value={threadSearchValue}
					onChange={(e) => {
						setThreadSearchValue(e.target.value);
					}}
				/>
			</div>
			<div className="flex w-full flex-col gap-1 border-neutral-100 border-t p-1">
				<div className="flex max-h-80 w-full flex-col gap-1 overflow-y-auto p-1">
					{filteredThreads.map((thread) => (
						<DropdownMenuItem
							key={thread.message_id}
							onSelect={() => {
								appContext.rightSidebarState.setFilterToThread(
									thread.message_id,
								);
							}}
							className="flex w-full flex-col items-start px-2"
						>
							<span className="text-neutral-500 text-xs">
								{new Date(thread.created_at).toLocaleString("en-US", {
									month: "numeric",
									day: "numeric",
									hour: "numeric",
									minute: "numeric",
								})}
							</span>
							<TextEditor
								className="w-full text-sm"
								options={{ content: thread.content, editable: false }}
							/>
						</DropdownMenuItem>
					))}
					{filteredThreads.length === 0 && (
						<span className="text-neutral-500 text-xs">No threads found</span>
					)}
				</div>
			</div>
		</DropdownMenuSubContent>
	);
});

const TopLevelSessionsViewer = observer(function TopLevelSessionsViewer() {
	const appContext = useAppContext();
	const { resourceFilterValue, threadIdFilterValue, sessionSearchQuery } =
		appContext.rightSidebarState;
	const activeTabHead = appContext.tabStore.activeTab?.state.head;
	const activeTabResourceRef = activeTabHead?.resourceRef ?? null;
	const activeResourcePath = activeTabResourceRef
		? resourceRefToPath(activeTabResourceRef)
		: null;

	const sessions =
		appContext.assistantSessionStore.enrichedAssistantSessionsWithFilters({
			resourceFilterValue,
			threadIdFilterValue,
			searchQuery: sessionSearchQuery,
		});

	const filteredResourcePath = resourceFilterValue
		? resourceRefToPath(resourceFilterValue)
		: null;

	const activeAssistantSessions = Array.from(sessions.values()).filter(
		(session) => !session.sessionAssistant.ended_at,
	);

	const completedAssistantSessions = Array.from(sessions.values()).filter(
		(session) => session.sessionAssistant.ended_at,
	);

	const thread = threadIdFilterValue
		? appContext.messageStore.getMessageByIdWithMetadata(threadIdFilterValue)
		: null;

	return (
		<div className="flex h-full w-full flex-col">
			<div className="flex flex-col gap-2 border-neutral-100 border-b bg-white p-3">
				<Input
					type="text"
					placeholder="Search sessions"
					className="w-full"
					value={sessionSearchQuery}
					onChange={(e) => {
						runInAction(() => {
							appContext.rightSidebarState.sessionSearchQuery = e.target.value;
						});
					}}
				/>

				{filteredResourcePath && (
					<div className="flex h-6 w-fit max-w-full items-center divide-x overflow-hidden rounded border border-neutral-200">
						<div className="whitespace-nowrap p-1 text-neutral-500 text-xs">
							Filtered by:
						</div>
						<ObjectLinkComponent href={filteredResourcePath}>
							<ObjectLinkContent
								href={filteredResourcePath}
								className="rounded-sm p-0.5 text-neutral-800 text-xs"
							/>
						</ObjectLinkComponent>
						<button
							type="button"
							onClick={() => {
								runInAction(() => {
									appContext.rightSidebarState.resourceFilterValue = null;
								});
							}}
							className="p-1 text-neutral-500 text-xs hover:bg-neutral-100"
						>
							<X size={14} />
						</button>
					</div>
				)}
				{thread && (
					<div className="flex h-6 w-fit max-w-full items-center divide-x overflow-hidden rounded border border-neutral-200">
						<div className="whitespace-nowrap p-1 text-neutral-500 text-xs">
							Filtered by:
						</div>
						<div className="flex min-w-0 shrink items-center gap-1 overflow-hidden p-1 text-neutral-500 text-xs">
							<Chat size={14} className="shrink-0 text-neutral-800" />
							<TextEditor
								// Weird way to limit to one line but it will do
								className="h-4 text-xs"
								options={{ content: thread.content, editable: false }}
							/>
						</div>
						<button
							type="button"
							onClick={() => {
								runInAction(() => {
									appContext.rightSidebarState.threadIdFilterValue = null;
									appContext.rightSidebarState.resourceFilterValue = null;
								});
							}}
							className="p-1 text-neutral-500 text-xs hover:bg-neutral-100"
						>
							<X size={14} />
						</button>
					</div>
				)}
				{!filteredResourcePath && !thread && (
					<div className="flex w-full items-center gap-2">
						<DropdownMenu>
							<DropdownMenuTrigger className="flex h-min w-min items-center gap-1 p-1 text-neutral-500 text-xs">
								<FunnelSimple size={16} className="text-neutral-500" />
								<span>Filter</span>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="start" className="w-fit p-0">
								<div className="flex w-42 flex-col p-2">
									<DropdownMenuSub>
										<DropdownMenuSubTrigger className="flex h-fit min-h-0 w-full items-center justify-start gap-2 px-2 py-1 text-neutral-800 text-sm">
											<File size={14} className="text-neutral-500" />
											<span className="truncate text-neutral-800">
												By Resource
											</span>
										</DropdownMenuSubTrigger>
										<DropdownMenuPortal>
											<ResourceFilterSubmenu />
										</DropdownMenuPortal>
									</DropdownMenuSub>
									<DropdownMenuSub>
										<DropdownMenuSubTrigger className="flex h-fit min-h-0 w-full items-center justify-start gap-2 px-2 py-1 text-neutral-800 text-sm">
											<Chat size={14} className="text-neutral-500" />
											<span className="truncate text-neutral-800">
												By Thread
											</span>
										</DropdownMenuSubTrigger>
										<DropdownMenuPortal>
											<ThreadFilterSubmenu />
										</DropdownMenuPortal>
									</DropdownMenuSub>
								</div>
							</DropdownMenuContent>
						</DropdownMenu>
						{activeResourcePath && (
							<button
								type="button"
								className=""
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									runInAction(() => {
										appContext.rightSidebarState.threadIdFilterValue = null;
										appContext.rightSidebarState.resourceFilterValue =
											activeTabResourceRef;
									});
								}}
							>
								<ObjectLinkContent
									href={activeResourcePath}
									className="rounded-sm border border-neutral-300 border-dashed p-0.5 text-neutral-500 text-xs"
								/>
							</button>
						)}
					</div>
				)}
			</div>
			<div className="flex min-h-0 grow flex-col gap-2 overflow-y-auto p-2">
				<Collapsible className="flex flex-col" defaultOpen={true}>
					<CollapsibleTrigger className="m-1 flex items-center gap-1 px-2 py-1 text-left font-semibold text-neutral-800 text-xs hover:bg-neutral-100">
						<StatusDot active={true} />
						<span className="font-semibold text-neutral-800 text-xs">
							Active
						</span>
						<span className="text-neutral-500 text-xs">
							{activeAssistantSessions.length}
						</span>
						<CaretDown size={14} className="[[data-state=open]_&]:rotate-180" />
					</CollapsibleTrigger>
					<CollapsibleContent className="mt-0">
						<div className="flex flex-col gap-3 p-1">
							{activeAssistantSessions.length > 0 ? (
								activeAssistantSessions.map((session) => (
									<SessionPreviewWithEvents
										key={session.sessionAssistant.session_assistant_id}
										assistantSession={session.sessionAssistant}
										eventsOrSteps={session.eventsOrSteps}
									/>
								))
							) : (
								<div className="border-neutral-100 px-2 py-1 text-neutral-500">
									No active assistants.
								</div>
							)}
						</div>
					</CollapsibleContent>
				</Collapsible>
				<Collapsible className="flex flex-col" defaultOpen={true}>
					<CollapsibleTrigger className="m-1 flex items-center gap-1 px-2 py-1 text-left font-semibold text-neutral-800 text-xs hover:bg-neutral-100">
						<StatusDot active={false} />
						<span>Completed</span>
						<CaretDown size={14} className="[[data-state=open]_&]:rotate-180" />
					</CollapsibleTrigger>
					<CollapsibleContent className="mt-0">
						<div className="flex flex-col gap-3 p-1">
							{completedAssistantSessions.length > 0 ? (
								completedAssistantSessions.map((session) => (
									<SessionPreviewWithEvents
										key={session.sessionAssistant.session_assistant_id}
										assistantSession={session.sessionAssistant}
										eventsOrSteps={session.eventsOrSteps}
									/>
								))
							) : (
								<div className="border-neutral-100 px-2 py-1 text-neutral-500">
									No completed assistants.
								</div>
							)}
						</div>
					</CollapsibleContent>
				</Collapsible>
			</div>
		</div>
	);
});

/**
 * Component that shows the activity of the assistant.
 */
export const AssistantActivityViewer = observer(() => {
	const appContext = useAppContext();
	if (
		appContext.rightSidebarState.activityViewerActiveSessionAssistantId === null
	) {
		return <TopLevelSessionsViewer />;
	}

	return (
		<SingleSessionViewer
			sessionAssistantId={
				appContext.rightSidebarState.activityViewerActiveSessionAssistantId
			}
		/>
	);
});
