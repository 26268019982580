import type { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import type { PageId, PageResource } from "@api/schemas";
import { File, House } from "@phosphor-icons/react";
import { makeAutoObservable } from "mobx";

export class PagesIndexTabState implements BaseTabState {
	tab: Tab;

	constructor(tab: Tab) {
		makeAutoObservable(this);
		this.tab = tab;
		this.tab.setState(this);
	}

	get head() {
		return {
			icon: File,
			label: "Pages",
		};
	}
}

export class PageTabState implements BaseTabState {
	tab: Tab;
	page: PageResource;

	constructor(tab: Tab, pageId: PageId) {
		makeAutoObservable(this);
		this.tab = tab;
		this.tab.setState(this);

		const pageResource = tab.tabStore.appState.workspace.pages.map.get(pageId);
		if (pageResource.isErr()) {
			throw new Error("Page not found");
		}
		this.page = pageResource.value;
	}

	get head() {
		const homePageId = this.tab.tabStore.appState.workspace.pages.homePageId;
		if (homePageId.isOk() && homePageId.value === this.page.page_id) {
			return {
				icon: House,
				label: this.page.name,
				resourceRef: {
					type: "page" as const,
					resource_id: this.page.page_id,
				},
			};
		}
		return {
			icon: File,
			label: this.page.name,
			resourceRef: {
				type: "page" as const,
				resource_id: this.page.page_id,
			},
		};
	}
}
