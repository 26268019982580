import { PDFViewer } from "@/components/pdf/pdfviewer";
import { API_ENDPOINT_HTTP } from "@/config";
import type { HighlightQuery } from "@/contexts/pdfviewer-context";
import { UploadTabState } from "@/contexts/uploads/tab-state";
import { getUploadPdf, getUploadToc } from "@api/fastAPI";
import { PageResolution, type UploadId } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

export const UploadPage = observer(() => {
	const { tabState } = Route.useLoaderData();
	const highlightQuery: HighlightQuery | null = tabState.highlightQuery;
	let highlights: HighlightQuery[] = [];
	let activeHighlight: HighlightQuery | undefined;
	if (highlightQuery) {
		highlights = [highlightQuery];
		activeHighlight = highlightQuery;
	}

	return (
		<PDFViewer
			resourceId={tabState.upload.upload_id}
			highlights={highlights}
			activeHighlight={activeHighlight}
			loader={async () => {
				const resp = await getUploadPdf(tabState.upload.upload_id, {
					responseType: "arraybuffer",
				});
				return new Uint8Array(resp.data as ArrayBuffer);
			}}
			tocLoader={async () => {
				const resp = await getUploadToc(tabState.upload.upload_id);
				return resp.data;
			}}
			headerMetadata={{
				title: tabState.upload.name,
				authors: tabState.upload.authors,
				description: tabState.upload.subtitle,
				url: tabState.upload.original_url,
			}}
			thumbnailUrl={(pageIndex: number) =>
				`${API_ENDPOINT_HTTP}/uploads/page_image/${tabState.upload.upload_id}/${pageIndex}/${PageResolution.thumbnail}.jpg`
			}
		/>
	);
});

export const Route = createFileRoute("/uploads/upload/$upload-id")({
	component: UploadPage,
	validateSearch: (
		search: Record<string, unknown>,
	): HighlightQuery | Record<string, unknown> => {
		// if no required fields, return null
		if (!search.start) {
			return {};
		}

		// TODO(Tae): Revisit this validation
		const highlightQuery: HighlightQuery = {
			textToHighlight: {
				textStart: String(search.start),
				...(search.end ? { textEnd: String(search.end) } : {}),
			},
			startPageIndex: search.startPageIndex ? Number(search.startPageIndex) : 0,
			endPageIndex: search.endPageIndex ? Number(search.endPageIndex) : 0,
		};

		return highlightQuery;
	},
	loaderDeps: ({ search }) => ({ search }),
	loader: ({ params, context: { tab }, deps }) => {
		const hasHighlight = "textToHighlight" in deps.search;

		const tabState = new UploadTabState(
			tab,
			params["upload-id"] as UploadId,
			// TODO(Tae): Revisit this
			hasHighlight ? (deps.search as HighlightQuery) : null,
		);
		return { tabState };
	},
});
