import { ThreadAssistantsPresenceIndicator } from "@/components/layout/right-sidebar/assistant-presence";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { ObjectLinkComponent } from "@/plugins/object-link";
import type { MessageId } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";

import { MessageTabState } from "@/contexts/messages/tab-state";
import { MessageWithMetadataComponent } from "@/pages/tabs/message/-components/message-component";
import { MessageInput } from "@/pages/tabs/message/-components/message-input";
import { ArrowLeft } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";

/**
 * A message with replies expanded; views a thread of messages.
 */
export const ThreadViewer = observer(function ThreadViewer({
	tabState,
}: {
	tabState: MessageTabState;
}) {
	const appContext = useAppContext();
	const messageId = tabState.parentMessage?.message_id;
	if (!messageId) {
		console.error("Tried to render ThreadViewer without a messageId");
		appContext.rightSidebarState.messageTab.router.navigate({
			href: "/message",
		});
		return null;
	}

	const thread = appContext.messageStore.getMessageByIdWithMetadata(messageId);
	return (
		<>
			<div className="relative flex min-h-0 w-full grow flex-col ">
				{/* Header */}
				<div className="flex w-full flex-none items-center justify-between border-neutral-100 border-b bg-white px-3 py-3">
					<div className="flex items-center gap-1">
						<ObjectLinkComponent
							href={
								thread.parent_message_id
									? `/message/${thread.parent_message_id}`
									: "/message"
							}
							className="h-min w-min items-start p-1"
						>
							<ArrowLeft size={16} />
						</ObjectLinkComponent>
						<span className="font-semibold text-neutral-800 text-sm">
							Thread
						</span>
					</div>
					{/* Presence Indicators */}
					<div className="flex h-full w-full items-center justify-end gap-4">
						<ThreadAssistantsPresenceIndicator messageId={messageId} />
					</div>
				</div>
				<div className="min-h-0 w-full grow overflow-y-auto p-2">
					{/* Main message */}
					<div className="flex w-full py-2">
						<MessageWithMetadataComponent message={thread} />
					</div>
					{/* Replies in thread */}
					{thread.replies.length > 0 ? (
						<>
							<div className="flex min-h-0 w-full items-center gap-2 px-4">
								<span className="text-neutral-500 text-xs">
									{thread.replies.length}{" "}
									{thread.replies.length === 1 ? "reply" : "replies"}
								</span>
								<div className="h-[1px] grow bg-neutral-300" />
							</div>
							<div className="flex flex-col gap-2 py-4">
								{thread.replies.map((reply) => (
									<MessageWithMetadataComponent
										key={reply.message_id}
										message={reply}
									/>
								))}
							</div>
						</>
					) : (
						<div className="flex w-full items-center justify-center p-4">
							<span className="text-neutral-500 text-sm">No replies yet.</span>
						</div>
					)}
				</div>
			</div>
			<MessageInput tabState={tabState} />
		</>
	);
});

export const Route = createFileRoute("/message/$message-id")({
	component: () => {
		const { tabState } = Route.useLoaderData();
		return <ThreadViewer tabState={tabState} />;
	},
	loader: ({ context: { tab }, params }) => {
		const tabState = new MessageTabState(
			tab,
			params["message-id"] as MessageId,
		);
		return {
			tabState,
		};
	},
});
